import { TextField } from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import tasks from "../../../../../../store/modules/tasks";
import Alert from "../../../../../System/Alert";
import Attention from "../../../../../System/Design/Animations/Attention";
import FormActions from "../../../../../System/FormActions";

const Reject = ({ open, setOpen, taskId }) => {
  const ref = useRef<any>();

  const history = useHistory();

  const [reason, setReason] = useState("");

  const [loading, setLoading] = useState(false);

  const submit = useCallback(async () => {
    if (!reason) {
      return;
    }

    setLoading(true);

    const result = await tasks.rejectTask(taskId, reason);

    if (result) {
      history.goBack();
    }

    setLoading(false);
  }, [history, reason, taskId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      ref?.current?.focus();
    }, 300);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  return (
    <Alert
      open={open}
      setOpen={setOpen}
      customTitle="Reject"
      content={
        <>
          <div className="flex justify-center mb-4">
            <Attention />
          </div>

          <TextField
            inputRef={ref}
            value={reason}
            label={<b>Description</b>}
            onChange={(e) => {
              setReason(e.target.value);
            }}
            multiline
            rows={7}
            required
            fullWidth
          />

          <FormActions
            showCloseButton
            showRejectButton
            loading={loading}
            disabledRejectButton={!reason || loading}
            onClickClose={() => {
              setOpen(false);
            }}
            onClickReject={submit}
          />
        </>
      }
      size="sm"
    />
  );
};

export default Reject;
