import { store } from "@risingstack/react-easy-state";
import ENDPOINTS from "../../endpoints";
import { get, showError } from "../api";
import auth from "./auth";
import { defaultParams } from "./tools/defaults";

interface IUsersV2 {
  loading: boolean;
  items: any[];
  pages: number;
  total: number;
  params: Record<string, any>;

  [key: string]: any;
}

const usersV2 = store<IUsersV2>({
  loading: false,
  items: [],
  pages: 1,
  total: 0,
  params: {
    ...defaultParams(),
    clientType: undefined,
    onBoardStatus: undefined,
    userState: "active",
    hidden: undefined,
    branchId: "",
    cities: undefined,
    countries: undefined,
    stateOrProvinces: undefined,
    from: undefined,
    to: undefined,
    showOnboardStat: undefined,
  },
  async fetchItems(customParams: Record<string, any>, exportCsv: boolean) {
    usersV2.loading = true;

    const params = {
      ...(customParams || {}),
    };

    if (customParams.pageIndex >= 0) {
      params.page = customParams.pageIndex + 1;
    }

    delete params.pageIndex;

    if (auth.profile && auth.profile?.isBranchAdmin) {
      params.branchId = auth.profile?.branch?.parentUserId;
    }

    try {
      const { data } = await get(
        ENDPOINTS.users.items,
        params,
        exportCsv ? "blob" : undefined,
        {
          Accept: exportCsv ? "text/csv" : "application/json",
        }
      );

      if (exportCsv) {
        const blob = data;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "all_clients.csv";
        link.click();
      } else {
        usersV2.items = data?.data || [];
        usersV2.pages = data?.pages || 1;
        usersV2.total = data?.total || 0;
      }

      usersV2.loading = false;

      return exportCsv ? true : data?.data || [];
    } catch (err) {
      usersV2.loading = false;
      showError(err);
      return false;
    }
  },
  async fetchItemsCustom(customParams: Record<string, any>) {
    usersV2.loading = true;

    const params = {
      ...(customParams || {}),
    };

    if (customParams.pageIndex >= 0) {
      params.page = customParams.pageIndex + 1;
    }

    delete params.pageIndex;

    if (auth.profile && auth.profile?.isBranchAdmin) {
      params.branchId = auth.profile?.branch?.parentUserId;
    }

    try {
      const res = await get(ENDPOINTS.users.items, params, undefined, {
        Accept: "application/json",
      });
      usersV2.loading = false;

      return res?.data?.data || [];
    } catch (err) {
      usersV2.loading = false;
      showError(err);
      return false;
    }
  },
  clearState() {
    usersV2.loading = false;
    usersV2.items = [];
    usersV2.pages = 1;
    usersV2.params = {
      pageIndex: 0,
      search: undefined,
      perPage: 20,
      sortBy: undefined,
      sortDirection: undefined,
      clientType: undefined,
      onBoardStatus: undefined,
      userState: "active",
      hidden: undefined,
      branchId: "",
      cities: undefined,
      countries: undefined,
      stateOrProvinces: undefined,
      from: undefined,
      to: undefined,
      showOnboardStat: undefined,
    };
  },
});

export default usersV2;
