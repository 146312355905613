import React, { memo } from "react";
import CustomRadioButtons from "../../../../../System/Components/CustomRadioButtons";

export default memo(({ filters, setFilters, enableModule }: any) => {
  return (
    <CustomRadioButtons
      label="Data type"
      value={filters.dataType}
      onChange={(e, value) => {
        setFilters((prevState) => ({
          ...prevState,
          dataType: value,
        }));
      }}
      items={[
        {
          label: "Sum ||",
          value: "Sum ||",
        },
        {
          label: "Sum",
          value: "Sum",
        },
        {
          label: "+/-",
          value: "+/-",
        },
        {
          label: "Count",
          value: "Count",
        },
        {
          label: "Average",
          value: "Average",
        },
      ].filter((item) => {
        if (!enableModule) {
          if (item.label !== "Sum ||") {
            return true;
          }
          return false;
        }

        return true;
      })}
      row
      className="!mr-4 !mb-2"
    />
  );
});
