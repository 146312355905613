import React, { memo } from "react";
import { UseControllerReturn } from "react-hook-form";

export default memo(
  ({ controller }: { controller: UseControllerReturn<any, any> }): any => {
    return (
      controller?.fieldState?.isTouched &&
      controller?.fieldState?.error?.message && (
        <p className="m-0 p-0 mt-1 text-sm font-bold text-red-500">
          {controller?.fieldState?.error?.message}
        </p>
      )
    );
  }
);
