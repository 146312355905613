import { store } from "@risingstack/react-easy-state";
import ENDPOINTS from "../../endpoints";
import { get, showError } from "../api";

const cryptoAdmin = store({
  transactions: [],
  transactionsPages: 1,
  transaction: {},
  openDetail: false,

  operations: [],
  operationsPages: 1,

  async fetchCryptoTransactions(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.fetchCryptoTransactions,
        params
      );

      cryptoAdmin.transactions = data?.data;
      cryptoAdmin.transactionsPages = data.pages;

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchCryptoOperations(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(ENDPOINTS.admin.fetchCryptoOperations, params);

      cryptoAdmin.operations = data?.data;
      cryptoAdmin.operationsPages = data.pages;

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchCryptoTransactionsCustom(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.fetchCryptoTransactions,
        params
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchCryptoOperationsCustom(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(ENDPOINTS.admin.fetchCryptoOperations, params);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchCryptoTransactionDetail(id: string) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.fetchCryptoTransactionDetail.replace("{id}", id)
      );

      cryptoAdmin.transaction = data;

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
});

export default cryptoAdmin;
