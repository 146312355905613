import { view } from "@risingstack/react-easy-state";
import React from "react";
import transactionDetails from "../../../../../../../store/modules/transactionDetails";
import { FormActions } from "../../../../../../System";
import DeleteOperationForm from "../DeleteOperationForm";

const DeleteOperation = view(() => {
  const { openDeleteOperation, loading } = transactionDetails;

  const openDialogDeleteOperation = () => {
    transactionDetails.openDeleteOperation = true;
  };
  return (
    <>
      {!loading && (
        <FormActions
          showRejectButton
          rejectButtonLabel="Delete Operation"
          onClickReject={openDialogDeleteOperation}
        />
      )}
      {openDeleteOperation && <DeleteOperationForm />}
    </>
  );
});

export default DeleteOperation;
