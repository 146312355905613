import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { view } from "@risingstack/react-easy-state";
import React from "react";
import ui from "../../store/modules/ui";

const NoRoles = view((): any => {
  return (
    ui.noRoles && (
      <Dialog open={ui.noRoles}>
        <DialogTitle>Access denied</DialogTitle>

        <DialogContent>
          <p>You are denied access, you do not have an appropriate role</p>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => (ui.noRoles = false)}
            color="primary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
});

export default NoRoles;
