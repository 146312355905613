import { TextField } from "@material-ui/core";
import React from "react";
import MyEditor from "../../../../../../System/MyEditor";

const TemplateEditor = ({ template = {}, setPayload, body, payload }: any) => {
  const onChangeSubject = (e) => {
    const { value } = e.target;

    setPayload((prevState) => {
      return { ...prevState, subject: value };
    });
  };

  console.log(payload);

  return (
    <>
      <div className="flex flex-row flex-wrap mb-2">
        <span className="mr-4">
          Name: <b>{template.name}</b>
        </span>
        <span className="mr-4">
          Type: <b>{template.depositMessageTypeId}</b>
        </span>
        <span className="mr-4">
          Currency: <b>{template.currencyId}</b>
        </span>
      </div>

      <div className="mb-2">
        <span>
          To: <b>{body?.Email}</b>
        </span>
      </div>

      <TextField
        label="Subject"
        defaultValue={payload?.subject || ""}
        onChange={onChangeSubject}
        style={{ marginBottom: "1rem" }}
        fullWidth
      />

      <MyEditor
        defaultValue={template.body}
        onChange={(value) =>
          setPayload((prevState) => {
            return { ...prevState, body: value };
          })
        }
      />

      <div className="h-14" />
    </>
  );
};

export default TemplateEditor;
