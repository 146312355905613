import { Button, DialogActions } from "@material-ui/core";
import numbro from "numbro";
import React, { memo, useState } from "react";
import { useWatch } from "react-hook-form";
import { formatToCurrencyString } from "../../../../../../helpers";
import { AreYouSure } from "../../../../../System";
import { Attention } from "../../../../../System/Design/Animations";
import GreenButton from "../components/GreenButton";

const Actions = memo(
  ({
    handleSubmit,
    preview,
    loading,
    control,
    setOpen,
    loadingCanSendSepa,
    isCanSendSepa,
    task,
  }: any) => {
    const [openWarning, setOpenWarning] = useState(false);

    const amount = useWatch({
      control: control,
      name: "amount",
    });

    const currencyId = useWatch({
      control: control,
      name: "currencyId",
    });

    const unformattedAmount = numbro.unformat(amount) || undefined;

    const feeType = useWatch({
      control: control,
      name: "feeType",
    });

    let onClick;

    if (loadingCanSendSepa) {
      onClick = null;
    } else if (task && task?.amount !== unformattedAmount) {
      onClick = () => setOpenWarning(true);
    } else {
      onClick = handleSubmit(preview);
    }

    return (
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(false)}
          disabled={loading}
        >
          Close
        </Button>

        <GreenButton
          onClick={onClick}
          label="Next"
          loading={loadingCanSendSepa || loading}
          disabled={
            loading ||
            loadingCanSendSepa ||
            (feeType?.value === "WDREU" && !isCanSendSepa)
          }
        />

        <AreYouSure
          open={openWarning}
          setOpen={setOpenWarning}
          customIcon={<Attention />}
          confirmCallback={() => {
            setOpenWarning(false);
            handleSubmit(preview)();
          }}
          loading={loading}
          disabledConfirmCallback={loading}
          customText={
            <div className="flex flex-col mt-4">
              <span>
                Client requested to withdraw:{" "}
                <b>{formatToCurrencyString(task?.amount, currencyId)}</b>
              </span>
              <span>
                You about to withdraw:{" "}
                <b>
                  {formatToCurrencyString(
                    unformattedAmount as number,
                    currencyId
                  )}
                </b>
              </span>
              <div className="h-2.5" />
              <span className="text-red-600">
                The amount shown on the notice is not equal to the amount shown
                on the form. Are you sure you want to continue?
              </span>
            </div>
          }
        />
      </DialogActions>
    );
  }
);

export default Actions;
