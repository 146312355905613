import { ArrowUpward } from "@material-ui/icons";
import { view } from "@risingstack/react-easy-state";
import { useSnackbar } from "notistack";
import numbro from "numbro";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { formatToCurrencyString } from "../../../../../../helpers";
import { customSortArrayLabel } from "../../../../../../helpers/customSortArray";
import { formatString } from "../../../../../../helpers/formatString";
import { getTaskTypeBooleans } from "../../../../../../helpers/task";
import ITask from "../../../../../../interfaces/task";
import IWireRecipient from "../../../../../../interfaces/wireRecipient";
import admin from "../../../../../../store/modules/admin";
import auth from "../../../../../../store/modules/auth";
import notifications from "../../../../../../store/modules/notifications";
import { Alert, AreYouSure } from "../../../../../System";
import MyAmount from "../../../../../System/FormComponents/MyAmount";
import MySimpleMenu from "../../../../../System/FormComponents/MySimpleMenu";
import ShowAndHide from "../../../../../System/FormComponents/ShowAndHide";
import LazyImage from "../../../../../System/LazyImage";
import { makeWithdrawFeeTypes } from "../../../../Admin/SmartForms/Withdraw/tools";
import AdvancedOptions from "../components/AdvancedOptions";
import AdvancedOptionsPart2 from "../components/AdvancedOptionsPart2";
import Amount from "../components/Amount";
import Comment from "../components/Comment";
import FinciDetails from "../components/FinciDetails";
import FixedOurCost from "../components/FixedOurCost";
import InitFee from "../components/InitFee";
import MessageToRecipient from "../components/MessageToRecipient";
import SepaBibDetails from "../components/SepaBibDetails";
import SepaDetail from "../components/SepaDetail";
import ShowAdvancedOptions from "../components/ShowAdvancedOptions";
import SwiftDetail from "../components/SwiftDetail";
import Title from "../components/Title";
import TitleContent from "../components/TitleContent";
import Actions from "./Actions";
import WrapPassword from "./WrapPassword";
import WrapPreview from "./WrapPreview";

const WrapFeeType = ({ form, account }) => {
  const options = makeWithdrawFeeTypes(account).sort(customSortArrayLabel);

  return (
    <MySimpleMenu
      {...form}
      name="feeType"
      renderOption={(option) => option.label}
      label="Fee type"
      options={options}
      fullWidth
    />
  );
};

const makeDefaultFeeType = (account, type, taskTypeName) => {
  let feeTypeDefault;

  const defaultFeeTypes = makeWithdrawFeeTypes(account);

  if (account?.currencyId === "USD" && taskTypeName.includes("ACH")) {
    feeTypeDefault = defaultFeeTypes[0];
  } else if (account?.currencyId === "USD" && taskTypeName.includes("Inter")) {
    feeTypeDefault = defaultFeeTypes[2];
  } else if (account?.currencyId === "EUR" && type === "SEPA") {
    feeTypeDefault = defaultFeeTypes[0];
  } else if (account?.currencyId === "EUR" && type === "SWIFT") {
    feeTypeDefault = defaultFeeTypes[1];
  } else {
    feeTypeDefault = defaultFeeTypes[0];
  }

  return feeTypeDefault || "";
};

const SendWire = view(
  ({
    open,
    setOpen,
    refresh,
    recipient,
    task,
    taskConverted,
  }: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    refresh: any;
    recipient?: IWireRecipient;
    task?: ITask;
    taskConverted?: boolean;
  }) => {
    const history = useHistory();

    const { enqueueSnackbar } = useSnackbar();

    const [, setError] = useState("");
    const [openAreYouSure, setOpenAreYouSure] = useState(false);
    const [loadingCanSendSepa, setLoadingCanSendSepa] = useState(false);
    const [isCanSendSepa, setIsCanSendSepa] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hideConfirmButtons, setHideConfirmButtons] = useState(false);
    const [previewData, setPreviewData] = useState<any>();
    const [step, setStep] = useState(1);

    const currencyId = task?.currencyId;

    const taskTypeBooleans = getTaskTypeBooleans(task);

    const defaultValues = useMemo(
      () => ({
        currencyId: "",
        amount: task?.amount || "",
        feeType: "",
        comment: "Credit Counterparty:",
        fixedOurCost: "",
        messageToRecipient: "",
        percentageOurCost: "",
        usePercentage: true,
        showAdvancedOptions: false,
        fixedFee: "",
        transactionTime: null,
        feeStructure: null,
        feeRule: null,
        isPendingTransaction: false,
        feeInsideAmount: true,
        allowNegativeBalance: false,
        useSepa: false,
        useSwift: false,
        useFinci: false,
        useSepaBibPayment: false,
        iDontWantToUseSEPA: false,
      }),
      [task?.amount]
    );

    const form = useForm({
      defaultValues: defaultValues,
      shouldUnregister: false,
      mode: "onChange",
    });

    const { register, getValues, handleSubmit, setValue } = form;

    const setDefaultType = useCallback(async () => {
      const defaultFeeType = await makeDefaultFeeType(
        task?.fromAccount,
        taskTypeBooleans.isSEPA ? "SEPA" : "SWIFT",
        task?.taskTypeName
      );

      if (defaultFeeType?.value && task?.fromAccount && open) {
        setTimeout(() => setValue("feeType", defaultFeeType.value), 0);
      }
    }, [
      open,
      setValue,
      task?.fromAccount,
      task?.taskTypeName,
      taskTypeBooleans.isSEPA,
    ]);

    useEffect(() => {
      setDefaultType();
    }, [setDefaultType]);

    useEffect(() => {
      if (step === 1) {
        setTimeout(() => setValue("useSepa", false), 0);
        setTimeout(() => setValue("useSwift", false), 0);
      }
    }, [setValue, step]);

    const feeType = useWatch({
      control: form?.control,
      name: "feeType",
    });

    const canSendSepa = useCallback(async () => {
      if (feeType !== "WDREU") {
        setError("");
        return;
      }

      setLoadingCanSendSepa(true);

      const [status, data] = await admin.canSendSepaAndError({
        ibanFrom: formatString(task?.fromAccount?.number as string),
        ibanTo: formatString(recipient?.recipientAccountNumber as string),
        currencyId: currencyId,
      });

      if (status) {
        setIsCanSendSepa(true);
      } else {
        setIsCanSendSepa(false);
        setError(data);
      }

      setLoadingCanSendSepa(false);
    }, [
      task?.fromAccount?.number,
      currencyId,
      feeType,
      recipient?.recipientAccountNumber,
    ]);

    useEffect(() => {
      if (task?.taskId) {
        canSendSepa();
      }
    }, [canSendSepa, task?.taskId]);

    useEffect(() => {
      if (!open) {
        /* reset */
        setStep(1);
        setHideConfirmButtons(false);
        setPreviewData(undefined);
      }
    }, [currencyId, open]);

    useEffect(() => {
      register("currencyId");
    }, [register]);

    useEffect(() => {
      if (open && currencyId) {
        setTimeout(() => setValue("currencyId", currencyId), 0);
      }
    }, [currencyId, open, setValue]);

    useEffect(() => {
      if (task?.messageToRecipient) {
        setTimeout(
          () => setValue("messageToRecipient", task?.messageToRecipient || ""),
          0
        );
      }
    }, [task?.messageToRecipient, setValue]);

    useEffect(() => {
      let message;

      if (!task?.messageToRecipient && recipient?.recipientName) {
        setTimeout(
          () =>
            setValue("messageToRecipient", `Cred. ${recipient?.recipientName}`),
          0
        );
      }

      if (
        recipient?.recipientName &&
        !task?.messageToRecipient &&
        !task?.memo
      ) {
        message = `Cred. ${recipient?.recipientName}`;
      }

      if (recipient?.recipientName && task?.messageToRecipient && !task?.memo) {
        message = `Cred. ${recipient?.recipientName} | ${task?.messageToRecipient}`;
      }

      if (recipient?.recipientName && !task?.messageToRecipient && task?.memo) {
        message = `Cred. ${recipient?.recipientName} (${task?.memo})`;
      }

      if (recipient?.recipientName && task?.messageToRecipient && task?.memo) {
        message = `Cred. ${recipient?.recipientName} | ${task?.messageToRecipient} (${task?.memo})`;
      }

      setTimeout(() => setValue("comment", message), 0);
    }, [
      task?.messageToRecipient,
      recipient?.recipientName,
      setValue,
      task?.memo,
    ]);

    const preview = async (values) => {
      setLoading(true);

      const payload: any = {
        isPending: values.isPendingTransaction,
        allowNegativeBalance: values.allowNegativeBalance,
        description: `Withdraw ${values.amount} ${currencyId} from ${task?.firstUser?.name}, ${task?.fromAccount?.number}, ${values.comment}`,
        operations: [],
        pendingTransactionId: task?.taskId,
        wireRecipientId: recipient?.recipientId,
        messageToRecipient: values.messageToRecipient,
      };

      if (values.transactionTime) {
        payload.transactionTime = new Date(
          values.transactionTime
        ).toISOString();
      }

      const a = numbro.unformat(values.amount) || 0;
      const p = numbro.unformat(values.percentageOurCost) || 0;
      const f = numbro.unformat(values.fixedOurCost) || 0;

      let ourCost = f;

      if (values.usePercentage) {
        ourCost = numbro.unformat((ourCost + (a / 100) * p).toFixed(2)) || 0;
      }

      const minus: any = {
        ourCost: ourCost,
        accountId: task?.fromAccount?.accountId,
        currencyId: currencyId,
        amount: -a,
        comment: values.comment,
      };

      minus.fee = {};

      minus.fee.titleId = values.feeType;
      minus.fee.changeAmount = values.feeInsideAmount;
      minus.fee.miscFee = values.fixedFee
        ? numbro.unformat(values.fixedFee)
        : undefined;

      if (values?.feeType?.includes("NO_FEE")) {
        minus.fee.titleId = values.feeType.split("NO_FEE_")[1];
      }

      if (values.feeRule?.ruleId) {
        minus.fee.ruleId = values.feeRule.ruleId;
      }

      if (values.feeStructure?.structureId) {
        minus.fee.structureId = values.feeStructure.structureId;
      }

      payload.operations.push(minus);

      const [status, result] = await admin.preview(payload);

      if (status) {
        setPreviewData(result);

        if (result?.sepaPayment) {
          setValue("useSepa", true);
        }

        if (result?.sepaFinciPayment) {
          setValue("useFinci", true);
        }

        if (result?.swiftPayment && !taskConverted) {
          setValue("useSwift", true);
        }

        if (result?.sepaBibPayment) {
          setValue("useSepaBibPayment", true);
        }

        setStep(2);
      }

      setLoading(false);
    };

    const confirm = async () => {
      setOpenAreYouSure(false);

      setLoading(true);

      const values = getValues();

      if (!values.useFinci && previewData?.sepaFinciPayment) {
        delete previewData.sepaFinciPayment;
      }

      if (!values.useSepa && previewData?.sepaPayment) {
        delete previewData.sepaPayment;
      }

      if (!values.useSwift && previewData?.swiftPayment) {
        delete previewData.swiftPayment;
      }

      if (!values.useSepaBibPayment && previewData?.sepaBibPayment) {
        delete previewData.sepaBibPayment;
      }

      const result = await admin.confirm(previewData);

      if (result) {
        if (previewData?.sepaPayment) {
          notifications.paymentResponse = result?.paymentResponse;
        }

        if (previewData?.swiftPayment) {
          notifications.paymentResponse2 = result?.paymentResponse2;
        }

        setHideConfirmButtons(true);

        enqueueSnackbar("Operation completed successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });

        if (!!auth?.profile && auth?.profile?.isAdmin) {
          history.goBack();
        } else {
          if (refresh) {
            refresh();
          }
        }
      }

      setLoading(false);
    };

    const sepa = previewData?.sepaPayment || {};

    const sepaBibPayment = previewData?.sepaBibPayment || {};

    const swift = previewData?.swiftPayment || {};

    const useSepa = useWatch({
      control: form.control,
      name: "useSepa",
    });

    const useSwift = useWatch({
      control: form.control,
      name: "useSwift",
    });

    const useSepaBibPayment = useWatch({
      control: form.control,
      name: "useSepaBibPayment",
    });

    const useFinci = useWatch({
      control: form.control,
      name: "useFinci",
    });

    let calculatorType: any = "WithdrawSWIFT";

    if (feeType === "WDREU") {
      calculatorType = "WithdrawSEPA";
    } else if (feeType === "WDRNEU") {
      calculatorType = "WithdrawSWIFT";
    } else {
      calculatorType = null;
    }

    return (
      <Alert
        open={open}
        disabledClose={loading}
        hideCloseBtn={loading}
        setOpen={setOpen}
        size={step === 1 ? "sm" : "md"}
        content={
          <div>
            <InitFee
              form={form}
              open={open}
              task={task}
              calculatorType={calculatorType}
            />

            <Title
              icon={
                <ArrowUpward style={{ color: "inherit", fontSize: "2.4rem" }} />
              }
              text={<TitleContent title="Withdraw" task={task} />}
            />

            <div className="h-4" />

            <ShowAndHide show={step === 1}>
              <Amount {...form} autoFocus />

              <div className="h-2.5" />

              <WrapFeeType form={form} account={task?.fromAccount} />

              <div className="h-2.5" />

              <MessageToRecipient {...form} />

              <div className="h-2.5" />

              <Comment {...form} />

              <div className="h-2.5" />

              <MyAmount
                control={form.control}
                controlCurrency={form.control}
                name="fixedFee"
                label="Fixed Fee (the client pays us)"
                currencyName="currencyId"
              />

              <div className="h-2.5" />

              <FixedOurCost {...form} />

              <ShowAdvancedOptions {...form} />
              <AdvancedOptions {...form} />
              <AdvancedOptionsPart2 {...form} />

              <div className="h-2.5" />

              <WrapPassword form={form} />

              <Actions
                {...form}
                preview={preview}
                loading={loading}
                setOpen={setOpen}
                task={task}
                loadingCanSendSepa={loadingCanSendSepa}
                isCanSendSepa={isCanSendSepa}
              />
            </ShowAndHide>

            <ShowAndHide show={step === 2}>
              <WrapPreview
                data={previewData}
                setStep={setStep}
                preview={handleSubmit(preview)}
                hideConfirmButtons={hideConfirmButtons}
                loading={loading}
                setOpen={setOpen}
                form={form}
                setOpenAreYouSure={setOpenAreYouSure}
                task={task}
              />
            </ShowAndHide>

            <AreYouSure
              open={openAreYouSure && useSepa}
              customIcon={
                <LazyImage
                  src="/images/design/transactive.png"
                  className="w-40"
                />
              }
              setOpen={setOpenAreYouSure}
              confirmCallback={confirm}
              loading={loading}
              confirmLabel="Make Payment"
              disabledConfirmCallback={loading}
              customText={<SepaDetail showDetail sepa={sepa} />}
              hideCloseButton
            />

            <AreYouSure
              open={openAreYouSure && useSepaBibPayment}
              customIcon={
                <LazyImage src="/images/design/sepa_bib.svg" className="w-40" />
              }
              setOpen={setOpenAreYouSure}
              confirmCallback={confirm}
              loading={loading}
              confirmLabel="Make Payment"
              disabledConfirmCallback={loading}
              customText={<SepaBibDetails showDetail sepa={sepaBibPayment} />}
              hideCloseButton
            />

            <AreYouSure
              open={openAreYouSure && useFinci}
              customIcon={
                <LazyImage src="/images/finci.png" className="w-40" />
              }
              setOpen={setOpenAreYouSure}
              confirmCallback={confirm}
              loading={loading}
              confirmLabel="Make Payment"
              disabledConfirmCallback={loading}
              customText={
                <FinciDetails
                  finci={previewData?.sepaFinciPayment}
                  task={task}
                />
              }
              hideCloseButton
            />

            <AreYouSure
              open={openAreYouSure && useSwift}
              customIcon={
                <LazyImage src="/images/design/swift.svg" className="w-40" />
              }
              setOpen={setOpenAreYouSure}
              confirmCallback={confirm}
              loading={loading}
              confirmLabel="Make Payment"
              disabledConfirmCallback={loading}
              customText={
                <div>
                  <h2 className="mb-2">
                    Ready to pay{" "}
                    {formatToCurrencyString(
                      swift?.paymentAmount?.amount || 0,
                      swift?.paymentAmount?.currency
                    )}
                  </h2>

                  <SwiftDetail showDetail swift={swift} />
                </div>
              }
              hideCloseButton
            />

            <AreYouSure
              open={
                openAreYouSure &&
                !useSepa &&
                !useSwift &&
                !useSepaBibPayment &&
                !useFinci
              }
              setOpen={setOpenAreYouSure}
              confirmCallback={confirm}
              loading={loading}
              disabledConfirmCallback={loading}
              confirmLabel="Make Payment"
              hideCloseButton
            />
          </div>
        }
      />
    );
  }
);

export default SendWire;
