import { useEffect, useRef } from "react";
import { useGetAccounts } from "./useGetAccounts";

export const useInitAccounts = (form, currentAccount, toAccountId) => {
  const isInit = useRef(false);

  const { options } = useGetAccounts({
    userId: currentAccount?.userId,
    form,
  });

  const { setValue } = form;

  useEffect(() => {
    if (!isInit.current && options && options?.length !== 0) {
      isInit.current = true;

      const toAccount = toAccountId
        ? options.find((item) => {
            return (
              item.accountId === toAccountId && item.accountStateId === "Open"
            );
          })
        : options.find((item) => {
            return (
              item.currencyId !== currentAccount?.currencyId &&
              item.accountStateId === "Open"
            );
          });

      setTimeout(() => setValue("fromAccountId", currentAccount?.accountId), 0);

      setTimeout(() => setValue("toAccountId", toAccount?.accountId), 0);

      setTimeout(
        () => setValue("sellCurrencyId", currentAccount?.currencyId),
        0
      );
      setTimeout(() => setValue("buyCurrencyId", toAccount?.currencyId), 0);
    }
  }, [options, setValue, currentAccount, toAccountId]);
};
