import { useCallback } from "react";
import { useWatch } from "react-hook-form";
import IAccount from "../../../../../../interfaces/account";
import { useQueryClientAccounts } from "../../Exchange/queries/useQueryClientAccounts";

export const useGetAccounts = ({ form, account }) => {
  const toUser = useWatch({
    control: form.control,
    name: "toUser",
  });

  const queryResult = useQueryClientAccounts(toUser?.userId);

  const options = queryResult?.data?.filter(
    (a) =>
      a.accountTypeId === account.accountTypeId &&
      a.currencyId === account.currencyId &&
      a.accountId !== account.accountId
  ) as IAccount[];

  const getToAccount = useCallback(
    (accountId) => {
      return queryResult?.data?.find((o) => o.accountId === accountId);
    },
    [queryResult?.data]
  );

  return { options, getToAccount, isLoading: queryResult.isLoading, toUser };
};
