import { FormHelperText } from "@material-ui/core";
import { view } from "@risingstack/react-easy-state";
import React from "react";
import { formatToCurrencyString } from "../../../../../../helpers";
import { customSortArrayCurrency } from "../../../../../../helpers/customSortArray";
import auth from "../../../../../../store/modules/auth";
import MySimpleMenu from "../../../../../System/FormComponents/MySimpleMenu";
import { useGetAccounts } from "../hooks/useGetAccounts";

const AutocompleteToAccount = view(({ form, currentAccount, setRate }) => {
  const { fromAccount, options } = useGetAccounts({
    userId: currentAccount?.userId,
    form,
  });

  const makeOptionString = (option) => {
    return option?.currencyId
      ? `${option?.currencyId || ""} ${formatToCurrencyString(
          option?.availableBalance,
          option?.currencyId
        )} `
      : "";
  };

  return (
    <div className="w-full">
      <MySimpleMenu
        {...form}
        name="toAccountId"
        disabled={!!auth.profile && !auth.profile?.isAdmin}
        rules={{ required: true }}
        fullWidth
        label="Account"
        onClick={(option) => {
          setRate(0);
          setTimeout(
            () => form.setValue("buyCurrencyId", option.currencyId),
            0
          );
        }}
        renderOption={makeOptionString}
        options={options
          .filter((item) => item.currencyId !== fromAccount?.currencyId)
          ?.sort(customSortArrayCurrency)
          .map((item) => ({ ...item, value: item.accountId }))}
      />

      {form?.formState?.errors?.toAccount?.type === "required" && (
        <FormHelperText error>Field is required</FormHelperText>
      )}
    </div>
  );
});

export default AutocompleteToAccount;
