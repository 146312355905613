import { Button, DialogActions } from "@material-ui/core";
import { green, grey, red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { CloudUpload } from "@material-ui/icons";
import { CSSProperties } from "@material-ui/styles";
import { clsx } from "clsx";
import React, { memo } from "react";
import LoadingCircular from "./../LoadingCircular";

const useStyles = makeStyles((theme) => {
  return {
    btn: {
      color: theme.palette.primary.contrastText,
      fontSize: "1rem",
      height: "auto",
      whiteSpace: "nowrap",
    },
    close: {
      backgroundColor: theme.palette.primary.main,
    },
    back: {
      backgroundColor: theme.palette.primary.main,
    },
    next: {
      backgroundColor: theme.palette.primary.main,
    },
    upload: {
      backgroundColor: theme.palette.primary.main,
    },
    confirm: {
      backgroundColor: green[600],
    },
    delete: {
      backgroundColor: red[600],
    },
    reject: {
      backgroundColor: red[600],
    },
  };
});

interface ICustomAction {
  show?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  style?: any;
  label?: string;
  loading?: boolean;
  id?: string;
}

interface IFormActions {
  backButtonLabel?: string;
  closeButtonLabel?: string;
  confirmButtonLabel?: string;
  nextButtonLabel?: string;
  deleteButtonLabel?: string;
  uploadButtonLabel?: string;
  rejectButtonLabel?: string;
  type?: "button" | "reset" | "submit";
  disabledNextButton?: boolean;
  disabledConfirmButton?: boolean;
  disabledDeleteButton?: boolean;
  disabledRejectButton?: boolean;
  loading?: boolean;
  onClickBack?: any;
  onClickClose?: any;
  onClickConfirm?: any;
  onClickNext?: any;
  onClickDelete?: any;
  onClickUpload?: any;
  onClickReject?: any;
  showBackButton?: boolean;
  showCloseButton?: boolean;
  showConfirmButton?: boolean;
  showNextButton?: boolean;
  showDeleteButton?: boolean;
  showUploadButton?: boolean;
  showRejectButton?: boolean;
  refActions?: React.Ref<undefined>;
  style?: CSSProperties;
  styleButton?: CSSProperties;
  idCloseButton?: string;
  idConfirmButton?: string;
  idBackButton?: string;
  customActions?: (ICustomAction | undefined)[];
  idRejectButton?: string;
  idNextButton?: string;
}

const FormActions = memo(
  ({
    backButtonLabel = "",
    closeButtonLabel = "",
    confirmButtonLabel = "",
    nextButtonLabel = "",
    deleteButtonLabel = "",
    uploadButtonLabel = "",
    rejectButtonLabel = "",
    type,
    disabledNextButton = false,
    disabledConfirmButton = false,
    disabledDeleteButton = false,
    disabledRejectButton = false,
    loading = false,
    onClickBack,
    onClickClose,
    onClickConfirm,
    onClickNext,
    onClickDelete,
    onClickUpload,
    onClickReject,
    showBackButton = false,
    showCloseButton = false,
    showConfirmButton = false,
    showNextButton = false,
    showDeleteButton = false,
    showUploadButton = false,
    showRejectButton = false,
    refActions,
    style,
    styleButton,
    customActions,
  }: IFormActions) => {
    const classes = useStyles();

    let nextButtonStyles = {};

    if (disabledNextButton || disabledConfirmButton || disabledDeleteButton) {
      nextButtonStyles = {
        backgroundColor: grey[300],
      };
    }

    const disabledStyles: CSSProperties = {
      ...nextButtonStyles,
      ...styleButton,
    };

    const customActionsMap = (customActions || []).map((item, index) => {
      const c: CSSProperties = {};

      const s1: CSSProperties = {
        ...(item?.style || c),
        ...(disabledStyles || c),
      } as CSSProperties;

      const s2: CSSProperties = {
        ...(item?.style || c),
        ...(styleButton || c),
      } as CSSProperties;

      const style: CSSProperties = item?.disabled ? s1 : s2;

      return (item?.show as boolean) ? (
        <Button
          key={index}
          variant="contained"
          color="primary"
          fullWidth
          className={clsx(classes.btn, classes.back)}
          onClick={item?.onClick}
          disabled={item?.disabled as boolean}
          style={style}
        >
          {item?.label}
          <LoadingCircular loading={Boolean(item?.loading)} />
        </Button>
      ) : null;
    });

    return (
      <DialogActions ref={refActions} style={style}>
        {customActionsMap}
        {showCloseButton && (
          <Button
            id="closeButton"
            variant="contained"
            color="primary"
            fullWidth
            type="button"
            className={clsx(classes.btn, classes.close)}
            onClick={onClickClose}
            style={styleButton}
          >
            {closeButtonLabel || "Close"}
          </Button>
        )}

        {showBackButton && (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={clsx(classes.btn, classes.back)}
            onClick={onClickBack}
            style={styleButton}
          >
            {backButtonLabel || "Back"}
          </Button>
        )}

        {showDeleteButton && (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={clsx(classes.btn, classes.delete)}
            disabled={disabledDeleteButton}
            onClick={onClickDelete}
            type={type}
            style={disabledStyles}
          >
            {deleteButtonLabel || "Delete"}
            <LoadingCircular loading={loading} />
          </Button>
        )}

        {showRejectButton && (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={clsx(classes.btn, classes.reject)}
            onClick={onClickReject}
            disabled={disabledRejectButton}
            type={type}
            style={styleButton}
          >
            {rejectButtonLabel || "Reject"}
            <LoadingCircular loading={loading} />
          </Button>
        )}

        {showNextButton && (
          <Button
            id="nextButton"
            variant="contained"
            color="primary"
            fullWidth
            className={clsx(classes.btn, classes.next)}
            disabled={disabledNextButton}
            onClick={onClickNext}
            type={type}
            style={disabledStyles}
          >
            {nextButtonLabel || "Next"}
            <LoadingCircular loading={loading} />
          </Button>
        )}
        {showUploadButton && (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={clsx(classes.btn, classes.upload)}
            onClick={onClickUpload}
            style={styleButton}
            type={type}
          >
            {uploadButtonLabel || "Upload"}
            <CloudUpload style={{ marginLeft: 5 }} />
          </Button>
        )}

        {showConfirmButton && (
          <Button
            id="confirmButton"
            variant="contained"
            color="primary"
            fullWidth
            className={clsx(classes.btn, classes.confirm)}
            disabled={disabledConfirmButton}
            onClick={onClickConfirm}
            type={type}
            style={disabledStyles}
          >
            {confirmButtonLabel || "Confirm"}
            <LoadingCircular loading={loading} />
          </Button>
        )}
      </DialogActions>
    );
  }
);

export default FormActions;
