import Cleave from "cleave.js/react";
import React, { useEffect, useState } from "react";

const Input = ({ inputRef, isCrypto, decimal, ...props }: any) => {
  const [rerender, setRerender] = useState(0);

  useEffect(() => {
    setRerender((prevState) => prevState + 1);
  }, [isCrypto]);

  const options = {
    numeral: true,
    numeralDecimalScale: isCrypto ? 8 : 2,
  };

  if (decimal !== undefined) {
    options.numeralDecimalScale = decimal;
  }

  return (
    <Cleave
      key={rerender}
      {...props}
      htmlRef={inputRef}
      options={{ ...options }}
    />
  );
};

export default Input;
