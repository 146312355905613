import { store } from "@risingstack/react-easy-state";
import { CancelTokenSource } from "axios";
import ENDPOINTS from "../../endpoints";
import { makeParams } from "../../helpers/makeParams";
import { get, post, showError } from "../api";
import auth from "./auth";
import { defaultParams } from "./tools/defaults";

interface ITasks {
  params: Record<string, any>;
  pushingState: any[];
  initTable: boolean;
  cancelToken?: CancelTokenSource;
  counts: any[];
  showCurrencies: boolean;
  showStates: boolean;
  showFilters: boolean;
  items: any[];
  loading: boolean;
  pages: number;

  [key: string]: any;
}

const tasks = store<ITasks>({
  params: {
    ...defaultParams({ perPage: 20 }),
    page: 1,
    taskTypes: undefined,
    taskStates: undefined,
    currencies: undefined,
    lessThan: undefined,
    moreThan: undefined,
    from: undefined,
    to: undefined,
    dateType: "None",
  },
  pushingState: [],
  initTable: false,
  cancelToken: undefined,
  counts: [],
  showCurrencies: true,
  showStates: false,
  showFilters: false,
  items: [],
  loading: true,
  pages: 1,
  setParams(newParams = {}) {
    tasks.params = {
      ...tasks.params,
      ...newParams,
    };
  },
  makeDefaultParams(pathname: string, p: Record<string, any>) {
    const isActivity = pathname.includes("/activity");
    const isInbox = pathname.includes("/inbox");
    const isWire = pathname.includes("/wire");
    const isDeposit = pathname.includes("/deposit");
    const isTransfer = pathname.includes("/transfer");
    const isCrypto = pathname.includes("/crypto");
    const isSecureMessages = pathname.includes("/secure-messages");
    const isRequestsAboutLostPayments = pathname.includes("/requests-about");

    const newParams = {
      ...p,
    };

    if (
      isActivity &&
      !tasks?.params?.taskStates?.includes("Submitted") &&
      !tasks?.params?.taskStates?.includes("Approved") &&
      !tasks?.params?.taskStates?.includes("Closed") &&
      !tasks?.params?.taskStates?.includes("Rejected") &&
      !tasks?.params?.taskStates?.includes("Canceled")
    ) {
      newParams.taskStates = undefined;
    }

    const taskStatesArr = tasks?.params?.taskStates?.split(",") || [];

    if (isRequestsAboutLostPayments) {
      newParams.taskTypes = undefined;

      if (isInbox) {
        newParams.taskStates = "Message";
      }
    } else if (isSecureMessages) {
      newParams.taskTypes = "SecureMessage";
    } else if (isWire) {
      if (
        !tasks?.params?.taskTypes?.includes("SEPA") &&
        !tasks?.params?.taskTypes?.includes("SWIFT")
      ) {
        newParams.taskTypes = "SEPA_wire,SWIFT_wire";
      }

      if (isInbox) {
        newParams.taskStates = "Pending";
      }
    } else if (isDeposit) {
      newParams.taskTypes = "Deposit";

      if (
        isInbox &&
        (!tasks.params?.taskTypes ||
          !tasks.params?.taskTypes?.includes("Deposit")) &&
        taskStatesArr?.some((i: string) => i === "Pending")
      ) {
        newParams.taskStates = undefined;
      }
    } else if (isCrypto) {
      newParams.taskTypes = "Crypto_withdraw";

      if (isInbox) {
        newParams.taskStates = "Pending";
      }
    } else if (isTransfer) {
      newParams.taskTypes = "Transfer";

      if (isInbox) {
        newParams.taskStates = "Pending";
      }
    } else {
      newParams.taskTypes = undefined;
    }

    if (!newParams.taskStates) {
      newParams.taskStates = undefined;
    }

    if (!tasks?.params?.sortBy?.length) {
      newParams.sortBy = [{ id: "ts", desc: true }];
    }

    tasks.setParams(newParams);
  },
  resetParams() {
    const params = {
      ...tasks.params,
      page: 1,
      perPage: 20,
      pageIndex: 0,
      search: "",

      taskStates: undefined,
      currencies: undefined,
      lessThan: undefined,
      moreThan: undefined,

      from: undefined,
      to: undefined,
      dateType: "Last 7 Days",
    };

    tasks.makeDefaultParams(window.location.pathname, params);
  },

  async fetchTask(taskId: string) {
    try {
      const { data } = await get(
        ENDPOINTS.tasks.fetchTask.replace("{taskId}", taskId)
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchTaskClient(taskId: string) {
    try {
      const { data } = await get(
        ENDPOINTS.tasks.fetchTaskClient.replace("{taskId}", taskId)
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  setCancelToken(value: CancelTokenSource) {
    tasks.cancelToken = value;
  },
  async fetchActivity() {
    try {
      tasks.loading = true;

      tasks.items = [];

      const params = makeParams({}, tasks.params);
      tasks.items = [];

      const { data } = await get(
        ENDPOINTS.tasks.activity,
        params,
        "json",
        {},
        tasks.cancelToken,
        tasks.setCancelToken
      );

      tasks.items = data?.data || [];
      tasks.pages = data?.pages || 1;
      tasks.loading = false;
      return data;
    } catch (err) {
      showError(err);

      tasks.loading = false;
      return false;
    }
  },

  async fetchActivityCustom(params) {
    try {
      tasks.loading = true;

      const { data } = await get(ENDPOINTS.tasks.activity, params);

      return [true, data];
    } catch (err) {
      showError(err);
      return [false, err];
    }
  },
  async fetchActivityClientByAdmin(userId, params) {
    try {
      tasks.loading = true;

      const { data } = await get(
        ENDPOINTS.tasks.fetchActivityClientByAdmin.replace("{userId}", userId),
        params
      );

      return [true, data];
    } catch (err) {
      showError(err);
      return [false, err];
    }
  },
  async fetchInbox() {
    try {
      tasks.loading = true;

      const params = makeParams({}, tasks.params);

      tasks.items = [];

      const { data } = await get(
        ENDPOINTS.tasks.inbox,
        params,
        "json",
        {},
        tasks.cancelToken,
        tasks.setCancelToken
      );

      tasks.items = data?.data || [];
      tasks.pages = data?.pages || 1;
      tasks.loading = false;
      return data;
    } catch (err) {
      showError(err);

      tasks.loading = false;
      return false;
    }
  },
  async fetchClientActivity(
    params: Record<string, any> | undefined,
    customTaskTypes: string | undefined
  ) {
    try {
      const { data } = await get(ENDPOINTS.tasks.activityClient, {
        perPage: 100,
        ...params,

        taskTypes: customTaskTypes
          ? customTaskTypes
          : "SecureMessage,MoneyRequest,Deposit,Crypto_withdraw,SWIFT_wire,SEPA_wire,Transfer",
      });

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async fetchClientInbox(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(ENDPOINTS.tasks.inboxClient, {
        perPage: 100,
        ...params,
      });

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchClientInboxCount() {
    try {
      const { data } = await get(ENDPOINTS.tasks.inboxCountClient);

      auth.onBellCount(data);

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async cancelTaskClient(taskId: string) {
    try {
      await post(ENDPOINTS.tasks.cancelTaskClient.replace("{taskId}", taskId));

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async closeTaskClient(taskId: string) {
    try {
      await post(ENDPOINTS.tasks.closeTaskClient.replace("{taskId}", taskId));

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async rejectTask(taskId: string, reason: string) {
    try {
      await post(ENDPOINTS.tasks.rejectTask.replace("{taskId}", taskId), {
        reason,
      });

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async rejectTaskClient(taskId: string, reason: string) {
    try {
      await post(ENDPOINTS.tasks.rejectTaskClient.replace("{taskId}", taskId), {
        reason,
      });

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async approveTask(taskId: string) {
    try {
      await post(ENDPOINTS.tasks.approveTask.replace("{taskId}", taskId));

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async proofOfPayment(userId: string, taskId: string) {
    try {
      const { data, headers } = await get(
        ENDPOINTS.tasks.proofOfPayment
          .replace("{userId}", userId)
          .replace("{taskId}", taskId),
        undefined,
        "arraybuffer"
      );

      const contentType = headers["content-type"];

      const file = new Blob([data], { type: contentType });
      const fileURL = URL.createObjectURL(file);

      open(fileURL, "_blank");

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async proofOfPaymentClient(taskId: string) {
    try {
      const { data, headers } = await get(
        ENDPOINTS.tasks.proofOfPaymentClient.replace("{taskId}", taskId),
        undefined,
        "arraybuffer"
      );

      const contentType = headers["content-type"];

      const file = new Blob([data], { type: contentType });
      const fileURL = URL.createObjectURL(file);

      open(fileURL, "_blank");

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async sendWireInfo(taskId: string, payload: Record<string, any> | undefined) {
    try {
      await post(
        ENDPOINTS.tasks.sendWireInfo.replace("{taskId}", taskId),
        payload
      );

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async makeUnread(eventId: string) {
    try {
      await post(ENDPOINTS.tasks.makeUnread.replace("{eventId}", eventId));

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchCountInboxAdmin() {
    try {
      const { data } = await get(ENDPOINTS.tasks.fetchCountInboxAdmin);

      tasks.counts = data;
      
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async inquire(taskId: string, payload: Record<string, any> | undefined) {
    try {
      await post(ENDPOINTS.tasks.inquire.replace("{taskId}", taskId), payload);

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async previewPassToRobot(taskIds: Record<string, any> | undefined) {
    try {
      const { data } = await post(ENDPOINTS.admin.previewPassToRobot, taskIds);

      return [true, data];
    } catch (err) {
      showError(err);
      return [false];
    }
  },

  async confirmPassToRobot(
    previewMassApproveResult: Record<string, any> | undefined
  ) {
    try {
      const { data } = await post(
        ENDPOINTS.admin.confirmPassToRobot,
        previewMassApproveResult
      );

      return [true, data];
    } catch (err) {
      showError(err);
      return [false];
    }
  },

  async previewMassApprove(taskIds: Record<string, any> | undefined) {
    try {
      const { data } = await post(ENDPOINTS.admin.previewMassApprove, taskIds);

      return [true, data];
    } catch (err) {
      showError(err);
      return [false];
    }
  },

  async confirmMassApprove(
    previewMassApproveResult: Record<string, any> | undefined
  ) {
    try {
      const { data } = await post(
        ENDPOINTS.admin.confirmMassApprove,
        previewMassApproveResult
      );

      return [true, data];
    } catch (err) {
      showError(err);
      return [false];
    }
  },

  async iSentMoney(payload: { amount: string; taskId: string }, files: any[]) {
    try {
      const formData = new FormData();

      formData.append("Amount", payload?.amount);
      formData.append("TaskId", payload?.taskId);

      if (files?.length > 0) {
        files.forEach((f: { data: string | Blob }) =>
          formData.append("Files", f.data)
        );
      }

      await post(ENDPOINTS.tasks.iSentMoney, formData);

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  addToItems(data: any) {
    const arr = [...tasks.items];

    arr.pop();

    arr.unshift(data);

    tasks.items = [...arr];
  },
  addCountToCounts(taskTypeId: any) {
    const findIndex = tasks.counts.findIndex(
      (i) => i.taskTypeId === taskTypeId
    );

    const item = tasks.counts[findIndex];

    if (item) {
      item.count += 1;

      tasks.counts[findIndex] = item;

      tasks.counts = [...tasks.counts];
    }
  },
  onTaskChanged() {
    // const taskStateBooleans = getTaskStateBooleans(task);
    // const taskTypeBooleans = getTaskTypeBooleans(task);
    // const isInbox = window.location.pathname.includes("/inbox");
    // const isDeposit = window.location.pathname.includes("/deposit");
    // const isWire = window.location.pathname.includes("/wire");
    // const isTransfer = window.location.pathname.includes("/transfer");
    // const isCrypto = window.location.pathname.includes("/crypto");
    // const isAll = !isDeposit && !isWire && !isTransfer && !isCrypto;
    // if (isInbox) {
    //   const d = taskStateBooleans.isPending && taskTypeBooleans.isDeposit;
    //   const toDeposit = isDeposit && d;
    //   const w =
    //     taskStateBooleans.isPending &&
    //     (taskTypeBooleans.isSEPA || taskTypeBooleans.isSWIFT);
    //   const toWire = isWire && w;
    //   const t = taskStateBooleans.isPending && taskTypeBooleans.isTransfer;
    //   const toTransfer = isTransfer && t;
    //   const c = taskStateBooleans.isPending && taskTypeBooleans.isCrypto;
    //   const toCrypto = isCrypto && c;
    //   const toAll = isAll && taskStateBooleans.isPending;
    //   if (toDeposit || toWire || toTransfer || toCrypto || toAll) {
    //     tasks.addToItems(task);
    //   }
    //   if (d) {
    //     tasks.addCountToCounts("Deposit");
    //   }
    //   if (w) {
    //     tasks.addCountToCounts("SWIFT_wire");
    //   }
    //   if (t) {
    //     tasks.addCountToCounts("Transfer");
    //   }
    //   if (c) {
    //     tasks.addCountToCounts("Crypto_withdraw");
    //   }
    // } else {
    //   const toDeposit = isDeposit && taskTypeBooleans.isDeposit;
    //   const toWire =
    //     isWire && (taskTypeBooleans.isSEPA || taskTypeBooleans.isSWIFT);
    //   const toTransfer = isTransfer && taskTypeBooleans.isTransfer;
    //   const toCrypto = isCrypto && taskTypeBooleans.isCrypto;
    //   const toAll = isAll;
    //   if (toDeposit || toWire || toTransfer || toCrypto || toAll) {
    //     tasks.addToItems(task);
    //   }
    // }
  },
});

export default tasks;
