import { atom } from "jotai";
import { makeDates } from "../../../../AllClients/hooks/useFilter";

const { from, to } = makeDates({ dateType: "Last 7 Days" });

export const clientParamsAtom = atom<any>({
  page: 1,
  perPage: 20,
  search: undefined,
  sortBy: undefined,
  sortDirection: undefined,
  totalCurrency: "EUR",
  branchId: undefined,
  from: from,
  to: to,
  lessThan: undefined,
  moreThan: undefined,
  showHidden: false,
});
