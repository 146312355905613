import { Button, Container, DialogActions } from "@material-ui/core";
import numbro from "numbro";
import React, { useState } from "react";
import { formatToCurrencyString } from "../../../../../../helpers";
import { Alert } from "../../../../../System";
import Field from "../../../../../System/FormComponents/Field";
import Credit from "../SendWire/Credit";
import Finci from "./Finci";
import GreenButton from "./GreenButton";
import PreviewTable from "./PreviewTable";
import Sepa from "./Sepa";
import SepaBib from "./SepaBib";
import Sfox from "./Sfox";
import Swift from "./Swift";

const WrapCredit = ({ preview, status, task, form }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <a className="text-base" onClick={() => setOpen(true)}>
        Do you want to make credit?
      </a>

      <Credit
        open={open}
        setOpen={setOpen}
        task={task}
        successCallback={preview}
        status={status}
        parentForm={form}
      />
    </>
  );
};

const Preview = ({
  data,
  setStep,
  setOpen,
  confirm,
  preview,
  loading,
  hideConfirmButtons,
  dayId,
  customConfirmButton,
  task,
  form,
}: any) => {
  // const iDontWantToUseSEPA = useWatch({
  //   control: form?.control,
  //   name: "iDontWantToUseSEPA",
  // });

  const [openAccess, setOpenAccess] = useState(false);
  const [access, setAccess] = useState(false);

  if (!data) {
    return null;
  }

  const values = form.getValues();

  const totalMasterFees = data.transaction?.operation?.reduce((acc, item) => {
    if (item?.accountId?.includes("MASTER-FEE")) {
      return (acc += item?.amount || 0);
    }

    return acc;
  }, 0);

  const totalFees = (data.transaction.totalFees || []).map((item) =>
    formatToCurrencyString(item.value, item.currencyId)
  );

  const totalDeposits = (data.transaction.totalDeposits || []).map((item) =>
    formatToCurrencyString(item.value, item.currencyId)
  );

  const totalWithdraws = (data.transaction.totalWithdraws || []).map((item) =>
    formatToCurrencyString(item.value, item.currencyId)
  );

  // const feeCalcDescription = data.transaction?.operation[0]?.feeCalcDescription;

  const showCreditLink = data?.status?.includes("desired amount");

  return (
    <>
      <div>
        <Container maxWidth="sm">
          {data?.transaction?.transactionId && (
            <Field
              className="text-lg"
              label="Transaction ID"
              value={<b>{data.transaction.transactionId}</b>}
            />
          )}

          {totalFees?.length > 0 && (
            <Field
              className="text-lg"
              label="Total fees"
              value={totalFees.join(", ")}
            />
          )}

          {(numbro.unformat(values?.fixedOurCost) || 0) > 0 && (
            <Field
              className="text-lg"
              label="Our Cost"
              value={`-${formatToCurrencyString(
                values?.fixedOurCost,
                values.currencyId
              )}`}
            />
          )}

          <Field
            className="text-lg"
            label="Bank's profit"
            value={formatToCurrencyString(totalMasterFees, values.currencyId)}
          />

          {totalMasterFees < 0 && (
            <p className="text-red-400 font-bold text-2xl">
              Attention, the bank&apos;s expenses are higher than the
              client&apos;s commission!
            </p>
          )}

          {totalDeposits?.length > 0 && (
            <Field
              className="text-lg"
              label="Total deposits"
              value={totalDeposits.join(", ")}
            />
          )}

          {totalWithdraws?.length > 0 && (
            <Field
              className="text-lg"
              label="Total withdraws"
              value={totalWithdraws.join(", ")}
            />
          )}

          {dayId && (
            <Field className="text-lg" label="Transaction time" value={dayId} />
          )}

          <Field
            className="text-lg"
            label="This will generate"
            value={
              data?.transaction?.isPending ? (
                <span>
                  <b>pending</b> transaction
                </span>
              ) : (
                <span>
                  <b>real</b> transaction
                </span>
              )
            }
          />

          <Field
            className="text-lg"
            label="Negative balance"
            value={
              data?.request?.allowNegativeBalance ? (
                <b className="text-red-500">allowed</b>
              ) : (
                "not allowed"
              )
            }
          />

          {form && (
            <>
              <Sepa {...form} data={data} task={task} />
              <Swift {...form} data={data} />
              <Sfox {...form} data={data} task={task} />
              <SepaBib {...form} data={data} task={task} />
              <Finci {...form} data={data} task={task} />
            </>
          )}
        </Container>

        <div className="h-4" />

        <PreviewTable data={data?.transaction?.operation} />

        <div className="h-2.5" />

        {data?.status && (
          <div className="flex flex-1 justify-end">
            <div className="flex flex-col">
              <b className="text-red-500 text-lg">{data.status}</b>
              <span>
                {showCreditLink && (
                  <WrapCredit
                    task={task}
                    preview={preview}
                    status={data?.status}
                    form={form}
                  />
                )}
              </span>

              {access ? (
                <p className="font-bold text-base text-main  mt-2">
                  The payment is blocked - the Confirm button is available to
                  you
                </p>
              ) : (
                <a
                  className="font-bold text-base text-main cursor-pointer mt-2"
                  onClick={() => setOpenAccess(true)}
                >
                  Do not send funds, perform withdraw only
                </a>
              )}
            </div>

            {/* {showCreditLink && (
            <MyCheckboxLabel
              {...form}
              name="iDontWantToUseSEPA"
              label="I don't want to use SEPA for this payment"
            />
          )} */}
          </div>
        )}

        {!hideConfirmButtons && (
          <DialogActions>
            {setStep && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setStep(1)}
                disabled={loading}
              >
                Back
              </Button>
            )}

            {customConfirmButton ? (
              customConfirmButton
            ) : (
              <GreenButton
                onClick={confirm}
                disabled={loading || (Boolean(data.status) && !access)}
                label="Confirm"
                loading={loading}
              />
            )}
          </DialogActions>
        )}

        {hideConfirmButtons && (
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(false)}
              disabled={loading}
            >
              Close
            </Button>
          </DialogActions>
        )}
      </div>

      <Alert
        open={openAccess}
        setOpen={setOpenAccess}
        content={
          <div>
            <h2 className="font-bold text-xl mb-4 text-center">WARNING</h2>

            <div className="text-center text-base">
              <p className="mb-0">There will be no real SEPA payment!</p>

              <p>You must send this payment using another program.</p>

              <p>
                By continuing this operation, only the withdrawal of money from
                the client&apos;s account will occur (the so-called
                &quot;withdrawal to nowhere&quot;).
              </p>

              <p>You are sure?</p>
            </div>

            <div className="flex justify-center pb-4">
              <Button
                variant="contained"
                color="primary"
                className="!mr-2"
                onClick={() => setOpenAccess(false)}
              >
                No
              </Button>

              <GreenButton
                label="Yes"
                onClick={() => {
                  setAccess(true);

                  setOpenAccess(false);
                }}
              />
            </div>
          </div>
        }
        size="xs"
      />
    </>
  );
};

export default Preview;
