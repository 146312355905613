import React from "react";
import { formatToCurrencyString } from "../../../../../helpers";
import CopyAccountNumber from "../../../../System/CopyAccountNumber";
import { IPayloadForConfirm } from "./interfaces/stepProps";
/* 
import { formatDateToMmDdYyyy } from "../../../../helpers" */

const Preview = ({
  currency,
  payload,
}: {
  currency: string;
  payload?: IPayloadForConfirm;
}) => {
  return (
    <div>
      <div className="h-4" />
      <div className="w-full">
        {payload?.transactionId && (
          <div className="text-2xl mb-2 flex justify-between border-b border-solid border-gray-200">
            <span>Transaction ID: </span>
            <b>{payload?.transactionId}</b>
          </div>
        )}
        <div className="text-2xl mb-2 flex justify-between border-b border-solid border-gray-200">
          <span>Amount: </span>{" "}
          <b>
            {formatToCurrencyString(Math.abs(payload?.amount || 0), currency)}
          </b>
        </div>

        <div className="text-2xl mb-2 flex justify-between border-b border-solid border-gray-200">
          <span>Sending Institution Name, Country: </span>{" "}
          <b>{payload?.nameOfBank}</b>
        </div>
        <div className="text-2xl mb-2 flex justify-between border-b border-solid border-gray-200">
          <span>Name of sending account: </span>
          <b>{payload?.nameOnAccount}</b>
        </div>
        <div className="text-2xl mb-2 flex justify-between border-b border-solid border-gray-200">
          <span>Sending IBAN (or Account Number): </span>
          <b>
            {payload?.accountNumber}

            {payload?.accountNumber && (
              <CopyAccountNumber number={payload?.accountNumber} />
            )}
          </b>
        </div>
        <div className="text-2xl mb-2 flex justify-between border-b border-solid border-gray-200">
          <span>Contact email: </span>
          <b>{payload?.email}</b>
        </div>
        <div className="text-2xl mb-2 flex justify-between border-b border-solid border-gray-200">
          <span>Deposit type: </span>
          <b>{payload?.swiftOrSepa}</b>
        </div>
      </div>
    </div>
  );
};

export default Preview;
