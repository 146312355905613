import React, { memo } from "react";
import CustomSimpleMenu from "../../../../../System/Components/CustomSimpleMenu";

export default memo(({ params, setParams }: any) => {
  return (
    <div className="min-w-[6rem] max-w-[20rem] mr-4">
      <CustomSimpleMenu
        value={params?.sumBy || "Week"}
        label="Sum by"
        options={[
          {
            label: "Week",
            value: "Week",
          },
          {
            label: "Month",
            value: "Month",
          },
          {
            label: "Quarter",
            value: "Quartal",
          },
          {
            label: "Year",
            value: "Year",
          },
        ]}
        onChange={(e, value) => {
          setParams((prevState) => ({
            ...prevState,
            page: 1,
            sumBy: value,
          }));
        }}
        fullWidth
      />
    </div>
  );
});
