import React, { memo } from "react";
import CustomCheckboxLabel from "../../../../../System/Components/CustomCheckboxLabel";

export default memo(({ params, setParams }: any) => {
  return (
    <CustomCheckboxLabel
      label="Ignore zero value"
      value={params.ignoreZeroValue ? true : false}
      onChange={(e, checked) => {
        setParams((prevState) => ({
          ...prevState,
          ignoreZeroValue: checked === false ? undefined : true,
        }));
      }}
    />
  );
});
