export const optionsEmploymentStatus = [
  {
    label: "Full-Time",
    value: "Full-Time",
  },
  {
    label: "Self-Employed",
    value: "Self-Employed",
  },
  {
    label: "Retired",
    value: "Retired",
  },
  {
    label: "Other (Please specify)",
    value: "Other",
  },
];

export const optionsEmploymentStatusOld = [
  {
    label: "Full-Time",
    value: "Full-Time",
  },
  {
    label: "Self-Employed",
    value: "Self-Employed",
  },
  {
    label: "Other (Please specify)",
    value: "Other",
  },
];

export const optionsProofSourceOfWealth = [
  {
    label:
      "Bank statement (incoming transfers from dividends, rentals, employment, deposit, inheritance, alimony gift, etc.)",
    value:
      "Bank statement (incoming transfers from dividends, rentals, employment, deposit, inheritance, alimony gift, etc.)",
  },
  {
    label:
      "Other document issued by financial institution (deposit or loan agreement, guarantee letter or other confirmation)",
    value:
      "Other document issued by financial institution (deposit or loan agreement, guarantee letter or other confirmation)",
  },
  {
    label: "Purchasing agreement (for movables or non-movables)",
    value: "Purchasing agreement (for movables or non-movables)",
  },
  {
    label: "Inheritance / Gift / Alimony Agreement",
    value: "Inheritance / Gift / Alimony Agreement",
  },
  {
    label: "Lottery statement issued by regulated Gambling",
    value: "Lottery statement issued by regulated Gambling",
  },
  {
    label: "Insurance statement",
    value: "Insurance statement",
  },
  {
    label: "Financial statements of the company",
    value: "Financial statements of the company",
  },
];

export const optionsNatureOfBusinessRelationship = [
  {
    label: "Lifestyle management services",
    value: "Lifestyle management services",
  },
  {
    label: "High cash withdrawals",
    value: "High cash withdrawals",
  },
  {
    label: "Purchasing power",
    value: "Purchasing power",
  },
  {
    label: "Remium services benefits",
    value: "Remium services benefits",
  },
  {
    label: "Travel insurance",
    value: "Travel insurance",
  },
];
