import { view } from "@risingstack/react-easy-state";
import React from "react";
import { redirects } from "../../helpers/redirects";
import auth from "../../store/modules/auth";
import TermsAndConditions from "../System/TermsAndConditions";

const Terms = view(() => {
  const callbackTerms = async () => {
    const result = await auth.acceptTerms();

    if (result) {
      redirects(location);
    }
  };

  return (
    <TermsAndConditions
      open={auth.showTerms}
      setOpen={(value) => (auth.showTerms = value)}
      callbackTerms={callbackTerms}
    />
  );
});

export default Terms;
