import React from "react";
import DeleteOperation from "./Actions/DeleteOperation";
import OpenTask from "./Actions/OpenTask";

const ActionsWithTransaction = () => {
  return (
    <>
      <OpenTask />

      <DeleteOperation />
    </>
  );
};

export default ActionsWithTransaction;
