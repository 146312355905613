import React, { memo } from "react";
import Anima from "../../Anima/index";
import LazyImage from "../../LazyImage";

const LazyAnima = memo(() => {
  return <Anima className="w-56" json="/keys/dialogs/attention.json" center />;
});

const Attention = () => {
  return <LazyImage anima={LazyAnima} />;
};

export default Attention;
