import { IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React, { memo, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  formatDateToMMddyyyySeparatorSlash,
  formatToCurrencyString,
} from "../../../../../../helpers";
import transactionDetails from "../../../../../../store/modules/transactionDetails";
import TransactionDetail from "../../../AllAccounts/Transactions/Detail";
import { makeAmount } from "../../../Reports/TrialBalance/makeAmount";
import FeatureSearch from "../components/FeatureSearchFilter";
import SimpleTable from "../components/SimpleTable";
import ShowNegInt from "./components/ShowNegInt";
import { useQueryFullDetails } from "./queries/useQueryFullDetails";

export default memo(() => {
  const history = useHistory();
  const state: any = history.location.state;

  const [params, setParams] = useState({
    page: state?.page || 1,
    perPage: state?.perPage || 50,
    sortDirection: state?.sortDirection,
    sortBy: state?.sortBy,
    search: state?.search,
    currencyId: state?.currencyId,
    from: state?.from,
    to: state?.to,
    showNegInt: state?.showNegInt,
    branchId: state?.branchId,
    userId: state?.userId,
    feeId: state?.feeId,
  });

  const fullDetailsQuery = useQueryFullDetails(params);

  const columns = useMemo(() => {
    return [
      {
        label: "Date",
        id: "ts",
        render: (rowData) => {
          return formatDateToMMddyyyySeparatorSlash(rowData.ts);
        },
      },
      {
        label: "Trans. ID",
        id: "transactionId",
        nowrap: true,
        render: (rowData) => {
          return rowData.transactionId;
        },
      },
      {
        label: "Comment",
        id: "comment",
        render: (rowData) => {
          return (
            <span
              className="break-words"
              dangerouslySetInnerHTML={{
                __html: (rowData.comment || "").replace(
                  /`([^"]+)`/g,
                  `<span style="border: 1px solid #ccc; padding: 2px 4px;">$1</span>`
                ),
              }}
            />
          );
        },
      },
      {
        label: "Amount",
        id: "amount",
        nowrap: true,
        right: true,
        render: (rowData) => {
          return formatToCurrencyString(rowData.amount, rowData.currencyId);
        },
      },
      {
        label: "Client",
        id: "userName",
        nowrap: true,
        render: (rowData) => {
          return rowData.userName;
        },
      },
      {
        label: "Fee ID",
        id: "feeId",
        nowrap: true,
        render: (rowData) => {
          return rowData.feeId;
        },
      },
      {
        label: "Profit",
        id: "profit",
        right: true,
        nowrap: true,

        render: (rowData) => {
          return rowData.profit != 0 ? (
            <span>{makeAmount(rowData.profit, rowData.currencyId)}</span>
          ) : (
            "-"
          );
        },
      },
      {
        label: "Loss",
        id: "loss",
        right: true,
        nowrap: true,
        render: (rowData) => {
          return rowData.loss != 0 ? (
            <span>{makeAmount(rowData.loss, rowData.currencyId)}</span>
          ) : (
            "-"
          );
        },
      },

      {
        label: "PL",
        id: "profitLoss",
        nowrap: true,
        right: true,
        render: (rowData) => {
          return rowData.profitLoss != 0
            ? makeAmount(rowData.profitLoss, rowData.currencyId)
            : "-";
        },
      },
    ];
  }, []);

  const labelArr: any = [];

  if (state?.branchName) {
    labelArr.push(state.branchName);
  }

  if (state?.userName) {
    labelArr.push(state.userName);
  }

  if (state?.feeName) {
    labelArr.push(state.feeName);
  }

  if (state?.currencyId) {
    labelArr.push(`col ${state.currencyId}`);
  }

  if (state?.from) {
    labelArr.push(`from ${state.from}`);
  }

  if (state?.to) {
    labelArr.push(`to ${state.to}`);
  }

  return (
    <div>
      <IconButton onClick={history.goBack}>
        <ArrowBack />
      </IconButton>

      {labelArr?.length > 0 && (
        <div className="mb-2">
          <b className="text-lg">{labelArr.join(", ")}</b>
        </div>
      )}

      <div className="flex flex-wrap flex-row items-end">
        <FeatureSearch params={params} setParams={setParams} />
        <ShowNegInt params={params} setParams={setParams} />
      </div>

      <SimpleTable
        columns={columns}
        queryData={fullDetailsQuery}
        setParams={setParams}
        params={params}
        onRowClick={(rowData) => {
          console.log(rowData?.transactionId);
          transactionDetails.fetchTransactions = () => {
            // logic
          };
          transactionDetails.fetchUserAccountTransaction(
            false,
            `${rowData?.transactionId}`
          );
          transactionDetails.openDetail = true;
        }}
      />

      <TransactionDetail />
    </div>
  );
});
