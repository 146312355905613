import { FormHelperText } from "@material-ui/core";
import numbro from "numbro";
import React, { useCallback, useState } from "react";
import accounts from "../../../../../../store/modules/accounts";
import LoadingCircular from "../../../../../LoadingCircular";
import MyAmount from "../../../../../System/FormComponents/MyAmount";
import { useGetAccounts } from "../hooks/useGetAccounts";

const SellAmount = ({ form, cursor, setCursor, userId }) => {
  const { setValue } = form;

  const { fromAccount, toAccount } = useGetAccounts({ userId, form });

  const [loading, setLoading] = useState(false);

  const fetchMaxAmount = useCallback(async () => {
    if (!fromAccount?.accountId || !toAccount?.accountId) {
      return;
    }

    setLoading(true);

    const result = await accounts.fetchMaxExchangeAmount(
      fromAccount?.accountId,
      toAccount?.accountId
    );

    if (result) {
      setCursor("sell");
      setTimeout(() => setValue("sellAmount", result.maxAmount), 0);
      setTimeout(() => setValue("from_fixedFee", result.fixedFee), 0);
    }

    setLoading(false);
  }, [fromAccount?.accountId, setCursor, setValue, toAccount?.accountId]);

  return (
    <div>
      <MyAmount
        {...form}
        name="sellAmount"
        controlCurrency={form.control}
        currencyName="sellCurrencyId"
        label={
          cursor === "sell" ? (
            <span className="font-bold">Sell Amount</span>
          ) : (
            "Sell Amount"
          )
        }
        rules={{
          required: true,
          validate: {
            valid: (value) => {
              const unformatted = numbro.unformat(value);

              if (unformatted > 0) {
                return true;
              }

              return false;
            },
          },
        }}
        onFocus={() => {
          setCursor("sell");
        }}
        endAdornment={
          <div className={"relative"}>
            <a
              className={loading ? "text-gray-400 hover:text-gray-400" : ""}
              onClick={fetchMaxAmount}
              disabled={
                loading || !fromAccount?.accountId || !toAccount?.accountId
              }
            >
              max
              <LoadingCircular loading={loading} />
            </a>
          </div>
        }
      />

      {form?.formState?.errors?.sellAmount?.type === "required" && (
        <FormHelperText error>Field is required</FormHelperText>
      )}

      {form?.formState?.errors?.sellAmount?.type === "valid" && (
        <FormHelperText error>
          The sell amount must be greater than zero
        </FormHelperText>
      )}
    </div>
  );
};

export default SellAmount;
