import { TextField } from "@material-ui/core";
import React, { memo } from "react";

export default memo(({ setParams, params }: any) => {
  return (
    <TextField
      value={params?.search || ""}
      label="Search"
      InputLabelProps={{
        shrink: true,
      }}
      onChange={(e) =>
        setParams((prevState) => ({
          ...prevState,
          search: e.target.value,
          page: 1,
        }))
      }
      className="!mr-4 !mb-2"
    />
  );
});
