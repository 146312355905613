import IUser from "../interfaces/user";

export const checkSystemUser = (user?: IUser | false | null) =>
  !!user &&
  (user?.isAdmin ||
    user?.isOperator ||
    user?.isRegulator ||
    user?.isClerk ||
    user?.isController ||
    user?.isSupportAgent);
