import React, { memo } from "react";
import { ReactComponent as MCSIcon } from "./icons/mcs.svg";
export default memo(() => {
  return (
    <div className="flex rounded-md py-2 px-4 justify-start items-center border border-main">
      <MCSIcon className="fill-current w-12" />

      <div className="ml-4 flex flex-col">
        <h2 className="font-bold text-xl mb-0 p-0">MCS</h2>
        <p className="text-sm mb-0 font-bold">Remium Crypto Sender</p>
      </div>
    </div>
  );
});
