import { makeDates } from "../components/Roles/Admin/AllClients/hooks/useFilter";
import { is } from "./is";

export const makeParams = (module, p) => {
  /*
        for hook replace module:
        { params: ref.current }

        global:
        module
    */
  const params = {
    ...module.params,
    ...p,
  };

  if (is(Array, p?.sortBy) && p?.sortBy?.length > 0) {
    params.sortBy = p.sortBy?.length ? p.sortBy[0]?.id : null;
    params.sortDirection = p.sortBy?.length
      ? p.sortBy[0]?.desc
        ? "desc"
        : "asc"
      : null;
  }

  if (p?.dateType) {
    const dateTypeResult = makeDates(p);

    params.from = dateTypeResult.from;
    params.to = dateTypeResult.to;

    delete params.dateType;
  }

  module.params = { ...params };

  params.page = module.params.pageIndex ? module.params.pageIndex + 1 : 1;

  delete params.pageIndex;

  return params;
};
