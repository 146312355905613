import { Button, FormHelperText, IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { view } from "@risingstack/react-easy-state";
import { clsx } from "clsx";
import numbro from "numbro";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { formatToCurrencyString } from "../../../../helpers";
import { post } from "../../../../store/api";
import auth from "../../../../store/modules/auth";
import ui from "../../../../store/modules/ui";
import LoadingCircular from "../../../LoadingCircular";
import MyAmount from "../../../System/FormComponents/MyAmount";
import MyAutocomplete from "../../../System/FormComponents/MyAutocomplete";
import MyTextField from "../../../System/FormComponents/MyTextField";
import PreviewBox from "../Pay/Helpers/PreviewBox";
import { useQueryCountries } from "./queries/useQueryCountries";

export default view(() => {
  const { profile } = auth;

  const history = useHistory();

  const state = history.location.state as any;

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      amount: "",
      currencyId: "MIGG",
      receiverName: "",
      email: "",
      phoneNumber: "",

      address1: "",
      address2: "",
      city: "",
      stateOrProvince: "",
      postalCode: "",

      country: null,
      additionalShippingInstructions: "",
    },
  });

  const { reset } = form;

  const countriesQuery = useQueryCountries();

  useEffect(() => {
    const country =
      !!profile &&
      countriesQuery?.data?.find((c) => c.id === profile?.countryId);

    if (!!profile && profile?.clientType === "Individual") {
      reset({
        currencyId: "MIGG",
        receiverName: profile?.name || "",
        email: profile?.email || "",
        phoneNumber: profile?.phoneNumber || "",

        address1: profile?.address1 || "",
        address2: profile?.address2 || "",
        city: profile?.city || "",
        stateOrProvince: profile?.stateOrProvince || "",
        postalCode: profile?.postalCode || "",

        country: country || null,
      });
    }

    if (!!profile && profile?.clientType === "Corporate") {
      reset({
        currencyId: "MIGG",
        receiverName: profile?.representative || "",
        email: profile?.email || "",
        phoneNumber: profile?.phoneNumber || "",

        address1: profile?.address1 || "",
        address2: profile?.address2 || "",
        city: profile?.city || "",
        stateOrProvince: profile?.stateOrProvince || "",
        postalCode: profile?.postalCode || "",

        country: country || null,
      });
    }
  }, [countriesQuery?.data, profile, reset]);

  const [step, setStep] = useState(1);
  const [loadingConfirm, setLoadingConfirm] = useState(false);

  const confirm = useCallback(
    async (values) => {
      setLoadingConfirm(true);
      try {
        await post(`${import.meta.env.VITE_API}/v1/operations/gold-delivery`, {
          fromGoldAccountId: state?.account?.accountId || "",
          amount: numbro.unformat(values.amount) || 0,
          receiverName: values?.receiverName || "",
          email: values?.email || "",
          phoneNumber: values?.phoneNumber || "",

          deliveryAddress: {
            address1: values?.address1 || "",
            address2: values?.address2 || "",
            city: values?.city || "",
            stateProvince: values?.stateOrProvince || "",
            postalCode: values?.postalCode || "",
            countryId: values?.country?.id || "",
          },
          additionalShippingInstructions:
            values.additionalShippingInstructions || "",
        });

        ui.salute = true;
        setStep(3);
        setLoadingConfirm(false);
      } catch (err) {
        console.log(err);

        setLoadingConfirm(false);
      }
    },
    [state?.account?.accountId]
  );

  const previewValues = form.getValues();

  return (
    <>
      <IconButton onClick={history.goBack}>
        <ArrowBack />
      </IconButton>

      {step === 3 && (
        <div className="w-full">
          <div className="max-w-sm mx-auto">
            <img className="object-cover" src="/gold/delivery-order.jpg" />
          </div>

          <h2 className="text-xl text-center mt-8">
            MIGG Coin Physical Delivery Order is being processed.
          </h2>

          <p className="text-center">
            Your order is being processed. Your MIGG coins will arrive in up to
            3 weeks depending on your nominated address location. We use
            reputable, certified and insured precious metals courier companies
            to delivery your MIGG coins. The courier may be contacting you prior
            to delivery.
          </p>

          <div className="flex justify-center w-full">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push("/app/overview");
              }}
            >
              Go to Overview
            </Button>
          </div>
        </div>
      )}

      <div
        className={clsx(
          step <= 2
            ? "md:grid-cols-2 max-w-[60rem]"
            : "md:grid-cols-1 max-w-[30rem]",
          "mx-auto grid grid-cols-1 gap-8"
        )}
      >
        {step === 1 && (
          <div className="w-full flex flex-col">
            <h2 className="text-xl">MIGG Coin Physical Delivery Order</h2>

            <p className="text-sm">
              If you wish to redeem your MIGG coins and take physical delivery
              of the same to your nominated address, please complete the form
              below. Note that physical delivery of MIGG coins to you may take
              up to 3 weeks depending on your nominated address location. You
              can only order a whole number of coins, for obvious reasons no
              fractional physical delivery is available. For avoidance of
              misunderstanding, if you own 1,5 MIGG coins electronically you can
              only take physical delivery of 1 MIGG coin to your nominated
              address. You may need to have enough liquid assets in your account
              (fiat or crypto) to cover applicable fees.
            </p>

            <div className="mb-4">
              <b className="text-lg">
                MIGG Available Balance:{" "}
                {formatToCurrencyString(
                  state?.account?.availableBalance,
                  state?.account?.currencyId
                )}
              </b>
            </div>

            <div className="mb-8">
              <MyAmount
                {...form}
                decimal={0}
                controlCurrency={form.control}
                currencyName="currencyId"
                name="amount"
                label="Amount*"
                fullWidth
                autoFocus
                rules={{
                  required: true,
                  validate: {
                    valid: (value) => {
                      const unformatted = numbro.unformat(value);

                      if (
                        unformatted > 0 &&
                        unformatted <= state?.account?.availableBalance
                      ) {
                        return true;
                      }

                      return false;
                    },
                  },
                }}
              />

              {form?.formState?.errors?.amount?.type === "required" && (
                <FormHelperText error>Field is required</FormHelperText>
              )}

              {form?.formState?.errors?.amount?.type === "valid" && (
                <FormHelperText error>
                  You don&apos;t have enough coins, please replenish your
                  account
                </FormHelperText>
              )}
            </div>

            <div className="mb-8">
              <h2 className="text-md text-gray-600">Receiver</h2>

              <div className="mb-2">
                <MyTextField
                  {...form}
                  name="receiverName"
                  label="Name*"
                  rules={{
                    required: true,
                  }}
                  fullWidth
                />

                {form?.formState?.errors?.receiverName?.type === "required" && (
                  <FormHelperText error>Field is required</FormHelperText>
                )}
              </div>

              <div className="mb-2">
                <MyTextField
                  {...form}
                  rules={{
                    required: true,
                  }}
                  name="email"
                  label="Email*"
                  fullWidth
                />

                {form?.formState?.errors?.email?.type === "required" && (
                  <FormHelperText error>Field is required</FormHelperText>
                )}
              </div>

              <div className="mb-2">
                <MyTextField
                  {...form}
                  rules={{
                    required: true,
                  }}
                  name="phoneNumber"
                  label="Phone*"
                  fullWidth
                />

                {form?.formState?.errors?.phoneNumber?.type === "required" && (
                  <FormHelperText error>Field is required</FormHelperText>
                )}
              </div>
            </div>

            <h2 className="text-md text-gray-600">Delivery Address</h2>

            <div className="mb-2">
              <MyTextField
                {...form}
                rules={{
                  required: true,
                }}
                name="address1"
                label="Address 1*"
                fullWidth
              />

              {form?.formState?.errors?.address1?.type === "required" && (
                <FormHelperText error>Field is required</FormHelperText>
              )}
            </div>

            <div className="mb-2">
              <MyTextField
                {...form}
                rules={{
                  required: false,
                }}
                name="address2"
                label="Address 2"
                fullWidth
              />
            </div>

            <div className="mb-2">
              <MyTextField
                {...form}
                rules={{
                  required: true,
                }}
                name="city"
                label="City*"
                fullWidth
              />

              {form?.formState?.errors?.city?.type === "required" && (
                <FormHelperText error>Field is required</FormHelperText>
              )}
            </div>

            <div className="mb-2">
              <MyTextField
                {...form}
                rules={{
                  required: true,
                }}
                name="stateOrProvince"
                label="State*"
                fullWidth
              />

              {form?.formState?.errors?.stateOrProvince?.type ===
                "required" && (
                <FormHelperText error>Field is required</FormHelperText>
              )}
            </div>

            <div className="mb-2">
              <MyAutocomplete
                control={form.control}
                rules={{
                  required: true,
                }}
                options={countriesQuery?.data || []}
                customGetOptionLabel={(o) => {
                  return o?.name || "";
                }}
                disablePortal={false}
                name="country"
                label="Country*"
                fullWidth
                getOptionSelected={(o, s) => o.id && s.id && o.id === s.id}
              />

              {form?.formState?.errors?.country?.type === "required" && (
                <FormHelperText error>Field is required</FormHelperText>
              )}
            </div>

            <div className="mb-2">
              <MyTextField
                {...form}
                rules={{
                  required: true,
                }}
                name="postalCode"
                label="Postal Code*"
                fullWidth
              />

              {form?.formState?.errors?.postalCode?.type === "required" && (
                <FormHelperText error>Field is required</FormHelperText>
              )}
            </div>

            <div className="mb-2 mt-8">
              <MyTextField
                {...form}
                rules={{
                  required: false,
                }}
                name="additionalShippingInstructions"
                label="Additional Shipping Instructions"
                fullWidth
              />
            </div>

            <div className="flex justify-end">
              <Button
                variant="contained"
                color="primary"
                onClick={form.handleSubmit(() => setStep(2))}
              >
                Continue
              </Button>
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="w-full">
            <h2 className="text-xl">MIGG Coin Physical Delivery Order</h2>

            <p className="text-sm">
              Please verify your nominated address, phone number and specify
              additional delivery options, if necessary. MIGG coins will be
              delivered in a gift box accompanied by certificate of
              authenticity. Please note that MIGG coins will be delivered in the
              tamper-proof packaging displaying a serial number and bar code. If
              the packaging is damaged in any way MIGG coins cannot be accepted
              back by Remium to be deposited in your wallet in the form of their
              crypto equivalent.
            </p>

            <div className="flex justify-between border-b border-gray-200 mb-2 pb-2">
              <span>Amount:</span>{" "}
              <span className="flex items-center">
                <img className="w-6 mr-1" src="/gold/migom-crypto.svg" />{" "}
                {previewValues?.amount}
              </span>
            </div>

            <PreviewBox header="Receiver">
              <div className="flex justify-between border-b border-gray-200 mb-2 pb-2">
                <span>Name:</span> <span>{previewValues?.receiverName}</span>
              </div>

              <div className="flex justify-between border-b border-gray-200 mb-2 pb-2">
                <span>Email:</span> <span>{previewValues?.email}</span>
              </div>

              <div className="flex justify-between border-b border-gray-200 mb-2 pb-2">
                <span>Phone Number:</span>{" "}
                <span>{previewValues?.phoneNumber}</span>
              </div>
            </PreviewBox>

            <PreviewBox header="Delivery Address">
              <>
                <div className="flex justify-between border-b border-gray-200 mb-2 pb-2">
                  <span>Address 1:</span> <span>{previewValues?.address1}</span>
                </div>

                {previewValues?.address2 && (
                  <div className="flex justify-between border-b border-gray-200 mb-2 pb-2">
                    <span>Address 2:</span>{" "}
                    <span>{previewValues?.address2}</span>
                  </div>
                )}

                <div className="flex justify-between border-b border-gray-200 mb-2 pb-2">
                  <span>City:</span> <span>{previewValues?.city}</span>
                </div>

                <div className="flex justify-between border-b border-gray-200 mb-2 pb-2">
                  <span>State:</span>{" "}
                  <span>{previewValues?.stateOrProvince}</span>
                </div>

                <div className="flex justify-between border-b border-gray-200 mb-2 pb-2">
                  <span>Country:</span>{" "}
                  <span>{previewValues?.country?.name}</span>
                </div>

                <div className="flex justify-between border-b border-gray-200 mb-2 pb-2">
                  <span>Postal Code:</span>{" "}
                  <span>{previewValues?.postalCode}</span>
                </div>
              </>
            </PreviewBox>

            {previewValues?.additionalShippingInstructions && (
              <div className="flex justify-between border-b border-gray-200 mb-2 pb-2">
                <span>Additional Shipping Instructions:</span>{" "}
                <span>{previewValues?.additionalShippingInstructions}</span>
              </div>
            )}

            <div className="flex justify-end mt-4">
              <Button
                variant="contained"
                color="primary"
                onClick={() => setStep(1)}
                className="!mr-2"
              >
                Back
              </Button>

              <Button
                disabled={loadingConfirm}
                variant="contained"
                color="primary"
                onClick={form.handleSubmit(confirm)}
                className="!bg-green-500 hover:!bg-green-500"
              >
                Confirm <LoadingCircular loading={loadingConfirm} />
              </Button>
            </div>
          </div>
        )}

        {step === 1 && (
          <div className="w-full">
            <img className="object-cover sticky top-0" src="/gold/dog-2.png" />
          </div>
        )}

        {step === 2 && (
          <div className="w-full">
            <img className="object-cover sticky top-0" src="/gold/dog-1.png" />
          </div>
        )}
      </div>
    </>
  );
});
