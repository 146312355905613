import { lazy } from "react";

const FormDepositStep1 = lazy(
  () => import("../../components/Offers/Release/FormDeposit/Step1")
);

const FormDepositStep2 = lazy(
  () => import("../../components/Offers/Release/FormDeposit/Step2")
);

export const OfferCreateAccount = [
  {
    path: "/app/release/step2",
    component: FormDepositStep2,
  },
  {
    path: "/app/release/step1",
    component: FormDepositStep1,
  },
];
