import React, { useState } from "react";
import { useWatch } from "react-hook-form";
import { formatToCurrencyString } from "../../../../../../helpers";
import Field from "../../../../../System/FormComponents/Field";
import MyCheckboxLabel from "../../../../../System/FormComponents/MyCheckboxLabel";
import ShowAndHide from "../../../../../System/FormComponents/ShowAndHide";
import SwiftDetail from "./SwiftDetail";

const Swift = ({ data, ...form }) => {
  const useSwift = useWatch({
    control: form?.control,
    name: "useSwift",
  });

  const [showDetail, setShowDetail] = useState(true);

  const swiftObject = data?.swiftPayment || {};

  const swift = data?.swiftPayment;

  return (
    <ShowAndHide show={Boolean(swift)}>
      {swift && useSwift ? (
        <Field
          className="text-lg"
          custom={
            <div className="flex flex-col">
              <span>Auto wire send:</span>
              <b className="text-main">
                Will execute auto SWIFT payment{" "}
                {formatToCurrencyString(
                  swiftObject?.paymentAmount?.amount,
                  swiftObject?.paymentAmount?.currency
                )}{" "}
                from {swiftObject?.ultimateDebtor?.name} to{" "}
                {swiftObject?.creditor?.name}
              </b>
            </div>
          }
        />
      ) : null}

      <div className="flex flex-col">
        <div className="flex flex-1 w-full justify-between">
          {useSwift && (
            <div className="flex flex-col">
              <b className="text-orange-400">SWIFT Payment</b>
              <a
                onClick={() => setShowDetail((prevState) => !prevState)}
                className="cursor-pointer text-base font-bold"
              >
                {!showDetail ? "Show Detail" : "Close Detail"}
              </a>
            </div>
          )}

          <MyCheckboxLabel
            control={form.control}
            name="useSwift"
            label="I want to confirm using SWIFT"
          />
        </div>

        {useSwift && (
          <SwiftDetail showDetail={showDetail} swift={swiftObject} />
        )}
      </div>
    </ShowAndHide>
  );
};

export default Swift;
