import numbro from "numbro";

export const makeWithdrawFeeTypes = (account) => {
  if (account?.accountTypeId === "DebitCard") {
    return [
      {
        value: "POS",
        label: "POS",
      },
      {
        value: "ATM",
        label: "ATM",
      },
    ];
  }

  if (account?.accountTypeId === "Bank" && account?.currencyId === "EUR") {
    return [
      {
        value: "WDREU",
        label: "WDREU - SEPA payment to external bank",
      },
      {
        value: "WDRNEU",
        label: "WDRNEU - SWIFT payment  to external bank",
      },
      {
        value: "NO_FEE_WDREU",
        label: "WDREU - No Fee - SEPA payment to external bank",
      },
      {
        value: "NO_FEE_WDRNEU",
        label: "WDRNEU - No Fee - SWIFT payment to external bank",
      },
    ];
  }

  if (account?.accountTypeId === "Bank" && account?.currencyId === "USD") {
    return [
      {
        value: "WDREU",
        label: "WDREU - USD domestic wire (ACH)",
      },
      {
        value: "NO_FEE_WDREU",
        label: "WDREU - No fee - USD domestic wire (ACH)",
      },
      {
        value: "WDRNEU",
        label: "WDRNEU - USD international wire (SWIFT)",
      },
      {
        value: "NO_FEE_WDRNEU",
        label: "WDRNEU - No fee - USD international wire (SWIFT)",
      },
    ];
  }

  if (account?.accountTypeId === "Bank" && account?.currencyId !== "EUR") {
    return [
      {
        value: "WDRNEU",
        label: "WDRNEU - SWIFT international wire (outside US)",
      },
      {
        value: "NO_FEE_WDRNEU",
        label: "WDRNEU - No fee - SWIFT international wire (outside US)",
      },
    ];
  }

  if (account?.accountTypeId === "Crypto" && account?.currencyId === "MIGG") {
    return [
      {
        value: "WDRWG",
        label: "WDRWG - Gold withdrawal",
      },
      { value: "NO_FEE_WDRWG", label: "WDRWG - No fee" },
    ];
  }

  if (account?.accountTypeId === "Crypto" && account?.currencyId !== "MIGG") {
    return [
      {
        value: "WDRW",
        label: "WDRW - Crypto Withdraw",
      },
      { value: "NO_FEE_WDRW", label: "WDRW - No fee" },
    ];
  }

  return [];
};

export const setWithdrawFee = (values) => {
  let titleId = values.feeType;

  const changeAmount = values.feeInsideAmount;

  const miscFee = values.fixedFee
    ? numbro.unformat(values.fixedFee)
    : undefined;

  if (values?.feeType?.includes("NO_FEE_")) {
    titleId = values.feeType.split("NO_FEE_")[1];
  }

  let ruleId;
  let structureId;

  if (!values?.feeType?.includes("NO_FEE")) {
    if (values.feeRule?.ruleId) {
      ruleId = values.feeRule.ruleId;
    }

    if (values.feeStructure?.structureId) {
      structureId = values.feeStructure.structureId;
    }
  }

  return { titleId, miscFee, changeAmount, ruleId, structureId };
};
