import { makeStyles } from "@material-ui/core/styles";
import { view } from "@risingstack/react-easy-state";
import { clsx } from "clsx";
import React, { useMemo } from "react";
import { formatToCurrencyString } from "../../../../../../../helpers";
import transactionDetails from "../../../../../../../store/modules/transactionDetails";
import { MigomTable } from "../../../../../../System";
import LazyImage from "../../../../../../System/LazyImage";

const useStyles = makeStyles({
  isPending: {
    opacity: 0.5,
  },
});

const Related = view(() => {
  const classes = useStyles();
  const { adminRelated, fetchUserAccountTransaction } = transactionDetails;

  const columns = useMemo(() => {
    return [
      {
        Header: "Fee ID",
        id: "adminFeeId",
        whiteSpace: "nowrap",
        accessor: (rowData) => {
          return (
            <span className={rowData.isPending ? "opacity-50" : "opacity-100"}>
              {rowData?.feeId}
            </span>
          );
        },
      },
      {
        Header: "Currency",
        id: "currencyId",
        whiteSpace: "nowrap",
        accessor: (rowData) => {
          return (
            <span className={rowData.isPending ? "opacity-50" : "opacity-100"}>
              {rowData?.currencyId}
            </span>
          );
        },
      },
      {
        Header: "Account Owner",
        id: "adminAccountOwner",
        width: 300,
        accessor: (rowData) => {
          return (
            <span
              className={clsx(
                "break-words flex",
                rowData.isPending ? "opacity-50" : "opacity-100"
              )}
            >
              {rowData.adminAccountOwner}
              {rowData.adminFeeId === "MARKUP" && (
                <div className="w-6">
                  <LazyImage
                    src="/images/fear.svg"
                    classNameImageBlock="max-w-full"
                  />
                </div>
              )}
            </span>
          );
        },
      },
      {
        Header: "Amount",
        id: "amount",
        styleHead: {
          textAlign: "right",
        },
        style: {
          textAlign: "right",
        },
        accessor: (rowData) => {
          const { amount, currencyId } = rowData;

          return (
            <div className={rowData.isPending ? "opacity-50" : "opacity-100"}>
              <b
                className={amount && amount > 0 ? "text-main" : "text-gray-500"}
              >
                {formatToCurrencyString(amount, currencyId)}
              </b>
            </div>
          );
        },
      },
    ];
  }, []);

  const memoData = useMemo(() => {
    return adminRelated || [];
  }, [adminRelated]);

  const makeRowClass = (row) => {
    if (row.isPending === "true") {
      return classes.isPending;
    }
    return null;
  };

  const onRow = (rowData) => {
    transactionDetails.clearTransaction();
    fetchUserAccountTransaction(rowData.isPending, rowData.operationId);
  };

  return memoData?.length > 0 ? (
    <>
      <div className="h-8" />

      <div className="mb-4">
        <h2 className="m-0 p-0">Other operations in this transaction</h2>
      </div>

      <MigomTable
        columns={columns}
        data={memoData}
        makeRowClass={makeRowClass}
        hidePagination
        onRowClick={onRow}
        autoHeight
      />
    </>
  ) : null;
});

export default Related;
