import { useEffect, useState } from "react";

export const useDebouncer = (value: string, ms: number): any => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = window.setTimeout(() => {
      setDebouncedValue(value);
    }, ms);

    return () => {
      if (timer) {
        window.clearTimeout(timer);
      }
    };
  }, [ms, value]);
  return debouncedValue;
};
