import React from "react";
import { formatToCurrencyString } from "../../../../../helpers";
import ShowAndHide from "../../../../System/FormComponents/ShowAndHide";

const SfoxExchangeDetail = ({ showDetail, sfox }) => {
  return (
    <ShowAndHide show={showDetail}>
      <div className="flex flex-col text-base mt-4">
        <div className="flex flex-col mb-2">
          <span>
            Sell Amount:{" "}
            <b>
              {formatToCurrencyString(
                sfox?.sellAmount || 0,
                sfox?.fromAccount?.currencyId
              )}
            </b>
          </span>
        </div>

        <div className="flex flex-col mb-2">
          <span>
            Buy Amount:{" "}
            <b>
              {formatToCurrencyString(
                sfox?.buyAmount || 0,
                sfox?.toAccount?.currencyId
              )}
            </b>
          </span>
        </div>

        <div className="flex flex-col mb-2">
          <span>
            Pair: <b>{sfox?.pair}</b>
          </span>
        </div>

        <div className="flex flex-col mb-2">
          <span>
            From: <b>{sfox?.fromAccountId}</b>
          </span>
        </div>

        <div className="flex flex-col mb-2 ">
          <span>
            To: <b>{sfox?.toAccountId}</b>
          </span>
        </div>
      </div>
    </ShowAndHide>
  );
};

export default SfoxExchangeDetail;
