import { KeyboardDatePicker } from "@material-ui/pickers";
import React from "react";
import WrapPicker from "../WrapPicker";

const CustomDate = ({ value, onChange, label, helperText, disabled }: any) => {
  return (
    <WrapPicker>
      <KeyboardDatePicker
        value={value}
        format="MM/dd/yyyy"
        label={label}
        clearable
        InputLabelProps={{
          shrink: true,
        }}
        onChange={onChange}
        fullWidth
        helperText={helperText}
        disabled={disabled}
      />
    </WrapPicker>
  );
};

export default CustomDate;
