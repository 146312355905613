import { FormHelperText } from "@material-ui/core";
import React from "react";
import { useWatch } from "react-hook-form";
import MyTextField from "../../../../System/FormComponents/MyTextField";

const Comment = ({ prefix = "", control, formState }) => {
  const feeType = useWatch({
    name: "feeType",
    control: control,
  });

  const rules = {
    required: true,
    maxLength: 140,
  };

  if (feeType?.value !== "WDRNEU" && rules.maxLength) {
    delete rules.maxLength;
  }

  return (
    <div>
      <MyTextField
        control={control}
        label="Comment"
        name={prefix + "comment"}
        fullWidth
        rules={rules}
        multiline
        rows={6}
      />

      {formState?.errors[prefix + "comment"]?.type === "required" && (
        <FormHelperText error>Field is required</FormHelperText>
      )}

      {formState?.errors[prefix + "comment"]?.type === "maxLength" && (
        <FormHelperText error>
          Maximum line length 140 characters
        </FormHelperText>
      )}
    </div>
  );
};

export default Comment;
