import React, { useCallback, useEffect } from "react";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";
import { formatString } from "../../../../../../helpers/formatString";
import { is } from "../../../../../../helpers/is";
import { useDebounce } from "../../../../../../hooks/useDebounce";
import IAccount from "../../../../../../interfaces/account";
import accounts from "../../../../../../store/modules/accounts";
import admin from "../../../../../../store/modules/admin";
import { IFormClientDeposit } from "../interfaces/stepProps";

const CheckIban = ({
  account,
  control,
  setValue,
  setCanSepa,
}: {
  account?: IAccount;
  control: Control<IFormClientDeposit>;
  setValue: UseFormSetValue<IFormClientDeposit>;
  setCanSepa: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const accountNumber = useWatch({
    control: control,
    name: "accountNumber",
  });

  const debouncedValue = useDebounce(accountNumber, 500);

  const checkIban = useCallback(async () => {
    if (account?.currencyId === "EUR") {
      const [result] = await admin.canSendSepa({
        ibanFrom: formatString(account?.number),
        ibanTo: formatString(debouncedValue),
        currencyId: account?.currencyId,
      });

      if (result) {
        setValue("swiftOrSepa", "SEPA");
      } else {
        setValue("swiftOrSepa", "SWIFT");
      }
    }
  }, [account?.currencyId, account?.number, debouncedValue, setValue]);

  const validIban = useCallback(async () => {
    const [result] = await accounts.validIban(account?.number);
    if (result) {
      setCanSepa(true);
    } else {
      setCanSepa(false);
    }
  }, [account?.number, setCanSepa]);

  useEffect(() => {
    validIban();
  }, [validIban]);

  useEffect(() => {
    if (is(String, debouncedValue) && debouncedValue?.length >= 1) {
      checkIban();
    }
  }, [checkIban, debouncedValue]);

  return null;
};

export default CheckIban;
