import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { view } from "@risingstack/react-easy-state";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { is } from "../helpers/is";
import auth from "../store/modules/auth";

const WelcomeMessage = ({ profile, close }) => (
  <span>
    <IconButton
      style={{ position: "absolute", top: 0, right: 0, color: "#fff" }}
      onClick={close}
    >
      <Close fontSize="small" />
    </IconButton>
    <b>{profile?.name}</b> Welcome to REMIUM!
  </span>
);

const Notification2faMessage = ({ close }) => {
  return (
    <span className="max-w-xs">
      <IconButton
        style={{ position: "absolute", top: 0, right: 0, color: "#fff" }}
        onClick={close}
      >
        <Close fontSize="small" />
      </IconButton>
      You are not secure enough, we strongly recommend that you enable 2FA.{" "}
      <Link
        className="text-black underline"
        to="/app/profile/security"
        onClick={close}
      >
        Enable two factor authentication?
      </Link>
    </span>
  );
};

const AdblockMessage = ({ close }) => {
  return (
    <span className="max-w-md">
      <IconButton
        style={{ position: "absolute", top: 0, right: 0, color: "#fff" }}
        onClick={close}
      >
        <Close fontSize="small" />
      </IconButton>
      Please disable the ad blocker in your browser for correct work with REMIUM
      Bank. An ad blocker can block files from opening.{" "}
      <a
        onClick={close}
        className="text-white underline"
        href="https://globalnews.ca/pages/disable-ad-blocker/#:~:text=Click%20the%20gear%20icon%20to,then%20click%20the%20Disable%20button."
        target="_blank"
        rel="noreferrer"
      >
        Instructions on how to disable the ad blocker.
      </a>
    </span>
  );
};

const mathRandom = Math.random();

const mathRandomCondition = mathRandom > 0.9;

const UserHelper = view(() => {
  const { profile } = auth;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const welcome = sessionStorage.getItem("welcome");

    if (!welcome && !!profile && profile?.name && mathRandomCondition) {
      sessionStorage.setItem("welcome", "1");

      const key = enqueueSnackbar(
        <WelcomeMessage close={() => closeSnackbar(key)} profile={profile} />,
        {
          variant: "info",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
          id: "snackbar-welcome",
        }
      );
    }
  }, [closeSnackbar, enqueueSnackbar, profile]);

  useEffect(() => {
    const addBlock = sessionStorage.getItem("add-block");

    if (!addBlock && !!profile && profile?.name && mathRandomCondition) {
      sessionStorage.setItem("add-block", "1");

      const key = enqueueSnackbar(
        <AdblockMessage close={() => closeSnackbar(key)} />,
        {
          variant: "info",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
          autoHideDuration: 12000,
          id: "snackbar-add-block",
        }
      );
    }
  }, [closeSnackbar, enqueueSnackbar, profile]);

  const show2faRef = useRef<boolean>(false);

  useEffect(() => {
    if (
      show2faRef.current &&
      profile &&
      is(Boolean, profile?.twoFactorEnabled) &&
      is(Boolean, profile?.twoFactorAuthenticatorEnabled) &&
      (!profile?.twoFactorEnabled || !profile?.twoFactorAuthenticatorEnabled) &&
      !profile?.isAdmin &&
      !profile?.isOperator &&
      !profile?.isClerk &&
      !profile?.isController &&
      !profile?.isRegulator &&
      mathRandomCondition
    ) {
      show2faRef.current = true;

      const key = enqueueSnackbar(
        <Notification2faMessage close={() => closeSnackbar(key)} />,
        {
          variant: "info",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
          autoHideDuration: 8000,
          id: "snackbar-2fa",
        }
      );
    }
  }, [closeSnackbar, enqueueSnackbar, profile]);

  return null;
});

export default UserHelper;
