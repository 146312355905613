import { useQuery } from "react-query";
import { get } from "../../../../../store/api";

export const useQueryAdminEventGroups = () => {
  return useQuery(
    ["useQueryAdminEventGroups"],
    async () => {
      try {
        const { data } = await get(
          `${import.meta.env.VITE_API}/v1/admin/event-groups`
        );

        return data;
      } catch (err) {
        return;
      }
    },
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      keepPreviousData: false,
    }
  );
};
