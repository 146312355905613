import { atom } from "jotai";

export const globalParamsAtom = atom<any>({
  page: 1,
  search: undefined,
  perPage: 50,
  sortDirection: "desc",
  sortBy: "ts",
  taskTypes: ["CardOrder"],
  lessThan: "None",
  moreThan: "None",
  countType: undefined,
  currencies: [],
});
