import { FormControlLabel, Switch } from "@material-ui/core";
import React from "react";
import { useController } from "react-hook-form";

const MySwitch = ({ control, name, rules, label, disabled }: any) => {
  const { field, fieldState } = useController({
    name: name,
    control: control,
    rules: rules,
  });

  console.log(fieldState.isDirty, fieldState.isTouched);

  const { onChange, value, ...props } = field;

  return (
    <FormControlLabel
      labelPlacement="end"
      label={label}
      control={
        <Switch
          {...props}
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
          disabled={disabled}
        />
      }
    />
  );
};

export default MySwitch;
