import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useRef, useState } from "react";

const NetworkConnectionStatus = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);

  const refKey = useRef<any>(null);

  const online = useCallback(() => {
    setOpen(false);
  }, []);

  const offline = useCallback(() => {
    setOpen(true);
  }, []);

  useEffect(() => {
    window.addEventListener("offline", offline);
    window.addEventListener("online", online);

    return () => {
      window.removeEventListener("offline", offline);
      window.removeEventListener("offline", online);
    };
  }, [offline, online]);

  useEffect(() => {
    if (open) {
      refKey.current = enqueueSnackbar(
        <div>
          <span>
            Network not available, please check your network connection
          </span>
        </div>,
        {
          variant: "warning",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
          autoHideDuration: null,
        }
      );
    } else if (refKey.current && !open) {
      closeSnackbar(refKey.current);

      enqueueSnackbar("Network is available!", {
        variant: "success",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
        autoHideDuration: 3000,
      });
    }
  }, [closeSnackbar, enqueueSnackbar, open]);

  return null;
};

export default NetworkConnectionStatus;
