import { useCallback } from "react";
import { createPortal } from "react-dom";

export const usePortal = () => {
  const Portal = useCallback(
    ({ children }: any) => createPortal(children, document.body),
    []
  );

  return { Portal };
};
