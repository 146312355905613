import React from "react";
import { useWatch } from "react-hook-form";
import MyCheckboxLabel from "../../../../System/FormComponents/MyCheckboxLabel";
import ShowAndHide from "../../../../System/FormComponents/ShowAndHide";
import FinciDetails from "./FinciDetails";

const Finci = ({ data, ...form }) => {
  const useFinci = useWatch({
    control: form?.control,
    name: "useFinci",
  });

  const finci = useFinci && data?.sepaFinciPayment;

  return (
    <ShowAndHide show={Boolean(finci)}>
      <MyCheckboxLabel
        control={form.control}
        name="useFinci"
        label="I want to confirm using Finci"
      />

      {finci ? (
        <div>
          <b className="text-main text-lg">
            Auto wire send: Will execute auto Finci payment
          </b>
        </div>
      ) : null}

      <div className="flex flex-col">
        <div className="flex flex-1 w-full justify-between">
          {useFinci && (
            <div className="flex flex-col">
              <b className="text-orange-600 text-lg">Finci Payment</b>
            </div>
          )}
        </div>

        {finci && (
          <ShowAndHide show>
            <FinciDetails finci={data?.sepaFinciPayment} />
          </ShowAndHide>
        )}
      </div>
    </ShowAndHide>
  );
};

export default Finci;
