import { store } from "@risingstack/react-easy-state";
import ENDPOINTS from "../../endpoints";
import { get, showError } from "../api";

interface ICommon {
  loading: boolean;
  countries: any[];
  documents: any[];
  currencies: any[];
  identityTypes: any[];

  [key: string]: any;
}

const common = store<ICommon>({
  loading: false,
  countries: [],
  documents: [],
  currencies: [],
  identityTypes: [],

  async fetchCommon() {
    common.loading = true;

    try {
      const res = await get(ENDPOINTS.common.items);
      common.countries = res.data.countries;
      common.documents = res.data.documents;
      common.identityTypes = res.data.identityTypes;
      common.loading = false;
      return true;
    } catch (err) {
      common.loading = false;
      showError(err);
      return false;
    }
  },

  async fetchCountries(params?: Record<string, any>) {
    try {
      const { data } = await get(ENDPOINTS.common.countries, params);
      common.countries = data || [];
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
});

export default common;
