import React from "react";
import { useEnter } from "../../hooks/useEnter";
import Alert from "./Alert";
import { Ays } from "./Design/Animations";
import FormActions from "./FormActions";

interface IAreYouSure {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loading?: boolean;
  confirmCallback?: (event?: any) => void;
  text?: any;
  size?: false | "xs" | "sm" | "md" | "lg" | "xl";
  headerText?: any;
  idConfirmButton?: string;
  idCloseButton?: string;
  customIcon?: any;
  customText?: any;
  disabledConfirmCallback?: boolean;
  cancelLabel?: string;
  cancelOnClick?: () => void;
  confirmLabel?: string;
  customClose?: () => void;
  hideCloseButton?: boolean;
}

const AreYouSure = ({
  open = false,
  setOpen,
  loading = false,
  confirmCallback,
  text = "Are you sure you want to perform this operation?",
  size = "xs",
  headerText = null,
  customIcon,
  customText,
  disabledConfirmCallback,
  cancelLabel,
  cancelOnClick,
  confirmLabel,
  customClose,
  hideCloseButton,
}: IAreYouSure) => {
  const close = () => {
    if (setOpen) {
      setOpen(false);
    }
  };

  const validComponent = String(customIcon).includes("React.createElement");

  const CustomIcon = validComponent ? customIcon : null;

  useEnter(open ? confirmCallback : null);

  return (
    <Alert
      open={open}
      setOpen={setOpen}
      customClose={
        customClose ? customClose : () => (setOpen ? setOpen(false) : null)
      }
      content={
        <div>
          {headerText && (
            <h1 className="text-2xl text-gray-600">
              <b>{headerText}</b>
            </h1>
          )}
          <div className="flex justify-center w-full">
            {CustomIcon && <CustomIcon />}
            {((!validComponent && customIcon === null) ||
              customIcon === undefined) && <Ays />}
            {!validComponent && customIcon}
          </div>

          {customText ? (
            customText
          ) : (
            <p className="text-2xl text-center break-words">{text}</p>
          )}

          <FormActions
            confirmButtonLabel={confirmLabel ? confirmLabel : "Confirm"}
            showConfirmButton
            showCloseButton={!hideCloseButton}
            disabledConfirmButton={loading || disabledConfirmCallback}
            onClickConfirm={confirmCallback}
            loading={loading}
            closeButtonLabel={cancelLabel ? cancelLabel : "Cancel"}
            onClickClose={cancelOnClick ? cancelOnClick : close}
          />
        </div>
      }
      size={size}
    />
  );
};

export default AreYouSure;
