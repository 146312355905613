import { IconButton, LinearProgress } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  getTaskStateBooleans,
  getTaskTypeBooleans,
} from "../../../../../helpers/task";
import auth from "../../../../../store/modules/auth";
import Actions from "./Actions";
import Attachments from "./Attachments";
import CardOrder from "./CardOrder";
import CryptoWithdraw from "./CryptoWithdraw";
import Deposit from "./Deposit";
import Events from "./Events";
import Gold from "./Gold";
import { useFetchTask } from "./hooks/useFetchTask";
import { useFetchWireRecipient } from "./hooks/useFetchWireRecipient";
import Message from "./Message";
import SendWire from "./SendWire";
import StateMessage from "./StateMessage";
import { getColorForState } from "./tools/getColorForState";
import Transfer from "./Transfer";

const Task = () => {
  const match = useRouteMatch<any>();

  const history = useHistory();

  const { taskId } = match.params;

  const { loading, task, fetchTask } = useFetchTask(taskId);

  const taskStateBooleans = getTaskStateBooleans(task);

  useEffect(() => {
    const taskTypeBooleans = getTaskTypeBooleans(task);

    if (taskTypeBooleans.isDeposit) {
      // logic
    }
  }, [task]);

  const { recipient } = useFetchWireRecipient(
    task?.firstUser?.id,
    task?.toWireRecipient?.id
  );

  const taskTypeBooleans = getTaskTypeBooleans(task);

  const isSendWire =
    (taskTypeBooleans.isSEPA || taskTypeBooleans.isSWIFT) &&
    !taskStateBooleans.isMessage;

  const isTransfer =
    (taskTypeBooleans.isTransfer ||
      taskTypeBooleans.isExchange ||
      taskTypeBooleans.isExchangeSell ||
      (taskTypeBooleans.isExchangeBuy && taskStateBooleans.isPending)) &&
    !taskStateBooleans.isMessage;

  const isDeposit = taskTypeBooleans.isDeposit && !taskStateBooleans.isMessage;

  const isGold = taskTypeBooleans.isGold && !taskStateBooleans.isMessage;

  const isCryptoWithdraw =
    (taskTypeBooleans.isCrypto && !taskStateBooleans.isMessage) ||
    (taskTypeBooleans.isExchangeBuy && taskStateBooleans.isPendingWithdraw);

  const isSecureMessage =
    taskTypeBooleans.isSecureMessage && !taskStateBooleans.isMessage;

  const colorState = getColorForState(task);

  const showSadRobot = task?.events?.some((value) => {
    return value.subject === "Error" && value.fromUserId?.includes("robot");
  });

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <>
      <IconButton onClick={history.goBack}>
        <ArrowBack />
      </IconButton>

      <div className="flex flex-col items-center">
        <h2 className="text-xl mb-4 flex">
          {!isSecureMessage && (
            <>
              <span className="mr-1.5" style={{ color: colorState }}>
                {task?.taskStateName}
              </span>

              <span>{task?.taskTypeName}</span>

              {!showSadRobot && task?.robot && (
                <span className="flex justify-end ml-[.5rem]">
                  <img src="/images/robot/work.svg" className="w-8" />
                </span>
              )}

              {showSadRobot && task?.robot && (
                <span className="flex justify-end ml-[.5rem]">
                  <img src="/images/robot/sad.svg" className="w-8" />
                </span>
              )}
            </>
          )}
        </h2>

        <Actions task={task} recipient={recipient} fetchTask={fetchTask} />

        {task?.toAccount?.accountTypeId === "DebitCard" && (
          <h2 className="text-lg text-center max-w-[26rem]">
            PLEASE MAKE SURE YOU HAVE MADE TRANSFER TO INSIGNIA ACCOUNT
          </h2>
        )}

        <div className="w-full max-w-[36rem]">
          {!!auth.profile && auth?.profile?.isController && (
            <Attachments files={task?.files || []} />
          )}

          {isSecureMessage && <Message task={task} />}

          {isSendWire && <SendWire task={task} recipient={recipient} />}

          {taskStateBooleans.isMessage && <StateMessage task={task} />}

          {isTransfer && <Transfer task={task} />}

          {isGold && <Gold task={task} />}

          {isDeposit && <Deposit task={task} />}

          {isCryptoWithdraw && <CryptoWithdraw task={task} />}

          {taskTypeBooleans.isCardOrder && <CardOrder task={task} />}

          {!!auth.profile &&
            !auth?.profile?.isController &&
            !(
              taskTypeBooleans.isExchangeBuy && taskStateBooleans.isPending
            ) && <Attachments files={task?.files || []} />}

          <Events events={task?.events || []} />
        </div>
      </div>
    </>
  );
};

export default Task;
