import { MenuItem, TextField } from "@material-ui/core";
import { view } from "@risingstack/react-easy-state";
import React from "react";
import templates from "../../../../../../../store/modules/templates";

const onChange = (event) => {
  const { value } = event.target;
  templates.params = {
    ...templates.params,
    type: value === "All" ? "" : value,
    pageIndex: 0,
  };
  templates.fetchItems();
};

const Type = view(() => {
  return (
    <TextField
      InputLabelProps={{
        shrink: true,
      }}
      label="Type"
      value={templates.params.type || "All"}
      onChange={onChange}
      select
      fullWidth
    >
      <MenuItem key="All" value="All">
        All
      </MenuItem>
      <MenuItem key="SWIFT" value="SWIFT">
        SWIFT
      </MenuItem>
      <MenuItem key="SEPA" value="SEPA">
        SEPA
      </MenuItem>
      <MenuItem key="Bitcoin" value="Bitcoin">
        Bitcoin
      </MenuItem>
    </TextField>
  );
});

export default Type;
