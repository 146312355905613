import { InputAdornment, TextField } from "@material-ui/core";
import React from "react";
import {
  checkIsCryptoCurrency,
  symbols,
} from "../../../helpers/formatToCurrencyString";
import Input from "./Input";

const CustomAmount = ({
  autoFocus,
  currency,
  disabled,
  label,
  percentage,
  onFocus,
  decimal,
  value,
  onChange,
}: any) => {
  const customOnChange = (e) => {
    onChange(e, e.target.value);
  };

  const isCrypto = checkIsCryptoCurrency(currency);

  return (
    <TextField
      value={value}
      onChange={customOnChange}
      onFocus={(e) => {
        if (onFocus) {
          onFocus(e);
        }
      }}
      autoFocus={autoFocus}
      type="text"
      InputProps={
        {
          inputComponent: Input,
          inputProps: {
            autoComplete: "new-password",
            decimal: decimal,
            isCrypto: isCrypto,
          },
          startAdornment: (
            <InputAdornment position="start">
              <b className="text-lg">{percentage ? "%" : symbols[currency]}</b>
            </InputAdornment>
          ),
        } as any
      }
      InputLabelProps={{
        shrink: true,
      }}
      fullWidth
      disabled={disabled}
      label={label}
    />
  );
};

export default CustomAmount;
