import { view } from "@risingstack/react-easy-state";
import React, { memo, useEffect, useState } from "react";
import ui from "../../store/modules/ui";
import Anima from "../System/Anima";
import LazyImage from "../System/LazyImage";

const LazyAnima = memo(({ onComplete }: any) => {
  return (
    <Anima
      json={"/keys/salute.json"}
      loop={false}
      onComplete={onComplete}
      center
    />
  );
});

const Salute = view((): any => {
  const [open, setOpen] = useState(false);

  const { salute } = ui;

  useEffect(() => {
    if (salute) {
      setOpen(true);
    }
  }, [salute]);

  const onComplete = () => {
    setOpen(false);
    ui.salute = false;
  };

  return (
    open && (
      <div className="fixed inset-0 z-[2000] pointer-events-none">
        <LazyImage anima={LazyAnima} animaProps={{ onComplete }} />
      </div>
    )
  );
});

export default Salute;
