import { view } from "@risingstack/react-easy-state";
import numbro from "numbro";
import React, { useEffect, useState } from "react";
import { formatToCurrencyString } from "../../helpers";
import { useQuerySocketQuotes } from "../../queries/useQuerySocketQuotes";

const BuyPrice = view(({ amount, pair }: any) => {
  const [price, setPrice] = useState({
    rate: 0,
  });

  const { setPair } = useQuerySocketQuotes({
    onUpdated: (data) => setPrice(data),
  });

  useEffect(() => {
    setPair(pair);
  }, [pair, setPair]);

  const unformat = numbro.unformat(amount || 0);

  const value = unformat * price.rate || price.rate;

  return (
    <div className="text-lg text-gray-500">
      <span>@ {formatToCurrencyString(value, "USD")}</span>
    </div>
  );
});

export default BuyPrice;
