import { makeStyles } from "@material-ui/core/styles";
import { view } from "@risingstack/react-easy-state";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { formatToCurrencyString } from "../../../../../../../helpers/formatToCurrencyString";
import cryptoAdmin from "../../../../../../../store/modules/cryptoAdmin";
import MigomTable from "../../../../../../System/MigomTable";

const useStyles = makeStyles({
  grey: {
    backgroundColor: "#eee  !important",
  },
  white: {
    backgroundColor: "#fff !important",
  },
  unknown: {
    backgroundColor: "#ecf7ff !important",
  },
});

const CryptoOperations = view(({ transactionId }) => {
  const classes = useStyles();
  const [data, setData] = useState([]);

  const columns = useMemo(() => {
    return [
      {
        Header: "Cur.",
        id: "currency",
        style: {
          fontSize: ".7rem",
        },
        styleHead: {
          whiteSpace: "nowrap",
        },
        accessor: (rowData) => {
          return rowData.currency;
        },
      },
      {
        Header: "Amount",
        id: "amount",
        style: {
          fontSize: ".7rem",
          textAlign: "right",
        },
        styleHead: {
          whiteSpace: "nowrap",
          textAlign: "right",
        },
        accessor: (rowData) => {
          if (rowData.amount < 0) {
            return (
              <b className="text-red-500">
                {formatToCurrencyString(rowData.amount, rowData.currency)}
              </b>
            );
          }
          return (
            <b className="text-main">
              {formatToCurrencyString(rowData.amount, rowData.currency)}
            </b>
          );
        },
      },
      {
        Header: "Fees",
        id: "fees",
        style: {
          fontSize: ".7rem",
          textAlign: "right",
        },
        styleHead: {
          whiteSpace: "nowrap",
          textAlign: "right",
        },
        accessor: (rowData) => {
          return formatToCurrencyString(rowData.fees, rowData.currency);
        },
      },
      {
        Header: "Balance",
        id: "balance",
        style: {
          fontSize: ".7rem",
          textAlign: "right",
        },
        styleHead: {
          whiteSpace: "nowrap",
          textAlign: "right",
        },
        accessor: (rowData) => {
          return rowData.balance !== undefined && rowData.balance.toFixed(3);
        },
      },
    ];
  }, []);

  const makeGroups = cryptoAdmin.operations.reduce((r, a) => {
    if (a.orderId) {
      r[a.orderId] = true;
    }

    return r;
  }, Object.create(null));

  let groups = [];

  if (makeGroups) {
    const keys = Object.keys(makeGroups);
    groups = keys.map((item, index) => ({
      orderId: item,
      color: index % 2 ? true : false,
    }));
  }

  const fetcher = useCallback(async () => {
    const result = await cryptoAdmin.fetchCryptoOperationsCustom({
      transactionId,
      perPage: 999,
    });

    if (result) {
      setData(result?.data);
    }
  }, [transactionId]);

  useEffect(() => {
    fetcher();
  }, [fetcher]);

  return (
    data?.length > 0 && (
      <div>
        <div className="h-4" />

        <div className="flex justify-center">
          <h2>Crypto operations</h2>
        </div>

        <div className="h-4" />

        <MigomTable
          columns={columns}
          data={data}
          useFeatures
          makeRowClass={(rowData) => {
            const findGroup = groups.find(
              (item) => item.orderId === rowData.orderId + ""
            );

            if (findGroup && findGroup.color) {
              return classes.grey;
            }

            if (findGroup && !findGroup.color) {
              return classes.white;
            }

            return classes.unknown;
          }}
          hidePagination
          autoHeight
        />
      </div>
    )
  );
});

export default CryptoOperations;
