import { memo, useEffect, useRef } from "react";
import { useGetAccounts } from "./../hooks/useGetAccounts";

export default memo(({ form, currentAccount }: any) => {
  const isLoad = useRef(false);

  const { fromAccount, toAccount } = useGetAccounts({
    userId: currentAccount?.userId,
    form,
  });

  const { setValue } = form;

  useEffect(() => {
    if (isLoad.current) {
      setTimeout(() => setValue("sellAmount", ""), 0);
      setTimeout(() => setValue("buyAmount", ""), 0);
    }

    if (!isLoad.current && fromAccount?.accountId && toAccount?.accountId) {
      isLoad.current = true;
    }
  }, [setValue, fromAccount, toAccount]);

  return null;
});
