import { useCallback, useEffect } from "react";
import blocks from "../store/modules/blocks";

export const useBlocks = ({ sections, sectionName }) => {
  const load = useCallback(async () => {
    if (!sections[sectionName]) {
      await blocks.fetchBlocksBySection({ sectionId: sectionName });
    }
  }, [sections, sectionName]);

  useEffect(() => {
    load();
  }, [load]);

  return useCallback(
    (id) => {
      return blocks.getBlockById(id, sectionName);
    },
    [sectionName]
  );
};
