import { Button, DialogActions } from "@material-ui/core";
import numbro from "numbro";
import React, { useState } from "react";
import {
  formatDateToMMddyyyyHHmm,
  formatToCurrencyString,
} from "../../../../../helpers";
import Field from "../../../../System/FormComponents/Field";
import Credit from "./Credit";
import Finci from "./Finci";
import GreenButton from "./GreenButton";
import PreviewTable from "./PreviewTable";
import Sepa from "./Sepa";
import Sfox from "./Sfox";
import SfoxExchange from "./SfoxExchange";
import Swift from "./Swift";

const WrapCredit = ({ account, preview, status, parentForm }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <a onClick={() => setOpen(true)}>Do you want to make credit?</a>

      <Credit
        open={open}
        setOpen={setOpen}
        rowAccount={account}
        successCallback={preview}
        status={status}
        parentForm={parentForm}
      />
    </>
  );
};

const Preview = ({
  data,
  setStep,
  setOpen,
  confirm,
  preview,
  loading,
  hideConfirmButtons,
  form,
  account,
  dayId,
  hideFeeStructure,
}: any) => {
  if (!data) {
    return null;
  }

  const values = form.getValues();

  const totalMasterFees = data.transaction?.operation?.reduce((acc, item) => {
    if (item?.accountId?.includes("MASTER-FEE")) {
      return (acc += item?.amount || 0);
    }

    return acc;
  }, 0);

  const totalDeposits = data.transaction?.totalDeposits?.map((item) =>
    formatToCurrencyString(item.value, item.currencyId)
  );

  const totalWithdraws = data.transaction?.totalWithdraws?.map((item) =>
    formatToCurrencyString(item.value, item.currencyId)
  );

  // const feeCalcDescription = data.transaction?.operation[0]?.feeCalcDescription;

  const showCreditLink = data?.status?.includes("desired amount");

  const totalFees = data.transaction?.totalFees?.map((item) =>
    formatToCurrencyString(item.value, item.currencyId)
  );

  return (
    <div>
      <div className="max-w-md w-full mx-auto">
        {data?.transaction?.transactionId && (
          <Field
            className="text-lg"
            label="Transaction ID"
            value={<b>{data.transaction.transactionId}</b>}
          />
        )}

        {totalFees?.length > 0 && (
          <Field
            className="text-lg"
            label="Total fees"
            value={totalFees.join(", ")}
          />
        )}

        {(numbro.unformat(values?.fixedOurCost) || 0) > 0 && (
          <Field
            className="text-lg"
            label="Our Cost"
            value={`-${formatToCurrencyString(
              values?.fixedOurCost,
              values.currencyId
            )}`}
          />
        )}

        <Field
          className="text-lg"
          label="Bank's profit"
          value={formatToCurrencyString(totalMasterFees, values.currencyId)}
        />

        {totalMasterFees < 0 && !values?.our && (
          <p className="text-red-400 font-bold text-2xl">
            Attention, the bank&apos;s expenses are higher than the
            client&apos;s commission!
          </p>
        )}

        {totalDeposits?.length > 0 && (
          <Field
            className="text-lg"
            label="Total deposits"
            value={totalDeposits.join(", ")}
          />
        )}

        {totalWithdraws?.length > 0 && (
          <Field
            className="text-lg"
            label="Total withdraws"
            value={totalWithdraws.join(", ")}
          />
        )}

        {dayId && (
          <Field className="text-lg" label="Transaction time" value={dayId} />
        )}

        {values.transactionTime && (
          <Field
            className="text-lg"
            label="Transaction time"
            value={formatDateToMMddyyyyHHmm(
              values.transactionTime.toISOString()
            )}
          />
        )}

        <Field
          className="text-lg"
          label="This will generate"
          value={
            data?.transaction?.isPending ? (
              <span>
                <b>pending</b> transaction
              </span>
            ) : (
              <span>
                <b>real</b> transaction
              </span>
            )
          }
        />

        <Field
          className="text-lg"
          label="Negative balance"
          value={
            data?.request?.allowNegativeBalance ? (
              <b className="text-red-500">allowed</b>
            ) : (
              "not allowed"
            )
          }
        />

        <Sepa {...form} data={data} />
        <Swift {...form} data={data} />
        <Sfox {...form} data={data} />
        <SfoxExchange {...form} data={data} />
        <Finci {...form} data={data} />
      </div>

      <div className="h-4" />

      <PreviewTable
        data={data?.transaction?.operation}
        hideFeeStructure={hideFeeStructure}
      />

      <div className="h-2.5" />

      {data?.status && (
        <div className="flex flex-1 justify-end">
          <div className="flex flex-col">
            <b className="text-red-500 text-lg">{data.status}</b>
            <span>
              {showCreditLink && (
                <WrapCredit
                  account={account}
                  preview={preview}
                  status={data?.status}
                  parentForm={form}
                />
              )}
            </span>
          </div>
        </div>
      )}

      {!hideConfirmButtons && (
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setStep(1)}
          >
            Back
          </Button>

          <GreenButton
            onClick={confirm}
            disabled={loading || Boolean(data.status)}
            label="Confirm"
            loading={loading}
          />
        </DialogActions>
      )}

      {hideConfirmButtons && (
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      )}
    </div>
  );
};

export default Preview;
