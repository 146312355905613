import { Checkbox, FormControlLabel } from "@material-ui/core";
import React from "react";

const CustomCheckboxLabel = ({
  label,
  disabled,
  checked,
  onChange,
  name,
  style,
  isSmall,
  styleLabel,
  className,
}: any) => {
  return (
    <FormControlLabel
      label={label}
      style={styleLabel}
      disabled={disabled}
      className={className}
      control={
        <Checkbox
          checked={checked}
          name={name}
          style={style}
          size={isSmall ? "small" : "medium"}
          onChange={(e) => onChange(e, e.target.checked)}
        />
      }
    />
  );
};

export default CustomCheckboxLabel;
