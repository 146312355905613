import { store } from "@risingstack/react-easy-state";
import ENDPOINTS from "../../endpoints";
import { getBase64 } from "../../helpers";
import { all, del, get, post, showError } from "../api";

interface INotifications {
  loading: boolean;
  items: any[];
  notification: any;
  paymentResponse?: Record<string, any>; // SERA
  paymentResponse2?: Record<string, any>; // SWIFT
  openNotify: boolean;
  pages: number;

  params: Record<string, any>;

  [key: string]: any;
}

const notifications = store<INotifications>({
  loading: false,
  items: [],
  notification: {
    attachments: [],
  },
  paymentResponse: undefined, // SERA
  paymentResponse2: undefined, // SWIFT

  openNotify: false,

  pages: 1,

  params: {
    type: undefined,
    read: undefined,
    unread: undefined,
    pending: "1",
    approved: undefined,
    rejected: undefined,
    pageIndex: 0,
    perPage: 20,
    sortBy: undefined,
    sortDirection: undefined,
    direction: undefined,
    search: undefined,
  },

  async uploadFiles(list: any[]) {
    try {
      const promises = list.map((item: { data: string | Blob }) => {
        const payload = new FormData();
        payload.append("formFile", item.data);
        return post(ENDPOINTS.notifications.upload, payload, {
          "Content-Type": "multipart/form-data",
        });
      });

      const results = await all(promises);

      if (results && results?.length > 0) {
        const ids: boolean | any[] | PromiseLike<false | any[]> = [];
        results.forEach((res) => {
          ids.push(res.data.id);
        });

        return ids;
      }
      return [];
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchNotifications() {
    notifications.loading = true;
    notifications.items = [];
    try {
      const params = {
        ...notifications.params,
        page: notifications.params.pageIndex + 1,
      } as Record<string, any>;

      delete params.pageIndex;

      const { data } = await get(ENDPOINTS.notifications.items, params);

      notifications.pages = data.pages;
      notifications.items = data.data;

      notifications.loading = false;

      return true;
    } catch (err) {
      notifications.loading = false;
      showError(err);

      return false;
    }
  },

  async fetchNotificationsV2(params: Record<string, any> | undefined) {
    notifications.loading = true;
    try {
      const { data } = await get(ENDPOINTS.notifications.items, params);

      notifications.pages = data.pages;
      notifications.items = data.data;
      notifications.loading = false;

      return true;
    } catch (err) {
      notifications.loading = false;
      showError(err);

      return false;
    }
  },

  async deleteNotification(notificationId: string) {
    try {
      await del(
        ENDPOINTS.notifications.deleteNotification.replace(
          "{notificationId}",
          notificationId
        )
      );
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async issueCardByController(payload: Record<string, any> | undefined) {
    try {
      const { data } = await post(
        ENDPOINTS.prepaidGate.issueCardByController,
        payload
      );
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async getCardInfo(sanOrPan: string) {
    try {
      const { data } = await get(
        ENDPOINTS.prepaidGate.getCardInfo.replace("{sanOrPan}", sanOrPan)
      );
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async fetchNotificationsByUserId(
    userId: string,
    params: Record<string, any> | undefined
  ) {
    try {
      const { data } = await get(
        ENDPOINTS.notifications.byUser.replace("{userId}", userId),
        params
      );
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async fetchNotificationByUserId(
    userId: string,
    notificationId: string,
    cb: (() => void) | undefined
  ) {
    notifications.loading = true;
    try {
      const res = await get(
        `${ENDPOINTS.notifications.byUser.replace(
          "{userId}",
          userId
        )}/${notificationId}`
      );
      notifications.notification = res.data;
      notifications.loading = false;

      notifications.items.forEach((item) => {
        if (item.id === notificationId) {
          item.isRead = true;
        }
      });

      notifications.items = [...notifications.items];

      if (cb) {
        cb();
      }
    } catch (err) {
      notifications.loading = false;
      showError(err);
    }
  },
  async fetchNotification(notificationId: any, cb: (() => void) | undefined) {
    notifications.loading = true;
    try {
      const res = await get(
        `${ENDPOINTS.notifications.items}/${notificationId}`
      );
      notifications.notification = res.data;
      notifications.loading = false;

      notifications.items.forEach((item) => {
        if (item.id === notificationId) {
          item.isRead = true;
        }
      });
      notifications.items = [...notifications.items];

      if (cb) {
        cb();
      }

      return res.data;
    } catch (err) {
      notifications.loading = false;
      showError(err);
      return false;
    }
  },
  async fetchNotificationForAdmin(notificationId: string) {
    try {
      const res = await get(
        ENDPOINTS.notifications.fetchNotificationForAdmin.replace(
          "{notificationId}",
          notificationId
        )
      );
      notifications.notification = res.data;
      notifications.loading = false;

      notifications.items.forEach((item) => {
        if (item.id === notificationId) {
          item.isRead = true;
        }
      });
      notifications.items = [...notifications.items];

      return res.data;
    } catch (err) {
      return false;
    }
  },

  async fetchNotificationAnswer(notificationId: string) {
    notifications.loading = true;
    try {
      const res = await get(
        `${ENDPOINTS.notifications.items}/${notificationId}`
      );
      notifications.loading = false;

      return res.data?.body;
    } catch (err) {
      notifications.loading = false;
      showError(err);
      return false;
    }
  },
  async fetchNotificationForAdminAnswer(notificationId: string) {
    try {
      const res = await get(
        ENDPOINTS.notifications.fetchNotificationForAdmin.replace(
          "{notificationId}",
          notificationId
        )
      );

      return res.data?.body;
    } catch (err) {
      return false;
    }
  },

  async fetchAttachment(notificationId: string, attachmentId: string) {
    try {
      const response = await get(
        ENDPOINTS.notifications.fetchAttachment
          .replace("{notificationId}", notificationId)
          .replace("{attachmentId}", attachmentId),
        undefined,
        "arraybuffer"
      );
      if (response.data) {
        return getBase64(response);
      }
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async fetchAddressAttachmentsArray(notificationId: string, attachments = []) {
    try {
      const attachmentsMap: any[] = [];
      attachments.forEach((attachmentId) => {
        const promise = get(
          ENDPOINTS.notifications.fetchAttachment
            .replace("{notificationId}", notificationId)
            .replace("{attachmentId}", attachmentId),
          undefined,
          "arraybuffer"
        );
        attachmentsMap.push(promise);
      });

      const responses = await all(attachmentsMap);

      return responses.map((item, index) => {
        return {
          data: item.data,
          type: item.headers["content-type"],
          base: getBase64(item),
          name: attachments[index],
        };
      });
    } catch (err) {
      showError(err);
      return [];
    }
  },

  async sendPush(
    userId: string,
    notificationId: string,
    payload: Record<string, any> | undefined
  ) {
    try {
      await post(
        ENDPOINTS.notifications.sendPush
          .replace("{userId}", userId)
          .replace("{notificationId}", notificationId),
        payload
      );
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchAttachmentDocument(notificationId: string, attachmentId: string) {
    notifications.loading = true;
    try {
      const { data, headers } = await get(
        ENDPOINTS.notifications.fetchAttachment
          .replace("{notificationId}", notificationId)
          .replace("{attachmentId}", attachmentId),
        undefined,
        "arraybuffer"
      );
      const contentType = headers["content-type"];

      const file = new Blob([data], { type: contentType });
      const fileURL = URL.createObjectURL(file);

      open(fileURL, "_blank");
      notifications.loading = false;
      return true;
    } catch (err) {
      showError(err);
      notifications.loading = false;
      return false;
    }
  },

  async getImageForAdmin(notificationId: string, attachmentId: string) {
    try {
      const response = await get(
        ENDPOINTS.notifications.getImageForAdmin
          .replace("{notificationId}", notificationId)
          .replace("{attachmentId}", attachmentId),
        undefined,
        "arraybuffer"
      );
      if (response.data) {
        return getBase64(response);
      }
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async pushNotification(
    userId: string,
    payload: Record<string, any> | undefined
  ) {
    try {
      await post(
        `${ENDPOINTS.notifications.pushNotification.replace(
          "{userId}",
          userId
        )}`,
        payload
      );

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async approveAddress(
    userId: string,
    payload: Record<string, any> | undefined
  ) {
    try {
      await post(
        ENDPOINTS.notifications.approveAddress.replace("{userId}", userId),
        payload
      );

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async rejectAddress(
    userId: string,
    payload: Record<string, any> | undefined
  ) {
    try {
      await post(
        ENDPOINTS.notifications.rejectAddress.replace("{userId}", userId),
        payload
      );

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async reject(
    id: string,
    payload: Record<string, any> | undefined,
    cb: () => void
  ) {
    notifications.loading = true;
    try {
      await post(
        `${ENDPOINTS.notifications.reject.replace("{notificationId}", id)}`,
        payload
      );

      notifications.loading = false;

      cb();
    } catch (err) {
      notifications.loading = false;
      showError(err);
    }
  },
  async sendEmailToUser(
    notificationId: string,
    payload: Record<string, any> | undefined
  ) {
    try {
      const { data } = await post(
        ENDPOINTS.notifications.sendEmailToUser.replace(
          "{notificationId}",
          notificationId
        ),
        payload
      );
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async rejectOperation(
    notificationId: string,
    payload: Record<string, any> | undefined
  ) {
    try {
      const { data } = await post(ENDPOINTS.notifications.rejectOperation, {
        ...payload,
        notificationId,
      });
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async printNotificationUser(userId: string, notificationId: string) {
    try {
      const { data, headers } = await get(
        ENDPOINTS.notifications.printNotificationUser
          .replace("{userId}", userId)
          .replace("{notificationId}", notificationId),
        undefined,
        "arraybuffer"
      );

      const contentType = headers["content-type"];

      const file = new Blob([data], { type: contentType });
      const fileURL = URL.createObjectURL(file);

      open(fileURL, "_blank");
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async printNotification(notificationId: string) {
    try {
      const { data, headers } = await get(
        ENDPOINTS.notifications.printNotification.replace(
          "{notificationId}",
          notificationId
        ),
        undefined,
        "arraybuffer"
      );
      const contentType = headers["content-type"];

      const file = new Blob([data], { type: contentType });
      const fileURL = URL.createObjectURL(file);

      open(fileURL, "_blank");
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async createNotification(
    notificationId: string,
    answer: string,
    userIdTo: string,
    body: Record<string, any> | undefined
  ) {
    try {
      await post(
        ENDPOINTS.notifications.createNotification
          .replace("{notificationId}", notificationId)
          .replace("{answer}", answer)
          .replace("{userIdTo}", userIdTo),
        body
      );
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async deleteWireSendNotification(notificationId: string) {
    try {
      await del(
        ENDPOINTS.notifications.deleteWireSendNotification.replace(
          "{notificationId}",
          notificationId
        )
      );

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async approveSendWireTreasure(notificationId: string) {
    try {
      const { data } = await post(
        ENDPOINTS.notifications.approveSendWireTreasure,
        {
          notificationId,
        }
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
});

export default notifications;
