import { FormHelperText } from "@material-ui/core";
import React, { memo } from "react";
import { Control, FormState } from "react-hook-form";
import { IFormClientDeposit } from "../../Roles/Client/Pay/Deposit/interfaces/stepProps";
import IAddMigomRecipientsForm from "../../Roles/Client/Recipients/MigomRecipients/interfaces/addMigomRecipientsForm";
import MyTextField from "../../System/FormComponents/MyTextField";
import IForgotPasswordForm from "../interfaces/forgotPasswordForm";
import ISignInForm from "../interfaces/signInForm";
import ISignUpShortForm from "../interfaces/signUpShortForm";

export default memo(
  ({
    control,
    formState,
    autoComplete,
    label = "Email address*",
    noEmailValidation,
    readOnly,
  }: {
    control?:
      | Control<IForgotPasswordForm>
      | Control<ISignInForm>
      | Control<ISignUpShortForm>
      | Control<IAddMigomRecipientsForm>
      | Control<IFormClientDeposit>;
    formState?:
      | FormState<IForgotPasswordForm>
      | FormState<ISignInForm>
      | FormState<ISignUpShortForm>
      | FormState<IAddMigomRecipientsForm>
      | FormState<IFormClientDeposit>;
    autoComplete?: string;
    label?: string;
    noEmailValidation?: boolean;
    tabindex?: string;
    readOnly?: boolean;
  }) => {
    return (
      <div className="w-full">
        <MyTextField
          readOnly={readOnly}
          control={control}
          inputProps={{
            id: "email",
          }}
          aria-required="true"
          rules={{
            required: true,
            validate: {
              valid: (value) => {
                if (noEmailValidation) {
                  return true;
                }

                if (value && value.includes("@")) {
                  return true;
                }

                return false;
              },
            },
          }}
          name="email"
          label={label}
          aria-label={label}
          fullWidth
          autoComplete={autoComplete}
        />

        {formState?.errors?.email?.type === "required" && (
          <FormHelperText error>Field is required</FormHelperText>
        )}

        {formState?.errors?.email?.type === "valid" && (
          <FormHelperText error>Enter a valid email</FormHelperText>
        )}
      </div>
    );
  }
);
