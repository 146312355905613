import { clsx } from "clsx";
import React from "react";

const SubTitle = ({
  children,
  className,
}: {
  children: JSX.Element | string;
  className?: string;
}) => {
  return (
    <div
      className={clsx("my-4 w-full text-center flex justify-center", className)}
    >
      <h2 className="m-0 p-0 max-w-md">{children}</h2>
    </div>
  );
};

export default SubTitle;
