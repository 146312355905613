import { Button, DialogActions } from "@material-ui/core";
import numbro from "numbro";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { formatToCurrencyString } from "../../../../../../helpers/formatToCurrencyString";
import transactive from "../../../../../../store/modules/transactive";
import Alert from "../../../../../System/Alert";
import ShowAndHide from "../../../../../System/FormComponents/ShowAndHide";
import Amount from "../components/Amount";
import Comment from "../components/Comment";
import GreenButton from "../components/GreenButton";
import { makeRebalancePreviewPayload } from "./tools";

const ResultPreview = ({ resultPreview, getValues }) => {
  if (!resultPreview) {
    return null;
  }

  const values = getValues();

  const { amount, from, to, instructionId, senderReference, type } =
    resultPreview;

  return (
    <div className="flex flex-col">
      <b className="text-center text-5xl text-main">
        {formatToCurrencyString(amount.value, amount.currency)}
      </b>

      <div className="h-8" />

      <div className="text-xl pb-2 mb-2 flex justify-between flex-1 border-b-2 border-solid border-gray-200">
        <span>From:</span>
        <span>
          {from.name}, <b>{from.iban}</b>
        </span>
      </div>

      <div className="text-xl pb-2 mb-2 flex justify-between flex-1 border-b-2 border-solid border-gray-200">
        <span>To:</span>
        <span>
          {to.name}, <b>{to.iban}</b>
        </span>
      </div>

      <div className="text-xl pb-2 mb-2 flex justify-between flex-1 border-b-2 border-solid border-gray-200">
        {type}, {resultPreview.class}, {instructionId}, {senderReference}
      </div>

      <div className="text-xl pb-2 mb-2 flex justify-between flex-1 border-b-2 border-solid border-gray-200">
        <span className="mr-4">Comment:</span>{" "}
        <span className="w-full">{values.comment}</span>
      </div>
    </div>
  );
};

const Credit = ({
  open,
  setOpen,
  task,
  successCallback,
  status,
  parentForm,
}) => {
  const [resultPreview, setResultPreview] = useState<any>();
  const [step, setStep] = useState(1);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);

  const form = useForm({
    defaultValues: {
      currencyId: "",
      amount: "",
      comment: "Rebalanced to client",
    },
    shouldUnregister: false,
  });

  const { setValue, handleSubmit, register } = form;

  useEffect(() => {
    register("currencyId");
  }, [register]);

  const { getValues } = parentForm;

  useEffect(() => {
    if (open && task?.currencyId) {
      const getBalanceFromString = status
        .split("balance ")[1]
        .split(" is")[0]
        .replace("€", "");

      if (getBalanceFromString) {
        const parentAmount = parentForm?.getValues("amount");
        const balance = numbro.unformat(getBalanceFromString) || 0;
        const amount = numbro.unformat(parentAmount) || 0;
        setTimeout(() => setValue("amount", (amount - balance + 1).toString()));
      }

      setTimeout(() => setValue("currencyId", task?.currencyId));
    }
  }, [open, task?.currencyId, setValue, status, getValues, parentForm]);

  const preview = async (values) => {
    setLoadingPreview(true);

    const payload = makeRebalancePreviewPayload({ values, task });

    const result = await transactive.preview(payload);

    if (result) {
      setResultPreview(result);
      setStep(2);
    }

    setLoadingPreview(false);
  };

  const confirm = async () => {
    setLoadingConfirm(true);
    const result = await transactive.confirm(resultPreview);

    if (result) {
      setSuccess(true);

      if (successCallback) {
        successCallback();
        setOpen(false);
      }
    }
    setLoadingConfirm(false);
  };

  return (
    <Alert
      open={open}
      setOpen={setOpen}
      disabledClose={loadingPreview || loadingConfirm}
      hideCloseBtn={loadingPreview || loadingConfirm}
      content={
        <div>
          <h1 className="font-bold text-3xl text-center m-0 text-gray-600">
            Credit to {task?.fromAccount?.number}
          </h1>

          <ShowAndHide show={step === 1}>
            <div>
              <Amount {...form} autoFocus />

              <div className="h-2.5" />

              <Comment {...form} />

              <div className="h-4" />

              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpen(false)}
                  disabled={loadingPreview}
                >
                  Close
                </Button>

                <GreenButton
                  onClick={handleSubmit(preview)}
                  label="Next"
                  loading={loadingPreview}
                  disabled={loadingPreview}
                />
              </DialogActions>
            </div>
          </ShowAndHide>

          <ShowAndHide show={step === 2}>
            <div>
              <ResultPreview resultPreview={resultPreview} {...form} />

              {!success && (
                <DialogActions>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={loadingConfirm}
                    onClick={() => setStep((prevState) => prevState - 1)}
                  >
                    Back
                  </Button>

                  <GreenButton
                    onClick={confirm}
                    label="Confirm"
                    loading={loadingConfirm}
                    disabled={loadingConfirm}
                  />
                </DialogActions>
              )}

              {success && (
                <DialogActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </Button>
                </DialogActions>
              )}
            </div>
          </ShowAndHide>
        </div>
      }
      size="sm"
    />
  );
};

export default Credit;
