import { ArrowUpward } from "@material-ui/icons";
import { view } from "@risingstack/react-easy-state";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { formatToCurrencyString } from "../../../../../../helpers";
import admin from "../../../../../../store/modules/admin";
import auth from "../../../../../../store/modules/auth";
import MigomCryptoSender from "../../../../../MigomCryptoSender";
import { Alert } from "../../../../../System";
import LazyImage from "../../../../../System/LazyImage";
import Preview from "../components/Preview";
import SfoxDetail from "../components/SfoxDetail";
import Title from "../components/Title";
import TitleContent from "../components/TitleContent";
import AreYouSure from "./../../../../../System/AreYouSure";

const WithdrawCrypto = view(({ task, open, setOpen }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const form = useForm({
    defaultValues: {
      useSfox: false,
    },
    mode: "onChange",
    shouldUnregister: false,
  });

  const [loading, setLoading] = useState(false);
  const [hideConfirmButtons, setHideConfirmButtons] = useState(false);
  const [previewData, setPreviewData] = useState<any>();

  const [openAreYouSure, setOpenAreYouSure] = useState(false);

  const { setValue } = form;

  const preview = useCallback(async () => {
    setLoading(true);

    const payload = {
      pendingTransactionId: task?.taskId,
    };

    const [status, result] = await admin.previewExisting(payload);

    if (status) {
      setPreviewData(result);

      if (result?.cryptoWithdrawOperation) {
        setValue("useSfox", true);
      }
    }

    setLoading(false);
  }, [setValue, task?.taskId]);

  useEffect(() => {
    preview();
  }, [preview]);

  const useSfox = useWatch({
    name: "useSfox",
    control: form.control,
  });

  const confirm = async () => {
    setLoading(true);

    const payload = { ...previewData };

    if (!useSfox && payload?.cryptoWithdrawOperation) {
      delete payload.cryptoWithdrawOperation;
    }

    const result = await admin.confirm(payload);

    if (result) {
      setHideConfirmButtons(true);

      enqueueSnackbar("Operation completed successfully", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });

      if (!!auth?.profile && auth?.profile?.isAdmin) {
        history.goBack();
      } else {
        history.push(`/app/tasks/inbox/crypto`);
      }
    }

    setLoading(false);
  };

  const sfox = previewData?.cryptoWithdrawOperation || {};

  return (
    <Alert
      open={open}
      setOpen={setOpen}
      disabledClose={loading}
      hideCloseBtn={loading}
      size="md"
      content={
        <>
          <Title
            icon={
              <ArrowUpward style={{ color: "inherit", fontSize: "2.4rem" }} />
            }
            text={<TitleContent title="Withdraw" />}
          />

          <div className="h-4" />

          <Preview
            data={previewData}
            confirm={() => setOpenAreYouSure(true)}
            hideConfirmButtons={hideConfirmButtons}
            loading={loading}
            setOpen={setOpen}
            form={form}
            task={task}
          />

          <AreYouSure
            open={openAreYouSure && useSfox}
            customIcon={
              task?.currencyId === "MIGG" ? (
                <MigomCryptoSender />
              ) : (
                <LazyImage
                  src="/images/design/sfox_logo.png"
                  className="w-40"
                />
              )
            }
            setOpen={setOpenAreYouSure}
            confirmCallback={confirm}
            loading={loading}
            disabledConfirmCallback={loading}
            confirmLabel="Make Payment"
            customText={
              <div className="mt-4">
                <h2 className="mb-2">
                  Ready to pay{" "}
                  {formatToCurrencyString(
                    sfox?.cryptoAmount || 0,
                    sfox?.cryptoCurrencyId?.toUpperCase()
                  )}
                </h2>

                <SfoxDetail showDetail sfox={sfox} />
              </div>
            }
            hideCloseButton
          />

          <AreYouSure
            open={openAreYouSure && !useSfox}
            setOpen={setOpenAreYouSure}
            confirmCallback={confirm}
            loading={loading}
            disabledConfirmCallback={loading}
            confirmLabel="Make Payment"
            hideCloseButton
          />
        </>
      }
    />
  );
});

export default WithdrawCrypto;
