import React from "react";
import MySwitch from "../../../../../System/FormComponents/MySwitch";

const ShowAdvancedOptions = ({ prefix = "", control }) => {
  return (
    <div>
      <div className="h-2.5" />
      <MySwitch
        control={control}
        name={prefix + "showAdvancedOptions"}
        label="Show advanced options"
      />
    </div>
  );
};

export default ShowAdvancedOptions;
