import { store } from "@risingstack/react-easy-state";
import ENDPOINTS from "../../endpoints";
import IWireRecipient from "../../interfaces/wireRecipient";
import { del, get, patch, post, showError } from "../api";

const recipients = store({
  loading: false,
  items: [],
  item: {
    aboutRecipientBank: {},
    finallyInformation: {},
    forInternationalRecipients: {},
  },

  async addWireRecipient(payload: IWireRecipient, callback?: () => void) {
    try {
      await post(ENDPOINTS.recipients.createItem, payload);

      if (callback) {
        callback();
      }

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchWireRecipients(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(ENDPOINTS.recipients.items, params);
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchWireRecipientsShort(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(
        ENDPOINTS.recipients.fetchWireRecipientsShort,
        params
      );
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async deleteWireRecipient(id: string) {
    try {
      await del(ENDPOINTS.recipients.item.replace("{wire-recipient-id}", id));
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async editWireRecipient(id: string, payload: IWireRecipient) {
    try {
      await patch(
        ENDPOINTS.recipients.editWireRecipient.replace(
          "{wire-recipient-id}",
          id
        ),
        payload
      );

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchWireRecipient(id: string) {
    try {
      const { data } = await get(
        ENDPOINTS.recipients.fetchWireRecipient.replace(
          "{wire-recipient-id}",
          id
        )
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchAllWireRecipients(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(
        ENDPOINTS.recipients.fetchAllWireRecipients,
        params
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchWireRecipientsUserAdmin(
    userId: string,
    params: Record<string, any> | undefined
  ) {
    try {
      const { data } = await get(
        ENDPOINTS.recipients.fetchWireRecipientsUserAdmin.replace(
          "{userId}",
          userId
        ),
        params
      );
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async createWireRecipientAdmin(userId: string, payload: IWireRecipient) {
    try {
      await post(
        ENDPOINTS.recipients.createWireRecipientAdmin.replace(
          "{userId}",
          userId
        ),
        payload
      );
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchWireRecipientDetail(userId: string, wireRecipientId: string) {
    try {
      const { data } = await get(
        ENDPOINTS.recipients.fetchWireRecipientDetail
          .replace("{userId}", userId)
          .replace("{wireRecipientId}", wireRecipientId)
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async editWireRecipientAdmin(
    userId: string,
    wireRecipientId: string,
    payload: IWireRecipient
  ) {
    try {
      await patch(
        ENDPOINTS.recipients.editWireRecipientAdmin
          .replace("{userId}", userId)
          .replace("{wireRecipientId}", wireRecipientId),
        payload
      );

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async deleteWireRecipientAdmin(userId: string, wireRecipientId: string) {
    try {
      await del(
        ENDPOINTS.recipients.deleteWireRecipient
          .replace("{userId}", userId)
          .replace("{wireRecipientId}", wireRecipientId)
      );

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
});

export default recipients;
