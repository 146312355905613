export const apiUrls = {
  VITE_API_KEY_EDITOR: import.meta.env.VITE_API_KEY_EDITOR,
  VITE_HELLO_SIGN_CLIENT_ID: import.meta.env.VITE_HELLO_SIGN_CLIENT_ID,
  VITE_API: import.meta.env.VITE_API,
  VITE_CARD_PORTAL: import.meta.env.VITE_CARD_PORTAL,
  VITE_FINCI: import.meta.env.VITE_FINCI,
  VITE_FINCI_WSS: import.meta.env.VITE_FINCI_WSS,
  VITE_FINCI_V2: import.meta.env.VITE_FINCI_V2,
  VITE_ROBOT: import.meta.env.VITE_ROBOT,
  VITE_BLOCKS: import.meta.env.VITE_BLOCKS,
  VITE_ONBOARD: import.meta.env.VITE_ONBOARD,
  VITE_ACCOUNT: import.meta.env.VITE_ACCOUNT,
  VITE_NOTIFICATIONS: import.meta.env.VITE_NOTIFICATIONS,
  VITE_PREPAID: import.meta.env.VITE_PREPAID,
  VITE_CARDS: import.meta.env.VITE_CARDS,
  VITE_INC: import.meta.env.VITE_INC,
  VITE_COINS: import.meta.env.VITE_COINS,
  VITE_OLD_CRYPTO: import.meta.env.VITE_OLD_CRYPTO,
  VITE_CRYPTO: import.meta.env.VITE_CRYPTO,
  VITE_TRANSACTIVE: import.meta.env.VITE_TRANSACTIVE,
  VITE_SEEKER: import.meta.env.VITE_SEEKER,
  VITE_PDF: import.meta.env.VITE_PDF,
  VITE_INTEREST: import.meta.env.VITE_INTEREST,
  VITE_ACQUIRING: import.meta.env.VITE_ACQUIRING,
  VITE_COINS_SENDER: import.meta.env.VITE_COINS_SENDER,
  VITE_DMCC_API: import.meta.env.VITE_DMCC_API,
  VITE_WSS_API: import.meta.env.VITE_WSS_API,
  VITE_WSS_TASKS: import.meta.env.VITE_WSS_TASKS,
  VITE_WSS_QUOTES: import.meta.env.VITE_WSS_QUOTES,
  VITE_WSS_RATES: import.meta.env.VITE_WSS_RATES,
  VITE_WSS_VALIDATION: import.meta.env.VITE_WSS_VALIDATION,
  VITE_WSS_BALANCE: import.meta.env.VITE_WSS_BALANCE,
  VITE_SUMSUB_API: import.meta.env.VITE_SUMSUB_API,
  VITE_ZENDESK_WIDGET: import.meta.env.VITE_ZENDESK_WIDGET,
  VITE_BLOCKCYPHER: import.meta.env.VITE_BLOCKCYPHER,
  VITE_ETHERSCAN: import.meta.env.VITE_ETHERSCAN,
  VITE_GET_APP_FROM_APPLE_STORE: import.meta.env.VITE_GET_APP_FROM_APPLE_STORE,
  VITE_GET_APP_FROM_GOOGLE_PLAY: import.meta.env.VITE_GET_APP_FROM_GOOGLE_PLAY,
  VITE_MIGOM_PAY_SDK: import.meta.env.VITE_MIGOM_PAY_SDK,
  VITE_DEMO_MIGOM_PAY_SDK: import.meta.env.VITE_DEMO_MIGOM_PAY_SDK,
};
