import { lazy } from "react";

const AnalystArea = lazy(() => import("../../components/Roles/Analyst"));

const AnalystAllOperations = lazy(
  () => import("../../components/Roles/Analyst/AllOperations")
);

const AnalystAllOperations2 = lazy(
  () => import("../../components/Roles/Analyst/AllOperations2")
);

export const Analyst = [
  {
    path: "/app/analyst",
    component: AnalystArea,

    routes: [
      {
        path: "/app/analyst/all-operations",
        component: AnalystAllOperations,
      },
      {
        path: "/app/analyst/all-operations-2",
        component: AnalystAllOperations2,
      },
    ],
  },
];
