import { lazy } from "react";

const RecipientsList = lazy(
  () => import("../../components/Roles/Client/Recipients")
);
const MigomRecipients = lazy(
  () => import("../../components/Roles/Client/Recipients/MigomRecipients")
);
const WireRecipientsDetail = lazy(
  () =>
    import(
      "../../components/Roles/Client/Recipients/WireRecipients/WireRecipientsDetail"
    )
);
const WireRecipientsEdit = lazy(
  () =>
    import("../../components/Roles/All/WireRecipients/WireRecipientDetail/Edit")
);
const AddWireRecipient = lazy(
  () => import("../../components/Roles/Client/Recipients/WireRecipients/Create")
);
const WireRecipients = lazy(
  () => import("../../components/Roles/Client/Recipients/WireRecipients")
);
const CryptoRecipients = lazy(
  () => import("../../components/Roles/Client/Recipients/CryptoRecipients")
);
const WireActivity = lazy(
  () => import("../../components/Roles/Client/Recipients/WireActivity")
);

export const Recipients = [
  {
    path: "/app/recipients",
    component: RecipientsList,

    routes: [
      {
        path: "/app/recipients/add",
        component: AddWireRecipient,
      },
      {
        path: "/app/recipients/contacts",
        component: MigomRecipients,
      },

      {
        path: "/app/recipients/wire-activity",
        component: WireActivity,
      },
      {
        path: "/app/recipients/crypto-recipients",
        component: CryptoRecipients,
      },
      {
        path: "/app/recipients/wire-list",
        component: WireRecipients,
      },
      {
        path: "/app/recipients/:id/edit",
        component: WireRecipientsEdit,
      },
      {
        path: "/app/recipients/:id",
        component: WireRecipientsDetail,
      },
    ],
  },
];
