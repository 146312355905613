import { store } from "@risingstack/react-easy-state";
import ENDPOINTS from "../../endpoints";
import { del, get, patch, post, showError } from "../api";

interface ISecureMessage {
  loading: boolean;
  itemsSent: [];
  pagesSent: number;
  newMessagesInInbox: any[];
  itemsInbox: any[];
  pagesInbox: number;

  itemsOffers: any[];
  pagesOffers: number;

  itemsInThread: any[];

  [key: string]: any;
}

const secureMessages = store<ISecureMessage>({
  loading: false,

  itemsSent: [],
  pagesSent: 1,

  newMessagesInInbox: [],

  itemsInbox: [],
  pagesInbox: 1,

  itemsOffers: [],
  pagesOffers: 1,

  itemsInThread: [],

  async fetchSent(params: Record<string, any> | undefined) {
    try {
      secureMessages.loading = true;

      const { data } = await get(ENDPOINTS.secureMessages.fetchSent, params);

      if (data?.data && data?.data?.length) {
        secureMessages.itemsSent = data.data;
        secureMessages.pagesSent = data.pages;
      }

      secureMessages.loading = false;
      return data;
    } catch (err) {
      showError(err);
      secureMessages.loading = false;
      return false;
    }
  },

  async fetchInbox(params: Record<string, any> | undefined) {
    try {
      secureMessages.loading = true;

      const { data } = await get(ENDPOINTS.secureMessages.fetchInbox, params);

      if (data?.data && data?.data?.length) {
        secureMessages.itemsInbox = data.data;
        secureMessages.pagesInbox = data.pages;
      }

      secureMessages.loading = false;
      return data;
    } catch (err) {
      showError(err);
      secureMessages.loading = false;
      return false;
    }
  },

  async fetchOffers(params: Record<string, any> | undefined) {
    try {
      secureMessages.loading = true;

      const { data } = await get(ENDPOINTS.secureMessages.fetchOffers, params);

      if (data?.data && data?.data?.length) {
        secureMessages.itemsOffers = data.data;
        secureMessages.pagesOffers = data.pages;
      }

      secureMessages.loading = false;
      return data;
    } catch (err) {
      showError(err);
      secureMessages.loading = false;
      return false;
    }
  },

  async createMessage(
    payload: {
      subject: string | Blob;
      body: string | Blob;
      abilityToReply: string | Blob;
      toUserId: string | Blob;
    },
    files: any[]
  ) {
    try {
      const formData = new FormData();

      formData.append("subject", payload?.subject);
      formData.append("body", payload?.body);
      formData.append("abilityToReply", payload?.abilityToReply);

      if (payload?.toUserId) {
        formData.append("toUserId", payload?.toUserId);
      }

      files.forEach((file: { data: string | Blob }) => {
        formData.append("files", file?.data);
      });

      await post(ENDPOINTS.secureMessages.createMessage, formData, {
        "Content-Type": "multipart/form-data",
        Accept: true,
      });

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchMessage(messageId: string) {
    try {
      const { data } = await get(
        ENDPOINTS.secureMessages.fetchMessage.replace("{id}", messageId)
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchThreadMessages(messageId: string) {
    try {
      const { data } = await get(
        ENDPOINTS.secureMessages.fetchMessagesThread.replace("{id}", messageId),
        {
          page: 1,
          perPage: 999999,
        }
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async reply(id: string, payload: { body: string | Blob }, files: any[]) {
    try {
      const formData = new FormData();

      formData.append("body", payload?.body);

      files.forEach((file: { data: string | Blob }) => {
        formData.append("files", file?.data);
      });

      await post(ENDPOINTS.secureMessages.reply.replace("{id}", id), formData, {
        "Content-Type": "multipart/form-data",
        Accept: true,
      });

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async readMessage(messageId: string) {
    try {
      await patch(
        ENDPOINTS.secureMessages.readMessage.replace("{id}", messageId)
      );

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async deleteThread(messageId: string) {
    try {
      await del(
        ENDPOINTS.secureMessages.deleteThread.replace("{id}", messageId)
      );

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchSentAdmin(params: Record<string, any> | undefined) {
    try {
      secureMessages.loading = true;

      const { data } = await get(
        ENDPOINTS.secureMessages.fetchSentAdmin,
        params
      );

      if (data?.data && data?.data?.length) {
        secureMessages.itemsSent = data.data;
        secureMessages.pagesSent = data.pages;
      }

      secureMessages.loading = false;
      return data;
    } catch (err) {
      showError(err);
      secureMessages.loading = false;
      return false;
    }
  },

  async fetchInboxAdmin(params: Record<string, any> | undefined) {
    try {
      secureMessages.loading = true;

      const { data } = await get(
        ENDPOINTS.secureMessages.fetchInboxAdmin,
        params
      );

      if (data?.data && data?.data?.length) {
        secureMessages.itemsInbox = data.data;
        secureMessages.pagesInbox = data.pages;
      }

      secureMessages.loading = false;
      return data;
    } catch (err) {
      showError(err);
      secureMessages.loading = false;
      return false;
    }
  },

  async fetchOffersAdmin(params: Record<string, any> | undefined) {
    try {
      secureMessages.loading = true;

      const { data } = await get(
        ENDPOINTS.secureMessages.fetchOffersAdmin,
        params
      );

      if (data?.data && data?.data?.length) {
        secureMessages.itemsOffers = data.data;
        secureMessages.pagesOffers = data.pages;
      }

      secureMessages.loading = false;
      return data;
    } catch (err) {
      showError(err);
      secureMessages.loading = false;
      return false;
    }
  },

  async createMessageAdmin(
    payload: { subject: any; body: any; abilityToReply: any; toUserId: any },
    files: any[]
  ) {
    try {
      const formData = new FormData();

      formData.append("subject", payload?.subject);
      formData.append("body", payload?.body);
      formData.append("abilityToReply", payload?.abilityToReply);

      if (payload?.toUserId) {
        formData.append("toUserId", payload?.toUserId);
      }

      files.forEach((file: { data: string | Blob }) => {
        formData.append("files", file?.data);
      });

      await post(ENDPOINTS.secureMessages.createMessageAdmin, formData, {
        "Content-Type": "multipart/form-data",
        Accept: true,
      });

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchMessageAdmin(messageId: string) {
    try {
      const { data } = await get(
        ENDPOINTS.secureMessages.fetchMessageAdmin.replace("{id}", messageId)
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchThreadMessagesAdmin(messageId: string) {
    try {
      const { data } = await get(
        ENDPOINTS.secureMessages.fetchMessagesThreadAdmin.replace(
          "{id}",
          messageId
        ),
        {
          page: 1,
          perPage: 999999,
        }
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async replyAdmin(id: string, payload: { body: string | Blob }, files: any[]) {
    try {
      const formData = new FormData();

      formData.append("body", payload?.body);

      files.forEach((file: { data: string | Blob }) => {
        formData.append("files", file?.data);
      });

      await post(
        ENDPOINTS.secureMessages.replyAdmin.replace("{id}", id),
        formData,
        {
          "Content-Type": "multipart/form-data",
          Accept: true,
        }
      );

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async readMessageAdmin(messageId: string) {
    try {
      await patch(
        ENDPOINTS.secureMessages.readMessageAdmin.replace("{id}", messageId)
      );

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async deleteThreadAdmin(messageId: string) {
    try {
      await del(
        ENDPOINTS.secureMessages.deleteThreadAdmin.replace("{id}", messageId)
      );

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
});

export default secureMessages;
