import React from "react";

const Title = ({ icon, text }) => {
  return (
    <div className="flex text-2xl items-center">
      <div className="mr-1.5 text-main flex">{icon}</div>
      <div>{text}</div>
    </div>
  );
};

export default Title;
