import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { clsx } from "clsx";
import React from "react";
import { useController } from "react-hook-form";

const useStyles = makeStyles({
  labelRoot: {
    color: "#282828",
  },
  label: {
    fontSize: ".85rem",
  },
  fontBold: {
    fontWeight: 700,
  },
  fontSize: {
    fontSize: "1.2rem",
  },
});

const MyRadioButtons = ({
  control,
  items,
  name,
  label,
  rules,
  row,
  trigger,
  fontBold,
  textLg,
  helperText,
  id,
}: any) => {
  const classes = useStyles();

  const {
    field: { onChange, ref, ...field },
    fieldState,
  } = useController({
    name: name,
    control: control,
    rules: rules,
  });

  console.log(fieldState.isDirty, fieldState.isTouched);

  const makeItems = items.map((item) => {
    return (
      <div key={item.label}>
        <FormControlLabel
          label={item.label}
          control={<Radio disabled={item?.disabled} value={item.value} />}
          classes={{
            root: classes.labelRoot,
            label: clsx(
              fontBold && classes.fontBold,
              textLg ? classes.fontSize : classes.label
            ),
          }}
        />
        {item.view}
      </div>
    );
  });

  return (
    <FormControl component="div" ref={ref}>
      <FormLabel component="label" filled style={{ fontSize: ".85rem" }}>
        {label}
      </FormLabel>
      <RadioGroup
        id={id}
        row={row}
        {...field}
        onChange={(e) => {
          const { value } = e.target;

          if (value === "true") {
            onChange(true);
          } else if (value === "false") {
            onChange(false);
          } else {
            onChange(e.target.value);
          }

          if (trigger) {
            setTimeout(() => trigger(), 0);
          }
        }}
      >
        {makeItems}

        {helperText && <>{helperText}</>}
      </RadioGroup>
    </FormControl>
  );
};

export default MyRadioButtons;
