import { clsx } from "clsx";
import React from "react";
import IBlock from "../../../interfaces/block";

const BlockView = ({
  block,
  classNameTitle,
  hideTitle,
}: {
  block: IBlock;
  classNameTitle?: string;
  hideTitle?: boolean;
}) => {
  return (
    <div>
      {!hideTitle && (
        <h2
          className={clsx(
            `font-bold mb-4 text-2xl`,
            classNameTitle ? classNameTitle : ""
          )}
          dangerouslySetInnerHTML={{ __html: block?.title }}
        />
      )}
      <div dangerouslySetInnerHTML={{ __html: block?.text }} />
    </div>
  );
};

export default BlockView;
