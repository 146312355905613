import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useRef, useState } from "react";
import { createHub } from "./tools/createHub";

const m1 = "Stock data: Connection not established, we are trying to reconnect";
const m2 = "Stock data: Connection established";

export const useQuerySocketQuotes = ({
  onUpdated,
}: {
  onUpdated?: (data: { symbol: string; rate: number }) => void;
  onSourcesUpdated?: (data: any) => void;
  onSourceUpdated?: (data: any) => void;
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const snackbar = useRef<any>();

  const hubConnection = useRef<HubConnection | undefined>();

  const isRunning = useRef<boolean>(false);

  const [pair, setP] = useState("");
  const [running, setRunning] = useState(false);

  const isConnected = useCallback(() => {
    if (hubConnection.current?.state === HubConnectionState.Connected) {
      return true;
    }
    return false;
  }, []);

  const unPair = useRef<string>("");

  const setPair = useCallback(
    (value) => {
      if (isConnected() && value) {
        setP(value);
      } else if (value) {
        unPair.current = value;
      }
    },
    [isConnected]
  );

  const success = useCallback(() => {
    snackbar.current = enqueueSnackbar(m2, {
      variant: "success",
      anchorOrigin: { horizontal: "right", vertical: "bottom" },
      autoHideDuration: 5000,
    });
  }, [enqueueSnackbar]);

  const warning = useCallback(() => {
    snackbar.current = enqueueSnackbar(m1, {
      variant: "warning",
      anchorOrigin: { horizontal: "right", vertical: "bottom" },
      autoHideDuration: null,
    });
  }, [enqueueSnackbar]);

  const unPairToPair = useCallback(() => {
    if (unPair.current) {
      setPair(unPair.current);
      unPair.current = "";
    }
  }, [setPair]);

  const onQuote = useCallback(() => {
    if (hubConnection.current && onUpdated) {
      hubConnection.current.off("onQuote");
      hubConnection.current.on("onQuote", (data) => onUpdated(data));
    }
  }, [onUpdated]);

  useEffect(() => {
    if (running) {
      onQuote();
    }
  }, [onQuote, running]);

  const start = useCallback(async () => {
    try {
      hubConnection.current = await createHub(
        import.meta.env.VITE_WSS_QUOTES as string
      );

      hubConnection.current.onclose(() => {
        if (snackbar.current) {
          closeSnackbar(snackbar.current);
        }
      });

      hubConnection.current.onreconnecting(() => {
        if (snackbar.current) {
          closeSnackbar(snackbar.current);
        }

        warning();
      });

      hubConnection.current.onreconnected(() => {
        unPairToPair();

        if (snackbar.current) {
          closeSnackbar(snackbar.current);
        }

        success();
      });

      await hubConnection.current.start();

      setRunning(true);

      if (snackbar.current) {
        closeSnackbar(snackbar.current);

        success();
      }

      unPairToPair();
    } catch (err) {
      if (!snackbar.current) {
        warning();
      }

      setTimeout(() => start(), 2000);
    }
  }, [closeSnackbar, success, unPairToPair, warning]);

  useEffect(() => {
    if (!isRunning.current) {
      isRunning.current = true;

      start();
    }
  }, [start]);

  const unSubPair = useCallback(
    (pair) => {
      if (!isConnected() || !hubConnection.current || !pair) {
        return;
      }

      hubConnection.current.send("Unsubscribe", pair);
    },
    [isConnected]
  );

  const subPair = useCallback(
    (pair) => {
      if (!isConnected() || !hubConnection.current || !pair) {
        return;
      }

      unSubPair(pair);

      hubConnection.current.send("Subscribe", pair);
    },
    [isConnected, unSubPair]
  );

  useEffect(() => {
    if (pair && isConnected()) {
      subPair(pair);
    }

    return () => {
      unSubPair(pair);
    };
  }, [isConnected, pair, subPair, unSubPair]);

  useEffect(() => {
    return () => {
      hubConnection.current?.stop();
    };
  }, []);

  return {
    setPair,
  };
};
