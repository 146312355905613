import { atom } from "jotai";

export const globalParamsAtom = atom<any>({
  page: 1,
  perPage: 50,
  sortBy: "ts",
  sortDirection: "desc",
  eventGroups: undefined,
  currencyIds: undefined,
  actorUserIds: undefined,
});
