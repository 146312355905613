import numbro from "numbro";

export const makeRebalancePreviewPayload = ({ values, task }) => {
  return {
    type: "ToClientAccount",
    amount: {
      currency: "EUR",
      value: numbro.unformat(values.amount) || 0,
    },
    clientAccount: {
      name: task?.firstUser?.name,
      iban: task?.fromAccount?.number,
    },
    comment: values.comment,
  };
};
