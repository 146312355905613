import { clsx } from "clsx";
import React, { memo } from "react";
import Lottie, { Options } from "react-lottie";

interface FeatureOptions extends Options {
  path?: string;
}

const Anima = memo(
  ({
    json,
    center,
    loop,
    className = "",
    pause,
    onComplete,
  }: {
    json: string;
    center?: boolean;
    loop?: boolean;
    className?: string;
    pause?: boolean;
    onComplete?: any;
  }) => {
    const defaultOptions: FeatureOptions = {
      loop: loop,
      autoplay: true,
      path: json,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
      animationData: undefined,
    };

    return (
      <div
        className={clsx(
          "flex overflow-hidden pointer-events-none",
          center && "justify-center",
          className
        )}
      >
        <Lottie
          eventListeners={[
            {
              eventName: "complete",
              callback: () => {
                onComplete && onComplete();
              },
            },
          ]}
          isPaused={pause}
          options={defaultOptions}
        />
      </div>
    );
  }
);

export default Anima;
