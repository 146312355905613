import { Icon, LinearProgress } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { CheckCircle } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles({
  success: {
    backgroundColor: green[600],
  },
  successIcon: {
    color: green[600],
  },
});

const Finish = ({ loading, success, completed, textSuccess }: any) => {
  const classes = useStyles();

  return (
    <div>
      {loading && (
        <LinearProgress
          classes={{ bar1Determinate: classes.success }}
          color="primary"
          variant="determinate"
          value={completed}
        />
      )}

      {success && (
        <span className="text-lg p-4 flex justify-center items-center font-bold text-green-500">
          <Icon
            component={CheckCircle}
            color="primary"
            classes={{ colorPrimary: classes.successIcon }}
          />
          {textSuccess}
        </span>
      )}
    </div>
  );
};

export default Finish;
