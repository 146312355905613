import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import tasks from "../../../../../../store/modules/tasks";
import { AreYouSure } from "../../../../../System";

const UsuallyApprove = ({ open, setOpen, taskId, taskSubject }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const approve = useCallback(async () => {
    setLoading(true);

    const result = await tasks.approveTask(taskId);

    if (result) {
      history.goBack();
    }

    setLoading(false);
  }, [history, taskId]);

  return (
    <AreYouSure
      open={open}
      setOpen={setOpen}
      confirmCallback={approve}
      text={`Are you sure you want to confirm this task '${
        taskSubject || taskId
      }'?`}
      loading={loading}
    />
  );
};

export default UsuallyApprove;
