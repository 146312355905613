import { lazy } from "react";

const SignIn = lazy(() => import("../../components/Auth/SignIn"));

const SignUp = lazy(() => import("../../components/Auth/SignUp"));

const ConfirmDevice = lazy(() => import("../../components/Auth/ConfirmDevice"));

const ConfirmEmail = lazy(() => import("../../components/Auth/ConfirmEmail"));

const ForgotPassword = lazy(
  () => import("../../components/Auth/ForgotPassword")
);

const ResetPassword = lazy(() => import("../../components/Auth/ResetPassword"));

const Short = lazy(() => import("../../components/Auth/SignUp/Short"));

export const Auth = [
  {
    path: "/open-account",
    component: SignUp,

    routes: [
      {
        path: "/open-account",
        component: Short,
      },
    ],
  },
  {
    path: "/sign-in",
    component: SignIn,
  },
  {
    path: "/sign-up",
    component: SignUp,

    routes: [
      {
        path: "/sign-up",
        component: Short,
      },
    ],
  },
  {
    path: "/confirm-email",
    component: ConfirmEmail,
  },
  {
    path: "/confirm-device",
    component: ConfirmDevice,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
  },
];
