import { lazy } from "react";
import Task from "../../components/Roles/Staff/Tasks/Task";

const TasksV3 = lazy(() => import("../../components/Roles/Staff/Tasks/v3"));

const TasksSectionInbox = lazy(
  () => import("../../components/Roles/Staff/Tasks/v3/Inbox")
);

const TasksSectionActivity = lazy(
  () => import("../../components/Roles/Staff/Tasks/v3/Activity")
);

const TasksSectionInboxWireTransfers = lazy(
  () => import("../../components/Roles/Staff/Tasks/v3/Inbox/WireTransfers")
);

const TasksSectionInboxDeposit = lazy(
  () => import("../../components/Roles/Staff/Tasks/v3/Inbox/Deposit")
);

const TasksSectionInboxCrypto = lazy(
  () => import("../../components/Roles/Staff/Tasks/v3/Inbox/Crypto")
);

const TasksSectionInboxTransfer = lazy(
  () => import("../../components/Roles/Staff/Tasks/v3/Inbox/Transfer")
);

const TasksSectionInboxExchange = lazy(
  () => import("../../components/Roles/Staff/Tasks/v3/Inbox/Exchange")
);

const TasksSectionInboxGold = lazy(
  () => import("../../components/Roles/Staff/Tasks/v3/Inbox/Gold")
);

const TasksSectionInboxRobot = lazy(
  () => import("../../components/Roles/Staff/Tasks/v3/Inbox/Robot")
);

const TasksSectionActivityWireTransfers = lazy(
  () => import("../../components/Roles/Staff/Tasks/v3/Activity/WireTransfers")
);

const TasksSectionActivityDeposit = lazy(
  () => import("../../components/Roles/Staff/Tasks/v3/Activity/Deposit")
);

const TasksSectionActivityCrypto = lazy(
  () => import("../../components/Roles/Staff/Tasks/v3/Activity/Crypto")
);

const TasksSectionActivityTransfer = lazy(
  () => import("../../components/Roles/Staff/Tasks/v3/Activity/Transfer")
);

const TasksSectionActivityGold = lazy(
  () => import("../../components/Roles/Staff/Tasks/v3/Activity/Gold")
);

const TasksSectionInboxFinciRobot = lazy(
  () => import("../../components/Roles/Staff/Tasks/v3/Inbox/FinciRobot")
);

export const NewTasksStaff = [
  {
    path: "/app/tasks/details/:taskId",
    component: Task,
  },
  {
    path: "/app/tasks",
    component: TasksV3,
    routes: [
      {
        path: "/app/tasks/inbox",
        component: TasksSectionInbox,
        routes: [
          {
            path: "/app/tasks/inbox/wire-transfers",
            component: TasksSectionInboxWireTransfers,
          },
          {
            path: "/app/tasks/inbox/deposit",
            component: TasksSectionInboxDeposit,
          },
          {
            path: "/app/tasks/inbox/crypto",
            component: TasksSectionInboxCrypto,
          },
          {
            path: "/app/tasks/inbox/transfer",
            component: TasksSectionInboxTransfer,
          },
          {
            path: "/app/tasks/inbox/exchange",
            component: TasksSectionInboxExchange,
          },
          {
            path: "/app/tasks/inbox/gold",
            component: TasksSectionInboxGold,
          },
          {
            path: "/app/tasks/inbox/robot",
            component: TasksSectionInboxRobot,
          },
          {
            path: "/app/tasks/inbox/finci-robot",
            component: TasksSectionInboxFinciRobot,
          },
        ],
      },

      {
        path: "/app/tasks/activity",
        component: TasksSectionActivity,
        routes: [
          {
            path: "/app/tasks/activity/wire-transfers",
            component: TasksSectionActivityWireTransfers,
          },
          {
            path: "/app/tasks/activity/deposit",
            component: TasksSectionActivityDeposit,
          },
          {
            path: "/app/tasks/activity/crypto",
            component: TasksSectionActivityCrypto,
          },
          {
            path: "/app/tasks/activity/transfer",
            component: TasksSectionActivityTransfer,
          },
          {
            path: "/app/tasks/activity/gold",
            component: TasksSectionActivityGold,
          },
        ],
      },
    ],
  },
];
