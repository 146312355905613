import { lazy } from "react";
import CardRequests from "../../components/Roles/CardAdmin/CardRequests";
import CardRequestsCompleted from "../../components/Roles/CardAdmin/CardRequestsCompleted";

const AllAccounts = lazy(
  () => import("../../components/Roles/CardAdmin/AllAccounts2")
);

const UserAccount = lazy(
  () => import("../../components/Roles/CardAdmin/AllAccounts/Account")
);

const ListOfCards = lazy(
  () => import("../../components/Roles/CardAdmin/GlobalCards/ListOfCards")
);

const AuthorizationsOfCards = lazy(
  () => import("../../components/Roles/CardAdmin/GlobalCards/Authorizations")
);

const CardsArea = lazy(
  () => import("../../components/Roles/CardAdmin/GlobalCards")
);

const Card = lazy(() => import("../../components/Roles/CardAdmin/Card"));

const CardRequest = lazy(
  () => import("../../components/Roles/CardAdmin/CardRequest")
);

export const CardAdmin = [
  {
    path: "/app/card-admin/card-requests/:taskId",
    component: CardRequest,
  },
  {
    path: "/app/card-admin/clients/:userId/cards/:cardId",
    component: Card,
  },
  {
    path: "/app/card-admin/accounts/:accountId",
    component: UserAccount,
  },

  {
    path: "/app/card-admin/accounts",
    component: AllAccounts,
  },

  {
    path: "/app/card-admin/global-cards",
    component: CardsArea,
    routes: [
      {
        path: "/app/card-admin/global-cards/authorizations",
        component: AuthorizationsOfCards,
      },
      {
        path: "/app/card-admin/global-cards/cards",
        component: ListOfCards,
      },
    ],
  },
  {
    path: "/app/card-admin/card-requests",
    component: CardRequests,
  },
  {
    path: "/app/card-admin/card-requests-completed",
    component: CardRequestsCompleted,
  },
];
