import React from "react";
import { useWatch } from "react-hook-form";
import { getTaskTypeBooleans } from "../../../../../../helpers/task";
import MyCheckboxLabel from "../../../../../System/FormComponents/MyCheckboxLabel";
import ShowAndHide from "../../../../../System/FormComponents/ShowAndHide";
import FinciDetails from "./FinciDetails";

const Finci = ({ data, task, ...form }) => {
  const useFinci = useWatch({
    control: form?.control,
    name: "useFinci",
  });

  const taskTypeBooleans = getTaskTypeBooleans(task);

  const finci = useFinci && data?.sepaFinciPayment && taskTypeBooleans.isSEPA;

  return (
    <ShowAndHide show={Boolean(data?.sepaFinciPayment)}>
      <MyCheckboxLabel
        control={form.control}
        name="useFinci"
        label="I want to confirm using Finci"
      />

      {finci ? (
        <div>
          <b className="text-main text-lg">
            Auto wire send: Will execute auto Finci payment
          </b>
        </div>
      ) : null}

      <div className="flex flex-col">
        <div className="flex flex-1 w-full justify-between">
          {useFinci && (
            <div className="flex flex-col">
              <b className="text-orange-600 text-lg">Finci Payment</b>
            </div>
          )}
        </div>

        {useFinci && data?.sepaFinciPayment && (
          <ShowAndHide show>
            <FinciDetails finci={data?.sepaFinciPayment} task={task} />
          </ShowAndHide>
        )}
      </div>
    </ShowAndHide>
  );
};

export default Finci;
