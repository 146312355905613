import { formatAmount } from "../../../../../helpers/formatAmount";
import { checkIsCryptoCurrency } from "../../../../../helpers/formatToCurrencyString";

export const makeAmount = (value, currency) => {
  if (value === undefined || value == 0) {
    return "-";
  }

  const isCrypto = checkIsCryptoCurrency(currency);

  const options = {
    currencyId: currency,
    clear: true,
    negative: "parenthesis",
    optionalMantissa: true,
  };

  if (!isCrypto) {
    options.mantissa = 0;
  }

  if (currency === "USDT" || currency === "USDC") {
    options.value = value.toFixed(0);
    options.mantissa = 0;
  } else if (currency === "BTC" || currency === "ETH" || currency === "LTC") {
    options.value = value.toFixed(3);
    options.mantissa = 3;
  } else {
    options.value = value.toFixed(0);
  }

  return formatAmount(options);
};
