import { makeStyles } from "@material-ui/core/styles";
import { clsx } from "clsx";
import React from "react";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      animation: "$hover 1200ms linear 2 alternate",
    },

    "&:active": {
      animation: "$active 1200ms ease 1 alternate",
    },
  },
  success: {
    backgroundColor: "#25b725",
    cursor: "default",
    color: "#fff",
    "&:hover": {
      opacity: "1",
    },
  },
  cancel: {
    backgroundColor: "red",
    color: "#fff",
  },
  pending: {
    backgroundColor: "#e7c300",
    cursor: "default",

    "&:hover": {
      opacity: "1",
    },
  },

  "@keyframes active": {
    "0%": {
      transform: "scale(1, 1)",
    },
    "90%": {
      transform: "scale(0.9, 0.88)",
    },
    "100%": {
      transform: "scale(0.92, 0.9)",
    },
  },
  "@keyframes hover": {
    "0%": {
      transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "1.8%": {
      transform:
        "matrix3d(1.016, 0, 0, 0, 0, 1.037, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "3.5%": {
      transform:
        "matrix3d(1.033, 0, 0, 0, 0, 1.094, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "4.7%": {
      transform:
        "matrix3d(1.045, 0, 0, 0, 0, 1.129, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "5.31%": {
      transform:
        "matrix3d(1.051, 0, 0, 0, 0, 1.142, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "7.01%": {
      transform:
        "matrix3d(1.068, 0, 0, 0, 0, 1.158, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "8.91%": {
      transform:
        "matrix3d(1.084, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "9.41%": {
      transform:
        "matrix3d(1.088, 0, 0, 0, 0, 1.132, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "10.71%": {
      transform:
        "matrix3d(1.097, 0, 0, 0, 0, 1.107, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "12.61%": {
      transform:
        "matrix3d(1.108, 0, 0, 0, 0, 1.077, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "14.11%": {
      transform:
        "matrix3d(1.114, 0, 0, 0, 0, 1.067, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "14.41%": {
      transform:
        "matrix3d(1.115, 0, 0, 0, 0, 1.067, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "16.32%": {
      transform:
        "matrix3d(1.119, 0, 0, 0, 0, 1.077, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "18.12%": {
      transform:
        "matrix3d(1.121, 0, 0, 0, 0, 1.096, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "18.72%": {
      transform:
        "matrix3d(1.121, 0, 0, 0, 0, 1.102, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "20.02%": {
      transform:
        "matrix3d(1.121, 0, 0, 0, 0, 1.113, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "21.82%": {
      transform:
        "matrix3d(1.119, 0, 0, 0, 0, 1.119, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "24.32%": {
      transform:
        "matrix3d(1.115, 0, 0, 0, 0, 1.11, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "25.53%": {
      transform:
        "matrix3d(1.113, 0, 0, 0, 0, 1.102, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "29.23%": {
      transform:
        "matrix3d(1.106, 0, 0, 0, 0, 1.089, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "29.93%": {
      transform:
        "matrix3d(1.105, 0, 0, 0, 0, 1.09, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "35.54%": {
      transform:
        "matrix3d(1.098, 0, 0, 0, 0, 1.105, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "36.64%": {
      transform:
        "matrix3d(1.097, 0, 0, 0, 0, 1.106, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "41.04%": {
      transform:
        "matrix3d(1.096, 0, 0, 0, 0, 1.099, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "44.04%": {
      transform:
        "matrix3d(1.096, 0, 0, 0, 0, 1.097, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "51.45%": {
      transform:
        "matrix3d(1.099, 0, 0, 0, 0, 1.102, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "52.15%": {
      transform:
        "matrix3d(1.099, 0, 0, 0, 0, 1.102, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "58.86%": {
      transform:
        "matrix3d(1.101, 0, 0, 0, 0, 1.099, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "63.26%": {
      transform:
        "matrix3d(1.101, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "66.27%": {
      transform:
        "matrix3d(1.101, 0, 0, 0, 0, 1.101, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "73.77%": {
      transform: "matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "81.18%": {
      transform: "matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "85.49%": {
      transform: "matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "88.59%": {
      transform: "matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "96%": {
      transform: "matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
    "100%": {
      transform: "matrix3d(1.1, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
  },
});

const Badge = ({ label, status, onClick }) => {
  const classes = useStyles();

  return (
    <div>
      <a
        className={clsx(
          classes.root,
          "cursor-pointer rounded-[100px] py-2 px-6 text-xs mb-2 text-center font-bold block mx-auto",
          classes[status]
        )}
        onClick={onClick}
      >
        {label}
      </a>
    </div>
  );
};

export default Badge;
