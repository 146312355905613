import { AxiosResponse } from "axios";
import { Buffer } from "buffer";

export const getBase64 = (response: AxiosResponse) => {
  try {
    const { data, headers } = response;
    const base64 = Buffer.from(data, "binary").toString("base64");
    const contentType = headers["content-type"];
    return `data:${contentType};base64,${base64}`;
  } catch (e) {
    // logic
  }
};
