import { view } from "@risingstack/react-easy-state";
import React, { useCallback, useEffect, useRef } from "react";
import admin from "../../../../../../store/modules/admin";
import transactionDetails from "../../../../../../store/modules/transactionDetails";
import { Alert, AreYouSure, FormActions } from "../../../../../System";
import PreviewTable from "./../../../SmartForms/components/PreviewTable";

const DeleteOperationForm = view(() => {
  const isLoad = useRef<boolean>(false);

  const changeCompleted = () => {
    if (transactionDetails.deleteOperationParams.completed === 100) {
      return 0;
    }
    const diff = Math.random() * 10;
    return Math.min(
      transactionDetails.deleteOperationParams.completed + diff,
      100
    );
  };

  const { preview, loading, completed, areYouSure } =
    transactionDetails.deleteOperationParams;

  const { detailSeeUser, successDelete } = transactionDetails;

  const { operationId } = detailSeeUser;

  const previewDeleteOperation = useCallback(async () => {
    transactionDetails.deleteOperationParams.loading = true;
    const result = await admin.previewDeleteOperation(operationId);

    if (result) {
      transactionDetails.deleteOperationParams.preview = result;
    }

    transactionDetails.deleteOperationParams.loading = false;
  }, [operationId]);

  useEffect(() => {
    if (!isLoad.current) {
      isLoad.current = true;
      previewDeleteOperation();
    }
  }, [previewDeleteOperation]);

  const confirmDeleteOperation = async () => {
    /* useCallback */
    transactionDetails.deleteOperationParams.areYouSure = false;

    const progress = () => {
      transactionDetails.deleteOperationParams.completed = changeCompleted();
    };

    transactionDetails.deleteOperationParams.loading = true;

    transactionDetails.deleteOperationParams.showProgress = true;

    const interval = setInterval(progress, 200);

    const result = await admin.confirmDeleteOperation(preview);

    const good = () => {
      clearInterval(interval);
      transactionDetails.goodCallbackForDeleteOperation();
    };

    const bad = () => {
      clearInterval(interval);
      transactionDetails.deleteOperationParams.completed = 0;
      transactionDetails.deleteOperationParams.loading = false;
    };

    if (result) {
      good();
    } else {
      bad();
    }
  };

  const title = (
    <h1 className="text-2xl text-gray-600">
      <span>{preview?.transaction?.description}</span>
    </h1>
  );

  const confirm = () => {
    transactionDetails.deleteOperationParams.areYouSure = true;
  };

  const close = () => {
    transactionDetails.openDeleteOperation = false;
  };

  const setOpenAreYouSure = (value) => {
    transactionDetails.deleteOperationParams.areYouSure = value;
  };

  return (
    <Alert
      customClose={
        successDelete
          ? transactionDetails.goodCallbackForDeleteOperation
          : close
      }
      open={transactionDetails.openDeleteOperation}
      setOpen={close}
      content={
        <div>
          {title}

          <PreviewTable
            data={
              transactionDetails.deleteOperationParams.preview?.transaction
                ?.operation
            }
          />

          {!loading && (
            <FormActions
              showConfirmButton={!completed}
              confirmButtonLabel="Delete Operation"
              onClickConfirm={confirm}
              showCloseButton
              onClickClose={close}
              loading={loading}
              disabledConfirmButton={loading}
            />
          )}

          <AreYouSure
            open={areYouSure}
            setOpen={setOpenAreYouSure}
            loading={loading}
            confirmCallback={confirmDeleteOperation}
            text="Are you sure you want to delete the operation and all related operations?"
          />
        </div>
      }
      size="md"
    />
  );
});

export default DeleteOperationForm;
