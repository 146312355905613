import React, { memo } from "react";
import Anima from "../../Anima/index";
import LazyImage from "../../LazyImage";

const LazyAnima = memo(() => {
  return (
    <Anima className="w-56" json={"/keys/dialogs/are_you_sure_2.json"} center />
  );
});

const Ays = memo(() => {
  return <LazyImage anima={LazyAnima} />;
});

export default Ays;
