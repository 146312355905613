import {
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";

export const createHub = async (url: string) => {
  return await new HubConnectionBuilder()
    .configureLogging(LogLevel.Error)
    .withUrl(url, {
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets,
      accessTokenFactory() {
        return sessionStorage.getItem("token") as string;
      },
    })
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: () => 2000,
    })
    .build();
};
