import { useQuery } from "react-query";
import { useDebouncer } from "../../../../../../../queries/useDebouncer";
import { get } from "../../../../../../../store/api";

export const useQueryPNLBranch = (params, enabled) => {
  const debouncedSearch = useDebouncer(params.search, 500);
  const queryParams = {
    ...params,
    search: params.search === "" ? params.search : debouncedSearch,
  };

  return useQuery(
    ["useQueryPNLBranch", queryParams],
    async () => {
      try {
        // await new Promise((resolve: any) => {
        //   setTimeout(() => resolve(), 3000);
        // });

        const { data } = await get(
          `${import.meta.env.VITE_API}/v4/reports/pnl-by-branch`,
          queryParams
        );

        return data || [];
      } catch (err) {
        return;
      }
    },
    {
      // keepPreviousData: false,
      // staleTime: 300000,
      // refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      keepPreviousData: true,
      enabled,
    }
  );
};
