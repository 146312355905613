import { InputAdornment, TextField } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { clsx } from "clsx";
import React, { useEffect, useRef, useState } from "react";
import {
  checkIsCryptoCurrency,
  symbols,
} from "../../helpers/formatToCurrencyString";
import InputFormatter from "./InputFormatter";

const useStyles = makeStyles({
  input: {
    fontSize: "1.4rem",
    fontWeight: 700,
  },
  shake: {
    animation: "$shake 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both",
    color: red[300],
  },
  "@keyframes shake": {
    "0%, 100%": {
      transform: "translateX(0)",
    },
    "10%, 30%, 50%, 70%": {
      transform: "translateX(-10px)",
    },
    "20%, 40%, 60%": {
      transform: "translateX(10px)",
    },
    "80%": {
      transform: "translateX(8px)",
    },
    "90%": {
      transform: "translateX(-8px)",
    },
  },
});

const Amount = ({
  name,
  currency,
  value,
  error,
  label = "Amount",
  endAdornment,
  ...props
}: {
  name?: string;
  currency?: string;
  value?: string;
  error?: any;
  label?: string | JSX.Element;
  [key: string]: any;
}) => {
  const classes = useStyles();

  const isCrypto = checkIsCryptoCurrency(currency || "");

  const [isError, setIsError] = useState(false);
  const repeater = useRef<boolean>(false);

  const timer = useRef<any>(undefined);

  const ref = useRef<any>();

  useEffect(() => {
    let timer;
    if (props.autoFocus) {
      /* без таймаута не работает - но ref.current уже имеет элемент */
      timer = setTimeout(() => {
        ref?.current?.focus();
      }, 100);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [props.autoFocus]);

  useEffect(() => {
    if (!repeater.current && value && error) {
      setIsError(true);
      repeater.current = true;

      timer.current = setTimeout(() => {
        setIsError(false);
        repeater.current = false;
        timer.current = null;
      }, 800);
    }
  }, [error, value]);

  return (
    <TextField
      inputRef={ref}
      type="text"
      name={name}
      value={value}
      label={label}
      autoFocus
      InputProps={
        {
          inputComponent: InputFormatter,
          inputProps: {
            isCrypto,
            autoComplete: "new-password",
            autoFocus: props.autoFocus,
          },
          classes: {
            input: clsx(classes.input, isError && classes.shake),
          },
          startAdornment: (
            <InputAdornment position="start">
              <b className="text-lg">{symbols[currency || ""]}</b>
            </InputAdornment>
          ),
          endAdornment,
        } as any
      }
      fullWidth
      {...props}
    />
  );
};

export default Amount;
