import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { view } from "@risingstack/react-easy-state";
import qs from "query-string";
import React, { Suspense, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { isDev } from "../../helpers/config";
import { checkSystemUser } from "../../helpers/roles";
import { MakeRoutes, Schema } from "../../routing";
import auth from "../../store/modules/auth";
import coins from "../../store/modules/coins";
import tasks from "../../store/modules/tasks";
import ui from "../../store/modules/ui";
import NetworkConnectionStatus from "../NetworkConnectionStatus";
import Welcome from "../Onboard/Welcome";
import Support from "../Roles/All/Profile/Support";
import SocketConnectionStatus from "../SocketConnectionStatus";
import { Alert } from "../System";
import LoadingScript from "../System/LoadingScript";
import UserHelper from "../UserHelper";
import { themeMigom } from "./../../theme/themeMigom";
import AutoLogout from "./../AutoLogout/index";
import AutoLogoutMessage from "./../AutoLogoutMessage";
import CheckYourEmailResetPassword from "./Confirms/CheckYourEmailResetPassword";
import DeviceConfirmed from "./Confirms/DeviceConfirmed";
import EmailConfirmed from "./Confirms/EmailConfirmed";
import DialogAccessDenied from "./DialogAccessDenied";
import Error from "./Error";
import GoldCoinsAlert from "./GoldCoinsAlert";
import HeaderError from "./HeaderError";
import HeaderLoading from "./HeaderLoading";
import HeaderSuccess from "./HeaderSuccess";
import Leave from "./Leave";
import NoRoles from "./NoRoles";
import Salute from "./Salute";
import ScrollToTop from "./ScrollToTop";
import Terms from "./Terms";

const AlertLeave = view(() => {
  return (
    <Alert
      open={ui.showLeave}
      setOpen={(value) => (ui.showLeave = value as boolean)}
      content={<Leave />}
      size="sm"
    />
  );
});

const SupportWrapper = view((): any => {
  const params = qs.parse(window.location.search);
  return (
    !isDev &&
    !!auth.profile &&
    !checkSystemUser(auth.profile) &&
    !params?.accessToken && <Support />
  );
});

const themeReducer = () => {
  return themeMigom;
};

const BellInit = view(() => {
  const { isClient, isBranchAdmin } = auth.profile || {
    isClient: false,
    isBranchAdmin: false,
  };

  useEffect(() => {
    if (isClient || isBranchAdmin) {
      tasks.fetchClientInboxCount();
    }
  }, [isBranchAdmin, isClient]);

  return null;
});

const UserInit = view(() => {
  const location = useLocation();

  const ref = useRef<any>(false);

  const { profile } = auth;

  useEffect(() => {
    if (!ref.current) {
      const token = sessionStorage.getItem("token");

      if (
        token &&
        (location.pathname.includes("/application") ||
          location.pathname.includes("/short-application"))
      ) {
        ref.current = true;

        auth.fetchAccessToApplication();
      } else if (token && profile === null) {
        ref.current = true;
        auth.auth();
      } else if (!token && profile === null) {
        auth.profile = false;
      }
    }
  }, [location, profile]);

  return null;
});

const WatchGoldCoins = () => {
  useEffect(() => {
    coins.getLocalGoldData();
  }, []);

  return null;
};

const Root = view(() => {
  return (
    <>
      <ThemeProvider theme={themeReducer()}>
        <UserInit />

        <BellInit />

        <AutoLogoutMessage />

        <CssBaseline />

        <Suspense fallback={<LoadingScript />}>
          <MakeRoutes routes={Schema} />
        </Suspense>

        <Error />

        <EmailConfirmed />

        <DeviceConfirmed />

        <CheckYourEmailResetPassword />

        <AlertLeave />

        {!isDev && <AutoLogout />}

        <Terms />

        <ScrollToTop />

        <HeaderError />

        <HeaderLoading />

        <HeaderSuccess />

        {!isDev && <UserHelper />}

        <Salute />

        <Welcome />

        {/* {isDev && <DialogActivateCards />} */}

        <SupportWrapper />

        <SocketConnectionStatus />

        <NetworkConnectionStatus />

        {/* {!isDev && <US />} */}

        <NoRoles />

        <DialogAccessDenied />

        <WatchGoldCoins />

        <GoldCoinsAlert />
      </ThemeProvider>
    </>
  );
});

export default Root;
