import { IconButton } from "@material-ui/core";
import { Close, PlayArrow } from "@material-ui/icons";
import React, { memo, useState } from "react";

const images = [
  {
    preview: "/preview/Gold_Global_Coin_Migom_Bank_Gift_Box_00_thumbnail.jpg",
    full: "/full/Gold_Global_Coin_Migom_Bank_Gift_Box_00.jpg",
  },
  {
    preview:
      "/preview/Gold_Global_Coin_of_Migom_Bank_front_side_00_thumbnail.jpg",
    full: "/full/Gold_Global_Coin_of_Migom_Bank_front_side_00.jpg",
  },
  {
    preview:
      "/preview/Gold_Global_Coin_of_Migom_Bank_back_side_00_thumbnail.jpg",
    full: "/full/Gold_Global_Coin_of_Migom_Bank_back_side_00.jpg",
  },
  {
    preview: "/preview/Gold_Global_Coin_Migom_Bank_Gift_Box_01_thumbnail.jpg",
    full: "/full/Gold_Global_Coin_Migom_Bank_Gift_Box_01.jpg",
  },
  {
    preview: "/preview/Gold_Global_Coin_Migom_Bank_Gift_Box_02_thumbnail.jpg",
    full: "/full/Gold_Global_Coin_Migom_Bank_Gift_Box_02.jpg",
  },
  {
    preview: "/preview/Gold_Global_Coin_Migom_Bank_Gift_Box_03_thumbnail.jpg",
    full: "/full/Gold_Global_Coin_Migom_Bank_Gift_Box_03.jpg",
  },
  {
    preview:
      "/preview/Gold_Global_Coin_of_Migom_Bank_in_a_plastic_protective_package_stack_00_thumbnail.jpg",
    full: "/full/Gold_Global_Coin_of_Migom_Bank_in_a_plastic_protective_package_stack_00.jpg",
  },
  {
    preview:
      "/preview/Gold_Global_Coin_of_Migom_Bank_in_a_plastic_protective_package_stack_01_thumbnail.jpg",
    full: "/full/Gold_Global_Coin_of_Migom_Bank_in_a_plastic_protective_package_stack_01.jpg",
  },
  {
    preview:
      "/preview/Gold_Global_Coin_of_Migom_Bank_in_a_plastic_protective_package_placer_00_thumbnail.jpg",
    full: "/full/Gold_Global_Coin_of_Migom_Bank_in_a_plastic_protective_package_placer_00.jpg",
  },
  {
    preview:
      "/preview/Gold_Global_Coin_of_Migom_Bank_in_a_plastic_protective_package_placer_01_thumbnail.jpg",
    full: "/full/Gold_Global_Coin_of_Migom_Bank_in_a_plastic_protective_package_placer_01.jpg",
  },
  {
    preview:
      "/preview/Gold_Global_Coin_of_Migom_Bank_in_a_plastic_protective_package_placer_02_thumbnail.jpg",
    full: "/full/Gold_Global_Coin_of_Migom_Bank_in_a_plastic_protective_package_placer_02.jpg",
  },
  {
    preview: "/preview2/Migom_Global_Gold_Сoin_011.jpg",
    full: "/full2/Migom_Global_Gold_Сoin_011.jpg",
  },
  {
    preview: "/preview2/Migom_Global_Gold_Сoin_012.jpg",
    full: "/full2/Migom_Global_Gold_Сoin_012.jpg",
  },
  {
    preview: "/preview2/Migom_Global_Gold_Сoin_014.jpg",
    full: "/full2/Migom_Global_Gold_Сoin_014.jpg",
  },
  {
    preview: "/preview2/Migom_Global_Gold_Сoin_018.jpg",
    full: "/full2/Migom_Global_Gold_Сoin_018.jpg",
  },
  {
    preview: "/preview2/Migom_Global_Gold_Сoin_019.jpg",
    full: "/full2/Migom_Global_Gold_Сoin_019.jpg",
  },
  {
    preview: "/preview2/Migom_Global_Gold_Сoin_020.jpg",
    full: "/full2/Migom_Global_Gold_Сoin_020.jpg",
  },
];

export default memo(({ button }: any) => {
  const [img, setImg] = useState("");

  return (
    <div className="max-w-[1000px] mx-auto mt-10">
      <div
        onClick={() => setImg("/gold/gold-coins.webm")}
        className="max-w-md w-full mx-auto  overflow-hidden cursor-pointer hover:opacity-60 rounded-md"
      >
        <div className="relative">
          <video className="object-contain" disabled>
            <source src="/gold/gold-coins.webm" type="video/webm" />
            <p>
              Your browser does not support HTML5 video or mp4 video format.
            </p>
          </video>

          <div className="absolute translate-x-1/2 -translate-y-1/2 right-1/2 top-1/2">
            <PlayArrow className="!text-white !text-[6rem]" />
          </div>
        </div>
      </div>

      <div className="flex justify-center mb-8">{button}</div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
        {images.map((index) => (
          <div
            key={index.full}
            onClick={() => setImg(`/gold/photos${index.full}`)}
            className="overflow-hidden cursor-pointer hover:opacity-60 mr-2 rounded-md"
          >
            <img
              className="object-cover w-full h-full"
              src={`/gold/photos${index.preview}`}
            />
          </div>
        ))}
      </div>

      {Boolean(img) && (
        <div
          onClick={() => {
            setImg("");
          }}
          className="z-[9999] flex justify-center items-center bg-black bg-opacity-80 p-8 fixed top-0 bottom-0 right-0 left-0"
        >
          <div className="absolute top-0 right-0">
            <IconButton>
              <Close fontSize="large" className="!text-white" />
            </IconButton>
          </div>

          {img.includes("webm") ? (
            <video className="m-auto object-contain" autoPlay playsInline>
              <source src={img} />
              <p>
                Your browser does not support HTML5 video or mp4 video format.
              </p>
            </video>
          ) : (
            <img className="m-auto object-contain w-full h-full" src={img} />
          )}
        </div>
      )}
    </div>
  );
});
