import React from "react";
import { useWatch } from "react-hook-form";
import MyCheckboxLabel from "../../../../System/FormComponents/MyCheckboxLabel";
import ShowAndHide from "../../../../System/FormComponents/ShowAndHide";
import SepaDetail from "./SepaDetail";

const Sepa = ({ data, ...form }) => {
  const useSepa = useWatch({
    control: form?.control,
    name: "useSepa",
  });

  const sepa = useSepa && data?.sepaPayment;

  return (
    <ShowAndHide show={Boolean(sepa)}>
      <MyCheckboxLabel
        control={form.control}
        name="useSepa"
        label="I want to confirm using SEPA"
      />

      {sepa && (
        <div>
          <b className="text-main text-lg">
            Auto wire send: Will execute auto sepa payment
          </b>
        </div>
      )}

      <div className="flex flex-col">
        <div className="flex flex-1 w-full justify-between">
          {useSepa && (
            <div className="flex flex-col">
              <b className="text-orange-600 text-lg">Sepa BIB Payment</b>
            </div>
          )}
        </div>

        {sepa && <SepaDetail sepa={sepa} />}
      </div>
    </ShowAndHide>
  );
};

export default Sepa;
