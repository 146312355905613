import { memo, useEffect } from "react";
import { useQuerySocketQuotes } from "../../../../../../queries/useQuerySocketQuotes";
import { useGetAccounts } from "../hooks/useGetAccounts";

const Socket = memo(({ form, setRate, userId }: any) => {
  const { fromAccount, toAccount } = useGetAccounts({ userId, form });

  const pair =
    fromAccount?.currencyId && toAccount?.currencyId
      ? `${fromAccount.currencyId.toUpperCase()}-${toAccount.currencyId.toUpperCase()}`
      : null;

  const { setPair } = useQuerySocketQuotes({
    onUpdated: (data) => {
      if (data?.symbol === pair) {
        setRate(data.rate);
      }
    },
  });

  useEffect(() => {
    if (pair?.split("-")?.length === 2) {
      setPair(pair);
    }
  }, [pair, setPair]);

  return null;
});

export default Socket;
