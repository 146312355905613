import { lazy } from "react";

const TasksSectionInboxFinciRobot = lazy(
  () => import("../../components/Roles/Staff/Tasks/v3/Inbox/FinciRobot")
);

export const FinciViewerTasks = [
  {
    path: "/app/tasks-finci",
    component: TasksSectionInboxFinciRobot,
  },
];
