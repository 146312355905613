import { Button } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import LoadingCircular from "../../../../../LoadingCircular";

const useStyles = makeStyles({
  root: {
    backgroundColor: green[800],
    color: "#fff",
    fontWeight: 700,
    "@media (max-width: 1000px)": {
      fontSize: ".85rem",
    },
    "@media (max-width: 600px)": {
      maxWidth: 420,
      fontSize: "1rem",
    },
    "@media (max-width: 380px)": {
      fontSize: ".85rem",
    },
  },
});

const GreenButton = ({ loading, label, ...props }: any) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.root}
      variant="contained"
      color="primary"
      {...props}
    >
      {label}
      <LoadingCircular loading={loading} />
    </Button>
  );
};

export default GreenButton;
