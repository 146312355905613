import numbro from "numbro";
import React from "react";
import { useWatch } from "react-hook-form";
import ShowAndHide from "../../../../../System/FormComponents/ShowAndHide";
import Password from "../components/Password";

const WrapPassword = ({ form }: any) => {
  const amount = useWatch({
    name: "amount",
    control: form.control,
  });

  const unformattedAmount = numbro.unformat(amount) || 0;

  return (
    <ShowAndHide show={unformattedAmount >= 10000}>
      <Password
        {...form}
        rules={{
          required: unformattedAmount >= 10000,
          validate: {
            valid: (value) => {
              if (value === "atb2021" || unformattedAmount < 10000) {
                return true;
              }
              return false;
            },
          },
        }}
      />
    </ShowAndHide>
  );
};

export default WrapPassword;
