import { view } from "@risingstack/react-easy-state";
import React, { useEffect } from "react";
import blocks from "../../store/modules/blocks";
import BlockView from "../Landing/components/BlockView";
import { FormActions } from "./";
import Alert from "./Alert";

const TermsAndConditions = view(
  ({ open, setOpen, callbackTerms, idConfirmButton, idCancelButton }: any) => {
    const { sections } = blocks as any;

    useEffect(() => {
      if (
        (open && !sections["terms-and-conditions"]) ||
        sections["terms-and-conditions"]?.length === 0
      ) {
        blocks.fetchBlocksBySection({ sectionId: "terms-and-conditions" });
      }
    }, [open, sections]);

    const termsBlock = blocks.getBlockById(
      "terms-of-use-agreemen",
      "terms-and-conditions"
    );

    return (
      <Alert
        open={open}
        setOpen={setOpen}
        size="md"
        content={
          <div>
            <h1 className="font-bold text-main">
              We have updated our Terms & Conditions
            </h1>

            <h2 className="text-main">
              You need to read and agree to the terms. Please scroll down
            </h2>

            <BlockView block={termsBlock} />

            <FormActions
              showConfirmButton
              showCloseButton
              onClickConfirm={() => {
                callbackTerms(true);
                setOpen(false);
              }}
              onClickClose={() => setOpen(false)}
              confirmButtonLabel="I have read and agree to the Terns & Conditions"
              idConfirmButton={idConfirmButton}
              idCloseButton={idCancelButton}
            />
          </div>
        }
      />
    );
  }
);

export default TermsAndConditions;
