import React from "react";
import { formatToCurrencyString } from "../../../../../../helpers";
import ShowAndHide from "../../../../../System/FormComponents/ShowAndHide";

const SfoxDetail = ({ showDetail, sfox }) => {
  return (
    <ShowAndHide show={showDetail}>
      <div className="flex flex-col text-base mt-4">
        <div className="flex flex-col mb-1">
          <span>
            Amount:{" "}
            <b>
              {formatToCurrencyString(
                sfox?.cryptoAmount || 0,
                sfox?.cryptoCurrencyId?.toUpperCase()
              )}
            </b>
          </span>
        </div>

        <div className="flex flex-col mb-1">
          <span>
            From: <b>{sfox?.fromAccountId}</b>
          </span>
        </div>

        <div className="flex flex-col mb-1 text-xs break-all">
          <span>
            To: <b>{sfox?.walletAddress}</b>
          </span>
        </div>

        <div className="flex flex-col mb-1">
          <span>
            Sender comment: <b>{sfox?.senderComment}</b>
          </span>
        </div>
      </div>
    </ShowAndHide>
  );
};

export default SfoxDetail;
