import { useCallback, useEffect, useState } from "react";
import ITask from "../../../../../../interfaces/task";
import tasks from "../../../../../../store/modules/tasks";

export const useFetchTask = (taskId: string, isClient?: boolean) => {
  const [task, setTask] = useState<ITask>();
  const [loading, setLoading] = useState(true);

  const fetcher = useCallback(async () => {
    if (!taskId) {
      return;
    }

    setLoading(true);

    const result = isClient
      ? await tasks.fetchTaskClient(taskId)
      : await tasks.fetchTask(taskId);

    if (result) {
      setTask(result);
    }

    setLoading(false);
  }, [isClient, taskId]);

  useEffect(() => {
    fetcher();
  }, [fetcher]);

  return { task, loading, fetchTask: fetcher };
};
