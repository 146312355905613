import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { memo } from "react";
import { useController } from "react-hook-form";

const getOptionLabel = (option) => {
  return option;
};

const MyAutocomplete = memo(
  ({
    control,
    name,
    options,
    label,
    rules,
    disabled,
    getOptionSelected,
    filterSelectedOptions,
    id,
    customGetOptionLabel,
    multiple,
    disablePortal = true,
    onChangeCallback,
    className,
    style,
  }: any) => {
    const {
      field: { ref, onChange: fieldOnChange, ...field },
      fieldState,
    } = useController({
      name: name,
      control: control,
      rules: rules,
    });

    console.log(fieldState.isDirty, fieldState.isTouched);

    const onChangeAutocomplete = (e, value) => {
      e.stopPropagation();
      fieldOnChange && fieldOnChange(value);
      onChangeCallback && onChangeCallback(value);
    };

    return (
      <Autocomplete
        {...field}
        disablePortal={disablePortal}
        options={options}
        style={style}
        className={className}
        renderInput={(props: any) => {
          return (
            <TextField
              id={id}
              {...props}
              inputRef={ref}
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
              inputProps={{
                ...props.inputProps,

                id: id ? id : props.inputProps.id,
              }}
              fullWidth
              label={label}
            />
          );
        }}
        getOptionLabel={customGetOptionLabel || getOptionLabel}
        getOptionSelected={getOptionSelected}
        filterSelectedOptions={filterSelectedOptions}
        onChange={onChangeAutocomplete}
        disableClearable
        fullWidth
        onInputChange={(e, value, reason) => {
          if (e) {
            e.stopPropagation();
          }

          if (reason === "clear") {
            fieldOnChange && fieldOnChange([]);
            onChangeCallback && onChangeCallback(null);
          }
        }}
        disabled={disabled}
        multiple={multiple}
        onClose={(e) => e.stopPropagation()}
      />
    );
  }
);

export default MyAutocomplete;
