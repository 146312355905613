import { Divider, Grid } from "@material-ui/core";
import React from "react";
import { useWatch } from "react-hook-form";
import MyCheckboxLabel from "../../../../../System/FormComponents/MyCheckboxLabel";
import MyDate from "../../../../../System/FormComponents/MyDate";
import ShowAndHide from "../../../../../System/FormComponents/ShowAndHide";

const AdvancedOptionsPart2 = ({ prefix = "", control, hideDetails }: any) => {
  const showAdvancedOptions = useWatch({
    control: control,
    name: prefix + "showAdvancedOptions",
  });

  return (
    <ShowAndHide show={showAdvancedOptions}>
      <div className="h-2.5" />

      {!hideDetails ? <Divider /> : null}

      <div className="h-2.5" />

      <Grid container>
        <Grid item xs={12} sm={6}>
          <MyDate
            control={control}
            name="transactionTime"
            label="Transaction time"
            showTime
          />
        </Grid>
      </Grid>

      <div className="flex">
        {!hideDetails && (
          <div className="mr-1.5">
            <MyCheckboxLabel
              control={control}
              name={prefix + "isPendingTransaction"}
              label="Is pending transaction"
            />
          </div>
        )}

        <div>
          <MyCheckboxLabel
            control={control}
            name={prefix + "allowNegativeBalance"}
            label="Allow negative balance"
          />
        </div>
      </div>
    </ShowAndHide>
  );
};

export default AdvancedOptionsPart2;
