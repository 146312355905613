import { blue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { view } from "@risingstack/react-easy-state";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  formatDateToMMddyyyyHHmm,
  formatToCurrencyString,
} from "../../../../../../../helpers";
import cryptoAdmin from "../../../../../../../store/modules/cryptoAdmin";
import FormatAmount from "../../../../../../System/FormatAmount";
import LazyImage from "../../../../../../System/LazyImage";
import MigomTable from "../../../../../../System/MigomTable";

const useStyles = makeStyles({
  root: {
    "@global": {
      path: {
        color: blue[600],
      },
    },
  },
});

export const makeDesc = (str) => {
  if (!str) {
    return null;
  }

  if (str?.length > 20) {
    return str.substring(0, 19) + "...";
  }

  return str;
};

const CryptoTransactions = view(({ transactionId }) => {
  const classes = useStyles();
  const [data, setData] = useState([]);

  const columns = useMemo(() => {
    return [
      {
        Header: " ",
        id: "manual",
        style: {
          fontSize: ".95rem",
          whiteSpace: "nowrap",
        },
        styleHead: {},
        accessor: (rowData) => {
          return (
            rowData?.manual && (
              <LazyImage
                classNameImageBlock="w-10 h-10"
                src="/images/select.svg"
              />
            )
          );
        },
      },
      {
        Header: "Date",
        id: "ts",
        style: {
          fontSize: ".95rem",
          whiteSpace: "nowrap",
        },
        styleHead: {},
        accessor: (rowData) => {
          return formatDateToMMddyyyyHHmm(rowData.ts);
        },
      },

      {
        Header: "Type",
        id: "nameAction",
        style: {
          fontSize: ".95rem",
          whiteSpace: "nowrap",
        },
        styleHead: {
          whiteSpace: "nowrap",
        },
        accessor: (rowData) => {
          return `${rowData.nameAction} ${rowData.pair || ""}`;
        },
      },

      {
        Header: "Description",
        disableSortBy: true,
        style: {
          fontSize: ".95rem",
          maxWidth: 520,
          width: "100%",
        },
        styleHead: {
          whiteSpace: "nowrap",
        },
        accessor: (rowData) => {
          if (rowData.action.toLowerCase() === "deposit") {
            return makeDesc(
              `${rowData?.nameAction} ${rowData?.coinAddress || ""}`
            );
          }
          if (rowData.action.toLowerCase() === "withdraw") {
            return (
              <div
                dangerouslySetInnerHTML={{
                  __html: (rowData.memo || "").replace(
                    /`([^"]+)`/g,
                    `<span style="border: 1px solid #ccc; padding: 2px 4px;">$1</span>`
                  ),
                }}
              />
            );
          }

          let amount = null;

          if (rowData?.nameAction?.toUpperCase()?.includes("BUY")) {
            amount = (
              <b className="text-main">
                <FormatAmount
                  amount={rowData?.amount}
                  currency={rowData?.targetCurrency?.toUpperCase()}
                />
              </b>
            );
          } else if (rowData?.nameAction?.toUpperCase()?.includes("SELL")) {
            amount = (
              <b className="text-red-600">
                <FormatAmount
                  amount={rowData?.amount}
                  currency={rowData?.targetCurrency?.toUpperCase()}
                />
              </b>
            );
          } else {
            amount = (
              <b>
                <FormatAmount
                  amount={rowData?.amount}
                  currency={rowData?.targetCurrency?.toUpperCase()}
                />
              </b>
            );
          }

          return (
            <span>
              {amount} @{" "}
              {formatToCurrencyString(
                rowData?.price,
                rowData?.currency?.toUpperCase()
              )}
            </span>
          );
        },
      },
      {
        Header: "Fees",
        id: "fees",
        style: {
          fontSize: ".95rem",
          textAlign: "right",
        },
        styleHead: {
          whiteSpace: "nowrap",
          textAlign: "right",
        },
        accessor: (rowData) => {
          return formatToCurrencyString(
            rowData.fees,
            rowData.currency?.toUpperCase()
          );
        },
      },
      {
        Header: "Net Amount",
        id: "netProceeds",
        style: {
          fontSize: ".95rem",
          textAlign: "right",
        },
        styleHead: {
          whiteSpace: "nowrap",
          textAlign: "right",
        },
        accessor: (rowData) => {
          return formatToCurrencyString(
            rowData.netProceeds,
            rowData.currency?.toUpperCase()
          );
        },
      },
    ];
  }, []);

  const fetcher = useCallback(async () => {
    if (transactionId?.toLowerCase()?.includes("unknown")) {
      return;
    }

    const result = await cryptoAdmin.fetchCryptoTransactionsCustom({
      transactionId,
      perPage: 999,
    });

    if (result) {
      setData(result?.data);
    }
  }, [transactionId]);

  useEffect(() => {
    fetcher();
  }, [fetcher]);

  return (
    data?.length > 0 && (
      <div className={classes.root}>
        <div className="h-4" />

        <div className="flex justify-center">
          <h2>Crypto transactions</h2>
        </div>

        <div className="h-4" />

        <MigomTable columns={columns} data={data} hidePagination autoHeight />
      </div>
    )
  );
});

export default CryptoTransactions;
