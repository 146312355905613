import { clsx } from "clsx";
import React from "react";
import CopyAccountNumber from "../../../../../System/CopyAccountNumber";

const FieldBox = ({
  label,
  value,
  showCopy,
  copyValue,
  className,
  style = {},
}: any) => {
  return (
    <div className="w-full flex justify-between text-base border-b border-solid border-gray-200 mb-2 pb-2">
      <span className="whitespace-nowrap">{label}</span>
      <span
        className={clsx("font-bold text-right ml-8 break-all", className)}
        style={style}
      >
        {value ? value : "-"}{" "}
        {showCopy && (
          <CopyAccountNumber
            number={copyValue ? copyValue : value?.toString()}
          />
        )}
      </span>
    </div>
  );
};

export default FieldBox;
