import Cleave from "cleave.js/react";
import React, { useEffect, useState } from "react";

const InputFormatter = ({ inputRef, value, isCrypto, ...props }: any) => {
  delete props.defaultValue;

  const [rerender, setRerender] = useState(0);

  useEffect(() => {
    setRerender((prevState) => prevState + 1);
  }, [isCrypto]);

  const onChange = (event: any) =>
    props.onChange({
      target: {
        name: props.name,
        value: event.target.value,
      },
    });

  const options = {
    numeral: true,
    numeralDecimalScale: isCrypto ? 8 : 2,
  };

  return (
    <Cleave
      key={rerender}
      ref={(ref: any) => inputRef(ref ? ref?.element : null)}
      {...props}
      value={value}
      onChange={onChange}
      options={options}
    />
  );
};

export default InputFormatter;
