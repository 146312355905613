import { store } from "@risingstack/react-easy-state";

interface IErrors {
  error?: any;
}

const errors = store<IErrors>({
  error: undefined,
});

export default errors;
