import { Link } from "@material-ui/core";
import React from "react";
import auth from "../../../../../../store/modules/auth";
import CopyAccountNumber from "../../../../../System/CopyAccountNumber";

const RelatedKey = ({ transaction }) => {
  let pathToDetail;

  if (transaction?.adminKeyType === "Account") {
    pathToDetail = `/app/admin/accounts/${transaction?.adminKey}`;
  }

  if (transaction?.adminKeyType === "Recipient") {
    pathToDetail = `/app/admin/global-recipients/wire/${transaction?.adminKey}`;
  }

  if (transaction?.adminKeyType === "CryptoRecipient") {
    pathToDetail = `/app/admin/global-recipients/crypto/${transaction?.adminKey}`;
  }

  if (transaction?.adminKeyType === "Transactive") {
    pathToDetail = `/app/admin/transactive/payments?search=${transaction?.adminKey}`;
  }

  if (transaction?.adminKeyType === "Incore") {
    pathToDetail = `/app/admin/incore/payments?search=${transaction?.adminKey}`;
  }

  return (
    <>
      <div className="flex justify-between items-center text-base flex-1 py-1 px-2 border-b border-solid border-gray-200 ">
        <div className="flex-1 pr-4">Related key</div>
        <div className="flex-1 flex justify-end font-bold text-right break-words w-full">
          {auth?.profile?.isAnalyst || !pathToDetail ? (
            <span>
              {transaction?.adminKey}{" "}
              <CopyAccountNumber number={transaction?.adminKey} /> (
              {transaction?.adminKeyType})
            </span>
          ) : (
            <>
              <Link to={pathToDetail} className="mr-1">
                {transaction?.adminKey}
              </Link>
              <CopyAccountNumber number={transaction?.adminKey} />{" "}
              <span className="ml-1">({transaction?.adminKeyType})</span>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default RelatedKey;
