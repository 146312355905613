import { IconButton } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import { view } from "@risingstack/react-easy-state";
import React, { useEffect } from "react";
import ui from "../store/modules/ui";

const AutoLogoutMessage = view(() => {
  useEffect(() => {
    const autoLogoutSuccess = sessionStorage.getItem("autoLogoutSuccess");

    if (autoLogoutSuccess === "1") {
      ui.openAutoLogoutMessage = true;
    } else {
      ui.openAutoLogoutMessage = false;
    }
  }, []);

  return ui.openAutoLogoutMessage ? (
    <div className=" text-main text-white flex justify-between items-center w-full py-2 px-4 z-[9999999] sticky">
      <p className="m-0">
        For security of your account we logged you out of your online banking
        session after a period of inactivity.
      </p>

      <IconButton
        onClick={() => {
          ui.openAutoLogoutMessage = false;

          sessionStorage.removeItem("autoLogoutSuccess");
        }}
      >
        <CloseOutlined className="h-4 text-white" />
      </IconButton>
    </div>
  ) : null;
});

export default AutoLogoutMessage;
