import React from "react";
import { formatToCurrencyString } from "../../../../../../helpers";

const FinciDetails = ({ finci, task }) => {
  return (
    <div className="flex flex-col text-base mt-4">
      <div className="flex flex-col mb-2">
        <span>
          Amount:{" "}
          <b>
            {formatToCurrencyString(
              finci?.transactionAmount || 0,
              task?.currencyId
            )}
          </b>
        </span>
      </div>

      <div className="flex flex-col mb-2">
        <span>
          From: <b>{finci?.debitAccountId}</b>
        </span>
      </div>

      <div className="flex flex-col mb-2">
        <span>
          To:{" "}
          <b>
            {finci?.creditAccountId} {finci?.beneficiaryName}
          </b>
        </span>
      </div>

      <div className="flex flex-col mb-2">
        <span>
          Description: <b>{finci?.narrative}</b>
        </span>
      </div>

      <div className="flex flex-col mb-2">
        <span>
          Additionally:{" "}
          <b>
            {finci?.paymentType}, {finci?.orderingReference}
          </b>
        </span>
      </div>
    </div>
  );
};

export default FinciDetails;
