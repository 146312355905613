import { lazy } from "react";

const Root = lazy(() => import("../../components/Roles/Admin/SecureMessages"));

const Inbox = lazy(
  () => import("../../components/Roles/Admin/SecureMessages/Inbox")
);

const SentMessages = lazy(
  () => import("../../components/Roles/Admin/SecureMessages/SentMessages")
);

const SpecialOffers = lazy(
  () => import("../../components/Roles/Admin/SecureMessages/SpecialOffers")
);

const CreateMessage = lazy(
  () => import("../../components/Roles/Admin/SecureMessages/CreateMessage")
);

const ThreadMessages = lazy(
  () => import("../../components/Roles/Admin/SecureMessages/ThreadMessages")
);

export const AdminSecureMessages = [
  {
    path: "/app/admin/secure-messages",
    component: Root,

    routes: [
      {
        path: "/app/admin/secure-messages/message/:messageId",
        component: ThreadMessages,
      },

      {
        path: "/app/admin/secure-messages/create-message",
        component: CreateMessage,
      },
      {
        path: "/app/admin/secure-messages/offers",
        component: SpecialOffers,
      },
      {
        path: "/app/admin/secure-messages/sent-messages",
        component: SentMessages,
      },
      {
        path: "/app/admin/secure-messages/inbox",
        component: Inbox,
      },
    ],
  },
];
