export const makeDepositFeeTypes = (account) => {
  if (account?.accountTypeId === "Bank" && account?.currencyId === "USD") {
    return [
      {
        value: "DEPBNK_Domestic",
        label: "DEPBNK - Domestic",
      },
      {
        value: "DEPBNK_International",
        label: "DEPBNK - International",
      },
      {
        value: "NO_FEE_DEPBNK",
        label: "DEPBNK - No fee",
      },
    ];
  }

  if (account?.accountTypeId === "DebitCard" && account?.currencyId === "USD") {
    return [
      {
        value: "DEPCRD_Domestic",
        label: "DEPCRD - Domestic",
      },
      {
        value: "DEPCRD_International",
        label: "DEPCRD - International",
      },
      {
        value: "NO_FEE_DEPCRD",
        label: "DEPCRD - No fee",
      },
    ];
  }

  if (account?.accountTypeId === "Bank" && account?.currencyId !== "USD") {
    return [
      {
        value: "DEPBNK",
        label: "DEPBNK - Receive transfer to Account from external bank",
      },
      {
        value: "NO_FEE_DEPBNK",
        label: "DEPBNK - No fee",
      },
    ];
  }

  if (account?.accountTypeId === "DebitCard" && account?.currencyId !== "USD") {
    return [
      {
        value: "DEPCRD",
        label: "DEPCRD - Receive transfer to Account from external bank",
      },
      {
        value: "NO_FEE_DEPCRD",
        label: "DEPCRD - No fee",
      },
    ];
  }

  if (account?.accountTypeId === "Crypto" && account?.currencyId === "MIGG") {
    return [
      {
        value: "DEPGLD",
        label: "DEPGLD",
      },
      {
        value: "NO_FEE_DEPGLD",
        label: "DEPGLD - No fee",
      },
    ];
  }

  if (account?.accountTypeId === "Crypto" && account?.currencyId !== "MIGG") {
    return [
      {
        value: "DEPCRY",
        label: "DEPCRY - Crypto Deposit",
      },
      {
        value: "NO_FEE_DEPCRY",
        label: "DEPCRY - No fee",
      },
    ];
  }

  return [];
};
