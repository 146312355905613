import { FormHelperText } from "@material-ui/core";
import numbro from "numbro";
import React, { useCallback, useState } from "react";
import accounts from "../../../../../../store/modules/accounts";
import LoadingCircular from "../../../../../LoadingCircular";
import MyAmount from "../../../../../System/FormComponents/MyAmount";

const Amount = ({ form, account }) => {
  const [loading, setLoading] = useState(false);

  const { setValue, clearErrors } = form;

  const fetchMaxAmount = useCallback(async () => {
    if (!account.accountId) {
      return;
    }

    setLoading(true);

    const result = await accounts.fetchMaxCryptoWithdrawAmountForAdmin(
      account.accountId,
      { feeId: "TRNSF" }
    );

    if (result) {
      setTimeout(() => setValue("amount", result.maxAmount), 0);
      setTimeout(() => setValue("fixedFee", result.fixedFee), 0);

      clearErrors("amount");
    }

    setLoading(false);
  }, [account.accountId, clearErrors, setValue]);

  return (
    <div>
      <MyAmount
        {...form}
        name="amount"
        controlCurrency={form.control}
        currencyName="currencyId"
        label="Transfer amount*"
        rules={{
          required: true,
          validate: {
            valid: (value) => {
              const unformatted = numbro.unformat(value);

              if (unformatted > 0) {
                return true;
              }

              return false;
            },
          },
        }}
        endAdornment={
          <div className="relative">
            {account && (
              <a
                className={
                  loading || !account.accountId
                    ? "text-gray-400 hover:text-gray-400"
                    : ""
                }
                onClick={fetchMaxAmount}
                disabled={loading || !account.accountId}
              >
                max
                <LoadingCircular loading={loading} />
              </a>
            )}
          </div>
        }
      />

      {form?.formState?.errors?.amount?.type === "required" && (
        <FormHelperText error>Field is required</FormHelperText>
      )}

      {form?.formState?.errors?.amount?.type === "valid" && (
        <FormHelperText error>
          The sell amount must be greater than zero
        </FormHelperText>
      )}
    </div>
  );
};

export default Amount;
