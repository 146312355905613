import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useHistory } from "react-router-dom";
import ui from "../../store/modules/ui";

const useStyles = makeStyles({
  grid: {
    padding: "30px 0",
  },
});

const Leave = () => {
  const history = useHistory();
  const classes = useStyles();

  const no = () => {
    ui.showLeave = false;
  };

  const leave = () => {
    ui.showLeave = false;

    history.push("/app/overview");
  };

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Are you sure ?
      </Typography>

      <Typography variant="body2">
        You haven&apos;t saved you changes yet, and you&apos;ll lose them if you
        leave the page.
      </Typography>

      <Grid
        container
        justify="space-around"
        alignItems="center"
        className={classes.grid}
        spacing={3}
      >
        <Grid item xs={12} sm={6}>
          <Button
            style={{ width: "100%" }}
            color="primary"
            variant="contained"
            onClick={no}
          >
            Don&apos;t leave
          </Button>
        </Grid>

        {!location.pathname.includes("accounts") && (
          <Grid item xs={12} sm={6}>
            <Button
              style={{ width: "100%" }}
              color="primary"
              variant="contained"
              onClick={leave}
            >
              Leave
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Leave;
