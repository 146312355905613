import React from "react";
import MyTextField from "../../../../../System/FormComponents/MyTextField";

const CurrentRate = ({ form, setChangeRate }) => {
  // const { fromAccount, toAccount } = useGetAccounts({ userId, form });

  return (
    <div>
      <MyTextField
        control={form.control}
        name="rate"
        label="Rate"
        onFocus={() => {
          setChangeRate(false);
        }}
      />
    </div>
  );
};

export default CurrentRate;
