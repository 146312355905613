import { view } from "@risingstack/react-easy-state";
import React from "react";
import { useHistory } from "react-router-dom";
import transactionDetails from "../../../../../../../store/modules/transactionDetails";
import { FormActions } from "../../../../../../System";

const OpenTask = view(() => {
  const { loading, detailSeeUser } = transactionDetails;

  const { operationId } = detailSeeUser || {};

  const history = useHistory();

  const openTask = () => {
    const taskId = operationId?.split("-")?.[0];

    if (!taskId) {
      return;
    }

    history.push(`/app/tasks/details/${taskId}`);
    transactionDetails.clearStore();
  };

  return detailSeeUser?.isPending ? (
    <FormActions
      showNextButton
      loading={loading}
      disabledNextButton={loading}
      nextButtonLabel="Open Task"
      onClickNext={openTask}
    />
  ) : null;
});

export default OpenTask;
