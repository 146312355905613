import { Button, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { view } from "@risingstack/react-easy-state";
import React, { memo } from "react";
import ui from "../../store/modules/ui";
import Anima from "../System/Anima";
import LazyImage from "../System/LazyImage";

const LazyAnima = memo(() => {
  return <Anima loop={false} json={"/keys/error.json"} className="w-20 h-20" />;
});

const HeaderError = view(() => {
  const close = () => {
    ui.errorOperation = "";
  };

  const showError = Boolean(ui.errorOperation);

  return showError ? (
    <div className="flex items-center justify-center fixed inset-x-0 bottom-0 bg-white py-4 px-0 z-[2000] w-full shadow-md shadow-black/20">
      <div>
        <LazyImage anima={LazyAnima} />
      </div>

      <b
        className=" w-80 -ml-2.5 relative"
        dangerouslySetInnerHTML={{ __html: ui.errorOperation }}
      />

      <Button
        style={{ margin: 0 }}
        variant="contained"
        color="primary"
        onClick={close}
      >
        Close
      </Button>

      <div className="absolute top-1.5 right-1.5">
        <IconButton onClick={close}>
          <Close />
        </IconButton>
      </div>
    </div>
  ) : null;
});

export default HeaderError;
