import { Button } from "@material-ui/core";
import React, { memo } from "react";
import { useHistory } from "react-router-dom";
import Blocks from "../../../Offers/GoldCoins/components/Blocks";
import GoldCoinsCollections from "./GoldCoinsCollections";

export default memo(() => {
  const history = useHistory();

  return (
    <div className="mx-auto max-w-[1000px] py-4 px-4">
      <h1 className="text-[2.2rem] text-center leading-tight mb-10 text-gray-600">
        Remium Global Coin (MIGG) is the future of money!
      </h1>

      <Blocks
        button={
          <Button
            onClick={() => {
              history.push("/app/exchange", history.location.state);
            }}
            variant="contained"
            color="primary"
          >
            BUY NOW
          </Button>
        }
      />

      <h2 className="text-center text-2xl mt-[8rem]">
        See how Remium Global Gold Coin looks like
      </h2>

      <GoldCoinsCollections
        button={
          <Button
            onClick={() => {
              history.push("/app/exchange", history.location.state);
            }}
            variant="contained"
            color="primary"
          >
            BUY NOW
          </Button>
        }
      />
    </div>
  );
});
