import { view } from "@risingstack/react-easy-state";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { is } from "../helpers/is";
import { useTimer } from "../hooks/useTimer";
import auth from "../store/modules/auth";

const Message = view(() => {
  const [seconds, setSeconds] = useState(6);

  const timer = useTimer({
    seconds: seconds,
    setSeconds: setSeconds,
    diff: -1,
  });

  useEffect(() => {
    if (seconds === 0) {
      setSeconds(6);
    }
  }, [seconds]);

  return is(Array, auth.notWorkingHubs) ? (
    <div className="flex flex-col">
      <span>You have non-working hubs: {auth.notWorkingHubs.join(", ")}.</span>
      <span>
        Trying to restore the connection <b>{timer}</b>
      </span>
    </div>
  ) : null;
});

const SocketConnectionStatus = view(() => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { notWorkingHubs } = auth;

  const refKey = useRef<any>(null);

  useEffect(() => {
    if (
      !refKey.current &&
      is(Array, notWorkingHubs) &&
      notWorkingHubs.length > 0
    ) {
      refKey.current = enqueueSnackbar(<Message />, {
        variant: "warning",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
        autoHideDuration: null,
      });
    } else if (
      refKey.current &&
      is(Array, notWorkingHubs) &&
      notWorkingHubs.length === 0
    ) {
      closeSnackbar(refKey.current);

      enqueueSnackbar("All hubs are connected!", {
        variant: "success",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
        autoHideDuration: 3000,
      });
    }
  }, [closeSnackbar, enqueueSnackbar, notWorkingHubs]);

  return null;
});

export default SocketConnectionStatus;
