import numbro from "numbro";
import {
  checkIsCryptoCurrency,
  isStableCoin,
  symbols,
} from "./formatToCurrencyString";

export const simpleFormatAmount = (
  value?: number,
  currencyId?: string,
  mantissa?: number
) => {
  const c = symbols[currencyId as string];

  if (!value) {
    return "-";
  }

  if (!mantissa) {
    return (c || "") + numbro(value || 0).format({ thousandSeparated: true });
  }

  if (
    checkIsCryptoCurrency(currencyId || "") &&
    !isStableCoin(currencyId || "")
  ) {
    return (
      (c || "") +
      numbro(value || 0).format({
        thousandSeparated: true,
        mantissa: 4,
        trimMantissa: false,
      })
    );
  }

  if (isStableCoin(currencyId || "")) {
    return (
      (c || "") +
      numbro(value || 0).format({
        thousandSeparated: true,
        mantissa: 2,
        trimMantissa: false,
      })
    );
  }

  return (
    (c || "") +
    numbro(value || 0).format({ thousandSeparated: true, mantissa: mantissa })
  );
};
