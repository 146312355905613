import { useEffect, useState } from "react";

const validPassword = "atb2021";

export const useCryptoPassword = (successCallback) => {
  const [enterPassword, setEnterPassword] = useState("");
  const [openPassword, setOpenPassword] = useState(false);

  const accessPassword = enterPassword === validPassword;

  useEffect(() => {
    if (accessPassword) {
      successCallback();
      setEnterPassword("");
      setOpenPassword(false);
    }
  }, [accessPassword, successCallback]);

  return {
    setEnterPassword,
    openPassword,
    setOpenPassword,
  };
};
