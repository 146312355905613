import React from "react";
import { useWatch } from "react-hook-form";
import MyCheckboxLabel from "../../../../../System/FormComponents/MyCheckboxLabel";
import ShowAndHide from "../../../../../System/FormComponents/ShowAndHide";

const CryptoAdvancedOptions = ({ prefix = "", control }) => {
  const showAdvancedOptions = useWatch({
    control: control,
    name: prefix + "showAdvancedOptions",
  });

  return (
    <ShowAndHide show={showAdvancedOptions}>
      <MyCheckboxLabel
        control={control}
        name={prefix + "feeInsideAmount"}
        label="Fee inside amount"
      />
    </ShowAndHide>
  );
};

export default CryptoAdvancedOptions;
