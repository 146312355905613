import { store } from "@risingstack/react-easy-state";
import { subDays } from "date-fns";
import qs from "query-string";
import ENDPOINTS from "../../endpoints";
import { getBase64 } from "../../helpers";
import { makeParams } from "../../helpers/makeParams";
import { del, get, patch, post, showError } from "../api";
import auth from "./auth";
import { defaultParams } from "./tools/defaults";

interface IAdmin {
  loading: boolean;
  reportColumns: any[];
  reportData: any[];
  reportParams: Record<string, any>;
  items: any[];

  pages: number;
  params: Record<string, any>;

  [key: string]: any;
}

const admin = store<IAdmin>({
  loading: false,
  reportColumns: [],
  reportData: [],
  reportParams: {
    from: subDays(new Date(), 7),
    to: new Date(),
    interval: "Week",
    dataType: "Value",
  },

  items: [],

  pages: 1,
  params: {
    ...defaultParams(),
    type: "",
    state: "Open",
    balance: "",
    group: "",
    currency: "",
    feeStructureId: null,
    isFreeze: null,
    isPrivateStructure: null,
    balanceMoreThen: null,
    branchId: "",
    feeRuleId: null,
    userId: null,
  },

  async fetchSumBalances(exportCsv = false) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.sumBalances,
        undefined,
        exportCsv ? "blob" : undefined,
        {
          Accept: exportCsv ? "text/csv" : "application/json",
        }
      );
      if (exportCsv) {
        const blob = data;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "deposits.csv";
        link.click();
      }
      return exportCsv ? true : data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async sendConfirmEmail(userId: string) {
    try {
      await post(ENDPOINTS.admin.sendConfirmEmail.replace("{userId}", userId));

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async sendWelcomeEmail(userId: string) {
    try {
      await post(ENDPOINTS.admin.sendWelcomeEmail.replace("{userId}", userId));

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchCountUsers() {
    try {
      const { data } = await get(ENDPOINTS.admin.fetchCountUsers);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchAccounts(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(ENDPOINTS.admin.accounts, {
        ...params,
        sortDirection: "Asc",
        sortBy: "ownerName",
        perPage: 9999,
      });

      return data?.data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchUserAccountsTransfer(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(ENDPOINTS.admin.accounts, {
        ...params,
        sortDirection: "Asc",
        sortBy: "ownerName",
        state: "Open",
      });

      return data?.data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchAllAccountsForSelect(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(ENDPOINTS.admin.accounts, {
        ...params,
        sortDirection: "Asc",
        sortBy: "ownerName",
      });

      return data?.data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async fetchDebitCardImageUser(accountId: string) {
    try {
      const response = await get(
        ENDPOINTS.admin.fetchDebitCardImageUser.replace(
          "{accountId}",
          accountId
        ),
        undefined,
        "arraybuffer"
      );
      if (response.data) {
        return getBase64(response);
      }
    } catch (err) {
      return null;
    }
  },

  async fetchAccountsV2(params: Record<string, any> | undefined) {
    admin.loading = true;
    try {
      if (!params) {
        params = {};
      }

      if (auth.profile && auth.profile?.isBranchAdmin) {
        params.branchId = auth.profile?.branch?.parentUserId;
      }

      const { data } = await get(ENDPOINTS.admin.accounts, params);

      admin.items = data?.data;
      admin.pages = data.pages;
      admin.loading = false;

      return true;
    } catch (err) {
      admin.loading = false;
      showError(err);
      return false;
    }
  },

  async fetchAccountsV2Csv() {
    admin.loading = true;
    try {
      const params = qs.parse(window.location.search) as Record<string, any>;

      if (
        auth.profile &&
        auth.profile?.isBranchAdmin &&
        auth.profile?.branch?.parentUserId
      ) {
        params.branchId = auth.profile.branch.parentUserId;
      }

      params.page = params.pageIndex ? +params.pageIndex + 1 : 1;

      delete params.pageIndex;

      const { data } = await get(ENDPOINTS.admin.accounts, params, "blob", {
        Accept: "text/csv",
      });

      const blob = data;
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "accounts.csv";
      link.click();
      admin.loading = false;

      return true;
    } catch (err) {
      admin.loading = false;
      showError(err);
      return false;
    }
  },

  async fetchAllAccounts({
    p,
    exportCsv = false,
  }: {
    p?: Record<string, any>;
    exportCsv?: boolean;
  } = {}) {
    admin.loading = true;
    try {
      const params: Record<string, any> = {
        ...admin.params,
        page: admin.params.pageIndex + 1,
        ...(p || {}),
      };

      if (auth.profile && auth.profile?.isBranchAdmin) {
        params.branchId = auth.profile?.branch?.parentUserId;
      }

      delete params.pageIndex;

      const { data } = await get(
        ENDPOINTS.admin.accounts,
        params,
        exportCsv ? "blob" : undefined,
        {
          Accept: exportCsv ? "text/csv" : "application/json",
        }
      );

      if (exportCsv) {
        const blob = data;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "accounts.csv";
        link.click();
      } else {
        admin.items = data?.data;
        admin.pages = data.pages;
      }
      admin.loading = false;

      return exportCsv ? true : data?.data;
    } catch (err) {
      admin.loading = false;
      showError(err);
      return false;
    }
  },

  async fetchCustomAllAccounts(params: Record<string, any> | undefined) {
    admin.loading = true;
    try {
      const { data } = await get(ENDPOINTS.admin.accounts, params);

      return data?.data;
    } catch (err) {
      admin.loading = false;
      showError(err);
      return false;
    }
  },
  async fetchAllSystemAccounts(p: Record<string, any>) {
    admin.loading = true;
    try {
      const params: Record<string, any> = {
        ...p,
        page: 1,
      };

      delete params.pageIndex;

      const { data } = await get(ENDPOINTS.admin.accounts, params);

      return data.data;
    } catch (err) {
      admin.loading = false;
      showError(err);
      return false;
    }
  },

  async fetchUserAccount(accountId: string) {
    try {
      const response = await get(
        ENDPOINTS.admin.fetchUserAccount.replace("{accountId}", accountId)
      );

      return response?.data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchOpenSignedAccountForm(signedContractId: string) {
    try {
      const { data, headers } = await get(
        ENDPOINTS.admin.fetchOpenSignedAccountForm.replace(
          "{signedContractId}",
          signedContractId
        ),
        undefined,
        "arraybuffer"
      );

      const contentType = headers["content-type"];
      const disposition = headers["content-disposition"];

      if (
        contentType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        const blob = new Blob([data], { type: contentType });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = disposition?.fileName || "word";
        link.click();
        link.remove();
      } else {
        const file = new Blob([data], { type: contentType });
        const fileURL = URL.createObjectURL(file);

        open(fileURL, "_blank");
      }

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchUserAccounts(
    userId: any,
    params: Record<string, any> | undefined
  ) {
    try {
      const { data } = await get(ENDPOINTS.admin.fetchUserAccounts, {
        page: 1,
        perPage: 999999,
        userId,
        ...params,
      });

      return data.data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async fetchUserAccountTransaction(isPending: any, operationId: string) {
    try {
      const response = await get(
        ENDPOINTS.admin.operation.replace("{operationId}", operationId),
        { isPending: isPending }
      );

      return response.data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async createVirtualAccount(payload: Record<string, any> | undefined) {
    try {
      const { data } = await post(ENDPOINTS.admin.accounts, payload);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async createDebitCard(payload: { [x: string]: string | Blob }) {
    try {
      const formData = new FormData();

      for (const key in payload) {
        if (payload[key]) {
          formData.append(key, payload[key]);
        }
      }

      const { data } = await post(ENDPOINTS.admin.createDebitCard, formData, {
        "Content-Type": "multipart/form-data",
        Accept: true,
      });

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async autoCreateIban(accountId: string) {
    try {
      await post(
        ENDPOINTS.admin.autoCreateIban.replace("{accountId}", accountId)
      );
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async closeIban(accountId: string) {
    try {
      await post(ENDPOINTS.admin.closeIban.replace("{accountId}", accountId));
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async plaidAccountsUserByAdmin(userId: string, params) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.plaidAccountsUserByAdmin.replace("{userId}", userId),
        params
      );
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async fetchOperations(
    accountId: string,
    params: Record<string, any> | undefined
  ) {
    try {
      const response = await get(
        ENDPOINTS.admin.fetchUserAccountTransactions.replace(
          "{accountId}",
          accountId
        ),
        params
      );

      return response.data;
    } catch (err) {
      showError(err);

      return false;
    }
  },
  async fetchAllOperations(
    params: Record<string, any> | undefined,
    exportCsv: undefined
  ) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.fetchAllOperations,
        params,
        exportCsv ? "blob" : undefined,
        {
          Accept: exportCsv ? "text/csv" : "application/json",
        }
      );
      if (exportCsv) {
        const blob = data;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "all_operations.csv";
        link.click();
      }

      return exportCsv ? true : data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchTransPayments(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(ENDPOINTS.admin.fetchTransPayments, params);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchFinPayments(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(ENDPOINTS.admin.fetchFinPayments, params);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchTransOperations(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(ENDPOINTS.admin.fetchTransOperations, params);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchFinOperations(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(ENDPOINTS.admin.fetchTransOperations, params);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async setCardStatus(
    userId: string,
    payload: Record<string, any> | undefined
  ) {
    try {
      await post(
        ENDPOINTS.admin.setCardStatus.replace("{userId}", userId),
        payload
      );

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async patchAccount(
    accountId: string,
    payload: Record<string, any> | undefined
  ) {
    try {
      await patch(
        ENDPOINTS.admin.patchAccount.replace("{accountId}", accountId),
        payload
      );
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async patchOperation(
    operationId: string,
    payload: Record<string, any> | undefined
  ) {
    try {
      await patch(
        ENDPOINTS.admin.patchOperation.replace("{operationId}", operationId),
        payload
      );
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async previewExisting(payload: Record<string, any> | undefined) {
    try {
      const { data } = await post(ENDPOINTS.admin.previewExisting, payload);
      return [true, data];
    } catch (err: any) {
      return [false, err?.response?.data];
    }
  },

  async preview(payload: Record<string, any> | undefined) {
    try {
      const { data } = await post(ENDPOINTS.admin.preview, payload);
      return [true, data];
    } catch (err: any) {
      return [false, err?.response?.data];
    }
  },
  async confirm(payload: Record<string, any> | undefined) {
    try {
      const { data } = await post(ENDPOINTS.admin.confirm, payload);
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async uploadUserAvatar(file: string | Blob, userId: string) {
    try {
      const formData = new FormData();
      formData.append("file", file);
      await post(
        ENDPOINTS.admin.uploadUserAvatar.replace("{userId}", userId),
        formData,
        {
          "Content-Type": "multipart/form-data",
          Accept: true,
        }
      );

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async deleteUserAvatar(userId: string) {
    try {
      await del(ENDPOINTS.admin.deleteUserAvatar.replace("{userId}", userId));
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  updateAccountField(accountId: string, prop: string, value: string) {
    if (admin.items?.length > 0) {
      admin.items.forEach((item) => {
        if (item.accountId === accountId) {
          item[prop] = value;
        }
      });
      admin.items = [...admin.items];
    }
  },
  async openAccount(accountId: string) {
    try {
      await post(ENDPOINTS.admin.openAccount.replace("{accountId}", accountId));
      admin.updateAccountField(accountId, "accountStateId", "Open");
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async suspendAccount(accountId: string) {
    try {
      await post(
        ENDPOINTS.admin.suspendAccount.replace("{accountId}", accountId)
      );
      admin.updateAccountField(accountId, "accountStateId", "Suspended");
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async closeAccount(accountId: string) {
    try {
      await post(
        ENDPOINTS.admin.closeAccount.replace("{accountId}", accountId)
      );
      admin.updateAccountField(accountId, "accountStateId", "Closed");
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchStatements(accountId: string, exportCsv: any) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.fetchStatements.replace("{accountId}", accountId),
        {
          page: 1,
          perPage: 100,
        },
        exportCsv ? "blob" : undefined,
        {
          Accept: exportCsv ? "text/csv" : "application/json",
        }
      );

      if (exportCsv) {
        const blob = data;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "statements.csv";
        link.click();
      }

      return exportCsv ? true : data.data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async openStatement(accountId: string, year: any, month: any) {
    try {
      const params = {
        year,
        month,
      };
      const { data, headers } = await get(
        ENDPOINTS.admin.openStatement.replace("{accountId}", accountId),
        params,
        "arraybuffer"
      );
      const contentType = headers["content-type"];

      const file = new Blob([data], { type: contentType });
      const fileURL = URL.createObjectURL(file);

      open(fileURL, "_blank");
    } catch (err) {
      showError(err);
    }
  },

  async downloadStatement(accountId: string, year: any, month: any, account) {
    try {
      const params = {
        year,
        month,
      };
      const { data, headers } = await get(
        ENDPOINTS.admin.openStatement.replace("{accountId}", accountId),
        params,
        "arraybuffer"
      );
      const contentType = headers["content-type"];

      const file = new Blob([data], { type: contentType });

      const link = document.createElement("a");

      link.href = URL.createObjectURL(file);
      link.download = `${account?.ownerName || ""}-${
        account?.currencyId || ""
      }-${month}-${year}.pdf`;

      link.click();
      link.remove();
    } catch (err) {
      showError(err);
      console.log(err);
    }
  },

  async generateStatement(accountId: string, year: any, month: any) {
    try {
      const params = {
        year,
        month,
      };
      await post(
        ENDPOINTS.admin.generateStatement.replace("{accountId}", accountId),
        params
      );

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async approveStatement(accountId: string, year: any, month: any) {
    try {
      await post(
        ENDPOINTS.admin.approveStatement.replace("{accountId}", accountId),
        { year, month }
      );
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async deleteStatement(accountId: string, year: any, month: any) {
    try {
      await del(
        ENDPOINTS.admin.deleteStatement.replace("{accountId}", accountId),
        {
          year,
          month,
        }
      );

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async previewDeleteOperation(operationId: any) {
    try {
      const { data } = await del(ENDPOINTS.admin.previewDeleteOperation, {
        operationId,
      });
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async confirmDeleteOperation(payload: Record<string, any> | undefined) {
    try {
      await del(ENDPOINTS.admin.confirmDeleteOperation, payload);
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchPendingFeeReportsGroupByUser(
    params: Record<string, any> | undefined,
    exportCsv: any
  ) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.fetchPendingFeeReportsGroupByUser,
        params,
        exportCsv ? "blob" : undefined,
        {
          Accept: exportCsv ? "text/csv" : "application/json",
        }
      );
      if (exportCsv) {
        const blob = data;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "fee_group_by_user.csv";
        link.click();
      }

      return exportCsv ? true : data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async fetchUsersBalances(
    params: Record<string, any> | undefined,
    exportCsv: boolean | undefined
  ) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.fetchUsersBalances,
        params,
        exportCsv ? "blob" : undefined,
        {
          Accept: exportCsv ? "text/csv" : "application/json",
        }
      );
      if (exportCsv) {
        const blob = data;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "users_balances.csv";
        link.click();
      }

      return exportCsv ? true : data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async sendEmailToUserAboutNotActive(userId: string) {
    try {
      await post(
        ENDPOINTS.admin.sendEmailToUserAboutNotActive.replace(
          "{userId}",
          userId
        )
      );
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  /* Reports */
  async fetchDOR2(
    params:
      | {
          from: undefined;
          to: undefined;
          currencies: undefined;
          pageIndex: number;
          dateType: string;
          isTotal: boolean;
        }
      | {
          from: any;
          to: any;
          currencies: undefined;
          pageIndex: number;
          dateType: string;
          isTotal: boolean;
        },
    exportCsv: boolean | undefined
  ) {
    try {
      const p = makeParams({}, params);

      const { data } = await get(
        ENDPOINTS.admin.fetchDOR2,
        p,
        exportCsv ? "blob" : undefined,
        {
          Accept: exportCsv ? "text/csv" : "application/json",
        }
      );
      if (exportCsv) {
        const blob = data;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "dor.csv";
        link.click();
      }

      return exportCsv ? true : data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchDOR(
    params: Record<string, any> | undefined,
    exportCsv: boolean | undefined
  ) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.fetchDOR,
        params,
        exportCsv ? "blob" : undefined,
        {
          Accept: exportCsv ? "text/csv" : "application/json",
        }
      );
      if (exportCsv) {
        const blob = data;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "dor.csv";
        link.click();
      }

      return exportCsv ? true : data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async fetchSD(
    params: Record<string, any> | undefined,
    exportCsv: boolean | undefined
  ) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.fetchSD,
        params,
        exportCsv ? "blob" : undefined,
        {
          Accept: exportCsv ? "text/csv" : "application/json",
        }
      );
      if (exportCsv) {
        const blob = data;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "sd.csv";
        link.click();
      }

      return exportCsv ? true : data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async fetchTH(
    params: Record<string, any> | undefined,
    exportCsv: boolean | undefined
  ) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.fetchTH,
        params,
        exportCsv ? "blob" : undefined,
        {
          Accept: exportCsv ? "text/csv" : "application/json",
        }
      );
      if (exportCsv) {
        const blob = data;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "th.csv";
        link.click();
      }

      return exportCsv ? true : data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async fetchCountApprovedTasks(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.fetchCountApprovedTasks,
        params
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async countOperations(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(ENDPOINTS.admin.countOperations, params);
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async patchApplicationFlags(
    userId: string,
    payload: Record<string, any> | undefined
  ) {
    try {
      await patch(
        ENDPOINTS.admin.patchApplicationFlags.replace("{userId}", userId),
        payload
      );
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async fetchApplicationsFlags(userId: string) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.patchApplicationFlags.replace("{userId}", userId)
      );
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async fetchCompaniesUbo() {
    try {
      const { data } = await get(ENDPOINTS.admin.fetchCompaniesUbo);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async activateVirtualCard(accountId: string) {
    try {
      await get(
        ENDPOINTS.admin.activateVirtualCard.replace("{accountId}", accountId)
      );

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async addRuleAccount(payload: Record<string, any> | undefined) {
    try {
      await post(ENDPOINTS.admin.ruleAccount, payload);

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async registerNewClient(payload: Record<string, any> | undefined) {
    try {
      const { data } = await post(ENDPOINTS.admin.registerNewClient, payload);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchRoles() {
    try {
      const { data } = await get(ENDPOINTS.admin.fetchRoles);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchNewOnboardUserFiles(userId: string) {
    try {
      const { data } = await get(
        ENDPOINTS.corporate.fetchNewOnboardUserFiles.replace("{userId}", userId)
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async canSendSepa(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(ENDPOINTS.admin.canSendSepa, params);

      if (data) {
        return [false, data];
      }

      return [true, data];
    } catch (err) {
      return [false, ""];
    }
  },

  async canSendSepaAndError(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(ENDPOINTS.admin.canSendSepa, params);

      if (data) {
        return [false, data];
      }

      return [true, data];
    } catch (err: any) {
      return [false, err?.response?.data];
    }
  },

  async canSendSepaToRecipient(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.canSendSepaToRecipient,
        params
      );

      if (data) {
        return [false, data];
      }

      return [true, data];
    } catch (err) {
      return [false, ""];
    }
  },

  async fetchPnlGraphics(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(ENDPOINTS.admin.fetchPnlGraphics, params);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async sumSubInit(userId: string) {
    try {
      const { data } = await post(
        ENDPOINTS.admin.sumSubInit.replace("{userId}", userId)
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async remindTerms(userId: string) {
    try {
      await post(ENDPOINTS.admin.remindTerms.replace("{userId}", userId));

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async transferToIndividual(userId: string) {
    try {
      await post(
        ENDPOINTS.admin.transferToIndividual.replace("{userId}", userId)
      );

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async onboard(params = {}) {
    try {
      const { data } = await get(ENDPOINTS.admin.onboard, params);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchTransactBalances() {
    try {
      const { data } = await get(ENDPOINTS.admin.fetchTransactBalances);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchFinBalances() {
    try {
      const { data } = await get(ENDPOINTS.admin.fetchFinBalances);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchDayAdminIncOperations(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.fetchDayAdminIncOperations,
        params
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async fetchDayAdminTransOperations(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.fetchDayAdminTransOperations,
        params
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchDayAdminDays() {
    try {
      const { data } = await get(ENDPOINTS.admin.fetchDayAdminDays);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchWireRecipientsBlocked(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(ENDPOINTS.admin.wireRecipientsBlocked, params);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async createWireRecipientsBlocked(keyword: string) {
    try {
      await post(ENDPOINTS.admin.wireRecipientsBlocked, { keyword });

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async deleteWireRecipientsBlocked(keyword: string) {
    try {
      await del(
        ENDPOINTS.admin.wireRecipientBlock.replace("{keyword}", keyword)
      );

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async changeWireRecipientsBlocked(oldKeyword: string, keyword: any) {
    try {
      await patch(
        ENDPOINTS.admin.wireRecipientBlock.replace("{keyword}", oldKeyword),
        { keyword }
      );

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchCountryByIso(code: string) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.fetchCountryByIso.replace("{countryIso}", code)
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchStatusIncService() {
    try {
      const { data } = await get(ENDPOINTS.admin.fetchStatusIncService);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async startIncService(key: string) {
    try {
      await post(ENDPOINTS.admin.startIncService, { key });

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async startLaunch(key: string) {
    try {
      await post(ENDPOINTS.admin.startLaunch, { key });

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchStatusLauncher() {
    try {
      const { data } = await get(ENDPOINTS.admin.fetchStatusLauncher);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchTasksHistory(
    taskId: string,
    params: Record<string, any> | undefined
  ) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.fetchTasksHistory.replace("{taskId}", taskId),
        params
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async fetchStates() {
    try {
      const { data } = await get(ENDPOINTS.admin.fetchStates);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async fetchVaults() {
    try {
      const { data } = await get(ENDPOINTS.admin.fetchVaults);

      return [true, data];
    } catch (err) {
      showError(err);
      return [false, err];
    }
  },

  async fetchConnectedUsers(
    userId: string,
    params: Record<string, any> | undefined
  ) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.fetchConnectedUsers.replace("{userId}", userId),
        params
      );

      return [true, data];
    } catch (err) {
      showError(err);
      return [false];
    }
  },

  async fetchLoginHistory(
    params: Record<string, any> | undefined,
    exportCsv: boolean | undefined
  ) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.fetchLoginHistory,
        params,
        exportCsv ? "blob" : undefined,
        {
          Accept: exportCsv ? "text/csv" : "application/json",
        }
      );

      if (exportCsv) {
        const blob = data;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "logins_history.csv";
        link.click();
      } else {
        return data;
      }

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchAllDevices(
    params: Record<string, any> | undefined,
    exportCsv: boolean | undefined
  ) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.fetchAllDevices,
        params,
        exportCsv ? "blob" : undefined,
        {
          Accept: exportCsv ? "text/csv" : "application/json",
        }
      );

      if (exportCsv) {
        const blob = data;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "all_devices.csv";
        link.click();
      } else {
        return data;
      }

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchBrokenUsers(
    params: Record<string, any> | undefined,
    exportCsv?: any
  ) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.fetchBrokenUsers,
        params,
        exportCsv ? "blob" : undefined,
        {
          Accept: exportCsv ? "text/csv" : "application/json",
        }
      );

      if (exportCsv) {
        const blob = data;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "broken_clients.csv";
        link.click();
      }

      return exportCsv ? true : data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchErrorsUser(userId: string) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.fetchErrorsUser.replace("{userId}", userId)
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchFutureInterestPayment(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.fetchFutureInterestPayment,
        params
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async inactiveUser(userId: string, reason) {
    try {
      await post(ENDPOINTS.admin.inactiveUser.replace("{userId}", userId), {
        reason,
      });
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async suspendUser(userId: string, payload: Record<string, any> | undefined) {
    try {
      await post(
        ENDPOINTS.admin.suspendUser.replace("{userId}", userId),
        payload
      );
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async banUser(userId: string, payload: Record<string, any> | undefined) {
    try {
      await post(ENDPOINTS.admin.banUser.replace("{userId}", userId), payload);
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async activeUser(userId: string) {
    try {
      await post(ENDPOINTS.admin.activeUser.replace("{userId}", userId), {});
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async closeSaving(payload: Record<string, any> | undefined) {
    try {
      const { data } = await post(ENDPOINTS.admin.closeSaving, payload);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchDetailSavingAccountClient(accountId: any) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.fetchDetailSavingAccountClient,
        {
          accountId,
        }
      );

      return data;
    } catch (err) {
      return false;
    }
  },

  async massSetUserState(userIds: string[], state: string) {
    try {
      await post(
        ENDPOINTS.admin.massSetUserState.replace("{userState}", state),
        userIds
      );

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async getFeeForSmartForm(params) {
    try {
      const { data } = await get(ENDPOINTS.fees.getFeeForSmartForm, params);

      return [true, data];
    } catch (err) {
      showError(err);
      return [false];
    }
  },

  async getFormulaForAccount(accountId: string, titleId: string) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.getFormulaForAccount
          .replace("{accountId}", accountId)
          .replace("{titleId}", titleId)
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchNumberOfApprovedTasks() {
    try {
      const { data } = await get(ENDPOINTS.admin.fetchNumberOfApprovedTasks);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchCurrentStatementUserAccount(accountId: string) {
    try {
      const { data, headers } = await get(
        ENDPOINTS.admin.fetchCurrentStatementUserAccount.replace(
          "{accountId}",
          accountId
        ),
        undefined,
        "arraybuffer"
      );

      const contentType = headers["content-type"];

      const file = new Blob([data], { type: contentType });
      const fileURL = URL.createObjectURL(file);

      open(fileURL, "_blank");

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchClientEvents(params) {
    try {
      const { data } = await get(ENDPOINTS.admin.fetchClientEvents, params);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchOperationsCountPerDay(params) {
    try {
      const { data } = await get(
        ENDPOINTS.admin.fetchOperationsCountPerDay,
        params
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchStatisticEvents(params) {
    try {
      const { data } = await get(ENDPOINTS.admin.fetchStatisticEvents, params);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
});

export default admin;
