import axios, { CancelTokenSource, ResponseType } from "axios";
import { Buffer } from "buffer";
import { is } from "../../helpers/is";
import errors from "../modules/errors";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const headers = () => {
  const token = sessionStorage.getItem("token");

  let Authorization: string | null = null;

  if (token) {
    Authorization = `Bearer ${token}`;
  }

  const getXt = sessionStorage.getItem("xt");

  const getXDevice = localStorage.getItem("deviceId");

  const others: Record<string, any> = {};

  if (getXt) {
    others["X-2FA-Access"] = getXt;
  }

  if (getXDevice) {
    others["x-device"] = getXDevice;
  }

  return Authorization ? { Authorization, ...others } : others;
};

export const all = (promises: any[]) => axios.all(promises);

export const get = (
  url: string,
  params?: Record<string, any>,
  responseType?: ResponseType,
  customHeaders?: Record<string, any>,
  cancelToken?: { cancel: () => void } | null,
  setCancelToken?: (value: CancelTokenSource) => void
) => {
  if (cancelToken) {
    cancelToken.cancel();
  }

  const source = axios.CancelToken.source();

  if (setCancelToken) {
    setCancelToken(source);
  }

  const authHeaders = headers() || {};

  if (!customHeaders) {
    customHeaders = {};
  }

  if (!customHeaders?.Accept) {
    customHeaders.Accept = "application/json";
  }

  return axios.get(url, {
    cancelToken: source.token,
    headers: { ...authHeaders, ...customHeaders },
    params,
    responseType: responseType,
    data: {},
  });
};

export const post = (
  url: string,
  payload?: Record<string, any>,
  customHeaders?: Record<string, any>,
  responseType?: ResponseType
) => {
  const authHeaders = headers() || {};

  if (!customHeaders) {
    customHeaders = {};
  }

  if (!customHeaders["Accept"]) {
    customHeaders["Accept"] = "application/json";
  }

  return axios.post(url, payload, {
    headers: { ...authHeaders, ...customHeaders },
    responseType,
  });
};

export const put = (url: string, payload?: Record<string, any>) =>
  axios.put(url, payload, { headers: headers() });

export const patch = (url: string, payload?: Record<string, any>) =>
  axios.patch(url, payload, { headers: headers() });

export const del = (url: string, payload?: Record<string, any>) =>
  axios.delete(url, { headers: headers(), data: payload });

export const showError = (err: any) => {
  const data = err?.response?.data;

  if (err?.response?.data?.code === 100) {
    return;
  }

  if (
    (err?.response?.status >= 500 &&
      err?.response?.data?.code !== 4010 &&
      err?.response?.data?.code !== 4011 &&
      err?.response?.data?.code !== 4012) ||
    err?.toString()?.includes("Network Error")
  ) {
    errors.error = {
      ...data,
      code: data?.status,
      description: err?.toString(),
      cfRay: err?.response?.["headers"]?.["cf-ray"],
    };
  } else if (err?.response?.status === 403) {
    errors.error = { code: 403 };
  } else if (is(ArrayBuffer, data)) {
    const res = {
      description: Buffer.from(err.response.data).toString("utf8"),
      cfRay: err?.response?.["headers"]?.["cf-ray"],
    };
    errors.error = res;
  } else if (data && data?.code !== 4010) {
    errors.error = {
      ...data,
      code: data?.code,
      message: data?.message,
      cfRay: err?.response?.["headers"]?.["cf-ray"],
    };
  }
};
