import React from "react";
import { formatDateToMMddyyyyHHmm } from "../../../../../../helpers/formatDate";

const StateMessage = ({ task }) => {
  return (
    <div>
      <>
        <h1 className="mb-8 text-2xl text-gray-600">{task?.subject}</h1>

        <div className="flex justify-between mb-8 w-full">
          <div className="flex flex-col">
            <b> {task?.fromUser?.name}</b>
            <span>whom: {task?.toUser?.name}</span>
          </div>

          <div className="flex items-center">
            <b>{formatDateToMMddyyyyHHmm(task?.ts)}</b>
          </div>
        </div>

        <div
          className="mb-4"
          dangerouslySetInnerHTML={{ __html: task?.body }}
        />
      </>
    </div>
  );
};

export default StateMessage;
