import React from "react";

const PreviewBox = ({
  header,
  children,
}: {
  header?: string;
  children?: JSX.Element[] | JSX.Element;
}) => {
  return (
    <div className="mb-3 py-2.5 px-3.5 bg-gray-100 rounded-2xl">
      {header && <h3 className="mb-4">{header}</h3>}

      {children}
    </div>
  );
};

export default PreviewBox;
