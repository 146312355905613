import React, { memo, useEffect } from "react";
import { useWatch } from "react-hook-form";
import { formatToCurrencyString } from "../../../../../../helpers";
import { useGetAccounts } from "../hooks/useGetAccounts";

const InitComment = memo(({ userId, form }: any) => {
  const { fromAccount, toAccount } = useGetAccounts({
    userId,
    form,
  });

  const sellAmount = useWatch({
    name: "sellAmount",
    control: form.control,
  });

  const buyAmount = useWatch({
    name: "buyAmount",
    control: form.control,
  });

  const { setValue } = form;

  useEffect(() => {
    if (fromAccount && toAccount && sellAmount && buyAmount) {
      setTimeout(
        () =>
          setValue(
            "to_comment",
            `${fromAccount.currencyId} ${formatToCurrencyString(
              sellAmount,
              fromAccount.currencyId
            )} -> ${toAccount.currencyId} ${formatToCurrencyString(
              buyAmount,
              toAccount.currencyId
            )}`
          ),
        0
      );
      setTimeout(
        () =>
          setValue(
            "from_comment",
            `${fromAccount.currencyId} ${formatToCurrencyString(
              sellAmount,
              fromAccount.currencyId
            )} -> ${toAccount.currencyId} ${formatToCurrencyString(
              buyAmount,
              toAccount.currencyId
            )}`
          ),
        0
      );
    }
  }, [buyAmount, fromAccount, sellAmount, setValue, toAccount]);

  return <div></div>;
});

export default InitComment;
