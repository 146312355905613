import { view } from "@risingstack/react-easy-state";
import numbro from "numbro";
import React, { useCallback, useEffect, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import { customSortArrayLabel } from "../../helpers/customSortArray";
import { allCurrencies2 } from "../../helpers/formatToCurrencyString";
import { usePortal } from "../../hooks/usePortal";
import coins from "../../store/modules/coins";
import CustomSimpleMenu from "../System/Components/CustomSimpleMenu";
import { ReactComponent as Close } from "../System/Design/Icons/close.svg";

export default view((): any => {
  const [rate, setRate] = useState<number>(1);
  const [amount, setAmount] = useState<string>("2.5");
  const [price, setPrice] = useState<string>("0");

  const { goldData } = coins;

  const focusRef = useRef<string>("amount");

  const inputRef = useRef<any>();

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  const amountNumber = numbro.unformat(amount) || 0;
  const priceNumber = numbro.unformat(price) || 0;

  useEffect(() => {
    if (focusRef.current === "amount") {
      setPrice((amountNumber * rate).toString());
    }
  }, [amountNumber, rate]);

  useEffect(() => {
    if (focusRef.current === "price") {
      setAmount((priceNumber / rate).toString());
    }
  }, [priceNumber, rate]);

  useEffect(() => {
    setAmount(goldData.amount);
  }, [goldData.amount]);

  const fetchRate = useCallback(async () => {
    if (!goldData?.currency) {
      return;
    }

    const result = await coins.fetchRateByCurrency(goldData.currency);

    if (result) {
      setRate(result);
    }
  }, [goldData?.currency]);

  useEffect(() => {
    fetchRate();
  }, [fetchRate]);

  const { Portal } = usePortal();

  const totalCurrency = allCurrencies2.find(
    (c) => c.value === goldData?.currency
  );

  return (
    goldData?.currency && (
      <Portal>
        <div
          role="dialog"
          className="z-[99999] fixed top-1/3 mb-0 right-[1rem] shadow-2xl max-w-[18.5rem] px-8 py-6 rounded-lg bg-white"
        >
          <div
            className="absolute right-3 top-3 cursor-pointer rounded-3xl z-[60]"
            onClick={() => {
              coins.resetGoldData();
              localStorage.removeItem("goldData");
            }}
          >
            <Close className="w-[1rem] fill-gray-400" />
          </div>

          <div className="relative">
            <h1 className="my-2 text-[.8rem] leading-4">
              Edit this information if required:
            </h1>

            <span className="block font-bold mb-2 text-[.8rem] leading-4 text-gray-500">
              Enter the quantity of MIGG coins you wish to buy:
            </span>

            <div className="relative flex border-2 border-solid border-gray-400 p-1 rounded-2xl ">
              <img src="/gold/migom-crypto.svg" className="w-[1.5rem] mr-1" />

              <NumberFormat
                getInputRef={inputRef}
                className="text-base font-bold border-0 outline-0 max-w-[9rem]"
                allowNegative={false}
                thousandSeparator
                decimalScale={4}
                value={amount}
                onValueChange={(values) => {
                  setAmount(values.value);

                  coins.saveLocalGoldData({
                    amount: values.value,
                  });
                }}
                onFocus={(e) => {
                  e.stopPropagation();
                  focusRef.current = "amount";
                }}
              />
            </div>

            <div className="flex mb-2 mt-2">
              <CustomSimpleMenu
                value={goldData?.currency || ""}
                label="Currency used for purchase"
                onChange={(e, value) => {
                  coins.saveLocalGoldData({
                    currency: value,
                  });
                }}
                options={allCurrencies2
                  .filter((item) => item.value !== "MIGG")
                  .map((item) => {
                    return {
                      label: item.value,
                      value: item.value,
                    };
                  })
                  .sort(customSortArrayLabel)}
                fullWidth
              />
            </div>

            <span className="font-bold text-[.8rem] leading-4  mr-2 text-gray-500">
              Total:
            </span>

            <div className="border-2 border-solid border-gray-400 p-1 rounded-2xl">
              <NumberFormat
                className="text-base font-bold border-0 outline-0 max-w-[10.313rem]"
                prefix={totalCurrency?.symbol}
                allowNegative={false}
                thousandSeparator
                decimalScale={totalCurrency?.decimalScale}
                value={price}
                onValueChange={(values) => {
                  setPrice(values.value);
                }}
                onFocus={(e) => {
                  e.stopPropagation();
                  focusRef.current = "price";
                }}
              />
            </div>
          </div>
        </div>
      </Portal>
    )
  );
});
