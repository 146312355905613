import Cleave from "cleave.js/react";
import React from "react";

const CardInput = ({ inputRef, ...props }: any) => {
  const options = {
    creditCard: true,
  };

  return <Cleave {...props} htmlRef={inputRef} options={{ ...options }} />;
};

export default CardInput;
