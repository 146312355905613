import { useQuery, UseQueryResult } from "react-query";
import ENDPOINTS from "../../../../../../endpoints";
import IAccount from "../../../../../../interfaces/account";
import { get, showError } from "../../../../../../store/api";

export const useQueryClientAccounts = (userId): UseQueryResult<IAccount[]> => {
  return useQuery(
    [`useQueryClientAccounts`, userId],
    async () => {
      try {
        const { data } = await get(ENDPOINTS.admin.accounts, {
          page: 1,
          perPage: 9999999,
          userId,
        });

        return data.data;
      } catch (err) {
        showError(err);
        return {};
      }
    },
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      enabled: Boolean(userId),
    }
  );
};
