import { FormHelperText } from "@material-ui/core";
import numbro from "numbro";
import React from "react";
import MyAmount from "../../../../../System/FormComponents/MyAmount";

const BuyAmount = ({ form, cursor, setCursor }) => {
  let label: JSX.Element | string = "Buy Amount";

  if (cursor === "buy") {
    label = <span className="font-bol">Buy Amount</span>;
  }

  return (
    <div>
      <MyAmount
        {...form}
        name="buyAmount"
        controlCurrency={form.control}
        currencyName="buyCurrencyId"
        label={label}
        rules={{
          required: true,
          validate: {
            valid: (value) => {
              const unformatted = numbro.unformat(value);

              if (unformatted > 0) {
                return true;
              }

              return false;
            },
          },
        }}
        onFocus={() => {
          setCursor("buy");
        }}
        fullWidth
      />

      {form?.formState?.errors?.buyAmount?.type === "required" && (
        <FormHelperText error>Field is required</FormHelperText>
      )}

      {form?.formState?.errors?.buyAmount?.type === "valid" && (
        <FormHelperText error>
          The buy amount must be greater than zero
        </FormHelperText>
      )}
    </div>
  );
};

export default BuyAmount;
