import { useTheme } from "@material-ui/core/styles";
import { useEffect, useMemo } from "react";
import { useScript } from "../../../../../hooks/useScript";

const Support = () => {
  const callback = useMemo(() => {
    // window.zE &&
    //   isFunc(window.zE) &&
    //   window.zE("webWidget", "setLocale", "en-GB");
  }, []);

  useScript(import.meta.env.VITE_ZENDESK_WIDGET, callback, "ze-snippet");

  const theme = useTheme();

  useEffect(() => {
    window.zESettings = {
      webWidget: {
        color: { theme: theme.palette.primary.main },
      },
    };
  }, [theme.palette.primary.main]);

  return null;
};

export default Support;
