import numbro from "numbro";
import React from "react";
import {
  formatDateToMMddyyyyHHmm,
  formatToCurrencyString,
} from "../../../../../../helpers";
import CopyAccountNumber from "../../../../../System/CopyAccountNumber";
import Box from "../components/Box";
import FieldBox from "../components/FieldBox";
import { getColorForState } from "../tools/getColorForState";

const Deposit = ({ task }) => {
  let body;

  try {
    body = task?.body ? JSON.parse(task?.body) : {};
  } catch {
    body = task?.body;
  }

  const colorState = getColorForState(task);

  return (
    <>
      <Box header="">
        <FieldBox
          label="Task state"
          value={task?.taskStateName}
          style={{ color: colorState }}
        />

        <>
          {task?.taskStateId === "Rejected" && (
            <FieldBox label="Reason" value={task?.internalSubject} />
          )}
        </>

        <FieldBox label="Task ID" value={task?.taskId} showCopy />

        <FieldBox label="Date:" value={formatDateToMMddyyyyHHmm(task?.ts)} />

        <FieldBox
          label="Amount:"
          value={formatToCurrencyString(
            numbro.unformat(task?.amount || 0),
            task?.currencyId
          )}
        />

        <FieldBox
          label="Currency:"
          value={
            <div className="flex">
              <img
                src={`/currencies/${task?.currencyId?.toLowerCase()}.svg`}
                className="w-6 min-w-6 mr-2"
              />
              <span>{task?.currencyId}</span>
            </div>
          }
        />

        <FieldBox
          label="Sending Institution Name, Country:"
          value={body?.NameOfBank}
        />

        <FieldBox
          label="Sending IBAN (or Account Number):"
          value={body?.AccountNumber}
        />

        <FieldBox
          label="Name of sending account:"
          value={body?.NameOnAccount}
        />

        <div className="w-full flex justify-between text-base border-b border-solid border-gray-200 mb-2 pb-2">
          <span className="whitespace-nowrap">To account:</span>
          <span className="font-bold text-right ml-8 break-all">
            {task?.toAccount?.number}{" "}
            <CopyAccountNumber number={task?.toAccount?.number} /> (
            {task?.toAccount?.accountId}{" "}
            <CopyAccountNumber number={task?.toAccount?.accountId} />)
          </span>
        </div>

        <FieldBox label="Client:" value={task?.firstUser?.name} />

        <FieldBox label="Contact email:" value={body?.Email} />

        <FieldBox label="Deposit type:" value={body?.SwiftOrSepa} />

        <FieldBox label="Memo:" value={task?.memo} />
      </Box>
    </>
  );
};

export default Deposit;
