import qs from "query-string";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { formatToCurrencyString } from "../../helpers";
import { get } from "../../store/api";
import Anima from "../System/Anima";

export default memo(() => {
  const [, setLoading] = useState<boolean>(true);
  const [result, setResult] = useState<any>();
  const location = useLocation();

  const queryParams: any = qs.parse(location.search);

  const checkOrder = useCallback(async () => {
    if (!queryParams?.order) {
      return;
    }

    try {
      const { data } = await get(
        `${import.meta.env.VITE_API}/v1/acquiring/orders/${queryParams?.order}`
      );
      setResult(data);

      setLoading(false);
    } catch (err) {
      console.log(err);

      setLoading(false);
    }
  }, [queryParams?.order]);

  useEffect(() => {
    checkOrder();
  }, [checkOrder]);

  const status = result?.paymentStatus?.toLowerCase();

  return (
    <div>
      <div className="max-w-lg w-full mx-auto px-[4rem] py-[2rem] shadow-md rounded-2xl mt-[4rem]">
        <div className="mb-[2rem]">
          {status === "complete" && (
            <div className="flex items-center">
              <div className="mr-[1rem]">
                <Anima
                  loop={false}
                  json={"/keys/check.json"}
                  className="w-16 min-w-16"
                />
              </div>

              <b className="text-[1.6rem] text-green-500">
                Your order has been successfully completed!
              </b>
            </div>
          )}
          {(status === "failed" || status === "rejected") && (
            <div className="flex items-center">
              <div className="mr-[1rem]">
                <Anima
                  loop={false}
                  json={"/keys/error.json"}
                  className="w-20 min-w-20"
                />
              </div>

              <div className="flex flex-col">
                <b className="text-[1.6rem] text-red-600">Failed</b>
                <span className="text-[1.2rem] text-red-600">
                  {result?.error}
                </span>
              </div>
            </div>
          )}
        </div>

        <div className="flex justify-between text-[1.2rem] mb-[.2rem] pb-[.2rem] border-b-2 border-gray-200">
          <span className="text-gray-600">Invoice ID:</span>{" "}
          <b>{queryParams?.invoice_id}</b>
        </div>

        <div className="flex justify-between text-[1.2rem]">
          <span className="text-gray-600">Amount:</span>{" "}
          <b>
            {formatToCurrencyString(queryParams?.amount, queryParams?.currency)}
          </b>
        </div>
      </div>
    </div>
  );
});
