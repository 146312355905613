import { view } from "@risingstack/react-easy-state";
import React from "react";
import { formatDateToMMddyyyyHHmm } from "../../../../../../helpers/formatDate";

const Message = view(({ task }) => {
  return (
    <>
      <h1 className="mb-8 text-2xl text-gray-600">{task?.subject}</h1>

      <div className="flex justify-between mb-8 w-full">
        <div>
          <b> {task?.fromUser?.name}</b>
          <div>whom: {task?.toUser?.name}</div>
        </div>

        <div className="flex items-center">
          <b>{formatDateToMMddyyyyHHmm(task?.ts)}</b>
        </div>
      </div>

      <div className="mb-4" dangerouslySetInnerHTML={{ __html: task?.body }} />
    </>
  );
});

export default Message;
