import React, { memo } from "react";

export default memo(({ value, size = 3 }: { value: string; size?: number }) => {
  return (
    <>
      {value.substring(0, size)}...
      {value.substring(value.length - size, value.length)}
    </>
  );
});
