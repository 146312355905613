import { FormHelperText } from "@material-ui/core";
import numbro from "numbro";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import IAccount from "../../../../../../interfaces/account";
import MyAmount from "../../../../../System/FormComponents/MyAmount";
import { IFormClientDeposit } from "../interfaces/stepProps";

const Amount = ({
  form,
  account,
}: {
  form: UseFormReturn<IFormClientDeposit>;
  account?: IAccount;
}) => {
  return (
    <>
      <MyAmount
        id="depositAmount"
        control={form?.control}
        controlCurrency={form?.control}
        currencyName="currencyId"
        name="amount"
        label="Amount"
        autoFocus
        required
        rules={{
          required: true,
          validate: {
            notZero: (value) => {
              const unformatted = numbro.unformat(value);

              if (unformatted === 0) {
                return false;
              }

              return true;
            },
            min: (value) => {
              const unformatted = numbro.unformat(value);

              if (unformatted < 500) {
                return false;
              }

              return true;
            },

            max: (value) => {
              const unformatted = numbro.unformat(value);

              if (unformatted > 3000000000) {
                return false;
              }

              return true;
            },
          },
        }}
      />

      {form?.formState?.errors?.amount?.type === "required" && (
        <FormHelperText id="errorDepositAmount" error>
          Field is required
        </FormHelperText>
      )}

      {form?.formState?.errors?.amount?.type === "notZero" && (
        <FormHelperText id="errorDepositAmountZero" error>
          Amount cannot be zero
        </FormHelperText>
      )}

      {form?.formState?.errors?.amount?.type === "min" && (
        <FormHelperText id="errorDepositAmountMin500" error>
          The minimum amount of deposit is 500 {account?.currencyId} or 1/2 of
          the allowed deposit limit, whichever is greater. Please <b>only</b>{" "}
          notify us of the deposits you intend to receive.{" "}
          <b>
            Multiple unfulfilled Deposit Notifications may lead to suspension
            and closing of your account.
          </b>
        </FormHelperText>
      )}

      {form?.formState?.errors?.amount?.type === "max" && (
        <FormHelperText id="errorDepositAmountMax3000000000" error>
          The maximum amount of deposit 3,000,000,000 {account?.currencyId}
        </FormHelperText>
      )}
    </>
  );
};
export default Amount;
