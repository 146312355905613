import { Button, Divider } from "@material-ui/core";
import React, { useState } from "react";
import { formatDateToMmDdYyyyHhMmSs } from "../../../../../../helpers";
import { formatDateForTask } from "../../../../../../helpers/formatDate";
import Alert from "../../../../../System/Alert";
import Box from "../components/Box";
import { getColorForState } from "../tools/getColorForState";

const Detail = ({ event }) => {
  const [open, setOpen] = useState(false);

  const showSadRobot =
    event?.subject === "Error" && event?.fromUserId?.includes("robot");

  let fieldUserName;

  const from = event?.fromUserName;
  const to = event?.toUserName;

  if (event?.fromUserId === event?.toUserId) {
    fieldUserName = from || to;
  } else if (event?.fromUserId !== event?.toUserId) {
    fieldUserName = `${from} -> ${to}`;
  } else if (!from) {
    fieldUserName = to;
  }

  if (event?.waitSeconds) {
    fieldUserName = (
      <i>
        <b>{`Waiting for ${event?.toUserName} ${formatDateForTask(
          event?.waitSeconds
        )}`}</b>
      </i>
    );
  }

  const onClick = () => {
    setOpen(true);
  };

  const color = getColorForState(event);

  return (
    <>
      <div className="text-base">
        {!event?.waitSeconds && (
          <div className="mb-1.5 text-sm text-gray-500 font-bold">
            <span>{formatDateToMmDdYyyyHhMmSs(event?.ts)}</span>
          </div>
        )}

        <div className="mb-1.5 flex items-center">
          {event?.waitSeconds && (
            <img src="/images/pending.svg" className="w-8 mr-2" />
          )}

          {showSadRobot && (
            <img src="/images/robot/sad.svg" className="w-8 mr-2" />
          )}

          <span>{fieldUserName}</span>
        </div>

        <div className="mb-1.5" style={{ color }}>
          {event?.taskStateName}
        </div>

        {event?.subject && <div className="mb-1.5">{event?.subject}</div>}

        {showSadRobot && <div className="mb-1.5">{event?.body}</div>}

        {event?.body && !showSadRobot && (
          <Button color="primary" variant="contained" onClick={onClick}>
            Show body
          </Button>
        )}
      </div>

      <Divider style={{ marginBottom: ".8rem", marginTop: ".8rem" }} />

      {open && (
        <Alert
          open={open}
          setOpen={setOpen}
          content={<div className="break-words max-w-full">{event?.body}</div>}
        />
      )}
    </>
  );
};

const Events = ({ events }) => {
  const mapItems = events?.map((event) => {
    return <Detail event={event} key={event?.eventId} />;
  });

  return <Box header="Events">{mapItems}</Box>;
};

export default Events;
