import { Button, TextField } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { view } from "@risingstack/react-easy-state";
import { clsx } from "clsx";
import { CheckCircle } from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { apiUrls } from "../../../../../../apiUrls";
import {
  getTaskStateBooleans,
  getTaskTypeBooleans,
} from "../../../../../../helpers/task";
import { useCryptoPassword } from "../../../../../../hooks/useCryptoPassword";
import IAccount from "../../../../../../interfaces/account";
import ITask from "../../../../../../interfaces/task";
import IWireRecipient from "../../../../../../interfaces/wireRecipient";
import { get, post, showError } from "../../../../../../store/api";
import tasks from "../../../../../../store/modules/tasks";
import LoadingCircular from "../../../../../LoadingCircular";
import { Alert } from "../../../../../System";
import ApproveGold from "../../../../Admin/SmartForms/ApproveGold";
import Exchange from "../../../../Admin/SmartForms/Exchange";
import Transfer2 from "../../../../Admin/SmartForms/Transfer2/index";
import TransferBetweenUsers from "../../../../Admin/SmartForms/TransferBetweenUsers";
import Deposit from "../../SmartForms/Deposit";
import OldWithdrawCrypto from "../../SmartForms/OldWithdrawCrypto";
import SendWire from "../../SmartForms/SendWire";
import WithdrawCrypto from "../../SmartForms/WithdrawCrypto";
import auth from "./../../../../../../store/modules/auth";
import ChooseTemplate from "./ChooseTemplate";
import Reject from "./Reject";
import UsuallyApprove from "./UsuallyApprove";

const useStyles = makeStyles({
  rejectBtn: {
    backgroundColor: red[600],
    marginRight: ".3rem",
  },
  approveBtn: {
    backgroundColor: green[600],
    fontWeight: 700,
  },
  btnRoot: {
    marginRight: ".5rem",
  },
});

const Actions = view(
  ({
    task,
    recipient,
    fetchTask,
  }: {
    task?: ITask;
    recipient?: IWireRecipient;
    fetchTask: any;
  }) => {
    const classes = useStyles();
    const history = useHistory();

    const taskTypeBooleans = getTaskTypeBooleans(task);
    const taskStateBooleans = getTaskStateBooleans(task);

    // Roles
    const isOperator = !!auth.profile && auth.profile?.isOperator;
    const isTreasurer = !!auth.profile && auth.profile?.isTreasurer;
    const isController = !!auth.profile && auth.profile?.isController;
    const isAdmin = !!auth.profile && auth?.profile?.isAdmin;

    const [openSendWireApprove, setOpenSendWireApprove] = useState(false);
    const [openCryptoApprove, setOpenCryptoApprove] = useState(false);
    const [openOldCryptoApprove, setOpenOldCryptoApprove] = useState(false);

    const [openDepositApprove, setOpenDepositApprove] = useState(false);
    const [openTransferApprove, setOpenTransferApprove] = useState(false);
    const [openUsuallyApprove, setOpenUsuallyApprove] = useState(false);
    const [openApproveGold, setOpenApproveGold] = useState(false);

    console.log(openTransferApprove, task?.fromAccount, task?.toAccount);

    const [openReject, setOpenReject] = useState(false);

    const confirmCryptoReject = useCryptoPassword(() => setOpenReject(true));

    const [loadingProof, setLoadingProof] = useState(false);

    const [openChooseTemplate, setOpenChooseTemplate] = useState(false);

    const printProofOfPayment = useCallback(async () => {
      setLoadingProof(true);

      await tasks.proofOfPayment(task?.firstUser?.id, task?.taskId);

      setLoadingProof(false);
    }, [task?.firstUser?.id, task?.taskId]);

    const deliveryOrder = useCallback(async () => {
      try {
        setLoadingProof(true);

        const { data, headers } = await get(
          `${import.meta.env.VITE_PDF}/v1/admin/delivery/${task?.taskId}/pdf`,
          undefined,
          "arraybuffer"
        );

        const contentType = headers["content-type"];

        const file = new Blob([data], { type: contentType });
        const fileURL = URL.createObjectURL(file);

        open(fileURL, "_blank");
        setLoadingProof(false);
      } catch (err) {
        showError(err);
        setLoadingProof(false);
        return false;
      }
    }, [task?.taskId]);

    const showProof =
      (taskTypeBooleans.isSWIFT &&
        (taskStateBooleans.isPending || taskStateBooleans.isApproved) &&
        (isOperator || isTreasurer || isAdmin)) ||
      (taskTypeBooleans.isSEPA &&
        (taskStateBooleans.isPending || taskStateBooleans.isApproved) &&
        (isOperator || isTreasurer || isAdmin));

    const showDeliveryOrder =
      taskTypeBooleans.isGold && (isOperator || isTreasurer || isAdmin);

    const showUsuallyApprove =
      (taskTypeBooleans.isSWIFT &&
        taskStateBooleans.isPending &&
        (isTreasurer || isController)) ||
      (taskTypeBooleans.isSEPA &&
        taskStateBooleans.isPending &&
        (isTreasurer || isController));

    const toOperator =
      !!auth.profile && task?.toUser?.id === auth?.profile?.userId;

    const showSendWireApprove =
      (taskTypeBooleans.isSWIFT &&
        taskStateBooleans.isPending &&
        isOperator &&
        toOperator) ||
      (taskTypeBooleans.isSEPA &&
        taskStateBooleans.isPending &&
        isOperator &&
        toOperator) ||
      (taskTypeBooleans.isSWIFT && taskStateBooleans.isPending && isAdmin) ||
      (taskTypeBooleans.isSEPA && taskStateBooleans.isPending && isAdmin);

    const showReject =
      (taskTypeBooleans.isSWIFT &&
        taskStateBooleans.isPending &&
        ((isOperator && toOperator) ||
          isController ||
          isAdmin ||
          isTreasurer)) ||
      (taskTypeBooleans.isSEPA &&
        taskStateBooleans.isPending &&
        ((isOperator && toOperator) ||
          isController ||
          isAdmin ||
          isTreasurer)) ||
      (taskTypeBooleans.isDeposit &&
        taskStateBooleans.isPending &&
        (isOperator || isAdmin || isTreasurer)) ||
      (taskTypeBooleans.isDeposit &&
        (taskStateBooleans.isPendingWireInfoSent ||
          taskStateBooleans.isPendingMoneySent) &&
        (isOperator || isAdmin || isTreasurer)) ||
      (taskTypeBooleans.isCrypto &&
        taskStateBooleans.isPending &&
        (isOperator || isAdmin || isTreasurer)) ||
      (taskTypeBooleans.isTransfer &&
        taskStateBooleans.isPending &&
        (isOperator || isAdmin || isTreasurer)) ||
      ((taskTypeBooleans.isExchange || taskTypeBooleans.isExchangeBuy) &&
        (taskStateBooleans.isPendingWithdraw || taskStateBooleans.isPending) &&
        (isOperator || isAdmin || isTreasurer)) ||
      (taskTypeBooleans.isExchangeSell &&
        taskStateBooleans.isPending &&
        (isOperator || isAdmin || isTreasurer)) ||
      (taskTypeBooleans.isGold &&
        taskStateBooleans.isPending &&
        (isTreasurer || isController || isOperator));

    const showDepositApprove =
      taskTypeBooleans.isDeposit &&
      (taskStateBooleans.isPendingWireInfoSent ||
        taskStateBooleans.isPendingMoneySent) &&
      (isOperator || isAdmin);

    const isExchangePending =
      (taskTypeBooleans.isExchange ||
        taskTypeBooleans.isExchangeSell ||
        taskTypeBooleans.isExchangeBuy) &&
      (taskStateBooleans.isPending || taskStateBooleans.isPendingMoneySent);

    const isCryptoWithdraw =
      (taskTypeBooleans.isExchange || taskTypeBooleans.isExchangeBuy) &&
      taskStateBooleans.isPendingWithdraw;

    const [showTestLink, setShowTestLink] = useState(false);
    const [testError, setTestError] = useState("");
    const [taskConverted, setTaskConverted] = useState(false);

    const validIban = useCallback(async () => {
      if (!task?.taskId) {
        return;
      }

      try {
        await get(
          `${apiUrls.VITE_API}/v1/admin/tasks/${task?.taskId}/can-convert-to-sepa`
        );

        setShowTestLink(true);
      } catch (err) {
        const desc = (err as any)?.response?.data?.description;
        if (desc) {
          setTestError(desc);
        }
      }
    }, [task?.taskId]);

    useEffect(() => {
      showSendWireApprove && validIban();
    }, [validIban, showSendWireApprove]);

    const [loadingConvert, setLoadingConvert] = useState(false);
    const [openSuccessConvert, setOpenSuccessConvert] = useState(false);

    return (
      <>
        <div className="flex mb-4 w-full justify-center">
          {showReject && (
            <>
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  taskTypeBooleans.isCrypto
                    ? confirmCryptoReject.setOpenPassword(true)
                    : setOpenReject(true)
                }
                className={clsx(classes.rejectBtn, classes.btnRoot)}
              >
                Reject
              </Button>

              {openReject && (
                <Reject
                  taskId={task?.taskId}
                  open={openReject}
                  setOpen={setOpenReject}
                />
              )}
            </>
          )}

          {showProof && (
            <div className="relative">
              <Button
                color="primary"
                variant="contained"
                disabled={loadingProof}
                onClick={printProofOfPayment}
                className={classes.btnRoot}
              >
                Proof of payment
                <LoadingCircular loading={loadingProof} />
              </Button>

              <span className="absolute text-center top-14 -right-12 block min-w-64 w-full">
                (please disable AdBlocker to view)
              </span>
            </div>
          )}

          {showDeliveryOrder && (
            <Button
              color="primary"
              variant="contained"
              disabled={loadingProof}
              onClick={deliveryOrder}
              className={classes.btnRoot}
            >
              Delivery Order
              <LoadingCircular loading={loadingProof} />
            </Button>
          )}

          {taskTypeBooleans.isDeposit &&
            taskStateBooleans.isPending &&
            (isOperator || isAdmin) && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => setOpenChooseTemplate(true)}
                className={classes.btnRoot}
              >
                Send wiring info to the client
              </Button>
            )}

          {showDepositApprove && (
            <>
              <Button
                color="primary"
                variant="contained"
                className={clsx(classes.approveBtn, classes.btnRoot)}
                onClick={() => setOpenDepositApprove(true)}
              >
                Approve
              </Button>

              {openDepositApprove && (
                <Deposit
                  task={task}
                  open={openDepositApprove}
                  setOpen={setOpenDepositApprove}
                  refresh={history.goBack}
                />
              )}
            </>
          )}

          {isExchangePending && (isOperator || isAdmin) && (
            <>
              <Button
                color="primary"
                variant="contained"
                className={clsx(classes.approveBtn, classes.btnRoot)}
                onClick={() => setOpenTransferApprove(true)}
              >
                Approve
              </Button>

              {openTransferApprove &&
                task?.fromAccount &&
                task?.toAccount &&
                task.fromAccount.ownerUserId === task.toAccount.ownerUserId &&
                task.fromAccount.currencyId !== task.toAccount.currencyId && (
                  <Exchange
                    open={openTransferApprove}
                    setOpen={setOpenTransferApprove}
                    currentAccount={
                      {
                        ...task.fromAccount,
                        userId: task.fromAccount.ownerUserId,
                      } as never as IAccount
                    }
                    refresh={fetchTask}
                    amount={task.amount}
                    toAccountId={task.toAccount.accountId}
                    taskId={task.taskId}
                    confirmCallback={
                      taskTypeBooleans.isExchangeSell ||
                      taskTypeBooleans.isExchangeBuy
                        ? () => history.goBack()
                        : undefined
                    }
                  />
                )}
            </>
          )}

          {isCryptoWithdraw && (isOperator || isAdmin) && (
            <>
              <Button
                color="primary"
                className={clsx(classes.approveBtn, classes.btnRoot)}
                variant="contained"
                onClick={() => setOpenOldCryptoApprove(true)}
              >
                Approve
              </Button>

              {openOldCryptoApprove && (
                <OldWithdrawCrypto
                  task={task}
                  open={openOldCryptoApprove}
                  setOpen={setOpenOldCryptoApprove}
                />
              )}

              {/* <Button
              color="primary"
              className={clsx(classes.approveBtn, classes.btnRoot)}
              variant="contained"
              onClick={() => confirmCryptoApprove.setOpenPassword(true)}
            >
              Approve
            </Button> */}

              {openCryptoApprove && (
                <WithdrawCrypto
                  task={task}
                  open={openCryptoApprove}
                  setOpen={setOpenCryptoApprove}
                />
              )}
            </>
          )}

          {taskTypeBooleans.isTransfer &&
            taskStateBooleans.isPending &&
            (isOperator || isAdmin) && (
              <>
                <Button
                  color="primary"
                  variant="contained"
                  className={clsx(classes.approveBtn, classes.btnRoot)}
                  onClick={() => setOpenTransferApprove(true)}
                >
                  Approve
                </Button>

                {openTransferApprove &&
                  task?.fromAccount &&
                  task?.toAccount &&
                  task.fromAccount.ownerUserId === task.toAccount.ownerUserId &&
                  task.fromAccount.currencyId === task.toAccount.currencyId && (
                    <Transfer2
                      open={openTransferApprove}
                      setOpen={setOpenTransferApprove}
                      account={
                        {
                          ...task.fromAccount,
                          userId: task.fromAccount.ownerUserId,
                        } as never as IAccount
                      }
                      refresh={fetchTask}
                      toAccountId={task.toAccount.accountId}
                      amount={task.amount}
                      taskId={task.taskId}
                    />
                  )}

                {openTransferApprove &&
                  task?.fromAccount &&
                  task?.toAccount &&
                  task.fromAccount.ownerUserId !== task.toAccount.ownerUserId &&
                  task.fromAccount.currencyId === task.toAccount.currencyId &&
                  (task.fromAccount.accountTypeId === "Bank" ||
                    task.fromAccount.accountTypeId === "Crypto") &&
                  (task.toAccount.accountTypeId === "Bank" ||
                    task.toAccount.accountTypeId === "Crypto") && (
                    <TransferBetweenUsers
                      open={openTransferApprove}
                      setOpen={setOpenTransferApprove}
                      account={
                        {
                          ...task.fromAccount,
                          userId: task.fromAccount.ownerUserId,
                        } as never as IAccount
                      }
                      toAccountId={task.toAccount.accountId}
                      toUser={{
                        userId: task.toAccount.ownerUserId,
                        name: task.toAccount.ownerName,
                      }}
                      refresh={fetchTask}
                      amount={task.amount}
                      taskId={task.taskId}
                    />
                  )}

                {openTransferApprove &&
                  task?.fromAccount &&
                  task?.toAccount &&
                  task.fromAccount.ownerUserId === task.toAccount.ownerUserId &&
                  task.fromAccount.currencyId !== task.toAccount.currencyId && (
                    <Exchange
                      open={openTransferApprove}
                      setOpen={setOpenTransferApprove}
                      currentAccount={
                        {
                          ...task.fromAccount,
                          userId: task.fromAccount.ownerUserId,
                        } as never as IAccount
                      }
                      refresh={fetchTask}
                      amount={task.amount}
                      toAccountId={task.toAccount.accountId}
                      taskId={task.taskId}
                      confirmCallback={
                        taskTypeBooleans.isExchangeSell ||
                        taskTypeBooleans.isExchangeBuy
                          ? () => history.goBack()
                          : undefined
                      }
                    />
                  )}
              </>
            )}

          {taskTypeBooleans.isGold &&
            taskStateBooleans.isPending &&
            (isOperator || isAdmin) && (
              <>
                <Button
                  color="primary"
                  variant="contained"
                  className={clsx(classes.approveBtn, classes.btnRoot)}
                  onClick={() => setOpenApproveGold(true)}
                >
                  Approve
                </Button>

                {openApproveGold && (
                  <ApproveGold
                    open={openApproveGold}
                    setOpen={setOpenApproveGold}
                    account={
                      {
                        ...(task?.fromAccount || {}),
                        userId: task?.fromAccount?.ownerUserId,
                      } as never as IAccount
                    }
                    refresh={fetchTask}
                    toAccountId="MASTER.C.MIGG.BAL"
                    amount={task?.amount}
                    taskId={task?.taskId}
                    task={task}
                  />
                )}
              </>
            )}

          {taskTypeBooleans.isCrypto &&
            taskStateBooleans.isPending &&
            (isOperator || isAdmin) && (
              <>
                <Button
                  color="primary"
                  className={clsx(classes.approveBtn, classes.btnRoot)}
                  variant="contained"
                  onClick={() => setOpenOldCryptoApprove(true)}
                  disabled={!task?.toCryptoRecipient?.address}
                >
                  Approve
                </Button>

                {openOldCryptoApprove && (
                  <OldWithdrawCrypto
                    task={task}
                    open={openOldCryptoApprove}
                    setOpen={setOpenOldCryptoApprove}
                  />
                )}

                {/* <Button
              color="primary"
              className={clsx(classes.approveBtn, classes.btnRoot)}
              variant="contained"
              onClick={() => confirmCryptoApprove.setOpenPassword(true)}
            >
              Approve
            </Button> */}

                {openCryptoApprove && (
                  <WithdrawCrypto
                    task={task}
                    open={openCryptoApprove}
                    setOpen={setOpenCryptoApprove}
                  />
                )}
              </>
            )}

          {showUsuallyApprove && (
            <>
              <Button
                color="primary"
                className={clsx(classes.approveBtn, classes.btnRoot)}
                variant="contained"
                onClick={() => setOpenUsuallyApprove(true)}
              >
                Approve
              </Button>
              <UsuallyApprove
                open={openUsuallyApprove}
                setOpen={setOpenUsuallyApprove}
                taskId={task?.taskId}
                taskSubject={task?.subject}
              />
            </>
          )}

          {showSendWireApprove && (
            <>
              <Button
                color="primary"
                className={clsx(classes.approveBtn, classes.btnRoot)}
                variant="contained"
                onClick={() => setOpenSendWireApprove(true)}
              >
                Approve
              </Button>

              {openSendWireApprove && (
                <SendWire
                  open={openSendWireApprove}
                  setOpen={setOpenSendWireApprove}
                  recipient={recipient}
                  task={task}
                  refresh={fetchTask}
                  taskConverted={taskConverted}
                />
              )}
            </>
          )}

          {openChooseTemplate && (
            <ChooseTemplate
              open={openChooseTemplate}
              setOpen={setOpenChooseTemplate}
              taskId={task?.taskId}
              task={task}
              fetchTask={fetchTask}
            />
          )}

          <Alert
            open={confirmCryptoReject.openPassword}
            setOpen={confirmCryptoReject.setOpenPassword}
            size="xs"
            content={
              <div className="pt-0 px-8 pb-8">
                <TextField
                  label="Password"
                  type="password"
                  onChange={(e) =>
                    confirmCryptoReject.setEnterPassword(e.target.value)
                  }
                  fullWidth
                />
              </div>
            }
            customClose={() => {
              confirmCryptoReject.setOpenPassword(false);
              confirmCryptoReject.setEnterPassword("");
            }}
          />
        </div>

        {showTestLink && !taskConverted && (
          <div className="flex justify-center w-full mb-4 mt-2">
            <Button
              color="primary"
              variant="contained"
              className={clsx(classes.approveBtn, classes.btnRoot)}
              disabled={loadingConvert}
              onClick={async () => {
                if (!task?.taskId) {
                  return;
                }

                setLoadingConvert(true);

                try {
                  await post(
                    `${apiUrls.VITE_API}/v1/admin/tasks/${task.taskId}/convert-to-sepa`
                  );
                  setTaskConverted(true);
                  setOpenSuccessConvert(true);
                  setLoadingConvert(false);
                } catch (err) {
                  showError(err);
                  setLoadingConvert(false);
                }
              }}
            >
              Convert to wire from ‘Remium Ltd’{" "}
              <LoadingCircular loading={loadingConvert} />
            </Button>
          </div>
        )}

        {testError && (
          <div className="text-center mb-4 mt-4">
            <span className="text-red-600 font-bold text-sm">{testError}</span>
          </div>
        )}

        <Alert
          open={openSuccessConvert}
          setOpen={setOpenSuccessConvert}
          content={
            <div className="flex flex-col items-center">
              <CheckCircle className="text-green-500" size={64} />
              <p className="mt-4 text-lg text-center max-w-[30rem]">
                Task <b>{task?.taskId}</b> has been successfully converted, now
                it can be approved, but it cannot be sent to the Transactive
                Bank, INCORE Bank, or FINCI.
              </p>

              <div className="flex justify-end w-full mb-6">
                <Button
                  color="primary"
                  variant="contained"
                  className="!mr-2"
                  onClick={() => setOpenSuccessConvert(false)}
                >
                  Close
                </Button>

                <Button
                  color="primary"
                  className={clsx(classes.approveBtn, classes.btnRoot)}
                  variant="contained"
                  onClick={() => {
                    setOpenSuccessConvert(false);
                    setOpenSendWireApprove(true);
                  }}
                >
                  Approve
                </Button>
              </div>
            </div>
          }
        />
      </>
    );
  }
);

export default Actions;
