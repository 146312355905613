import { lazy } from "react";

const Security = lazy(
  () => import("../../components/Roles/All/Profile/Security")
);
const Merchant = lazy(
  () => import("../../components/Roles/All/Profile/Merchant")
);
const SelectDocumentType = lazy(
  () => import("../../components/Roles/All/Profile/SelectDocumentType")
);
const Main = lazy(() => import("../../components/Roles/All/Profile"));
const MainProfile = lazy(
  () => import("../../components/Roles/All/Profile/Main")
);
const ProfilePlaidMenuAccounts = lazy(
  () => import("../../components/Roles/Client/PlaidAccounts")
);

const ProfilePlaidAccounts = lazy(
  () => import("../../components/Roles/Client/PlaidAccounts/PlaidAccounts")
);

const ProfilePlaidTransfer = lazy(
  () => import("../../components/Roles/Client/Pay/TransferPlaid")
);

const ProfileSections = lazy(
  () => import("../../components/Roles/All/Profile/Sections")
);

const Devices = lazy(
  () => import("../../components/Roles/All/Profile/Devices")
);

const DevicesHistory = lazy(
  () => import("../../components/Roles/All/Profile/Devices/HistoryDevices")
);
const SumSubVerify = lazy(() => import("../../components/Roles/Client/SumSub"));

const Alerts = lazy(() => import("../../components/Roles/All/Profile/Alerts"));

const Support = lazy(
  () => import("../../components/Roles/All/Profile/Support")
);

export const Profile = [
  {
    path: "/app/plaid-accounts",
    component: ProfilePlaidMenuAccounts,

    routes: [
      {
        path: "/app/plaid-accounts/transfer",
        component: ProfilePlaidTransfer,
      },
      {
        path: "/app/plaid-accounts",
        component: ProfilePlaidAccounts,
      },
    ],
  },
  {
    path: "/app/profile",
    component: Main,

    routes: [
      {
        path: "/app/profile/select-document-type",
        component: SelectDocumentType,
      },
      {
        path: "/app/profile/security",
        component: Security,
      },
      {
        path: "/app/profile/alerts",
        component: Alerts,
      },
      {
        path: "/app/profile/support",
        component: Support,
      },

      {
        path: "/app/profile/merchant",
        component: Merchant,
      },
      {
        path: "/app/profile/personal-data",
        component: MainProfile,
      },
      {
        path: "/app/profile/devices/history/:deviceId",
        component: DevicesHistory,
      },
      {
        path: "/app/profile/sumsub-verify",
        component: SumSubVerify,
      },
      {
        path: "/app/profile/devices",
        component: Devices,
      },
      {
        path: "/app/profile",
        component: ProfileSections,
      },
    ],
  },
];
