import { store } from "@risingstack/react-easy-state";
import ENDPOINTS from "../../endpoints";
import { is } from "../../helpers/is";
import { del, get, patch, post, showError } from "../api";
import { defaultParams } from "./tools/defaults";

const templates = store({
  loading: false,
  items: [],
  pages: 1,
  params: {
    ...defaultParams({ perPage: 15 }),
  },

  template: {},

  async fetchItems(p?: Record<string, any>) {
    templates.loading = true;
    try {
      /* concat */

      const makeParams = {
        ...templates.params,
        ...p,
      };

      /* concat end */

      /* make sorting */

      if (p && is(Array, p.sortBy) && p.sortBy?.length > 0) {
        makeParams.sortBy = p.sortBy?.length ? p.sortBy[0]?.id : null;
        makeParams.sortDirection = p.sortBy?.length
          ? p.sortBy[0]?.desc
            ? "desc"
            : "asc"
          : null;
      }

      /* make sorting end */

      /* set global params */

      templates.params = { ...makeParams };

      /* set global params end */

      /* make page */

      makeParams.page = templates.params.pageIndex + 1;

      delete makeParams.pageIndex;

      /* make page end */

      const { data } = await get(
        ENDPOINTS.templates.depositMessages,
        makeParams
      );

      templates.pages = data.data.pages;
      templates.items = data.data;
      templates.loading = false;

      return data;
    } catch (err) {
      templates.loading = false;
      showError(err);
      return false;
    }
  },

  async fetchTemplate(templateId: string) {
    templates.loading = true;
    try {
      const { data } = await get(
        ENDPOINTS.templates.depositMessage.replace("{templateId}", templateId)
      );

      templates.loading = false;
      return [true, data];
    } catch (err) {
      templates.loading = false;
      showError(err);

      return [false];
    }
  },

  async patchTemplate(
    templateId: string,
    payload: Record<string, any> | undefined
  ) {
    templates.loading = true;
    try {
      await patch(
        ENDPOINTS.templates.depositMessage.replace("{templateId}", templateId),
        payload
      );

      templates.loading = false;
    } catch (err) {
      templates.loading = false;
      showError(err);
    }
  },

  async delTemplate(templateId: string) {
    templates.loading = true;
    try {
      await del(
        ENDPOINTS.templates.depositMessage.replace("{templateId}", templateId)
      );

      templates.loading = false;
      return true;
    } catch (err) {
      templates.loading = false;
      showError(err);
      return false;
    }
  },

  async createTemplate(payload: Record<string, any> | undefined) {
    templates.loading = true;
    try {
      await post(ENDPOINTS.templates.depositMessages, payload);

      templates.loading = false;
      return true;
    } catch (err) {
      templates.loading = false;
      showError(err);
      return false;
    }
  },
});

export default templates;
