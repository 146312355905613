import { store } from "@risingstack/react-easy-state";
import ENDPOINTS from "../../endpoints";
import { getBase64 } from "../../helpers";
import { is } from "../../helpers/is";
import { del, get, patch, post, showError } from "../api";

interface IFiles {
  loading: boolean;
  items: any[];
  pages: number;
  page: number;
  params: Record<string, any>;
  stopFetch: boolean;

  [key: string]: any;
}

const files = store<IFiles>({
  loading: false,
  items: [],
  pages: 1,
  page: 1,
  params: {
    pageIndex: 0,
    search: null,
    perPage: 18,
    sortBy: null,
    sortDirection: null,
  },
  stopFetch: false,

  clearStorage() {
    files.loading = false;
    files.items = [];
    files.pages = 1;
    files.page = 1;
    files.params = {
      pageIndex: 0,
      search: null,
      perPage: 18,
      sortBy: null,
      sortDirection: null,
    };
    files.stopFetch = false;
  },

  async fetchItems(p: Record<string, any>) {
    if (files.stopFetch) {
      return;
    }
    files.loading = true;
    try {
      /* concat */

      const makeParams: Record<string, any> = {
        ...files.params,
        ...p,
      };

      /* concat end */

      /* make sorting */

      if (is(Array, p.sortBy) && p.sortBy?.length > 0) {
        makeParams.sortBy = p.sortBy?.length ? p.sortBy[0]?.id : null;
        makeParams.sortDirection = p.sortBy?.length
          ? p.sortBy[0]?.desc
            ? "desc"
            : "asc"
          : null;
      }

      /* make sorting end */

      /* set global params */

      files.params = { ...makeParams };

      /* set global params end */

      /* make page */

      makeParams.page = files.params.pageIndex + 1;

      delete makeParams.pageIndex;

      /* make page end */

      const { data } = await get(ENDPOINTS.files.items, makeParams);

      files.pages = data.pages;

      if (data.data?.length === 0) {
        files.stopFetch = true;
      }

      if (makeParams.page > 1) {
        files.items = [...files.items, ...data.data];
      } else {
        files.items = data.data;
      }

      files.page = data.page;
      files.loading = false;

      return data.data;
    } catch (err) {
      files.loading = false;
      showError(err);
      return false;
    }
  },

  async getMiniImage(fileId: string) {
    if (!fileId) {
      return false;
    }
    try {
      const response = await get(
        ENDPOINTS.files.miniImage.replace("{fileId}", fileId),
        undefined,
        "arraybuffer"
      );

      return getBase64(response);
    } catch (err) {
      return false;
    }
  },

  async openDoc(fileId: string, isNewTab: boolean | undefined) {
    try {
      const response = await get(
        ENDPOINTS.files.item.replace("{fileId}", fileId),
        undefined,
        "arraybuffer"
      );

      if (isNewTab) {
        const { data, headers } = response;

        const contentType = headers["content-type"];

        const blob = new Blob([data], { type: contentType });
        const fileURL = URL.createObjectURL(blob);

        open(fileURL, "_blank");
      }

      return getBase64(response);
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async openPdf(fileId: string, name: undefined) {
    try {
      const response = await get(
        ENDPOINTS.files.item.replace("{fileId}", fileId),
        undefined,
        "arraybuffer"
      );

      const { data, headers } = response;

      const contentType = headers["content-type"];
      const disposition = headers["content-disposition"];

      const blob = new Blob([data], { type: contentType });
      const fileURL = URL.createObjectURL(blob);

      if (contentType.includes("pdf")) {
        open(fileURL, "_blank");
      } else {
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = name || disposition?.fileName || "word";
        link.click();
        link.remove();
      }

      return getBase64(response);
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async upload(
    userId: string,
    payload: { file: any; fileType: any; fileName?: any; ubo?: any }
  ) {
    try {
      const formData = new FormData();
      formData.append("file", payload?.file);

      if (payload?.fileType) {
        formData.append("DocumentId", payload?.fileType);
      }

      if (payload?.fileName) {
        formData.append("fileName", payload?.fileName);
      }

      if (payload?.ubo) {
        formData.append("Ubo", payload?.ubo);
      }

      formData.append("ProcessImmediately", "true");

      const { data } = await post(
        ENDPOINTS.files.upload.replace("{userId}", userId),
        formData,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async uploadClient(payload: { file: any; fileType: any; fileName?: any }) {
    try {
      const formData = new FormData();
      formData.append("file", payload?.file);
      if (payload?.fileType) {
        formData.append("fileType", payload?.fileType);
      }
      if (payload?.fileName) {
        formData.append("fileName", payload?.fileName);
      }

      formData.append("ProcessImmediately", "true");

      const { data } = await post(ENDPOINTS.files.itemsClient, formData, {
        "Content-Type": "multipart/form-data",
      });
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchClientItems(params) {
    try {
      const { data } = await get(ENDPOINTS.files.itemsClient, params);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchMiniImageClient(fileId: string) {
    if (!fileId) {
      return false;
    }
    try {
      const response = await get(
        ENDPOINTS.files.miniImageClient.replace("{fileId}", fileId),
        undefined,
        "arraybuffer"
      );

      return getBase64(response);
    } catch (err) {
      return false;
    }
  },

  async patchUserFile(
    fileId: string,
    payload: Record<string, any> | undefined
  ) {
    try {
      await patch(
        ENDPOINTS.files.newPatchUserFile.replace("{fileId}", fileId),
        payload
      );

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async deleteUserFile(fileId: string) {
    try {
      await del(ENDPOINTS.files.patchUserFile.replace("{fileId}", fileId));

      return true;
    } catch (err) {
      return false;
    }
  },

  async fetchOpenDocCrypto(fileId: string) {
    try {
      const response = await get(
        ENDPOINTS.files.item.replace("{fileId}", fileId),
        undefined,
        "arraybuffer"
      );
      const contentType = response.headers["content-type"];

      if (contentType.includes("image")) {
        return getBase64(response);
      }

      const file = new Blob([response.data], { type: contentType });
      const fileURL = URL.createObjectURL(file);

      open(fileURL, "_blank");
      return false;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchOpenDocClient(fileId: string) {
    try {
      const response = await get(
        ENDPOINTS.files.itemClient.replace("{fileId}", fileId),
        undefined,
        "arraybuffer"
      );
      const contentType = response.headers["content-type"];

      if (contentType.includes("image")) {
        return getBase64(response);
      }

      const file = new Blob([response.data], { type: contentType });
      const fileURL = URL.createObjectURL(file);

      open(fileURL, "_blank");
      return false;
    } catch (err) {
      showError(err);
      return false;
    }
  },
});

export default files;
