import { useInfiniteQuery } from "react-query";
import ENDPOINTS from "../endpoints";
import { get, showError } from "../store/api";
import { useDebouncer } from "./useDebouncer";

export const useQueryAdminUsers = (params) => {
  const debouncedSearch = useDebouncer(params.search, 500);

  return useInfiniteQuery(
    [
      "adminUsers",
      params.page,
      debouncedSearch,
      params.sortDirection,
      params.sortBy,
    ],
    async ({ pageParam = 1 }) => {
      try {
        const { data } = await get(ENDPOINTS.users.items, {
          page: params.page,
          search: debouncedSearch,
          sortDirection: params.sortDirection,
          sortBy: params.sortBy,
        });

        return { ...data, nextPage: pageParam + 1 };
      } catch (err) {
        showError(err);
        return {};
      }
    },
    {
      keepPreviousData: false,
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      getNextPageParam: (lastPage: any) => {
        return lastPage.page < lastPage.pages ? lastPage.nextPage : undefined;
      },
    }
  );
};
