import React, { memo } from "react";
import CustomRadioButtons from "../../../../../System/Components/CustomRadioButtons";

export default memo(({ params, setParams }: any) => {
  return (
    <CustomRadioButtons
      label="Total currency"
      value={params.totalCurrency}
      onChange={(e, value) => {
        setParams((prevState) => ({
          ...prevState,
          totalCurrency: value,
          page: 1,
        }));
      }}
      items={[
        {
          label: "EUR",
          value: "EUR",
        },
        {
          label: "USD",
          value: "USD",
        },
      ]}
      row
    />
  );
});
