import { useCallback } from "react";
import IAccount from "../../../../../../interfaces/account";
import { useQueryClientAccounts } from "../../Exchange/queries/useQueryClientAccounts";

export const useGetAccounts = ({ account }) => {
  const queryResult = useQueryClientAccounts(account?.userId);

  const options = queryResult?.data?.filter(
    (a) => a.accountId !== account.accountId && a.accountStateId === "Open"
  ) as IAccount[];

  const getToAccount = useCallback(
    (accountId) => {
      return queryResult?.data?.find((o) => o.accountId === accountId);
    },
    [queryResult?.data]
  );

  return { options, getToAccount, isLoading: queryResult.isLoading };
};
