import { clsx } from "clsx";
import React, { memo } from "react";

export default memo(
  ({
    children,
    header,
    className,
  }: {
    children?: React.ReactNode;
    header: string;
    className?: string;
  }) => {
    return (
      <div className={clsx("w-full mb-8", className)}>
        <h3 className="mb-4 text-lg">{header}</h3>

        {children}
      </div>
    );
  }
);
