import { blue, green, orange, red } from "@material-ui/core/colors";
import { getTaskStateBooleans } from "../../../../../../helpers/task";

export const getColorForState = (task) => {
  let colorState;

  const taskStateBooleans = getTaskStateBooleans(task);

  if (taskStateBooleans.isPending || taskStateBooleans.isPendingWireInfoSent) {
    colorState = orange[600];
  }

  if (taskStateBooleans.isApproved) {
    colorState = green[600];
  }

  if (taskStateBooleans.isRejected) {
    colorState = red[600];
  }

  if (taskStateBooleans.isCanceled) {
    colorState = red[600];
  }

  if (taskStateBooleans.isClosed) {
    colorState = red[600];
  }

  if (taskStateBooleans.isSubmitted) {
    colorState = blue[600];
  }

  if (taskStateBooleans.isMessage) {
    colorState = blue[600];
  }

  if (taskStateBooleans.isMoneySent) {
    colorState = green[600];
  }

  if (taskStateBooleans.isRejected) {
    colorState = red[600];
  }

  return colorState;
};
