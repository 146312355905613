import { useQuery } from "react-query";
import { useDebouncer } from "../../../../../../../queries/useDebouncer";
import { get } from "../../../../../../../store/api";
import auth from "../../../../../../../store/modules/auth";

export const useQueryVolumePerClient = (params, enabled) => {
  const debouncedSearch = useDebouncer(params.search, 500);
  const queryParams = {
    ...params,
    search: params.search === "" ? params.search : debouncedSearch,
  };

  return useQuery(
    ["useQueryVolumePerClient", queryParams],
    async () => {
      try {
        // await new Promise((resolve: any) => {
        //   setTimeout(() => resolve(), 3000);
        // });

        const params = { ...queryParams };

        if (auth.profile && auth.profile?.isBranchAdmin) {
          params.branchId = auth.profile?.branch?.parentUserId;
        }

        const { data } = await get(
          `${import.meta.env.VITE_API}/v2/reports/volume-per-client`,
          params
        );

        return data || [];
      } catch (err) {
        return;
      }
    },
    {
      // keepPreviousData: false,
      // staleTime: 300000,
      // refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      keepPreviousData: true,
      enabled,
    }
  );
};
