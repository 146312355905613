import { useCallback, useEffect, useState } from "react";
import IWireRecipient from "../../../../../../interfaces/wireRecipient";
import recipients from "../../../../../../store/modules/recipients";

export const useFetchWireRecipient = (
  userId?: string,
  wireRecipientId?: string
) => {
  const [recipient, setRecipient] = useState<IWireRecipient | undefined>();
  const [loading, setLoading] = useState(false);

  const fetcher = useCallback(async () => {
    if (!userId || !wireRecipientId) {
      return;
    }
    setLoading(true);

    const result = await recipients.fetchWireRecipientDetail(
      userId,
      wireRecipientId
    );

    if (result) {
      setRecipient(result);
    }

    setLoading(false);
  }, [userId, wireRecipientId]);

  useEffect(() => {
    fetcher();
  }, [fetcher]);

  return { recipient, loading, fetchRecipient: fetcher };
};
