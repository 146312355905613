import { CircularProgress } from "@material-ui/core";
import React from "react";

const LoadingCircular = ({ loading }) => {
  return loading ? (
    <CircularProgress
      size={24}
      className="text-main absolute top-1/2 left-1/2 -mt-3 -ml-3"
    />
  ) : null;
};

export default LoadingCircular;
