import { Container, Drawer, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CloseOutlined } from "@material-ui/icons";
import { view } from "@risingstack/react-easy-state";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { formatDateToMmDdYyyyHhMmSs } from "../../../../../../helpers";
import {
  checkIsCryptoCurrency,
  formatToCurrencyString,
} from "../../../../../../helpers/formatToCurrencyString";
import cryptoAdmin from "../../../../../../store/modules/cryptoAdmin";
import transactionDetails from "../../../../../../store/modules/transactionDetails";
import FormatAmount from "../../../../../System/FormatAmount";
import UsuallyTransaction from "../../../AllAccounts/Transactions/Detail";

const useStyles = makeStyles((theme) => {
  return {
    drawer: {
      width: "100%",
    },
    drawerPaper: {
      fontSize: "1.125rem",
      width: "40rem",

      "@media (max-width: 640px)": {
        width: "100%",
      },
    },
    close: {
      position: "absolute",
      right: 15,
      top: 0,

      [theme.breakpoints.down("sm")]: {
        right: 15,
        top: 0,
      },
      [theme.breakpoints.down("xs")]: {
        right: 15,
        top: 0,
      },
    },
  };
});

const Transaction = view(() => {
  const classes = useStyles();
  const location = useLocation();
  const { transaction, openDetail } = cryptoAdmin;

  const { currency } = transaction;

  const isCrypto = checkIsCryptoCurrency(currency);

  const txHashLink =
    currency === "BTC"
      ? `${import.meta.env.VITE_BLOCKCYPHER}/btc/tx/${transaction?.txHash}`
      : `${import.meta.env.VITE_ETHERSCAN}/tx/${transaction?.txHash}`;

  const clearStore = () => {
    cryptoAdmin.transaction = {};
    cryptoAdmin.openDetail = false;
  };

  const openUsuallyDetails = async () => {
    const result = await transactionDetails.fetchUserAccountTransaction(
      false,
      transaction?.transactionId
    );

    if (result) {
      transactionDetails.openDetail = true;
    }
  };

  return (
    <>
      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
        open={openDetail}
        onClose={clearStore}
      >
        <Container maxWidth="md">
          <IconButton
            className={classes.close}
            onClick={clearStore}
            style={{ padding: 10 }}
          >
            <CloseOutlined />
          </IconButton>

          <div className="h-5" />

          <div className="flex justify-center">
            <h2>Crypto transaction detail</h2>
          </div>

          <div className="h-4" />

          <div className="mb-8">
            <h2 className="w-full flex justify-center text-main text-5xl m-0 p-0 leading-none">
              <b>
                {isCrypto ? (
                  <FormatAmount
                    amount={transaction?.amount}
                    currency={currency}
                  />
                ) : (
                  formatToCurrencyString(transaction?.amount, currency)
                )}
              </b>
            </h2>

            <div className="h-1" />

            <p className="text-center m-0 text-gray-500">Amount</p>
          </div>

          <div className="flex justify-between items-center flex-1 py-2 px-4 border-b border-solid border-gray-200 hover:bg-gray-100">
            <div className="flex-1 pr-4">Transaction date</div>

            <div className="flex-1 flex justify-end font-bold text-right break-words">
              {formatDateToMmDdYyyyHhMmSs(transaction?.ts)}
            </div>
          </div>

          <div className="flex justify-between items-center flex-1 py-2 px-4 border-b border-solid border-gray-200 hover:bg-gray-100">
            <div className="flex-1 pr-4">Atx ID: </div>

            <div className="flex-1 flex justify-end font-bold text-right break-words">
              {transaction?.atxId}
            </div>
          </div>

          <div className="flex justify-between items-center flex-1 py-2 px-4 border-b border-solid border-gray-200 hover:bg-gray-100">
            <div className="flex-1 pr-4">Transaction ID: </div>

            <div className="flex-1 flex justify-end font-bold text-right break-words">
              {transaction?.transactionId?.includes("UNKNOWN") ||
              !location.pathname.includes("/app/admin/crypto") ? (
                transaction?.transactionId
              ) : (
                <a onClick={openUsuallyDetails}>{transaction?.transactionId}</a>
              )}
            </div>
          </div>

          <div className="flex justify-between items-center flex-1 py-2 px-4 border-b border-solid border-gray-200 hover:bg-gray-100">
            <div className="flex-1 pr-4">Algo name: </div>

            <div className="flex-1 flex justify-end font-bold text-right break-words">
              {transaction?.algoName}
            </div>
          </div>

          <div className="flex justify-between items-center flex-1 py-2 px-4 border-b border-solid border-gray-200 hover:bg-gray-100">
            <div className="flex-1 pr-4">Action: </div>

            <div className="flex-1 flex justify-end font-bold text-right break-words">
              {transaction?.action}
            </div>
          </div>

          <div className="flex justify-between items-center flex-1 py-2 px-4 border-b border-solid border-gray-200 hover:bg-gray-100">
            <div className="flex-1 pr-4">Name action: </div>

            <div className="flex-1 flex justify-end font-bold text-right break-words">
              {transaction?.nameAction}
            </div>
          </div>

          <div className="flex justify-between items-center flex-1 py-2 px-4 border-b border-solid border-gray-200 hover:bg-gray-100">
            <div className="flex-1 pr-4">Pair: </div>

            <div className="flex-1 flex justify-end font-bold text-right break-words">
              {transaction?.pair}
            </div>
          </div>

          <div className="flex justify-between items-center flex-1 py-2 px-4 border-b border-solid border-gray-200 hover:bg-gray-100">
            <div className="flex-1 pr-4">Target currency: </div>

            <div className="flex-1 flex justify-end font-bold text-right break-words">
              {transaction?.targetCurrency}
            </div>
          </div>

          <div className="flex justify-between items-center flex-1 py-2 px-4 border-b border-solid border-gray-200 hover:bg-gray-100">
            <div className="flex-1 pr-4">Coin address: </div>

            <div className="flex-1 flex justify-end font-bold text-right break-words">
              {transaction?.coinAddress}
            </div>
          </div>

          <div className="flex justify-between items-center flex-1 py-2 px-4 border-b border-solid border-gray-200 hover:bg-gray-100">
            <div className="flex-1 pr-4">Account ID</div>
            <div className="flex-1 flex justify-end font-bold text-right break-words">
              {transaction?.accountId ? (
                <Link to={`/app/admin/accounts/${transaction?.accountId}`}>
                  {transaction?.accountId}
                </Link>
              ) : (
                transaction?.accountId
              )}
            </div>
          </div>

          <div className="flex justify-between items-center flex-1 py-2 px-4 border-b border-solid border-gray-200 hover:bg-gray-100">
            <div className="flex-1 pr-4">Order ID: </div>

            <div className="flex-1 flex justify-end font-bold text-right break-words">
              {transaction?.orderId}
            </div>
          </div>

          <div className="flex justify-between items-center flex-1 py-2 px-4 border-b border-solid border-gray-200 hover:bg-gray-100">
            <div className="flex-1 pr-4">Price: </div>

            <div className="flex-1 flex justify-end font-bold text-right break-words">
              {isCrypto ? (
                <FormatAmount amount={transaction?.price} currency={currency} />
              ) : (
                formatToCurrencyString(transaction?.price, currency)
              )}
            </div>
          </div>

          <div className="flex justify-between items-center flex-1 py-2 px-4 border-b border-solid border-gray-200 hover:bg-gray-100">
            <div className="flex-1 pr-4">Fees: </div>

            <div className="flex-1 flex justify-end font-bold text-right break-words">
              {isCrypto ? (
                <FormatAmount amount={transaction?.fees} currency={currency} />
              ) : (
                formatToCurrencyString(transaction?.fees, currency)
              )}
            </div>
          </div>

          <div className="flex justify-between items-center flex-1 py-2 px-4 border-b border-solid border-gray-200 hover:bg-gray-100">
            <div className="flex-1 pr-4">Net proceeds: </div>

            <div className="flex-1 flex justify-end font-bold text-right break-words">
              {isCrypto ? (
                <FormatAmount
                  amount={transaction?.netProceeds}
                  currency={currency}
                />
              ) : (
                formatToCurrencyString(transaction?.netProceeds, currency)
              )}
            </div>
          </div>

          <div className="flex justify-between items-center flex-1 py-2 px-4 border-b border-solid border-gray-200 hover:bg-gray-100">
            <div className="flex-1 pr-4">Asset balance: </div>

            <div className="flex-1 flex justify-end font-bold text-right break-words">
              {isCrypto ? (
                <FormatAmount
                  amount={transaction?.assetBalance}
                  currency={currency}
                />
              ) : (
                formatToCurrencyString(transaction?.assetBalance, currency)
              )}
            </div>
          </div>

          <div className="flex justify-between items-center flex-1 py-2 px-4 border-b border-solid border-gray-200 hover:bg-gray-100">
            <div className="flex-1 pr-4">Price balance: </div>

            <div className="flex-1 flex justify-end font-bold text-right break-words">
              {isCrypto ? (
                <FormatAmount
                  amount={transaction?.priceBalance}
                  currency={currency}
                />
              ) : (
                formatToCurrencyString(transaction?.priceBalance, currency)
              )}
            </div>
          </div>

          <div className="flex justify-between items-center flex-1 py-2 px-4 border-b border-solid border-gray-200 hover:bg-gray-100">
            <div className="flex-1 pr-4">Memo</div>

            <div className="flex justify-end font-bold text-right break-words w-full">
              {transaction?.memo}
            </div>
          </div>

          <div className="flex justify-between items-center flex-1 py-2 px-4 border-b border-solid border-gray-200 hover:bg-gray-100">
            <div className="flex-1 pr-4">Manual</div>

            <div className="flex justify-end font-bold text-right break-words w-full">
              {transaction?.manual ? "Yes" : "No"}
            </div>
          </div>

          {transaction.txHash && (
            <div className="flex justify-between items-center flex-1 py-2 px-4 border-b border-solid border-gray-200 hover:bg-gray-100">
              <div className="flex-1 pr-4">TX Hash:</div>

              <div className="flex justify-end font-bold text-right break-words w-full">
                <div className="flex items-start justify-center">
                  <a
                    href={txHashLink}
                    className="break-all"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {transaction.txHash}
                  </a>
                </div>
              </div>
            </div>
          )}
        </Container>
      </Drawer>

      {location.pathname.includes("/app/admin/crypto") && (
        <UsuallyTransaction />
      )}
    </>
  );
});

export default Transaction;
