import { view } from "@risingstack/react-easy-state";
import React from "react";
import { formatToCurrencyString } from "../../../../../helpers";
import { useEnter } from "../../../../../hooks/useEnter";
import accounts from "../../../../../store/modules/accounts";
import ui from "../../../../../store/modules/ui";
import { en } from "../../../../../translate/en";
import { FormActions } from "../../../../System";
import ShortAccount from "../components/ShortAccount";
import SubTitle from "../SubTitle";
import IStepProps, { IPayloadForConfirm } from "./interfaces/stepProps";
import Preview from "./Preview";

const Step2 = view((props: IStepProps) => {
  const { success, setSuccess, account, step } = props;

  const makeConfirm = async () => {
    ui.loadingOperation = true;

    ui.textSuccess = en.deposit.finish.success
      .replace(
        "{amount}",
        formatToCurrencyString(
          props.payloadForConfirm?.amount,
          account?.currencyId
        )
      )
      .replace("{shortNumber}", account?.shortNumber || "");

    const result = await accounts.deposit(props.payloadForConfirm);

    if (result) {
      ui.successOperation = true;
      setSuccess(true);

      props.setPayloadForConfirm(
        (prevState: IPayloadForConfirm | undefined) => {
          return {
            ...prevState,
            transactionId: result?.transaction?.transactionId,
          };
        }
      );

      if (props?.fetch) {
        props.fetch();
      }
    }
    ui.loadingOperation = false;
  };

  const showConfirmActions = !ui.loadingOperation && !success;

  useEnter(!success && step === 2 && makeConfirm);

  return (
    <>
      {success ? (
        <SubTitle>Confirmation</SubTitle>
      ) : (
        <SubTitle>Preview</SubTitle>
      )}

      <Preview
        currency={props?.account?.currencyId || ""}
        payload={props.payloadForConfirm}
      />

      <SubTitle>Deposit to:</SubTitle>

      <ShortAccount account={props.account} />

      {showConfirmActions && (
        <>
          <FormActions
            onClickConfirm={makeConfirm}
            onClickBack={() => {
              props.setStep(1);
            }}
            disabledConfirmButton={
              ui.loadingOperation || Boolean(ui.errorOperation)
            }
            loading={ui.loadingOperation}
            showConfirmButton={!ui.loadingOperation}
            showBackButton
          />
        </>
      )}

      {success && (
        <FormActions
          showCloseButton
          onClickClose={() => {
            props.setOpen(false);
          }}
        />
      )}
    </>
  );
});

export default Step2;
