import React from "react";
import { useWatch } from "react-hook-form";
import MyCheckboxLabel from "../../../../../System/FormComponents/MyCheckboxLabel";
import ShowAndHide from "../../../../../System/FormComponents/ShowAndHide";

const AdvancedOptions = ({ prefix = "", control }) => {
  const showAdvancedOptions = useWatch({
    control: control,
    name: prefix + "showAdvancedOptions",
  });

  return (
    <ShowAndHide show={showAdvancedOptions}>
      <>
        <div>
          <MyCheckboxLabel
            control={control}
            name={prefix + "feeInsideAmount"}
            label="Fee inside amount"
          />
        </div>
      </>
    </ShowAndHide>
  );
};

export default AdvancedOptions;
