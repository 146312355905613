import numbro from "numbro";
import { memo, useCallback, useEffect } from "react";
import { useWatch } from "react-hook-form";
import admin from "../../../../../../store/modules/admin";

const InitFee = memo(({ form, open, task, calculatorType }: any) => {
  const { setValue } = form;

  const feeType = useWatch({
    name: "feeType",
    control: form.control,
  });

  const amount = useWatch({
    name: "amount",
    control: form.control,
  });

  useEffect(() => {
    if (
      feeType?.value?.includes("NO_FEE") ||
      (typeof feeType === "string" && feeType?.includes("NO_FEE"))
    ) {
      setTimeout(() => setValue("fixedFee", "0"));
    } else {
      setTimeout(() => setValue("fixedFee", ""));
    }
  }, [feeType, setValue]);

  const getFeeForSmartForm = useCallback(async () => {
    if (!calculatorType || !amount || !task?.currencyId) {
      return;
    }

    const params = {
      calculatorType,
      amount: numbro.unformat(amount) || undefined,
      currencyId: task?.currencyId,
    };

    const [status, result] = await admin.getFeeForSmartForm(params);

    if (status) {
      setTimeout(() => setValue("fixedOurCost", result?.ourCost), 0);
    }
  }, [amount, calculatorType, setValue, task?.currencyId]);

  useEffect(() => {
    if (open && task?.currencyId) {
      getFeeForSmartForm();
    }
  }, [getFeeForSmartForm, open, task?.currencyId]);

  return null;
});

export default InitFee;
