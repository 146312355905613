import { addSeconds, format } from "date-fns";
import { useEffect } from "react";

export const useTimer = ({ seconds, setSeconds, diff }: any) => {
  useEffect(() => {
    const callback = () => {
      const val = seconds - 1;
      setSeconds(val);
    };

    if (!seconds) {
      return;
    }

    const intervalId = setInterval(callback, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [seconds, setSeconds]);

  const time = addSeconds(new Date(0), seconds + (diff || 0));

  return format(time, "mm:ss");
};
