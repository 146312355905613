import { store } from "@risingstack/react-easy-state";
import ENDPOINTS from "../../endpoints";
import { getBase64 } from "../../helpers";
import IUser from "../../interfaces/user";
import { del, get, patch, post, showError } from "../api";
import auth from "./auth";
import { defaultParams } from "./tools/defaults";

export const defaultRoles = [
  "Operator",
  "Controller",
  "Clerk",
  "Regulator",
  "SupportAgent",
  "BranchAdmin",
];

interface IUsers {
  loading: boolean;
  items: any[];
  pages: number;
  params: Record<string, any>;

  user: IUser;
  changedUser?: IUser;

  [key: string]: any;
}

const users = store<IUsers>({
  loading: false,
  items: [],
  pages: 1,
  params: {
    ...defaultParams({ perPage: 15 }),
    clientTypes: undefined,
    onBoardStatus: undefined,
    onlyRoles: defaultRoles,
    rolesExcept: undefined,
    branchId: undefined,
    userState: undefined,
  },

  user: {},
  changedUser: {},

  async searchStaff(input: any) {
    try {
      const params = {
        pageIndex: 0,
        search: input,
        perPage: 999999,
        page: 1,
      };

      const { data } = await get(ENDPOINTS.users.items, params);

      return data.data;
    } catch (err) {
      showError(err);

      return false;
    }
  },
  async fetchItems(exportCsv = false) {
    users.loading = true;
    try {
      const params: Record<string, any> = {
        ...users.params,
        clientTypes:
          users.params.clientTypes?.length > 0
            ? `${users.params.clientTypes.join(",")}`
            : null,
        onBoardStatus:
          users.params.onBoardStatus?.length > 0
            ? `${users.params.onBoardStatus.join(",")}`
            : null,
        onlyRoles:
          users.params.onlyRoles?.length > 0
            ? `${users.params.onlyRoles.join(",")}`
            : null,
        rolesExcept:
          users.params.rolesExcept?.length > 0
            ? `${users.params.rolesExcept.join(",")}`
            : null,
        page: users.params.pageIndex + 1,
      };

      if (auth.profile && auth.profile?.isBranchAdmin) {
        params.branchId = auth.profile?.branch?.parentUserId;
      }

      delete params.pageIndex;

      const { data } = await get(
        ENDPOINTS.users.items,
        params,
        exportCsv ? "blob" : undefined,
        {
          Accept: exportCsv ? "text/csv" : "application/json",
        }
      );

      if (exportCsv) {
        const blob = data;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "all_clients.csv";
        link.click();
      }

      if (!exportCsv) {
        users.pages = data.pages;
        users.items = data.data;
      }

      users.loading = false;

      return true;
    } catch (err) {
      users.loading = false;
      showError(err);

      return false;
    }
  },

  async fetchCustomUsers(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(ENDPOINTS.users.items, params);

      return data?.data;
    } catch (err) {
      showError(err);

      return false;
    }
  },
  async fetchUser(userId: string) {
    users.loading = true;
    try {
      const { data } = await get(
        ENDPOINTS.users.item.replace("{userId}", userId)
      );

      data.avatar = "/images/pack/friend.svg";

      users.user = { ...data };

      users.loading = false;

      if (data.avatarId) {
        const avatar = await users.fetchAvatar(data.avatarId);

        if (avatar) {
          data.avatar = avatar;

          if (users.user) {
            users.user.avatar = avatar;

            users.user = { ...users.user };
          }
        }
      }

      return data;
    } catch (err) {
      showError(err);

      users.loading = false;
      return false;
    }
  },

  async fetchCustomUser(userId: string) {
    try {
      const { data } = await get(
        ENDPOINTS.users.item.replace("{userId}", userId)
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchRolesUser(userId: string) {
    users.loading = true;
    try {
      const { data } = await get(
        ENDPOINTS.users.fetchRolesUser.replace("{userId}", userId)
      );

      data.isCorporate = data.includes("Corporate");
      data.isAdmin = data.includes("Admin");
      data.isOperator = data.includes("Operator");
      data.isController = data.includes("Controller");
      data.isClerk = data.includes("Clerk");
      data.isClient = data.includes("Client");
      data.isRegulator = data.includes("Regulator");
      data.isTreasurer = data.includes("Treasurer");

      users.loading = false;

      return data;
    } catch (err) {
      showError(err);

      users.loading = false;
      return false;
    }
  },

  onChangeUser({
    name,
    value,
    save,
  }: {
    name: string;
    value: any;
    save: boolean;
  }) {
    if (save) {
      users.user = { ...users.user, [name]: value } as any;
      users.patchUser(users.user?.userId, { [name]: value });
    } else {
      users.changedUser = { ...users.changedUser, [name]: value } as any;
    }
  },
  async deleteUser(userId: string) {
    try {
      await del(ENDPOINTS.users.deleteUser.replace("{userId}", userId));
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchAvatar(avatarId: string) {
    try {
      const response = await get(
        ENDPOINTS.auth.getAvatar.replace("{avatarFileId}", avatarId),
        undefined,
        "arraybuffer"
      );

      if (response.data) {
        if (response?.data?.byteLength > 0) {
          return getBase64(response);
        }

        return "/images/pack/friend.svg";
      }
    } catch (err) {
      showError(err);
    }
  },
  async patchUser(userId: string, payload: Record<string, any>) {
    users.loading = true;
    try {
      await patch(ENDPOINTS.users.item.replace("{userId}", userId), payload);

      users.user =
        Object.keys(users.changedUser || {}).length > 0
          ? { ...users.user, ...users.changedUser }
          : ({ ...users.user, ...payload } as any);

      users.changedUser = undefined;
      users.loading = false;
      return true;
    } catch (err) {
      users.loading = false;
      showError(err);
      return false;
    }
  },
  async confirmEmail(userId: string, callback?: () => void) {
    try {
      await post(ENDPOINTS.users.confirmEmail.replace("{userId}", userId));

      if (callback) {
        callback();
      }
    } catch (err) {
      showError(err);
    }
  },
  async changePassword(userId: string, password: string) {
    try {
      await post(ENDPOINTS.users.changePassword.replace("{userId}", userId), {
        password,
      });

      return true;
    } catch (err) {
      showError(err);

      return false;
    }
  },
  async findCountry(input: string, cb: (data: any) => void) {
    try {
      const { data } = await get(ENDPOINTS.common.findCountry, {
        countryName: input,
      });

      cb(
        data.map((item: { codes: any[]; name: any }, index: number) => {
          return {
            ...item,
            value: item?.codes[3],
            label: item.name,
            id: `client-list-detail-country-option${index + 1}`,
          };
        })
      );

      return data;
    } catch (err) {
      // logic
    }
  },

  async registerStaff(payload: Record<string, any>) {
    try {
      await post(ENDPOINTS.users.registerStaff, payload);
      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async remindReg(userId: string, typeMessage: string, messageId: string) {
    try {
      await get(ENDPOINTS.users.remindReg.replace("{userId}", userId), {
        typeMessage,
        messageId,
      });

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchMessagesRemind() {
    try {
      const { data } = await get(ENDPOINTS.users.fetchMessagesRemind);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
});

export default users;
