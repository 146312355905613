import React, { memo } from "react";
import CustomCheckboxLabel from "../../../../System/Components/CustomCheckboxLabel";

export default memo(({ params, setParams }: any) => {
  return (
    <div className="!mr-4 !mb-2">
      <CustomCheckboxLabel
        isSmall
        label="Unread"
        checked={params.countType === "UnreadOnly"}
        onChange={(e, checked) => {
          if (checked) {
            setParams((prevState) => {
              return {
                ...prevState,
                page: 1,
                countType: "UnreadOnly",
              };
            });
          } else {
            setParams((prevState) => {
              return {
                ...prevState,
                page: 1,
                countType: undefined,
              };
            });
          }
        }}
      />
    </div>
  );
});
