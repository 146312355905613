import React from "react";
import MyAmount from "../../../../../System/FormComponents/MyAmount";

const FixedOurCost = ({ prefix = "", control }) => {
  return (
    <div>
      <MyAmount
        control={control}
        controlCurrency={control}
        name="fixedOurCost"
        label="Our cost (we pay to external bank)"
        currencyName="currencyId"
        prefix={prefix}
      />
    </div>
  );
};

export default FixedOurCost;
