import React from "react";
import { useWatch } from "react-hook-form";
import { getTaskTypeBooleans } from "../../../../../../helpers/task";
import MyCheckboxLabel from "../../../../../System/FormComponents/MyCheckboxLabel";
import ShowAndHide from "../../../../../System/FormComponents/ShowAndHide";
import SepaDetail from "./SepaDetail";

const Sepa = ({ data, task, ...form }) => {
  const useSepa = useWatch({
    control: form?.control,
    name: "useSepa",
  });

  const taskTypeBooleans = getTaskTypeBooleans(task);

  const sepa = useSepa && data?.sepaPayment && taskTypeBooleans.isSEPA;

  return (
    <ShowAndHide show={Boolean(data?.sepaPayment)}>
      <MyCheckboxLabel
        control={form.control}
        name="useSepa"
        label="I want to confirm using SEPA"
      />

      {sepa ? (
        <div>
          <b className="text-main text-lg">
            Auto wire send: Will execute auto sepa payment
          </b>
        </div>
      ) : null}

      <div className="flex flex-col">
        <div className="flex flex-1 w-full justify-between">
          {useSepa && (
            <div className="flex flex-col">
              <b className="text-orange-600 text-lg">Transactive Payment</b>
            </div>
          )}
        </div>

        {useSepa && data?.sepaPayment && (
          <SepaDetail sepa={data?.sepaPayment} />
        )}
      </div>
    </ShowAndHide>
  );
};

export default Sepa;
