import numbro from "numbro";
import React, { memo } from "react";
import CustomAmount from "../../../../../System/Components/CustomAmount";

export default memo(({ params, setParams }: any) => {
  return (
    <div className="max-w-[8rem] mr-4">
      <CustomAmount
        label="More Than"
        value={params.moreThan}
        onChange={(e) => {
          setParams((prevState) => ({
            ...prevState,
            page: 1,
            moreThan: numbro.unformat(e.target.value),
          }));
        }}
      />
    </div>
  );
});
