import { store } from "@risingstack/react-easy-state";
import ENDPOINTS from "../../endpoints";
import { formatDateToMMddyyyySeparatorSlash } from "../../helpers";
import ITransaction from "../../interfaces/transaction";
import { del, get, post, showError } from "../api";
import { defaultParams } from "./tools/defaults";

interface ITransactions {
  loading: boolean;
  items: ITransaction[];
  pages: number;
  params: Record<string, any>;

  [key: string]: any;
}

const transactions = store<ITransactions>({
  loading: false,
  items: [],
  pages: 1,

  params: {
    ...defaultParams({ perPage: 32 }),
    page: 1,
    from: undefined,
    to: undefined,
    idTo: undefined,
  },

  async fetchUserAccountTransactions(
    accountId: string,
    params: Record<string, any> | undefined,
    exportCsv: boolean
  ) {
    try {
      const response = await get(
        ENDPOINTS.admin.fetchUserAccountTransactions.replace(
          "{accountId}",
          accountId
        ),
        params,
        exportCsv ? "blob" : undefined,
        {
          Accept: exportCsv ? "text/csv" : "application/json",
        }
      );

      if (exportCsv) {
        const blob = response.data;

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "transactions.csv";
        link.click();
      }

      return exportCsv ? true : response.data;
    } catch (err) {
      showError(err);

      return false;
    }
  },
  async fetchMyAccountTransactions(
    accountId: string,
    params: Record<string, any> | undefined,
    exportCsv: boolean
  ) {
    try {
      const response = await get(
        ENDPOINTS.transactions.fetchMyAccountTransactions.replace(
          "{accountId}",
          accountId
        ),
        params,
        exportCsv ? "blob" : undefined,
        {
          Accept: exportCsv ? "text/csv" : "application/json",
        }
      );

      if (exportCsv) {
        const blob = response.data;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "transactions.csv";
        link.click();
      }

      return exportCsv ? true : response.data;
    } catch (err) {
      showError(err);

      return false;
    }
  },

  async fetchMyAccountTransactionsCsv(
    accountId: string,
    params: any = {},
    account
  ) {
    try {
      const response = await get(
        ENDPOINTS.transactions.fetchMyAccountTransactionsCsv.replace(
          "{accountId}",
          accountId
        ),
        params,
        "blob",
        {
          Accept: "text/csv",
        }
      );

      const blob = response.data;
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = account
        ? `transactions-${account?.name}-${
            account?.number
          }-${formatDateToMMddyyyySeparatorSlash(new Date().toISOString())}.csv`
        : "transactions.csv";
      link.click();

      return true;
    } catch (err) {
      showError(err);

      return false;
    }
  },

  async fetchUserAccountTransactionsCsv(accountId: string) {
    try {
      const response = await get(
        ENDPOINTS.transactions.fetchUserAccountTransactionsCsv.replace(
          "{accountId}",
          accountId
        ),
        {},
        "blob",
        {
          Accept: "text/csv",
        }
      );

      const blob = response.data;
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "transactions-user.csv";
      link.click();

      return true;
    } catch (err) {
      showError(err);

      return false;
    }
  },
  async fetchUserItems(
    userId: string,
    direction: string,
    cb: { (data: any): void; (data: any): void; (arg0: any): void }
  ) {
    try {
      const { data } = await get(
        ENDPOINTS.transactions.itemsByUserId.replace("{userId}", userId),
        {
          direction,
        }
      );

      if (cb) {
        cb(data.data);
      }
    } catch (err) {
      transactions.loading = false;
      showError(err);
    }
  },
  async deleteOperation(operationId: string) {
    try {
      await del(
        ENDPOINTS.transactions.deleteOperation.replace(
          "{operationId}",
          operationId
        )
      );

      transactions.items = transactions.items.filter((item) => {
        return item.operationId !== operationId;
      });

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async generateStatement(accountId: string, payload) {
    try {
      await post(
        ENDPOINTS.transactions.generateStatement.replace(
          "{accountId}",
          accountId
        ),
        payload
      );

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
});

export default transactions;
