import { Container, Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { view } from "@risingstack/react-easy-state";
import React from "react";
import { useLocation } from "react-router-dom";
import cryptoAdmin from "../../../../../../store/modules/cryptoAdmin";
import transactionDetails from "../../../../../../store/modules/transactionDetails";
import CryptoTransactionDetail from "../../../Crypto/Transactions/components/Transaction";
import ActionsWithTransaction from "./ActionsWithTransaction";
import TransactionMoreDetails from "./TransactionMoreDetails";
import TransactionSeeUser from "./TransactionSeeUser";

const useStyles = makeStyles({
  drawer: {
    width: "100%",
  },
  drawerPaper: {
    fontSize: "1.125rem",
    width: "40rem",

    "@media (max-width: 640px)": {
      width: "100%",
    },
  },
});

const TransactionDetail = view(() => {
  const classes = useStyles();
  const location = useLocation();

  const { openDetail: open, clearStore } = transactionDetails;

  return open ? (
    <>
      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
        open={open}
        onClose={clearStore}
      >
        <Container maxWidth="md">
          <div className="h-5" />

          <TransactionSeeUser />

          <TransactionMoreDetails />

          <ActionsWithTransaction />
        </Container>
      </Drawer>

      {cryptoAdmin.openDetail &&
        !location.pathname.includes("/app/admin/crypto") && (
          <CryptoTransactionDetail />
        )}
    </>
  ) : null;
});

export default TransactionDetail;
