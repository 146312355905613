import { HubConnection } from "@microsoft/signalr";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useRef, useState } from "react";
import ITask from "../interfaces/task";
import { createHub } from "./tools/createHub";

const m1 = "Tasks: Connection not established, we are trying to reconnect";
const m2 = "Tasks: Connection established";

export const useQuerySocketTasks = ({
  onUpdated,
}: {
  onUpdated?: (data: ITask) => void;
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const snackbar = useRef<any>();

  const hubConnection = useRef<HubConnection | undefined>();

  const isRunning = useRef<boolean>(false);

  const [running, setRunning] = useState(false);

  const success = useCallback(() => {
    snackbar.current = enqueueSnackbar(m2, {
      variant: "success",
      anchorOrigin: { horizontal: "right", vertical: "bottom" },
      autoHideDuration: 5000,
    });
  }, [enqueueSnackbar]);

  const warning = useCallback(() => {
    snackbar.current = enqueueSnackbar(m1, {
      variant: "warning",
      anchorOrigin: { horizontal: "right", vertical: "bottom" },
      autoHideDuration: null,
    });
  }, [enqueueSnackbar]);

  const onTaskChanged = useCallback(() => {
    if (hubConnection.current && onUpdated) {
      hubConnection.current.off("onTaskChanged");
      hubConnection.current.on("onTaskChanged", (data) => onUpdated(data));
    }
  }, [onUpdated]);

  useEffect(() => {
    if (running) {
      onTaskChanged();
    }
  }, [onTaskChanged, running]);

  const start = useCallback(async () => {
    try {
      hubConnection.current = await createHub(
        `${import.meta.env.VITE_WSS_API}/hubNotification`
      );

      hubConnection.current.onclose(() => {
        if (snackbar.current) {
          closeSnackbar(snackbar.current);
        }
      });

      hubConnection.current.onreconnecting(() => {
        if (snackbar.current) {
          closeSnackbar(snackbar.current);
        }

        warning();
      });

      hubConnection.current.onreconnected(() => {
        if (snackbar.current) {
          closeSnackbar(snackbar.current);
        }

        success();
      });

      await hubConnection.current.start();

      setRunning(true);

      if (snackbar.current) {
        closeSnackbar(snackbar.current);

        success();
      }
    } catch (err) {
      if (!snackbar.current) {
        warning();
      }

      setTimeout(() => start(), 2000);
    }
  }, [closeSnackbar, success, warning]);

  useEffect(() => {
    if (!isRunning.current) {
      isRunning.current = true;

      start();
    }
  }, [start]);

  useEffect(() => {
    return () => {
      hubConnection.current?.stop();
    };
  }, []);

  return null;
};
