import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CloseOutlined } from "@material-ui/icons";
import { view } from "@risingstack/react-easy-state";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { apiUrls } from "../../../../../../apiUrls";
import {
  formatDateToMmDdYyyy,
  formatToCurrencyString,
} from "../../../../../../helpers";
import { checkIsCryptoCurrency } from "../../../../../../helpers/formatToCurrencyString";
import { makeLinkToClientAccounts } from "../../../../../../helpers/makeLinkToAccountsClient";
import { get } from "../../../../../../store/api";
import auth from "../../../../../../store/modules/auth";
import cryptoAdmin from "../../../../../../store/modules/cryptoAdmin";
import transactionDetails from "../../../../../../store/modules/transactionDetails";
import { FormatAmount } from "../../../../../System";
import CopyAccountNumber from "../../../../../System/CopyAccountNumber";
import RelatedKey from "./RelatedKey";
import CryptoOperations from "./Tables/CryptoOperations";
import CryptoTransactions from "./Tables/CryptoTransactions";
import Related from "./Tables/Related";
import RelatedFx from "./Tables/RelatedFx";
import RelatedVault from "./Tables/RelatedVault";

const useStyles = makeStyles((theme) => {
  return {
    close: {
      position: "absolute",
      right: 15,
      top: 0,

      [theme.breakpoints.down("sm")]: {
        right: 15,
        top: 0,
      },
      [theme.breakpoints.down("xs")]: {
        right: 15,
        top: 0,
      },
    },
  };
});

const TransactionMoreDetails = view(() => {
  const classes = useStyles();
  const location = useLocation();

  const {
    moreDetailsForAdmin: transaction,
    clearStore,
    adminCosts,
    adminFees,
    adminPnL,
    detailSeeUser,
  } = transactionDetails;

  const mapFees = adminFees?.map((item) => {
    const { currencyId } = item;

    const isCrypto = checkIsCryptoCurrency(currencyId);

    return isCrypto ? (
      <div className="mr-1">
        <FormatAmount amount={item.value} currency={item.currencyId} />
      </div>
    ) : (
      <div className="mr-1">
        {formatToCurrencyString(item.value, item.currencyId)}
      </div>
    );
  });

  const mapCosts = adminCosts?.map((item) => {
    const { currencyId } = item;

    const isCrypto = checkIsCryptoCurrency(currencyId);

    return isCrypto ? (
      <div className="mr-1">
        <FormatAmount amount={item.value} currency={item.currencyId} />
      </div>
    ) : (
      <div className="mr-1">
        {formatToCurrencyString(item.value, item.currencyId)}
      </div>
    );
  });

  const mapPnl = adminPnL?.map((item) => {
    const { currencyId } = item;

    const isCrypto = checkIsCryptoCurrency(currencyId);

    return isCrypto ? (
      <div className="mr-1">
        <FormatAmount amount={item.value} currency={item.currencyId} />
      </div>
    ) : (
      <div className="mr-1">
        {formatToCurrencyString(item.value, item.currencyId)}
      </div>
    );
  });

  const onClick = async () => {
    if (location.pathname.includes("/app/admin/crypto")) {
      return;
    }

    const result = await cryptoAdmin.fetchCryptoTransactionDetail(
      transaction?.adminSfoxId
    );

    if (result) {
      cryptoAdmin.openDetail = true;
    }
  };

  const [fileObjects, setFileObjects] = useState([]);

  const fetchDocs = useCallback(async () => {
    if (!transaction?.adminTransactionId) {
      return;
    }
    try {
      const { data } = await get(
        `${apiUrls.VITE_API}/v2/admin/bot-check/files/${transaction?.adminTransactionId}`
      );
      console.log(data);

      setFileObjects(data);
    } catch (err) {
      console.log(err);
    }
  }, [transaction?.adminTransactionId]);

  useEffect(() => {
    fetchDocs();
  }, [fetchDocs]);

  return (
    <>
      <IconButton
        className={classes.close}
        onClick={clearStore}
        style={{ padding: 10 }}
      >
        <CloseOutlined />
      </IconButton>

      <div className="h-8" />

      <div className="mb-4">
        <h2 className="m-0 p-0">More details, Admin only</h2>
      </div>

      <div className="flex justify-between items-center text-base flex-1 py-1 px-2 border-b border-solid border-gray-200 ">
        <div className="flex-1 pr-4">
          <span>Account Owner</span>
        </div>
        <div className="flex-1 flex justify-end font-bold text-right break-words w-full">
          {!!auth?.profile &&
          (auth?.profile?.isAnalyst ||
            !transaction?.adminAccountOwnerUserId) ? (
            transaction?.adminAccountOwner
          ) : (
            <Link
              to={makeLinkToClientAccounts(
                transaction?.adminAccountOwnerUserId,
                false,
                true
              )}
            >
              {transaction?.adminAccountOwner}
            </Link>
          )}
          <CopyAccountNumber number={transaction?.adminAccountOwner} />
        </div>
      </div>

      <div className="flex justify-between items-center text-base flex-1 py-1 px-2 border-b border-solid border-gray-200 ">
        <div className="flex-1 pr-4">Account ID</div>
        <div className="flex-1 flex justify-end font-bold text-right break-words w-full">
          {!!auth?.profile && auth?.profile?.isAnalyst ? (
            transaction?.adminAccountId
          ) : (
            <Link to={`/app/admin/accounts/${transaction?.adminAccountId}`}>
              {transaction?.adminAccountId}
            </Link>
          )}

          <CopyAccountNumber number={transaction?.adminAccountId} />
        </div>
      </div>

      {transaction?.adminKey && <RelatedKey transaction={transaction} />}

      <div className="flex justify-between items-center text-base flex-1 py-1 px-2 border-b border-solid border-gray-200 ">
        <div className="flex-1 pr-4">Pending</div>
        <div className="flex-1 flex justify-end font-bold text-right break-words w-full">
          {detailSeeUser?.isPending ? "Yes" : "No"}
        </div>
      </div>

      {transaction?.adminExternalId && (
        <div className="flex justify-between items-center text-base flex-1 py-1 px-2 border-b border-solid border-gray-200 ">
          <div className="flex-1 pr-4">External ID</div>
          <div className="flex-1 flex justify-end font-bold text-right break-words w-full">
            {transaction?.adminExternalId}
          </div>
        </div>
      )}

      {transaction?.adminTransactiveId && (
        <div className="flex justify-between items-center text-base flex-1 py-1 px-2 border-b border-solid border-gray-200 ">
          <div className="flex-1 pr-4">ID</div>
          <div className="flex-1 flex justify-end font-bold text-right break-words w-full">
            {transaction?.adminTransactiveId}
          </div>
        </div>
      )}

      {transaction?.adminSfoxId && (
        <div className="flex justify-between items-center text-base flex-1 py-1 px-2 border-b border-solid border-gray-200 ">
          <div className="flex-1 pr-4">Sfox ID</div>
          <div className="flex-1 flex justify-end font-bold text-right break-words w-full">
            <a onClick={onClick}>{transaction?.adminSfoxId}</a>
          </div>
        </div>
      )}

      {transaction?.dealId && (
        <div className="flex justify-between items-center text-base flex-1 py-1 px-2 border-b border-solid border-gray-200 ">
          <div className="flex-1 pr-4">Deal ID</div>
          <div className="flex-1 flex justify-end font-bold text-right break-words w-full">
            {transaction?.dealId}
          </div>
        </div>
      )}

      {transaction?.adminDescription && (
        <div className="flex justify-between items-center text-base flex-1 py-1 px-2 border-b border-solid border-gray-200 ">
          <div className="flex-1 pr-4">Description</div>

          <div className="flex justify-end font-bold text-right break-words w-full">
            {transaction?.adminDescription}
          </div>
        </div>
      )}

      {transaction?.adminTransactionId && (
        <div className="flex justify-between items-center text-base flex-1 py-1 px-2 border-b border-solid border-gray-200 ">
          <div className="flex-1 pr-4">Transaction ID</div>
          <div className="flex-1 flex justify-end font-bold text-right break-words w-full">
            {!!auth?.profile && !auth?.profile?.isSupportAgent ? (
              <Link
                to={`/app/tasks/details/${transaction?.adminTransactionId}`}
              >
                {transaction?.adminTransactionId}
              </Link>
            ) : (
              <span>{transaction?.adminTransactionId}</span>
            )}{" "}
            <CopyAccountNumber number={transaction?.adminTransactionId} />
          </div>
        </div>
      )}

      {transaction?.adminFeeRuleId && (
        <div className="flex justify-between items-center text-base flex-1 py-1 px-2 border-b border-solid border-gray-200 ">
          <div className="flex-1 pr-4">Fee Rule ID</div>
          <div className="flex-1 flex justify-end font-bold text-right break-words w-full">
            {!!auth?.profile &&
            (auth?.profile?.isAnalyst || auth?.profile?.isSupportAgent) ? (
              transaction?.adminFeeRuleId
            ) : (
              <Link to={`/app/admin/fees/rules/${transaction?.adminFeeRuleId}`}>
                {transaction?.adminFeeRuleId}
              </Link>
            )}
          </div>
        </div>
      )}

      {Boolean(mapFees && mapFees?.length > 0) && (
        <div className="flex justify-between items-center text-base flex-1 py-1 px-2 border-b border-solid border-gray-200 ">
          <div className="flex-1 pr-4">Fees</div>
          <span className="flex-1 flex justify-end font-bold text-right break-words w-full">
            {mapFees}
          </span>
        </div>
      )}

      {mapCosts && mapCosts?.length > 0 && (
        <div className="flex justify-between items-center text-base flex-1 py-1 px-2 border-b border-solid border-gray-200 ">
          <div className="flex-1 pr-4">Costs</div>
          <span className="flex-1 flex justify-end font-bold text-right break-words w-full">
            {mapCosts}
          </span>
        </div>
      )}

      {mapPnl && mapPnl?.length > 0 && (
        <div className="flex justify-between items-center text-base flex-1 py-1 px-2 border-b border-solid border-gray-200 ">
          <div className="flex-1 pr-4">P&L</div>
          <span className="flex-1 flex justify-end font-bold text-right break-words w-full">
            {mapPnl}
          </span>
        </div>
      )}

      {transaction?.adminStructureId && (
        <div className="flex justify-between items-center text-base flex-1 py-1 px-2 border-b border-solid border-gray-200 ">
          <div className="flex-1 pr-4">Structure ID: </div>

          <div className="flex-1 flex justify-end font-bold text-right break-words w-full">
            <div className="flex items-start justify-center">
              {!!auth?.profile && !auth?.profile?.isSupportAgent ? (
                <Link
                  to={`/app/admin/fees/structures/${transaction?.adminStructureId}/formulas`}
                >
                  {transaction?.adminStructureId}
                </Link>
              ) : (
                <span>{transaction?.adminStructureId}</span>
              )}
            </div>
          </div>
        </div>
      )}

      {fileObjects.length > 0 && (
        <div className="mt-8">
          <h2 className="m-4 mx-0">AML Reports</h2>
          <div className="grid grid-cols-1 gap-4">
            {fileObjects.map((item) => {
              return (
                <div
                  onClick={async () => {
                    try {
                      const { data, headers } = await get(
                        `${import.meta.env.VITE_API}/v2/files/${item.fileId}`,
                        undefined,
                        "arraybuffer"
                      );

                      const contentType = headers["content-type"];

                      const file = new Blob([data], { type: contentType });
                      const fileURL = URL.createObjectURL(file);

                      const link = document.createElement("a");
                      link.href = fileURL;
                      link.download = "Source of Wealth Declaration.pdf";
                      link.click();
                      link.remove();
                    } catch (err) {
                      console.log(err);
                    }
                  }}
                  key={item.fileId}
                  className="w-full cursor-pointer hover:text-main font-medium"
                >
                  <span>Download - {formatDateToMmDdYyyy(item.createTs)}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}

      <Related />

      <RelatedFx />

      <RelatedVault />

      {!!auth?.profile &&
        (auth?.profile?.isAdmin ||
          auth?.profile?.isAnalyst ||
          (auth?.profile?.roles && auth?.profile?.roles?.includes("CFO"))) &&
        transaction?.adminTransactionId && (
          <>
            <CryptoTransactions
              transactionId={transaction?.adminTransactionId}
            />

            <CryptoOperations transactionId={transaction?.adminTransactionId} />
          </>
        )}
    </>
  );
});

export default TransactionMoreDetails;
