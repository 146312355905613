import React from "react";
import { useWatch } from "react-hook-form";
import { getTaskTypeBooleans } from "../../../../../../helpers/task";
import MyCheckboxLabel from "../../../../../System/FormComponents/MyCheckboxLabel";
import ShowAndHide from "../../../../../System/FormComponents/ShowAndHide";
import SepaBibDetails from "./SepaBibDetails";

const SepaBib = ({ data, task, ...form }) => {
  const useSepaBibPayment = useWatch({
    control: form?.control,
    name: "useSepaBibPayment",
  });

  const taskTypeBooleans = getTaskTypeBooleans(task);

  const sepa =
    useSepaBibPayment && data?.sepaBibPayment && taskTypeBooleans.isSEPA;

  return (
    <ShowAndHide show={Boolean(data?.sepaBibPayment)}>
      <MyCheckboxLabel
        control={form.control}
        name="useSepaBibPayment"
        label="I want to confirm using SEPA"
      />

      {sepa ? (
        <div>
          <b className="text-main text-lg">
            Auto wire send: Will execute auto sepa payment
          </b>
        </div>
      ) : null}

      <div className="flex flex-col">
        <div className="flex flex-1 w-full justify-between">
          {useSepaBibPayment && (
            <div className="flex flex-col">
              <b className="text-orange-600 text-lg">Sepa BIB Payment</b>
            </div>
          )}
        </div>

        {useSepaBibPayment && data?.sepaBibPayment && (
          <SepaBibDetails sepa={data?.sepaBibPayment} />
        )}
      </div>
    </ShowAndHide>
  );
};

export default SepaBib;
