import { store } from "@risingstack/react-easy-state";
import ENDPOINTS from "../../endpoints";
import { makeParams } from "../../helpers/makeParams";
import { get, showError } from "../api";

interface IStocks {
  loading: boolean;
  items: any[];
  pages: number;

  [key: string]: any;
}

const stocks = store<IStocks>({
  loading: false,
  items: [],
  pages: 1,

  async fetchStocksByAccountId(accountId: string, customParams: any) {
    stocks.loading = true;

    const params = makeParams(stocks, customParams);

    try {
      const res = await get(
        ENDPOINTS.stocks.fetchStocksByAccountId.replace(
          "{accountId}",
          accountId
        ),
        { ...params, sortBy: "symbol", sortDirection: "asc" },
        undefined,
        {
          Accept: "application/json",
        }
      );
      stocks.items = res?.data || [];
      /* stocks.pages = res?.data?.pages || 1; */
      stocks.loading = false;
    } catch (err) {
      stocks.loading = false;
      showError(err);
    }
  },
  async searchSymbols(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(ENDPOINTS.stocks.searchSymbols, params);

      return data;
    } catch (err) {
      return false;
    }
  },
});

export default stocks;
