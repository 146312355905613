import { clsx } from "clsx";
import React, { memo } from "react";
import { formatToCurrencyString } from "../../../../../helpers";
import IAccount from "../../../../../interfaces/account";

export default memo(
  ({
    account,
    classNameInfo,
  }: {
    account?: IAccount;
    classNameInfo?: string;
  }) => {
    if (!account?.currencyId) {
      return null;
    }

    return (
      <div className="flex py-2">
        <img
          src={`/currencies/${account.currencyId.toLowerCase()}.svg`}
          className="w-14 mr-4"
        />

        <div
          className={clsx(
            "flex flex-col",
            classNameInfo ? classNameInfo : "text-lg"
          )}
        >
          <b>
            {account.currencyId} {account.shortNumber}
          </b>
          <span>
            Available balance:{" "}
            <b>
              {formatToCurrencyString(
                account.availableBalance,
                account.currencyId
              )}
            </b>
          </span>
        </div>
      </div>
    );
  }
);
