import { lazy } from "react";

const Main = lazy(() => import("../../components/Roles/Client/Tasks"));

const Table = lazy(
  () => import("../../components/Roles/Client/Tasks/v2/Tasks")
);

const Task = lazy(() => import("../../components/Roles/Client/Tasks/Task"));

export const Tasks = [
  {
    path: "/app/task-manager/:taskId/details",
    component: Task,
  },

  {
    path: "/app/task-manager",
    component: Main,

    routes: [
      {
        path: "/app/task-manager",
        component: Table,
      },
    ],
  },
];
