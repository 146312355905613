import { FormHelperText } from "@material-ui/core";
import { view } from "@risingstack/react-easy-state";
import numbro from "numbro";
import React, { useState } from "react";
import { bankName } from "../../../../../helpers/config";
import accounts from "../../../../../store/modules/accounts";
import ui from "../../../../../store/modules/ui";
import Email from "../../../../Auth/components/Email";
import { Alert, FormActions } from "../../../../System";
import MyRadioButtons from "../../../../System/FormComponents/MyRadioButtons";
import MyTextField from "../../../../System/FormComponents/MyTextField";
import { useEnter } from "./../../../../../hooks/useEnter";
import Amount from "./components/Amount";
import IStepProps, {
  IFormClientDeposit,
  IPayloadForConfirm,
} from "./interfaces/stepProps";

const Step1 = view((props: IStepProps) => {
  const [depositHelp, setDepositHelp] = useState(false);

  const { account, form, setOpen, step } = props;

  const next = async (values: IFormClientDeposit) => {
    ui.errorOperation = "";
    ui.loadingOperation = true;

    const payload: IPayloadForConfirm = {
      ...values,
      amount: numbro.unformat(values.amount as string),
      toAccountId: account?.accountId || "",
      currency: account?.currencyId || "",
    };

    payload.parentTaskId = account?.parentTaskId;

    const result = await accounts.previewDeposit(payload);

    props.setPayloadForConfirm(payload);

    if (result && result.status) {
      /* Something went wrong */
      ui.errorOperation = result.status;
      props.setData(result);
      props.setStep(2);
    } else if (result && result?.request && !result.status) {
      /* Everything is perfect */
      props.setData(result);
      props.setStep(2);
    }

    ui.loadingOperation = false;
  };

  let radioItems = [
    {
      label: "SWIFT",
      value: "SWIFT",
    },
    {
      label: "SEPA",
      value: "SEPA",
    },
  ];

  if (account?.currencyId !== "EUR" || !props.canSepa) {
    radioItems = radioItems.filter((item) => item.value !== "SEPA");
  }

  useEnter(step === 1 && form.handleSubmit(next));

  return (
    <>
      <div className="w-full mb-8">
        <div className="w-full mb-4 text-base">
          <p>
            Please provide the required information regarding the deposit that
            you intend to deliver to your account below. Based on the
            information you provide, we will email to you the wire details of
            the {bankName} correspondent account at the financial institution,
            where your deposit will be received.
          </p>

          <Amount form={form} account={account} />

          <div className="h-4" />

          <MyTextField
            id="nameOfBank"
            control={form?.control}
            name="nameOfBank"
            label="Sending Institution Name, Country"
            rules={{ required: true }}
            required
            fullWidth
          />

          {form?.formState?.errors?.nameOfBank?.type === "required" && (
            <FormHelperText id="errorNameOfBank" error>
              Field is required
            </FormHelperText>
          )}

          <div className="h-4" />

          <MyTextField
            id="nameOnAccount"
            control={form?.control}
            name="nameOnAccount"
            label="Name of sending account"
            rules={{ required: true }}
            required
            fullWidth
          />

          {form?.formState?.errors?.nameOnAccount?.type === "required" && (
            <FormHelperText id="errorNameOnAccount" error>
              Field is required
            </FormHelperText>
          )}

          <div className="h-4" />

          <MyTextField
            id="accountNumber"
            control={form?.control}
            name="accountNumber"
            label="Sending IBAN (or Account Number)"
            fullWidth
          />

          <div className="h-4" />

          <Email
            label="Email where you wish to receive deposit instructions"
            control={form.control}
            formState={form.formState}
          />

          <div className="h-4" />
        </div>

        <MyRadioButtons
          control={form?.control}
          name="swiftOrSepa"
          label="Deposit type"
          items={radioItems}
          row
        />

        <FormActions
          loading={ui.loadingOperation}
          disabledNextButton={ui.loadingOperation}
          onClickClose={() => {
            setOpen(false);
          }}
          onClickNext={form.handleSubmit(next)}
          showCloseButton
          showNextButton
        />

        <div className="w-full flex justify-center">
          <a
            className="mt-4 cursor-pointer text-main font-bold"
            onClick={() => {
              setDepositHelp(true);
            }}
          >
            Incoming bank transfer particulars
          </a>
        </div>

        <Alert
          open={depositHelp}
          setOpen={setDepositHelp}
          size="sm"
          content={
            <>
              <h1 className="text-center m-0 text-2xl text-gray-600">
                <b>Incoming bank transfer particulars</b>
              </h1>
              <p className="text-base">
                Your account numbers are indicated on your account management
                system landing page. When you are ready to receive a transfer
                into one of these accounts, please use the {"<DEPOSIT>"} button
                located in the middle of the account management page, and
                complete the Deposit Notification form.
              </p>

              <p className="text-base">
                We will then reply with the particulars of one of our several
                correspondent banks or electronic payment systems, which will be
                optimized to receive the specific transfer with the source,
                currency, amount and transfer system (SWIFT/SEPA) indicated in
                the deposit notification.
              </p>

              <p className="text-base">
                For every new payer you need to complete a new Deposit
                Notification. For your benefit we tailor the payment delivery
                instructions to your specific needs, which helps avoid problems
                with the correspondent banks&apos; compliance when your transfer
                does not fit into the range of transactions that they allow.
              </p>

              <FormActions
                showCloseButton
                closeButtonLabel="Okay"
                onClickClose={() => {
                  setDepositHelp(false);
                }}
              />
            </>
          }
        />
      </div>
    </>
  );
});

export default Step1;
