import React, { memo } from "react";
import MigomTable3 from "../../../../../System/MigomTable3";

export default memo(
  ({
    columns,
    setParams,
    params,
    queryData,
    rowClassName,
    onRowClick,
    id,
    totalCount,
    totalText,
  }: any) => {
    return (
      <MigomTable3
        id={id}
        columns={columns}
        rows={queryData?.data?.data}
        loading={queryData.isLoading || queryData.isFetching}
        defaultSort={{
          sortBy: params.sortBy,
          sortDirection: params.sortDirection,
        }}
        onSort={({ sortBy, sortDirection }) => {
          setParams((prevState) => ({
            ...prevState,
            sortBy,
            sortDirection,
          }));
        }}
        page={params.page}
        perPage={params.perPage}
        onChangePage={(page) =>
          setParams((prevState) => ({ ...prevState, page }))
        }
        onChangePerPage={(perPage) =>
          setParams((prevState) => ({ ...prevState, perPage, page: 1 }))
        }
        pages={queryData?.data?.pages}
        rowClassName={rowClassName}
        onRowClick={onRowClick}
        totalCount={totalCount}
        totalText={totalText}
      />
    );
  }
);
