import { store } from "@risingstack/react-easy-state";
import ENDPOINTS from "../../endpoints";
import { get, post, showError } from "../api";

const coins = store({
  goldData: {
    amount: "",
    currency: "",
    account: undefined,
  },

  resetGoldData() {
    coins.goldData = {
      amount: "",
      currency: "",
      account: undefined,
    };
  },

  saveLocalGoldData(newData) {
    const data = { ...coins.goldData, ...newData };

    localStorage.setItem("goldData", JSON.stringify(data));

    coins.goldData = { ...data };
  },

  getLocalGoldData() {
    try {
      const stringData = localStorage.getItem("goldData");

      if (stringData) {
        const json = JSON.parse(stringData);

        coins.goldData = { ...json };

        return json;
      }
    } catch (err) {
      console.log(err);
    }
  },

  async fetchCurrentCost() {
    try {
      const { data } = await get(ENDPOINTS.coins.fetchCurrentCost);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async changeCurrentCost(payload: Record<string, any>) {
    try {
      await post(ENDPOINTS.coins.changeCurrentCost, payload);

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchRateByCurrency(currencyId: string) {
    try {
      const { data } = await get(
        ENDPOINTS.coins.fetchRateByCurrency.replace("{currencyId}", currencyId)
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
});

export default coins;
