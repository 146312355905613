import { Checkbox, FormControlLabel } from "@material-ui/core";
import React, { memo } from "react";

export default memo(({ setParams, params }: any) => {
  return (
    <div>
      <FormControlLabel
        label="Show neg int"
        control={
          <Checkbox
            checked={params.showNegInt || false}
            onChange={(e) =>
              setParams((prevState) => ({
                ...prevState,
                page: 1,
                showNegInt: e.target.checked,
              }))
            }
          />
        }
      />
    </div>
  );
});
