import { memo } from "react";
import { simpleFormatAmount } from "../../../../../../helpers/simpleFormatAmount";

export default memo(({ rowData, params, filters }: any) => {
  const data = rowData?.currencies?.total;

  if (!data) {
    return "-";
  }

  const currency = params.totalCurrency;

  if (filters.dataType === "Sum") {
    return simpleFormatAmount(data?.total, currency) || "-";
  }

  if (filters.dataType === "Sum ||") {
    return (
      simpleFormatAmount(
        Math.abs(data?.profit) + Math.abs(data?.loss),
        currency
      ) || "-"
    );
  }

  if (filters.dataType === "+/-") {
    return (
      (data?.profit ? simpleFormatAmount(data?.profit, currency) : "-") +
      "/" +
      (data?.loss ? simpleFormatAmount(data?.loss, currency) : "-")
    );
  }

  if (filters.dataType === "Count") {
    return data?.count;
  }

  if (filters.dataType === "Average") {
    return simpleFormatAmount(data?.average, currency) || "-";
  }

  return "-";
});
