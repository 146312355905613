import { MenuItem, TextField } from "@material-ui/core";
import { view } from "@risingstack/react-easy-state";
import React from "react";
import { allCurrencies } from "../../../../../../../helpers";
import templates from "../../../../../../../store/modules/templates";

const onChange = (event) => {
  const { value } = event.target;
  templates.params = {
    ...templates.params,
    currency: value === "All" ? "" : value,
    pageIndex: 0,
  };
  templates.fetchItems();
};

const Currency = view(() => {
  const menuItemMap = allCurrencies.map((item) => {
    return (
      <MenuItem key={item.value} value={item.value}>
        {item.value}
      </MenuItem>
    );
  });
  return (
    <TextField
      InputLabelProps={{
        shrink: true,
      }}
      label="Currency"
      value={templates.params.currency || "All"}
      onChange={onChange}
      select
      fullWidth
    >
      <MenuItem key="All" value="All">
        All
      </MenuItem>
      {menuItemMap}
    </TextField>
  );
});

export default Currency;
