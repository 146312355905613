import IStructure from "../interfaces/structure";

export const customSortArray = (
  a: { name: string } | IStructure,
  b: { name: string } | IStructure
) => {
  const labelA = a?.name?.toLowerCase() || "",
    labelB = b?.name?.toLowerCase() || "";
  if (labelA < labelB) {
    return -1;
  }
  if (labelA > labelB) {
    return 1;
  }
  return 0;
};

export const customSortArrayTitles = (
  a: { titleId: string },
  b: { titleId: string }
) => {
  const labelA = a?.titleId?.toLowerCase() || "",
    labelB = b?.titleId?.toLowerCase() || "";
  if (labelA < labelB) {
    return -1;
  }
  if (labelA > labelB) {
    return 1;
  }
  return 0;
};

export const customSortArrayLabel = (
  a: { label?: string },
  b: { label?: string }
) => {
  const labelA = a?.label?.toLowerCase() || "",
    labelB = b?.label?.toLowerCase() || "";
  if (labelA < labelB) {
    return -1;
  }
  if (labelA > labelB) {
    return 1;
  }
  return 0;
};

export const customSortArrayValue = (
  a: { value?: string },
  b: { value?: string }
) => {
  const labelA = a?.value?.toLowerCase() || "",
    labelB = b?.value?.toLowerCase() || "";
  if (labelA < labelB) {
    return -1;
  }
  if (labelA > labelB) {
    return 1;
  }
  return 0;
};

export const customSortArrayCurrency = (
  a: { currencyId?: string },
  b: { currencyId?: string }
) => {
  const labelA = a?.currencyId?.toLowerCase() || "",
    labelB = b?.currencyId?.toLowerCase() || "";
  if (labelA < labelB) {
    return -1;
  }
  if (labelA > labelB) {
    return 1;
  }
  return 0;
};

export const customSortArrayString = (a: string, b: string) => {
  const labelA = a?.toLowerCase() || "",
    labelB = b?.toLowerCase() || "";
  if (labelA < labelB) {
    return -1;
  }
  if (labelA > labelB) {
    return 1;
  }
  return 0;
};

export const customSortArrayEventTypeGroup = (
  a: { eventTypeGroupId?: string },
  b: { eventTypeGroupId?: string }
) => {
  const labelA = a?.eventTypeGroupId?.toLowerCase() || "",
    labelB = b?.eventTypeGroupId?.toLowerCase() || "";
  if (labelA < labelB) {
    return -1;
  }
  if (labelA > labelB) {
    return 1;
  }
  return 0;
};
