import { view } from "@risingstack/react-easy-state";
import numbro from "numbro";
import React, { useEffect, useRef } from "react";
import {
  formatDateToMMddyyyyHHmm,
  formatToCurrencyString,
} from "../../../../../../helpers";
import common from "../../../../../../store/modules/common";
import Box from "../components/Box";
import FieldBox from "../components/FieldBox";
import { getColorForState } from "../tools/getColorForState";

const FieldCountry = view(({ countryId }) => {
  const refInit = useRef<any>(false);

  const { countries } = common;

  useEffect(() => {
    if (!refInit.current && !countries?.length) {
      refInit.current = true;

      common.fetchCountries();
    }
  }, [countries]);

  const findCountry = common.countries.find((item) => countryId === item?.id);

  return <FieldBox label="Country:" value={findCountry?.name} />;
});

const Gold = ({ task }) => {
  let body;

  try {
    body = task?.body ? JSON.parse(task?.body) : {};
  } catch {
    body = task?.body;
  }

  const colorState = getColorForState(task);

  return (
    <>
      <Box header="">
        <FieldBox
          label="Task state"
          value={task?.taskStateName}
          style={{ color: colorState }}
        />

        <>
          {task?.taskStateId === "Rejected" && (
            <FieldBox label="Reason" value={task?.internalSubject} />
          )}
        </>

        <FieldBox label="Task ID" value={task?.taskId} showCopy />

        <FieldBox label="Date:" value={formatDateToMMddyyyyHHmm(task?.ts)} />

        <FieldBox
          label="Amount:"
          value={formatToCurrencyString(
            numbro.unformat(body?.Amount || 0),
            task?.currencyId
          )}
        />

        <FieldBox
          label="Currency:"
          value={
            <div className="flex">
              <img
                src={`/currencies/${task?.currencyId?.toLowerCase()}.svg`}
                className="w-6 min-w-6 mr-2"
              />
              <span>{task?.currencyId}</span>
            </div>
          }
        />

        <FieldBox label="Name:" value={body?.Name} />

        <FieldBox label="Address 1:" value={body?.Address?.Address1} />

        <FieldBox label="Address 2:" value={body?.Address?.Address2} />

        <FieldBox label="City:" value={body?.Address?.City} />

        <FieldBox
          label="State province:"
          value={body?.Address?.StateProvince}
        />

        <FieldBox label="Post code:" value={body?.Address?.PostCode} />

        <FieldCountry countryId={body?.Address?.CountryId} />

        <FieldBox
          label="Additional Shipping Instructions:"
          value={body?.AdditionalShippingInstructions}
        />
      </Box>
    </>
  );
};

export default Gold;
