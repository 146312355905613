import { FormHelperText } from "@material-ui/core";
import { view } from "@risingstack/react-easy-state";
import React, { useEffect } from "react";
import { formatToCurrencyString } from "../../../../../../helpers";
import { customSortArrayCurrency } from "../../../../../../helpers/customSortArray";
import auth from "../../../../../../store/modules/auth";
import MySimpleMenu from "../../../../../System/FormComponents/MySimpleMenu";
import { useGetAccounts } from "../hooks/useGetAccounts";

const ToAccount = view(({ form, account, toAccountId }): any => {
  const { options, isLoading } = useGetAccounts({
    account,
  });

  const makeOptionString = (a) => {
    return (
      <span>
        {a.accountTypeId} {a.currencyId} <b>{a.number}</b>,{" "}
        {formatToCurrencyString(a.availableBalance, a.currencyId)}
      </span>
    );
  };

  const { setValue } = form;

  useEffect(() => {
    if (auth.profile && auth.profile?.isAdmin && options?.length === 1) {
      setTimeout(() => setValue("toAccountId", options[0].accountId), 0);
    }

    if (auth.profile && auth.profile?.isOperator && toAccountId) {
      setTimeout(() => setValue("toAccountId", toAccountId), 0);
    }
  }, [setValue, options, toAccountId]);

  if (options?.length === 0 && !isLoading) {
    return (
      <p className="font-bold text-red-600 border-b-2 border-gray-200 py-1">
        {`This client doesn't have second account'`}
      </p>
    );
  }

  if (options?.length === 1 && !isLoading) {
    const a = options[0];
    return (
      <div className="flex flex-col mt-2 mb-2 border-b-2 border-gray-200 py-1">
        <span className="text-md">
          To account: {a.accountTypeId}, {a.currencyId} <b>{a.number}</b>
        </span>
        <span>
          Available balance:{" "}
          <b>{formatToCurrencyString(a.availableBalance, a.currencyId)}</b>
        </span>
      </div>
    );
  }

  return (
    !isLoading && (
      <div className="w-full">
        <MySimpleMenu
          {...form}
          name="toAccountId"
          rules={{ required: true }}
          fullWidth
          label={`Choose an account to receive funds*`}
          renderOption={makeOptionString}
          disabled={!!auth.profile && !auth.profile?.isAdmin}
          options={
            options
              ?.sort(customSortArrayCurrency)
              ?.map((item) => ({ ...item, value: item.accountId })) || []
          }
        />

        {form?.formState?.errors?.toAccountId?.type === "required" && (
          <FormHelperText error>Field is required</FormHelperText>
        )}
      </div>
    )
  );
});

export default ToAccount;
