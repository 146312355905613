import { TextField } from "@material-ui/core";
import React, { memo } from "react";
import { useController } from "react-hook-form";
import ErrorMessage from "./ErrorMessage";

export default memo(
  ({
    control,
    name,
    rules,
    disabled,
    label,
    fullWidth,
    placeholder,
    autoFocus,
    required,
  }: any) => {
    const controller = useController({
      name: name,
      control: control,
      rules: {
        required: required ? `Field '${label}' is required` : false,
        ...(rules || {}),
      },
    });

    console.log(controller.fieldState.isDirty, controller.fieldState.isTouched);

    return (
      <>
        <TextField
          name={controller?.field?.name}
          value={controller?.field?.value || ""}
          onBlur={controller?.field?.onBlur}
          onChange={controller?.field?.onChange}
          inputRef={controller?.field?.ref}
          required={required}
          autoFocus={autoFocus}
          disabled={disabled}
          placeholder={placeholder}
          autoCapitalize="none"
          InputLabelProps={{
            shrink: true,
          }}
          label={label}
          fullWidth={fullWidth}
        />

        <ErrorMessage controller={controller} />
      </>
    );
  }
);
