import { Icon } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { WarningRounded } from "@material-ui/icons";
import { clsx } from "clsx";
import React from "react";

const useStyles = makeStyles({
  successIcon: {
    color: red[600],
  },
});

const StatusError = ({ open, text, userId, className }: any) => {
  const classes = useStyles();

  const newText = userId
    ? text.replace(
        `<a href="/app/profile">increase</a>`,
        `<a href="/app/admin/clients/${userId}/detail">increase</a>`
      )
    : text;

  return (
    open && (
      <>
        {!userId && (
          <div
            className={clsx(
              "text-lg p-4 flex justify-center items-center font-bold text-red-500",
              className
            )}
          >
            <Icon
              component={WarningRounded}
              color="primary"
              classes={{ colorPrimary: classes.successIcon }}
              style={{ marginRight: ".7rem" }}
            />{" "}
            <span
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
          </div>
        )}

        {userId && (
          <div
            className={clsx(
              "text-lg p-4 flex justify-center items-center font-bold text-red-500",
              className
            )}
          >
            <Icon
              component={WarningRounded}
              color="primary"
              classes={{ colorPrimary: classes.successIcon }}
            />{" "}
            <span dangerouslySetInnerHTML={{ __html: newText }} />
          </div>
        )}
      </>
    )
  );
};

export default StatusError;
