import { lazy } from "react";

const HighBalanceOperator = lazy(
  () => import("../../components/Roles/Operator/HighBalance")
);

const HighBalanceOperatorDetail = lazy(
  () => import("../../components/Roles/Operator/DetailClient")
);
const HighBalanceOperatorDetailAccount = lazy(
  () => import("../../components/Roles/Operator/DetailAccount")
);

export const HighBalance = [
  {
    path: "/app/high-balance/detail/:accountId/account",
    component: HighBalanceOperatorDetailAccount,
  },
  {
    path: "/app/high-balance/detail/:userId",
    component: HighBalanceOperatorDetail,
  },

  {
    path: "/app/high-balance",
    component: HighBalanceOperator,
  },
];
