export const defaultParams = (
  params?: Record<string, any>
): Record<string, any> => ({
  pageIndex: 0,
  perPage: 20,
  search: undefined,
  sortBy: undefined,
  sortDirection: undefined,
  ...params,
});
