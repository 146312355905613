import { store } from "@risingstack/react-easy-state";
import IPayloadForConfirm from "../../components/Roles/Client/Pay/CryptoWithdraw/interfaces/payloadForConfirm";
import ENDPOINTS from "../../endpoints";
import IAccount from "../../interfaces/account";
import { get, post, showError } from "../api";

interface IAccounts {
  loading: boolean;
  items: IAccount[];
  account?: IAccount;
  inactiveCards: any[];
  chooseFilter: string;

  [key: string]: any;
}

const accounts = store<IAccounts>({
  loading: false,
  items: [],
  account: undefined,
  inactiveCards: [],
  chooseFilter: "allAccounts",

  async fetchAccounts() {
    try {
      accounts.loading = true;
      const { data } = await get(ENDPOINTS.accounts.items, {
        perPage: 999999,
      });

      if (data.data && data.data?.length > 0) {
        accounts.items = data?.data;
        accounts.loading = false;
        return data?.data;
      }
      accounts.loading = false;
    } catch (err) {
      showError(err);
      accounts.loading = false;
      return false;
    }
  },

  async fetchLastOperations() {
    try {
      const { data } = await get(ENDPOINTS.accounts.fetchLastOperations);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async validIban(iban: string) {
    try {
      await get(ENDPOINTS.accounts.validIban, { iban });

      return [true, ""];
    } catch (err: any) {
      return [false, err?.response?.data?.description];
    }
  },

  async validSwift(swift: string) {
    try {
      const { data } = await get(
        ENDPOINTS.accounts.validSwift.replace("{swiftCode}", swift)
      );

      return data;
    } catch (err) {
      return false;
    }
  },

  async fetchBankAddressBySwift(swift: string) {
    try {
      const { data } = await get(
        ENDPOINTS.accounts.fetchBankAddressBySwift.replace("{swiftCode}", swift)
      );

      if (data?.success) {
        return data?.data;
      }

      return false;
    } catch (err) {
      return false;
    }
  },

  async fetchBankAddressByIBAN(iban: string) {
    try {
      const { data } = await get(
        ENDPOINTS.accounts.fetchBankAddressByIBAN.replace("{iban}", iban)
      );

      if (data?.success) {
        return data?.data;
      }

      return false;
    } catch (err) {
      return false;
    }
  },
  async fetchAccount(accountId: string) {
    try {
      const { data } = await get(
        ENDPOINTS.accounts.item.replace("{accountId}", accountId)
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async loadByCard(payload: Record<string, any> | undefined) {
    try {
      await post(ENDPOINTS.accounts.loadByCard, payload, {
        "Content-Type": "multipart/form-data",
        Accept: true,
      });

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async previewLoadByCard(payload: Record<string, any> | undefined) {
    try {
      const { data } = await post(
        ENDPOINTS.accounts.previewLoadByCard,
        payload
      );
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async previewDeposit(payload: Record<string, any> | undefined) {
    try {
      const { data } = await post(ENDPOINTS.accounts.previewDeposit, payload);
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async previewCryptoWire(payload: IPayloadForConfirm | undefined) {
    try {
      const { data } = await post(
        ENDPOINTS.accounts.previewCryptoWire,
        payload
      );
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async cryptoWireSend(payload: Record<string, any> | undefined) {
    try {
      const { data } = await post(ENDPOINTS.accounts.cryptoWireSend, payload);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async closeSaving(payload: Record<string, any> | undefined) {
    try {
      const { data } = await post(ENDPOINTS.accounts.closeSaving, payload);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchTransferFrom(userId: string) {
    try {
      const { data } = await get(ENDPOINTS.accounts.fetchTransferFrom, {
        perPage: 999999,
        page: 1,
        transferToUSerId: userId,
      });
      return data.data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async getAccountId(userId: string, fromAccountId: string) {
    try {
      const { data } = await get(ENDPOINTS.accounts.getAccountId, {
        userId,
        fromAccountId,
      });
      return data;
    } catch (err) {
      return false;
    }
  },

  async previewTransfer(payload: Record<string, any> | undefined) {
    try {
      const { data } = await post(ENDPOINTS.accounts.previewTransfer, payload);
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async payMigom(payload: Record<string, any> | undefined) {
    try {
      const { data } = await post(ENDPOINTS.accounts.payMigom, payload);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async transfer(payload: Record<string, any> | undefined) {
    try {
      const { data } = await post(ENDPOINTS.accounts.transfer, payload);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async newTransfer(
    payload: {
      amount: string;
      fromAccountId: string;
      toAccountId: string;
      senderComment?: "";
    },
    files: any[]
  ) {
    try {
      const formData = new FormData();

      formData.append("Amount", payload.amount);
      formData.append("FromAccountId", payload.fromAccountId);
      formData.append("ToAccountId", payload.toAccountId);
      formData.append("SenderComment", payload.senderComment || "");

      if (files.length > 0) {
        files.forEach((f: { data: string | Blob }) =>
          formData.append("Files", f.data)
        );
      }

      const { data } = await post(ENDPOINTS.accounts.newTransfer, formData);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async request(payload: Record<string, any> | undefined) {
    try {
      const { data } = await post(ENDPOINTS.accounts.request, payload);
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async previewWireSend(payload: Record<string, any> | undefined) {
    try {
      const { data } = await post(ENDPOINTS.accounts.previewWireSend, payload);
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async wireSend(payload: Record<string, any> | undefined) {
    try {
      const { data } = await post(ENDPOINTS.accounts.wireSend, payload);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchMaxExchangeAmount(fromAccountId, toAccountId) {
    try {
      const { data } = await get(
        ENDPOINTS.accounts.fetchMaxExchangeAmount.replace(
          "{accountId}",
          fromAccountId
        ),
        {
          toAccountId: toAccountId,
        }
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async fetchMaxWireSend(fromAccountId) {
    try {
      const { data } = await get(
        ENDPOINTS.accounts.fetchMaxWireSend.replace(
          "{accountId}",
          fromAccountId
        )
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async fetchMaxCryptoWithdrawAmount(accountId) {
    try {
      const { data } = await get(
        ENDPOINTS.accounts.fetchMaxCryptoWithdrawAmount.replace(
          "{accountId}",
          accountId
        )
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchMaxCryptoWithdrawAmountForAdmin(accountId, params) {
    try {
      const { data } = await get(
        ENDPOINTS.accounts.fetchMaxCryptoWithdrawAmountForAdmin.replace(
          "{accountId}",
          accountId
        ),
        params
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async newWireSend(
    payload: {
      amount: string;
      fromAccountId: string;
      toWireRecipientId: string;
      requestType: "SWIFT" | "SEPA";
      memo: string;
      messageToRecipient: string;
      paymentPurpose: string;
      parentTaskId: string;
    },
    files: any[]
  ) {
    try {
      const formData = new FormData();

      formData.append("Amount", payload.amount);
      formData.append("FromAccountId", payload.fromAccountId);
      formData.append("ToWireRecipientId", payload.toWireRecipientId);
      formData.append("RequestType", payload.requestType);
      formData.append("Memo", payload.memo);
      formData.append("MessageToRecipient", payload.messageToRecipient);
      formData.append("PaymentPurpose", payload.paymentPurpose);

      if (payload.parentTaskId) {
        formData.append("ParentTaskId", payload.parentTaskId);
      }

      if (files.length > 0) {
        files.forEach((f: { data: string | Blob }) =>
          formData.append("Files", f.data)
        );
      }

      const { data } = await post(ENDPOINTS.accounts.newWireSend, formData);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async deposit(payload: Record<string, any> | undefined) {
    try {
      const { data } = await post(ENDPOINTS.accounts.deposit, payload);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async depositOffer(payload: Record<string, any> | undefined) {
    try {
      const { data } = await post(ENDPOINTS.accounts.depositOffer, payload);

      const result = await accounts.fetchAccount(data?.accountId);

      accounts.items = [...accounts.items, result];

      return result;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async cryptoLoan(payload: Record<string, any> | undefined) {
    try {
      await post(ENDPOINTS.accounts.cryptoLoan, payload);

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchDetailSavingAccount(accountId: string) {
    try {
      const { data } = await get(ENDPOINTS.accounts.fetchDetailSavingAccount, {
        accountId,
      });

      return data;
    } catch (err) {
      return false;
    }
  },

  async fetchRate(fromCurrency: string, toCurrency: string) {
    try {
      const { data } = await get(ENDPOINTS.accounts.fetchRate, {
        baseCur: fromCurrency,
        quoteCur: toCurrency,
      });

      return data;
    } catch (err) {
      return false;
    }
  },
  async fetchUserDataByPhone(phone: string) {
    try {
      const { data } = await get(ENDPOINTS.accounts.fetchUserDataByPhone, {
        phone,
      });

      return data;
    } catch (err) {
      return false;
    }
  },

  async getFormula(accountId: string, titleId: string) {
    try {
      const { data } = await get(
        ENDPOINTS.accounts.getFormula
          .replace("{accountId}", accountId)
          .replace("{titleId}", titleId)
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchResolveFormulas(accountId: string) {
    try {
      const { data } = await get(
        `${import.meta.env.VITE_API}/v1/accounts/${accountId}/resolve-formulas`
      );

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
});

export default accounts;
