import React from "react";
import { formatToCurrencyString } from "../../../../../../helpers";
import CopyAccountNumber from "../../../../../System/CopyAccountNumber";
import ShowAndHide from "../../../../../System/FormComponents/ShowAndHide";

const SepaDetail = ({ showDetail = true, showId, sepa }: any) => {
  return (
    <ShowAndHide show={showDetail}>
      <div className="flex flex-col text-base mt-4">
        {showId && (
          <div className="flex flex-col mb-2">
            <span>
              ID:{" "}
              <b>
                {sepa?.id} <CopyAccountNumber number={sepa?.id} />
              </b>
            </span>
          </div>
        )}

        <div className="flex flex-col mb-2">
          <span>
            Amount:{" "}
            <b>
              {formatToCurrencyString(
                sepa?.amount?.value || 0,
                sepa?.amount?.currency
              )}
            </b>
          </span>
        </div>

        <div className="flex flex-col mb-2">
          <span>
            From:{" "}
            <b>
              {sepa?.from?.name} {sepa?.from?.iban}
            </b>
          </span>
        </div>

        <div className="flex flex-col mb-2">
          <span>
            To:{" "}
            <b>
              {sepa?.to?.name} {sepa?.to?.iban}
            </b>
          </span>
        </div>

        <div className="flex flex-col mb-2">
          <span>
            Description: <b>{sepa?.description}</b>
          </span>
        </div>

        <div className="flex flex-col mb-2">
          <span>
            Additionally:{" "}
            <b>
              {sepa?.instructionId}, {sepa?.senderReference}, {sepa?.type},{" "}
              {sepa?.class}
            </b>
          </span>
        </div>
      </div>
    </ShowAndHide>
  );
};

export default SepaDetail;
