import { FormHelperText } from "@material-ui/core";
import React from "react";
import MyAmount from "../../../../System/FormComponents/MyAmount";

const Amount = ({
  prefix = "",
  control,
  formState,
  onFocus,
  autoFocus,
}: any) => {
  return (
    <div>
      <MyAmount
        autoFocus={autoFocus}
        control={control}
        controlCurrency={control}
        name="amount"
        label="Amount"
        currencyName="currencyId"
        rules={{
          required: true,
        }}
        prefix={prefix}
        onFocus={onFocus}
      />

      {formState?.errors[prefix + "amount"]?.type === "required" && (
        <FormHelperText error>Field is required</FormHelperText>
      )}
    </div>
  );
};

export default Amount;
