import React from "react";
import Preview from "../components/Preview";

const WrapPreview = ({
  data,
  form,
  hideConfirmButtons,
  setOpenAreYouSure,
  setStep,
  loading,
  setOpen,
  preview,
  task,
}) => {
  return (
    <Preview
      data={data}
      setStep={setStep}
      confirm={() => setOpenAreYouSure(true)}
      hideConfirmButtons={hideConfirmButtons}
      loading={loading}
      setOpen={setOpen}
      form={form}
      task={task}
      preview={preview}
    />
  );
};

export default WrapPreview;
