import React from "react";
import { formatToCurrencyString } from "../../../../../../helpers";

const TitleContent = ({ title, task }: any) => {
  const makeAmount = task?.amount
    ? formatToCurrencyString(task?.amount, task?.currencyId)
    : null;

  return (
    <div className="flex flex-col">
      {task?.fromAccount?.accountId && task?.toAccount?.accountId && (
        <span>
          <b>
            {task?.fromAccount?.accountId} - {task?.toAccount?.accountId}
          </b>{" "}
          {title}
        </span>
      )}

      {!task?.fromAccount?.accountId && task?.toAccount?.accountId && (
        <span>
          <b>{task?.currencyId}</b> {title} <b>{makeAmount}</b>
        </span>
      )}

      {task?.fromAccount?.accountId && !task?.toAccount?.accountId && (
        <span>
          <b>{task?.fromCurrencyId}</b> {title} <b>{makeAmount}</b>
        </span>
      )}

      {title &&
        !task?.fromAccount?.accountId &&
        !task?.toAccount?.accountId && <b>{title}</b>}
    </div>
  );
};

export default TitleContent;
