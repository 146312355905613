import { store } from "@risingstack/react-easy-state";
import ENDPOINTS from "../../endpoints";
import { get, showError } from "../api";

interface ITransactionDetails {
  loading: boolean;
  detailSeeUser: Record<string, any>;
  moreDetailsForAdmin: Record<string, any>;
  adminFees: any[];
  adminCosts: any[];
  adminPnL: any[];
  adminRelatedFx: any[];
  adminRelatedVault: any[];
  adminRelated: any[];
  openDetail: boolean;
  successDelete: boolean;
  openDeleteOperation: boolean;
  deleteOperationParams: Record<string, any>;

  [key: string]: any;
}

const transactionDetails = store<ITransactionDetails>({
  loading: false,
  detailSeeUser: {},
  moreDetailsForAdmin: {},
  adminFees: [],
  adminCosts: [],
  adminPnL: [],
  adminRelatedFx: [],
  adminRelatedVault: [],
  adminRelated: [],
  openDetail: false,
  successDelete: false,
  openDeleteOperation: false,
  deleteOperationParams: {
    preview: null,
    loading: false,
    completed: 0,
    showProgress: false,
    areYouSure: false,
  },

  clearStore() {
    transactionDetails.loading = false;
    transactionDetails.detailSeeUser = {};
    transactionDetails.moreDetailsForAdmin = {};
    transactionDetails.adminFees = [];
    transactionDetails.adminCosts = [];
    transactionDetails.adminPnL = [];
    transactionDetails.adminRelatedFx = [];
    transactionDetails.adminRelatedVault = [];
    transactionDetails.adminRelated = [];
    transactionDetails.openDetail = false;
    transactionDetails.successDelete = false;
    transactionDetails.openDeleteOperation = false;
    transactionDetails.deleteOperationParams = {
      preview: null,
      loading: false,
      completed: 0,
      showProgress: false,
      areYouSure: false,
    };
    transactionDetails.fetchTransactions = undefined;
    transactionDetails.fetchUserAccount = undefined;
  },

  clearTransaction() {
    transactionDetails.detailSeeUser = {};
    transactionDetails.moreDetailsForAdmin = {};
    transactionDetails.adminFees = [];
    transactionDetails.adminCosts = [];
    transactionDetails.adminPnL = [];
    transactionDetails.adminRelatedFx = [];
    transactionDetails.adminRelatedVault = [];
    transactionDetails.adminRelated = [];
    transactionDetails.successDelete = false;
    transactionDetails.openDeleteOperation = false;
    transactionDetails.deleteOperationParams = {
      preview: null,
      loading: false,
      completed: 0,
      showProgress: false,
      areYouSure: false,
    };
  },

  goodCallbackForDeleteOperation() {
    transactionDetails.deleteOperationParams.completed = 100;
    transactionDetails.successDelete = true;
    transactionDetails.deleteOperationParams.loading = false;
    transactionDetails.fetchTransactions({ pageIndex: 0 });

    if (transactionDetails.fetchUserAccount) {
      transactionDetails.fetchUserAccount();
    }

    transactionDetails.clearStore();
  },

  initializationObjectsForAdmin(data: Record<string, any>, item: string) {
    if (item.includes("admin")) {
      if (item === "adminFees") {
        transactionDetails.adminFees = data[item];
      } else if (item === "adminCosts") {
        transactionDetails.adminCosts = data[item];
      } else if (item === "adminPnL") {
        transactionDetails.adminPnL = data[item];
      } else if (item === "adminRelatedFx") {
        transactionDetails.adminRelatedFx = data[item];
      } else if (item === "adminRelatedVault") {
        transactionDetails.adminRelatedVault = data[item];
      } else if (item === "adminRelated") {
        transactionDetails.adminRelated = data[item];
      } else if (transactionDetails.moreDetailsForAdmin) {
        transactionDetails.moreDetailsForAdmin[item] = data[item];
      }
    } else if (transactionDetails.detailSeeUser) {
      transactionDetails.detailSeeUser[item] = data[item];
    }
  },

  async fetchUserAccountTransaction(isPending: boolean, operationId: string) {
    transactionDetails.loading = true;
    try {
      const { data } = await get(
        ENDPOINTS.admin.operation.replace("{operationId}", operationId),
        { isPending: isPending }
      );

      const keys = Object.keys(data);

      if (keys?.length > 0) {
        keys.forEach((item) => {
          transactionDetails.initializationObjectsForAdmin(data, item);
        });
      }
      transactionDetails.loading = false;

      return true;
    } catch (err) {
      showError(err);
      transactionDetails.loading = false;
      return false;
    }
  },
  async fetchMyAccountTransaction(isPending: boolean, operationId: string) {
    transactionDetails.loading = true;
    try {
      const { data } = await get(
        ENDPOINTS.transactions.fetchMyAccountTransaction.replace(
          "{operationId}",
          operationId
        ),
        { isPending }
      );
      transactionDetails.detailSeeUser = data;
      transactionDetails.loading = false;
      return true;
    } catch (err) {
      showError(err);
      transactionDetails.loading = false;
      return false;
    }
  },
});

export default transactionDetails;
