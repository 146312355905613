import { lazy } from "react";

const DepositByCard = lazy(
  () => import("../../components/Roles/Client/Pay/DepositByCard")
);

const WireSend = lazy(
  () => import("../../components/Roles/Client/Pay/WireSend")
);
const Request = lazy(
  () => import("../../components/Roles/Client/Pay/RequestMoney")
);

const SendToMigomUser = lazy(
  () => import("../../components/Roles/Client/Pay/SendToMigomUser")
);
const CryptoWithdraw = lazy(
  () => import("../../components/Roles/Client/Pay/CryptoWithdraw")
);
const SendToMyCustomer = lazy(
  () => import("../../components/Roles/Client/Pay/SendToMyCustomer")
);
const RequestMoneyFromMyCustomer = lazy(
  () => import("../../components/Roles/Client/Pay/RequestFromMyCustomer")
);
const InternalTransfer = lazy(
  () => import("../../components/Roles/Client/Pay/InternalTransfer")
);
const Exchange = lazy(
  () => import("../../components/Roles/Client/Pay/Exchange")
);
const PayInvoice = lazy(
  () => import("../../components/Roles/Client/Pay/PayInvoice")
);

const BulkWireTransfer = lazy(
  () => import("../../components/Roles/Client/Pay/BulkWireTransfer")
);

const CloseSaving = lazy(
  () => import("../../components/Roles/Client/Pay/CloseSaving")
);

const WithdrawGoldCoins = lazy(
  () => import("../../components/Roles/Client/Pay/WithdrawGoldCoins")
);

export const Pay = [
  {
    path: "/app/transfer",
    component: InternalTransfer,
  },
  {
    path: "/app/exchange",
    component: Exchange,
  },
  {
    path: "/app/close-saving",
    component: CloseSaving,
  },
  {
    path: "/app/pay-invoice",
    component: PayInvoice,
  },
  {
    path: "/app/send/wire",
    component: WireSend,
  },
  {
    path: "/app/send/bulk-wire-send",
    component: BulkWireTransfer,
  },
  {
    path: "/app/send/migom",
    component: SendToMigomUser,
  },
  {
    path: "/app/send/withdraw-gold",
    component: WithdrawGoldCoins,
  },
  {
    path: "/app/send/crypto-recipient",
    component: CryptoWithdraw,
  },

  {
    path: "/app/send/my-customer",
    component: SendToMyCustomer,
  },

  {
    path: "/app/send/request-money-my-customer",
    component: RequestMoneyFromMyCustomer,
  },
  {
    path: "/app/request",
    component: Request,
  },
  {
    path: "/app/deposit-by-card",
    component: DepositByCard,
  },
];
