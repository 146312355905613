import { store } from "@risingstack/react-easy-state";
import numbro from "numbro";
import ENDPOINTS from "../../endpoints";
import { allCurrencies } from "../../helpers";
import IAccount from "../../interfaces/account";
import { get, post, showError } from "../api";
import coins from "./coins";
import ui from "./ui";

interface IExchange {
  step: number;
  loading: boolean;
  feeData: any;
  sellAmount: string;
  buyAmount: string;
  exchangeName: string;

  exchangeRate?: number;

  selectFromAccount?: IAccount;
  selectToAccount?: IAccount;

  fromAccounts: IAccount[];
  toAccounts: IAccount[];

  success: boolean;
  payloadForConfirm: any;

  cursor: string;

  data: {
    request: { operations: any[]; [key: string]: any };
    transaction: { operation: any[]; [key: string]: any };
    ui?: Record<string, any>;
  };

  userAccounts: IAccount[];
  loadingUserAccounts: boolean;
  realTrade: boolean;
  gcPartners: boolean;
  enableGCPartners: boolean;

  possibles: {
    exchangePossibleFrom: any;
    exchangePossibleTo: any;
  };

  loadingPossibles: boolean;

  [key: string]: any;
}

const exchange = store<IExchange>({
  step: 1,
  loading: false,
  feeData: {},
  sellAmount: "",
  buyAmount: "",
  exchangeName: "",

  exchangeRate: 0,

  selectFromAccount: undefined,
  selectToAccount: undefined,

  success: false,
  payloadForConfirm: undefined,

  cursor: "sell",

  fromAccounts: [],
  toAccounts: [],

  data: {
    request: { operations: [] },
    transaction: { operation: [] },
  },

  userAccounts: [],
  loadingUserAccounts: false,
  realTrade: false,
  gcPartners: false,
  enableGCPartners: false,

  possibles: {
    exchangePossibleFrom: undefined,
    exchangePossibleTo: undefined,
  },

  loadingPossibles: true,

  clearStore() {
    exchange.step = 1;
    exchange.sellAmount = "";
    exchange.buyAmount = "";

    exchange.exchangeName = "";

    exchange.exchangeRate = 0;

    exchange.selectFromAccount = undefined;
    exchange.selectToAccount = undefined;

    exchange.success = false;
    exchange.payloadForConfirm = undefined;

    exchange.cursor = "sell";

    exchange.data = {
      request: { operations: [] },
      transaction: { operation: [] },
    };

    exchange.userAccounts = [];
    exchange.fromAccounts = [];
    exchange.toAccounts = [];

    exchange.loadingUserAccounts = false;
    exchange.realTrade = false;
    exchange.gcPartners = false;
    exchange.enableGCPartners = false;

    exchange.possibles = {
      exchangePossibleFrom: undefined,
      exchangePossibleTo: undefined,
    };
  },
  async getFormula(accountId: string, titleId: string) {
    try {
      const { data } = await get(
        ENDPOINTS.accounts.getFormula
          .replace("{accountId}", accountId)
          .replace("{titleId}", titleId)
      );

      exchange.feeData = data;

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async filtersArraysAccounts(obj: { direction: string; value?: IAccount }) {
    if (obj && obj.direction && obj.value) {
      if (
        obj.direction === "from" &&
        obj.value?.currencyId &&
        exchange.selectToAccount?.currencyId &&
        obj.value?.currencyId === exchange.selectToAccount?.currencyId
      ) {
        const accounts = exchange.userAccounts.filter((item) => {
          return item.currencyId !== exchange.selectToAccount?.currencyId;
        });

        if (accounts[0]) {
          exchange.selectToAccount = accounts[0];
        }
      }

      if (
        obj.direction === "to" &&
        obj.value?.currencyId &&
        exchange.selectFromAccount?.currencyId &&
        obj.value?.currencyId === exchange.selectFromAccount?.currencyId
      ) {
        const accounts = exchange.userAccounts.filter((item) => {
          return item.currencyId !== exchange.selectFromAccount?.currencyId;
        });

        if (accounts[0]) {
          exchange.selectFromAccount = accounts[0];
        }
      }

      if (obj.direction === "from") {
        exchange.selectFromAccount = obj.value;

        if (!exchange.selectToAccount?.currencyId) {
          const accounts = exchange.userAccounts.filter((item) => {
            return item.currencyId !== exchange.selectFromAccount?.currencyId;
          });

          exchange.selectToAccount = accounts[0];
        }
      }

      if (obj.direction === "to") {
        exchange.selectToAccount = obj.value;

        if (!exchange.selectFromAccount?.currencyId) {
          const accounts = exchange.userAccounts.filter((item) => {
            return item.currencyId !== exchange.selectToAccount?.currencyId;
          });

          exchange.selectFromAccount = accounts[0];
        }
      }

      if (!coins.goldData?.currency) {
        exchange.sellAmount = "";
        exchange.buyAmount = "";
      }
    }

    if (
      exchange.selectFromAccount?.currencyId &&
      exchange.selectToAccount?.currencyId &&
      !window.location.pathname.includes("notifications") &&
      !window.location.pathname.includes("admin")
    ) {
      const getToCurrency = allCurrencies.find(
        (c) => c.value === exchange.selectToAccount?.currencyId
      );

      const getFromCurrency = allCurrencies.find(
        (c) => c.value === exchange.selectFromAccount?.currencyId
      );
      const titleId =
        getToCurrency?.type === "Crypto" || getFromCurrency?.type === "Crypto"
          ? "EXCRY"
          : "EXCH";
      exchange.getFormula(exchange.selectFromAccount?.accountId, titleId);
    }

    exchange.setPossibles();
  },
  validAccount(account) {
    const validTypeId =
      account.accountTypeId === "Bank" ||
      account.accountTypeId === "Crypto" ||
      account.accountTypeId === "DebitCard";

    const isOpen = account.accountStateId === "Open";

    return isOpen && validTypeId;
  },
  setBuySellFieldsByCursor() {
    if (exchange.exchangeRate) {
      if (exchange.cursor === "sell" && exchange.sellAmount) {
        const v = exchange.exchangeRate * numbro.unformat(exchange.sellAmount);

        if (v > 0) {
          exchange.buyAmount = v.toString();
        }
      }

      if (exchange.cursor === "buy" && exchange.buyAmount) {
        const v = numbro.unformat(exchange.buyAmount) / exchange.exchangeRate;

        if (v > 0) {
          exchange.sellAmount = v.toString();
        }
      }
    }
  },

  setPossibles() {
    if (
      !exchange.selectFromAccount?.currencyId ||
      !exchange.selectToAccount?.currencyId
    ) {
      return false;
    }

    const fromKey = exchange.selectFromAccount?.currencyId.toLowerCase();
    const toKey = exchange.selectToAccount?.currencyId.toLowerCase();

    const fromPossibles = exchange.possible?.exchangePossibleFrom[fromKey];
    const toPossibles = exchange.possible?.exchangePossibleTo[toKey];

    if (!fromPossibles) {
      return false;
    }

    if (fromPossibles.includes(exchange.selectToAccount?.currencyId)) {
      exchange.selectToAccount.possible = true;
    } else {
      exchange.selectToAccount.possible = false;
    }

    if (toPossibles.includes(exchange.selectFromAccount?.currencyId)) {
      exchange.selectFromAccount.possible = true;
    } else {
      exchange.selectFromAccount.possible = false;
    }

    exchange.selectToAccount = { ...exchange.selectToAccount };
    exchange.selectFromAccount = { ...exchange.selectFromAccount };
  },

  async exchangeAutoPossible() {
    try {
      exchange.loadingPossibles = true;

      const { data } = await get(ENDPOINTS.exchange.exchangeAutoPossible);
      exchange.possible = data;

      exchange.loadingPossibles = false;
      return data;
    } catch (err) {
      showError(err);
      exchange.loadingPossibles = false;
      return false;
    }
  },

  async exchange(payload: Record<string, any> | undefined) {
    try {
      const { data } = await post(ENDPOINTS.exchange.exchange, payload);

      exchange.data = data;

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async exchangePreview(payload: Record<string, any> | undefined) {
    ui.errorOperation = "";
    try {
      const { data } = await post(ENDPOINTS.exchange.exchangePreview, payload);

      exchange.setBuySellFieldsByCursor();

      return data;
    } catch (err: any) {
      if (err?.response?.data?.description) {
        ui.errorOperation = err?.response?.data?.description;
      }

      return false;
    }
  },
  async exchangePreviewRate(payload: Record<string, any> | undefined) {
    try {
      const { data } = await post(ENDPOINTS.exchange.exchangePreview, payload);

      exchange.setBuySellFieldsByCursor();

      return data;
    } catch (err: any) {
      return false;
    }
  },
  async exchangeByAdmin(payload: Record<string, any> | undefined) {
    try {
      const { data } = await post(ENDPOINTS.exchange.exchangeByAdmin, {
        ...payload,
        enableGCPartners: exchange.enableGCPartners,
      });

      exchange.data = data;

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  calcBuyAmount(exchangeRate: number, sellAmount: string) {
    return exchangeRate * numbro.unformat(sellAmount);
  },
  calcSellAmount(buyAmount: string, exchangeRate: number) {
    return numbro.unformat(buyAmount) / exchangeRate;
  },

  async exchangePreviewByAdmin(payload: Record<string, any> | undefined) {
    try {
      const { data } = await post(
        ENDPOINTS.exchange.exchangePreviewByAdmin,
        payload
      );

      return [true, data];
    } catch (err: any) {
      showError(err);

      return [false, err];
    }
  },

  async exchangePreviewByAdminBalance(
    payload: Record<string, any> | undefined
  ) {
    ui.errorOperation = "";
    try {
      const { data } = await post(
        ENDPOINTS.exchange.exchangePreviewByAdminBalance,
        { ...payload, enableGCPartners: exchange.enableGCPartners }
      );

      exchange.setBuySellFieldsByCursor();

      return data;
    } catch (err: any) {
      if (err?.response?.data?.description) {
        ui.errorOperation = err?.response?.data?.description;
      }

      return false;
    }
  },
  async exchangeByAdminBalance(payload: Record<string, any> | undefined) {
    try {
      await post(ENDPOINTS.exchange.exchangeByAdminBalance, {
        ...payload,
        enableGCPartners: exchange.enableGCPartners,
      });

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
  async fetchUserAccounts(
    userId: any,
    body: {
      fromAccountId: any;
      toAccountId: any;
      buyAmount: any;
      sellAmount: any;
    }
  ) {
    try {
      const { fromAccountId, toAccountId, buyAmount, sellAmount } = body;
      exchange.loadingUserAccounts = true;

      const { data } = await get(ENDPOINTS.admin.fetchUserAccounts, {
        page: 1,
        perPage: 999999,
        userId,
      });

      exchange.userAccounts = data.data;

      const findFrom = exchange.userAccounts.find((item) => {
        return item.accountId === fromAccountId;
      });

      const findTo = exchange.userAccounts.find((item) => {
        return item.accountId === toAccountId;
      });

      if (findFrom) {
        exchange.selectFromAccount = findFrom;
      }

      if (findTo) {
        exchange.selectToAccount = findTo;
      }

      if (findFrom && findTo) {
        if (buyAmount) {
          exchange.cursor = "buy";

          if (buyAmount != 0) {
            exchange.buyAmount = buyAmount;
          }

          const v = exchange.calcSellAmount(buyAmount, exchange.exchangeRate);

          if (v != 0) {
            exchange.sellAmount = v;
          }
        } else if (sellAmount) {
          exchange.cursor = "sell";

          if (sellAmount != 0) {
            exchange.sellAmount = sellAmount;
          }

          const v = exchange.calcBuyAmount(sellAmount, exchange.exchangeRate);

          if (v != 0) {
            exchange.buyAmount = v;
          }
        }
        exchange.filtersArraysAccounts();
      }

      exchange.loadingUserAccounts = false;
    } catch (err) {
      exchange.loadingUserAccounts = false;
      showError(err);
    }
  },

  async fetchUserAccountsAdmin(userId: any, fromAccountId: any) {
    try {
      exchange.loadingUserAccounts = true;

      const { data } = await get(ENDPOINTS.admin.fetchUserAccounts, {
        page: 1,
        perPage: 999999,
        userId,
      });

      exchange.userAccounts = data.data;

      const findFrom = exchange.userAccounts.find((item) => {
        return item.accountId === fromAccountId;
      });

      if (findFrom) {
        exchange.selectFromAccount = findFrom;
      }

      exchange.filtersArraysAccounts();

      exchange.loadingUserAccounts = false;
    } catch (err) {
      exchange.loadingUserAccounts = false;
      showError(err);
    }
  },
});

export default exchange;
