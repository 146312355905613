import { FormHelperText } from "@material-ui/core";
import React from "react";
import { useWatch } from "react-hook-form";
import MyTextField from "../../../../../System/FormComponents/MyTextField";
import { latinRegexp } from "../../../../All/WireRecipients/tools/regexp";

const MessageToRecipient = ({ prefix = "", control, formState }) => {
  const feeType = useWatch({
    name: "feeType",
    control: control,
  });

  const maxLength = feeType?.value === "WDREU" ? 30 : 140;

  const rules = {
    maxLength: maxLength,
    pattern: latinRegexp,
  };

  return (
    <div>
      <MyTextField
        control={control}
        label="Message to recipient"
        name={prefix + "messageToRecipient"}
        fullWidth
        rules={rules}
        multiline
        rows={2}
      />

      {formState?.errors[prefix + "messageToRecipient"]?.type ===
        "required" && <FormHelperText error>Field is required</FormHelperText>}

      {formState?.errors[prefix + "messageToRecipient"]?.type ===
        "maxLength" && (
        <FormHelperText error>
          Maximum line length {maxLength} characters
        </FormHelperText>
      )}

      {formState?.errors[prefix + "messageToRecipient"]?.type === "pattern" && (
        <FormHelperText error>
          Special characters {`(e.g. :;",'!#*@)`} are not allowed in this form
          field
        </FormHelperText>
      )}
    </div>
  );
};

export default MessageToRecipient;
