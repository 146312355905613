import { useCallback, useEffect } from "react";
import { useWatch } from "react-hook-form";
import { get } from "../../../../../../store/api";
import { useGetAccounts } from "./useGetAccounts";

export const useRate = ({ form, fromAccount }) => {
  const { getToAccount } = useGetAccounts({ account: fromAccount });

  const toAccountId = useWatch({
    name: "toAccountId",
    control: form.control,
  });

  const toAccount = getToAccount(toAccountId);

  const { setValue } = form;

  const getRate = useCallback(
    async (fromCurrency, toCurrency) => {
      try {
        const { data } = await get(
          `${
            import.meta.env.VITE_OLD_CRYPTO
          }/v1/quotes/${fromCurrency}/${toCurrency}`
        );

        setTimeout(
          () =>
            setValue("rate", data?.rate || "", {
              shouldDirty: true,
              shouldValidate: true,
              shouldTouch: true,
            }),
          0
        );
      } catch (err) {
        console.log(err);
      }
    },
    [setValue]
  );

  useEffect(() => {
    if (fromAccount?.currencyId && toAccount?.currencyId) {
      getRate(fromAccount?.currencyId, toAccount?.currencyId);
    }
  }, [fromAccount?.currencyId, getRate, toAccount?.currencyId]);

  return {
    showRate:
      fromAccount &&
      toAccount &&
      fromAccount?.currencyId !== toAccount?.currencyId,
  };
};
