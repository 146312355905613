import React from "react";
import { formatToCurrencyString } from "../../helpers";

const FormatAmount = ({
  amount,
  currency,
}: {
  amount?: string | number;
  currency?: string;
}) => {
  return <>{formatToCurrencyString(amount, currency)}</>;
};

export default FormatAmount;
