import { FormHelperText } from "@material-ui/core";
import numbro from "numbro";
import React from "react";
import MyAmount from "../../../../../System/FormComponents/MyAmount";

const Amount = ({ form }) => {
  return (
    <div>
      <MyAmount
        {...form}
        name="amount"
        controlCurrency={form.control}
        currencyName="currencyId"
        label="Transfer amount*"
        rules={{
          required: true,
          validate: {
            valid: (value) => {
              const unformatted = numbro.unformat(value);

              if (unformatted > 0) {
                return true;
              }

              return false;
            },
          },
        }}
      />

      {form?.formState?.errors?.amount?.type === "required" && (
        <FormHelperText error>Field is required</FormHelperText>
      )}

      {form?.formState?.errors?.amount?.type === "valid" && (
        <FormHelperText error>
          The sell amount must be greater than zero
        </FormHelperText>
      )}
    </div>
  );
};

export default Amount;
