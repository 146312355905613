import { Button, ButtonProps } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import LoadingCircular from "../../../../LoadingCircular";

const useStyles = makeStyles({
  root: {
    backgroundColor: green[500],
    color: "#fff",
    fontWeight: 700,
    "@media (max-width: 1000px)": {
      fontSize: ".85rem",
    },
    "@media (max-width: 600px)": {
      maxWidth: 420,
      fontSize: "1rem",
    },
    "@media (max-width: 380px)": {
      fontSize: ".85rem",
    },
  },
});

interface IGreenButton extends ButtonProps {
  id?: string;
  loading?: boolean;
  label: string;

  [key: string]: any;
}

const GreenButton = ({ loading, label, id, ...props }: IGreenButton) => {
  const classes = useStyles();

  return (
    <Button
      id={id}
      className={classes.root}
      variant="contained"
      color="primary"
      {...props}
    >
      {label}
      <LoadingCircular loading={loading} />
    </Button>
  );
};

export default GreenButton;
