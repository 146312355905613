import { useQuery } from "react-query";
import ENDPOINTS from "../../../../../endpoints";
import { useDebouncer } from "../../../../../queries/useDebouncer";
import { get, showError } from "../../../../../store/api";

export const useQueryCardRequestsCompleted = (params, enabled) => {
  const debouncedSearch = useDebouncer(params.search, 500);

  const queryParams = {
    ...params,
    search: params.search === "" ? params.search : debouncedSearch,
    lessThan: params.lessThan === "None" ? undefined : params.lessThan,
    moreThan: params.moreThan === "None" ? undefined : params.moreThan,
    currencies: params.currencies.join(","),
    taskTypes: params.taskTypes.join(","),
  };

  return useQuery(
    ["useQueryCardRequestsCompleted", queryParams],
    async () => {
      try {
        const { data } = await get(ENDPOINTS.tasks.activity, queryParams);

        return data;
      } catch (err) {
        showError(err);
        return {};
      }
    },
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      enabled,
    }
  );
};
