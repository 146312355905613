import { useCallback, useEffect, useState } from "react";
import IUser from "../../../../../../interfaces/user";
import users from "../../../../../../store/modules/users";

export const useFetchUser = (userId?: string) => {
  const [user, setUser] = useState<IUser>();
  const [loading, setLoading] = useState(false);

  const fetcher = useCallback(async () => {
    if (!userId) {
      return;
    }
    setLoading(true);

    const result = await users.fetchCustomUser(userId);

    if (result) {
      setUser(result);
    }

    setLoading(false);
  }, [userId]);

  useEffect(() => {
    fetcher();
  }, [fetcher]);

  return { user, loading, fetchRecipient: fetcher };
};
