import { clsx } from "clsx";
import React from "react";

const Field = ({
  label,
  value = "-",
  custom,
  className,
  labelId,
  valueId,
}: {
  label?: string;
  value?: JSX.Element | string;
  custom?: JSX.Element;
  className?: string;
  labelId?: string;
  valueId?: string;
}) => {
  return custom ? (
    <div
      className={clsx(
        "flex justify-between mb-1.5 pb-1.5 border-b border-solid border-gray-200",
        className
      )}
    >
      <span>{custom}</span>
    </div>
  ) : (
    <div
      className={clsx(
        "flex justify-between mb-1.5 pb-1.5 border-b border-solid border-gray-200",
        className
      )}
    >
      <span id={labelId}>{label}:</span>
      <b id={valueId} className="text-right">
        {value}
      </b>
    </div>
  );
};

export default Field;
