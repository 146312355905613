import numbro from "numbro";
import { checkIsCryptoCurrency, symbols } from "./formatToCurrencyString";

export const ro = (value: number) => {
  /* округление до ближайшего цента */

  const cents = value * 100;
  const roundedCents = Math.round(cents);
  return roundedCents / 100;
};

export const formatAmount = ({
  value,
  currencyId,
  clear,
  average,
  negative,
  mantissa,
  optionalMantissa,
  noZero,
}: {
  value: number | string;
  currencyId: string;
  clear?: boolean;
  average?: boolean;
  negative?: "sign" | "parenthesis";
  mantissa?: number;
  optionalMantissa?: boolean;
  noZero?: boolean;
}) => {
  let val = value;

  const fiatOptions: numbro.Format = {
    thousandSeparated: true,
    mantissa: mantissa || 2,
  };

  const cryptoOptions: numbro.Format = {
    thousandSeparated: true,
    mantissa: mantissa || 8,
  };

  if (optionalMantissa) {
    fiatOptions.optionalMantissa = optionalMantissa;
    cryptoOptions.optionalMantissa = optionalMantissa;
  }

  if (average) {
    fiatOptions.average = average;
    cryptoOptions.average = average;
  }

  if (negative) {
    fiatOptions.negative = negative;
    cryptoOptions.negative = negative;
  }

  if (checkIsCryptoCurrency(currencyId)) {
    val = numbro.unformat(val as string);
  } else {
    val = ro(numbro.unformat(val as string));
  }

  if (clear) {
    delete fiatOptions.mantissa;
  }

  if (
    (currencyId || currencyId === "USDC") &&
    (value === 0 || !value) &&
    noZero
  ) {
    return `${symbols[currencyId] || ""}0`;
  }

  if (value === "-") {
    return "0.00";
  }

  if (value === ".") {
    return "0.00";
  }

  if (!symbols[currencyId] && !value) {
    return "0.00";
  }

  if (!symbols[currencyId] && value) {
    return numbro(val).format(fiatOptions);
  }

  if (!currencyId && !value) {
    return "0.00";
  }

  if (currencyId && !value) {
    if (checkIsCryptoCurrency(currencyId)) {
      return `${symbols[currencyId]}0.00000000`;
    }

    return `${symbols[currencyId]}0.00`;
  }

  if (!currencyId && value) {
    return numbro(val).format(fiatOptions);
  }

  if (currencyId && value) {
    if (checkIsCryptoCurrency(currencyId)) {
      const formatted = numbro(val).format(cryptoOptions);
      return `${symbols[currencyId]}${formatted}`;
    }
    const formatted = numbro(val).format(fiatOptions);
    return `${symbols[currencyId]}${formatted}`;
  }

  return "";
};
