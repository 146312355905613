import { lazy } from "react";

const Root = lazy(() => import("../../components/Onboard/ShortApplication"));

const Home = lazy(
  () => import("../../components/Onboard/ShortApplication/Home")
);

const Finish = lazy(
  () => import("../../components/Onboard/ShortApplication/Finish")
);
const ClientType = lazy(
  () => import("../../components/Onboard/ShortApplication/ClientType")
);

export const ShortApplication = [
  {
    path: "/short-application",
    component: Root,
    routes: [
      {
        path: "/short-application",
        exact: true,
        component: Home,
      },
      {
        path: "/short-application/finish",
        component: Finish,
      },
      {
        path: "/short-application/client-type",
        component: ClientType,
      },
    ],
  },
];
