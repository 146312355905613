import { Button } from "@material-ui/core";
import { CheckCircle } from "lucide-react";
import React, { memo, useState } from "react";
import { useForm } from "react-hook-form";
import { Alert } from "../../../../System";
import MyCardTextField from "../../../../System/FormComponents/MyCardTextField";
import MySimpleMenu from "../../../../System/FormComponents/MySimpleMenu";
import MyTextField from "../../../../System/FormComponents/MyTextField";
import GreenButton from "../../../Admin/SmartForms/components/GreenButton";

const reg = new RegExp(/^\d+$/);

const years: {
  label: string;
  value: string;
}[] = [];

let startYear = 2022;
const endYear = 2046;

while (startYear <= endYear) {
  years.push({
    label: startYear.toString(),
    value: startYear.toString(),
  });
  startYear++;
}

const months: {
  label: string;
  value: string;
}[] = [];

let startMonth = 1;
const endMonth = 12;

while (startMonth <= endMonth) {
  months.push({
    label: startMonth.toString(),
    value: startMonth.toString(),
  });
  startMonth++;
}

export default memo(() => {
  const form = useForm({
    mode: "onChange",
    defaultValues: {
      cardNumber: "",
      cvv: "",
    },
  });

  const [open, setOpen] = useState(false);

  const submit = () => {
    console.log(1);
    setOpen(true);
  };

  return (
    <div className="flex justify-center">
      <div className="max-w-sm w-full">
        <div className="h-8" />

        <h1 className="text-center mb-8 text-2xl text-gray-600">Top Up</h1>

        <form onSubmit={form.handleSubmit(submit)}>
          <MyCardTextField
            {...form}
            InputLabelProps={{
              shrink: true,
            }}
            name="cardNumber"
            rules={{ required: true }}
            label="Card Number*"
          />

          <div className="grid grid-cols-3 gap-4 mt-4">
            <MySimpleMenu
              label="Exp Month*"
              name="expMonth"
              {...form}
              options={months}
            />

            <MySimpleMenu
              label="Exp Year*"
              name="expYear"
              {...form}
              options={years}
            />

            <MyTextField
              {...form}
              name="cvv"
              label="CVV*"
              fullWidth
              rules={{
                required: true,
                validate: {
                  onlyNumber: (value) => {
                    if (value && reg.test(value)) {
                      return true;
                    }
                    return false;
                  },
                  maxLength: (value) => {
                    if (value && value?.length !== 3) {
                      return false;
                    }
                    return true;
                  },
                },
              }}
            />
          </div>

          <div className="flex justify-end mt-8">
            <GreenButton label="Top Up" type="submit" />
          </div>
        </form>

        <Alert
          open={open}
          setOpen={setOpen}
          content={
            <div className="flex flex-col items-center">
              <CheckCircle className="text-green-500" size={64} />

              <p className="mt-4 text-lg text-center max-w-[30rem]">
                Top up completed successfully
              </p>

              <div className="flex justify-end w-full mb-6">
                <Button
                  color="primary"
                  variant="contained"
                  className="!mr-2"
                  onClick={() => setOpen(false)}
                >
                  Close
                </Button>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
});
