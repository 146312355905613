import { store } from "@risingstack/react-easy-state";
import ENDPOINTS from "../../endpoints";
import { get, showError } from "../api";

interface IUi {
  openDialogAccessDenied: boolean;
  showDesktopView: boolean;
  showLeave: boolean;
  error: string;
  loading: boolean;
  autoLogoutMs: number;
  autoLogout: boolean;
  openAutoLogoutMessage: boolean;
  hints: Record<string, any>;
  showFiles: boolean;
  stock: Record<string, any>;
  openWizard: boolean;
  openChangePhone: boolean;

  noRoles: boolean;

  salute: boolean;
  openPayMenu: boolean | null;

  dashboardPnlShowHidden: boolean;

  /* HeaderSuccess, HeaderLoading, HeaderError */
  loadingOperation: boolean;
  successOperation: boolean;
  showConfirmButton: boolean;
  errorOperation: string;

  textLoading: string;
  textSuccess: string;

  /* End */

  showDialogActivateCards: boolean;

  [key: string]: any;
}

const ui = store<IUi>({
  openDialogAccessDenied: false,
  showDesktopView: false,
  showLeave: false,
  error: "",
  loading: false,
  autoLogoutMs: -1,
  autoLogout: false,
  openAutoLogoutMessage: false,
  robotStatus: "",
  hints: {
    welcome: null,
    accounts: null,
    menuHeader: null,
    transactions: null,
  },
  showFiles: false,
  stock: { points: [], stats: {} },
  openWizard: false,
  openChangePhone: false,

  dashboardPnlShowHidden: false,
  noRoles: false,

  salute: false,
  openPayMenu: null,

  /* HeaderSuccess, HeaderLoading, HeaderError */
  loadingOperation: false,
  successOperation: false,
  showConfirmButton: true,
  errorOperation: "",

  textLoading: "",
  textSuccess: "",

  /* End */

  showDialogActivateCards: false,

  setOpenWizard(value) {
    ui.openWizard = value;
  },

  async fetchChartData() {
    try {
      const res = await get(`${ENDPOINTS.chartData.line}`);

      ui.stock = res.data;
    } catch (err) {
      showError(err);
    }
  },
});

export default ui;
