import { store } from "@risingstack/react-easy-state";
import ENDPOINTS from "../../endpoints";
import { get, post, showError } from "../api";

const transactive = store({
  async fetchTransactiveAccountsV2(params: Record<string, any> | undefined) {
    try {
      const { data } = await get(ENDPOINTS.transactive.accounts, params);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async preview(payload: Record<string, any> | undefined) {
    try {
      const { data } = await post(ENDPOINTS.transactive.preview, payload);
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async confirm(payload: Record<string, any> | undefined | null) {
    try {
      const { data } = await post(ENDPOINTS.transactive.confirm, payload);
      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },
});

export default transactive;
