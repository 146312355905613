export const states = [
  {
    label: "Submitted",
    value: "Submitted",
  },
  {
    label: "Approved",
    value: "Approved",
  },
  {
    label: "Rejected",
    value: "Rejected",
  },
  {
    label: "Canceled",
    value: "Canceled",
  },
  {
    label: "Closed",
    value: "Closed",
  },
  {
    label: "Pending",
    value: "Pending",
  },
  {
    label: "Instructions sent",
    value: "PendingWireInfoSent",
  },
  {
    label: "MoneySent",
    value: "PendingMoneySent",
  },
];

export const getTaskTypeBooleans = (task) => {
  const isWires = task?.taskTypeId === "Wires";
  const isSEPA = task?.taskTypeId === "SEPA_wire";
  const isSWIFT = task?.taskTypeId === "SWIFT_wire";
  const isTransfer = task?.taskTypeId === "Transfer";
  const isDeposit = task?.taskTypeId === "Deposit";
  const isCrypto = task?.taskTypeId === "Crypto_withdraw";
  const isMoneyRequest = task?.taskTypeId === "MoneyRequest";
  const isSecureMessage = task?.taskTypeId === "SecureMessage";
  const isExchange = task?.taskTypeId === "Exchange";
  const isExchangeSell = task?.taskTypeId === "ExchangeSell";
  const isExchangeBuy = task?.taskTypeId === "ExchangeBuy";
  const isGold = task?.taskTypeId === "MIGG_Delivery";
  const isWireIns = task?.taskTypeId === "WiringInstruction";
  const isCardOrder = task?.taskTypeId === "CardOrder";

  return {
    isExchange,
    isSEPA,
    isSWIFT,
    isTransfer,
    isDeposit,
    isCrypto,
    isMoneyRequest,
    isSecureMessage,
    isWires,
    isExchangeSell,
    isExchangeBuy,
    isGold,
    isWireIns,
    isCardOrder,
  };
};

export const getTaskStateBooleans = (task) => {
  const isPending = task?.taskStateId === "Pending";
  const isPendingWireInfoSent = task?.taskStateId === "PendingWireInfoSent";
  const isMessageWI =
    task?.taskStateId === "Message" && task?.taskId?.includes("-WI");
  const isMessage = task?.taskStateId === "Message";
  const isApproved = task?.taskStateId === "Approved";
  const isRejected = task?.taskStateId === "Rejected";
  const isCanceled = task?.taskStateId === "Canceled";
  const isClosed = task?.taskStateId === "Closed";
  const isSubmitted = task?.taskStateId === "Submitted";
  const isMoneySent = task?.taskStateId === "MoneySent";
  const isPendingMoneySent = task?.taskStateId === "PendingMoneySent";
  const isPendingWithdraw = task?.taskStateId === "PendingWithdraw";

  return {
    isPending,
    isApproved,
    isCanceled,
    isClosed,
    isMessage,
    isMessageWI,
    isPendingWireInfoSent,
    isRejected,
    isSubmitted,
    isMoneySent,
    isPendingMoneySent,
    isPendingWithdraw,
  };
};
