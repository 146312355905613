import { atom } from "jotai";
import { makeDates } from "../../../../AllClients/hooks/useFilter";

const { from, to } = makeDates({ dateType: "2022" });

export const dateParamsAtom = atom<any>({
  page: 1,
  perPage: 20,
  search: undefined,
  sortBy: "date",
  sortDirection: "desc",
  totalCurrency: "EUR",
  branchId: undefined,
  from: from,
  to: to,
  lessThan: undefined,
  moreThan: undefined,
  showHidden: false,
  sumBy: "Month",
});
