import { lazy } from "react";

const SignIn = lazy(() => import("../../components/Auth/SignIn"));
const PayMigomServicePay = lazy(
  () => import("../../components/Roles/Client/Pay/PayMigom/ServicePay")
);

export const PayService = [
  {
    path: "/service/pay/sign-in",
    component: SignIn,
  },
  {
    path: "/service/pay",
    component: PayMigomServicePay,
  },
];
