import { IconButton, Tooltip } from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";
import React from "react";
import { useClipboard } from "use-clipboard-copy";

const CopyAccountNumber = ({ number }: any) => {
  const clipboard = useClipboard({
    copiedTimeout: 3000,
  });

  return (
    <>
      <Tooltip title={clipboard.copied ? `Copied: ${number}` : "Copy"}>
        <IconButton
          style={{
            padding: 0,
            marginLeft: ".2rem",
          }}
          onClick={(e) => {
            e.stopPropagation();
            clipboard.copy(number);
          }}
        >
          <FileCopy style={{ fontSize: "1.3rem" }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default CopyAccountNumber;
