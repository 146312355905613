import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import ErrorBound from "./ErrorBound";

const MakeRoutes = ({ routes }) => {
  const location = useLocation();

  if (!routes) {
    return null;
  }

  const routesMap = routes.map((route, i) => {
    return (
      <Route key={i} path={route.path} exact={route?.exact}>
        <route.component {...route} />
      </Route>
    );
  });

  return (
    <ErrorBound>
      <Switch location={location}>
        {routesMap}

        <Route path="*">
          <Redirect to="/404" />
        </Route>
      </Switch>
    </ErrorBound>
  );
};

export default MakeRoutes;
