import { useQuery } from "react-query";
import { get } from "../../../../../store/api";

export const useQueryAdminEvent = (eventId) => {
  return useQuery(
    ["useQueryAdminEvent", eventId],
    async () => {
      try {
        // await new Promise((resolve: any) => {
        //   setTimeout(() => resolve(), 3000);
        // });

        const { data } = await get(
          `${import.meta.env.VITE_API}/v1/admin/events/${eventId}/details`
        );

        return data;
      } catch (err) {
        return;
      }
    },
    {
      // keepPreviousData: false,
      // staleTime: 300000,
      // refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      keepPreviousData: false,
      enabled: Boolean(eventId),
    }
  );
};
