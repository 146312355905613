export const makeLinkToClientAccounts = (
  userId: string,
  isBranch: boolean,
  isCompleted = true
) => {
  if (isBranch) {
    return isCompleted
      ? `/app/branch-admin/clients/${userId}/accounts?userId=${userId}&sortBy=currency&sortDirection=desc`
      : `/app/branch-admin/clients/${userId}/onboard`;
  }

  return isCompleted
    ? `/app/admin/clients/${userId}/accounts?userId=${userId}&sortBy=currency&sortDirection=desc`
    : `/app/admin/clients/${userId}/onboard`;
};
