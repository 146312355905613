import { green, red, yellow } from "@material-ui/core/colors";
import { ArrowDown, CreditCard } from "lucide-react";
import numbro from "numbro";
import React from "react";
import {
  formatDateToMMddyyyyHHmm,
  formatToCurrencyString,
} from "../../../../../../helpers";
import Box from "../components/Box";
import FieldBox from "../components/FieldBox";
import { getColorForState } from "../tools/getColorForState";
import CopyAccountNumber from "./../../../../../System/CopyAccountNumber";

const getColorForRisk = (risk) => {
  let color;

  if (risk === "Green") {
    color = green[400];
  }

  if (risk === "Red") {
    color = red[600];
  }

  if (risk === "Yellow") {
    color = yellow[600];
  }

  return color;
};

const CircularRisk = ({ risk }) => {
  if (risk === "Green") {
    return <div className="w-2 h-2 rounded-full bg-green-500 ml-2" />;
  }

  if (risk === "Yellow") {
    return <div className="w-2 h-2 rounded-full bg-yellow-500 ml-2" />;
  }

  if (risk === "Red") {
    return <div className="w-2 h-2 rounded-full bg-rd-500 ml-2" />;
  }

  return null;
};

const Transfer = ({ task }) => {
  const fromAccountColorRisk = getColorForRisk(
    task?.fromAccount?.ownerRiskLevelId
  );

  const toAccountColorRisk = getColorForRisk(task?.toAccount?.ownerRiskLevelId);

  const colorState = getColorForState(task);

  return (
    <>
      <Box header="">
        <div className="mb-8">
          <FieldBox
            label="Task state"
            value={task?.taskStateName}
            style={{ color: colorState }}
          />
          <>
            {task?.taskStateId === "Rejected" && (
              <FieldBox label="Reason" value={task?.internalSubject} />
            )}
          </>
          <FieldBox label="Task ID" value={task?.taskId} showCopy />
          <FieldBox label="Date:" value={formatDateToMMddyyyyHHmm(task?.ts)} />
          <FieldBox label="Client:" value={task?.firstUser?.name} />
        </div>

        <div
          className="text-xl mb-2 p-3 border-2 border-solid rounded-md flex flex-col"
          style={{ borderColor: `${fromAccountColorRisk}` }}
        >
          <div className="flex justify-between">
            <div className=" flex items-center">
              <b>
                {task?.fromAccount?.ownerName} ({task?.fromAccount?.currencyId})
              </b>

              {task.fromAccount.accountTypeId === "DebitCard" && (
                <CreditCard size={38} className="ml-2 text-main" />
              )}
            </div>
            <div className="flex items-center text-sm">
              <span>
                {task?.fromAccount?.ownerRiskLevelId === "GoldOnly"
                  ? "Client with incomplete verification"
                  : task?.fromAccount?.ownerRiskLevelId}
              </span>
              <CircularRisk risk={task?.fromAccount?.ownerRiskLevelId} />{" "}
            </div>
          </div>

          <span className="text-sm">
            {task?.fromAccount?.number}
            <CopyAccountNumber number={task?.fromAccount?.number} />{" "}
          </span>
        </div>
        <div className="flex justify-center">
          <div className="flex items-center">
            <ArrowDown size={44} className="text-green-500" />

            <span className="text-xl font-bold">
              {formatToCurrencyString(
                numbro.unformat(task?.amount || 0),
                task?.currencyId
              )}
            </span>
          </div>
        </div>
        <div
          className="text-xl mb-2 p-3 border-2 border-solid rounded-md flex flex-col"
          style={{ borderColor: `${toAccountColorRisk}` }}
        >
          <div className="flex justify-between">
            <div className=" flex items-center">
              <b>
                {task?.toAccount?.ownerName} ({task?.toAccount?.currencyId})
              </b>

              {task.toAccount.accountTypeId === "DebitCard" && (
                <CreditCard size={38} className="ml-2 text-main" />
              )}
            </div>

            <div className="flex items-center text-sm">
              <span>
                {task?.toAccount?.ownerRiskLevelId === "GoldOnly"
                  ? "Client with incomplete verification"
                  : task?.toAccount?.ownerRiskLevelId}
              </span>{" "}
              <CircularRisk risk={task?.toAccount?.ownerRiskLevelId} />
            </div>
          </div>

          <span className="text-sm">
            {task?.toAccount?.number}{" "}
            <CopyAccountNumber number={task?.toAccount?.number} />
          </span>
        </div>
      </Box>
    </>
  );
};

export default Transfer;
