import { useEffect } from "react";

export const useScript = (url, loadScript, id) => {
  useEffect(() => {
    const script = document.createElement("script");

    if (id) {
      script.id = id;
    }

    script.src = url;
    script.async = true;

    if (loadScript) {
      script.addEventListener("load", loadScript);
    }

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [id, loadScript, url]);
};
