import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import React from "react";

const theme = createTheme({
  palette: {
    primary: { main: "#1278a6", contrastText: "#fff" },
    secondary: { main: "#1278a6", contrastText: "#fff" },
  },
  typography: {
    fontFamily: "'Noto Sans JP', sans-serif",
  },
  overrides: {
    MuiButton: {
      root: {},
    },
    MuiFormLabel: {
      root: {
        color: "#444444d9",
        fontSize: "1rem",
        fontWeight: 700,
        lineHeight: "1.6",
      },
    },
    MuiInputLabel: {
      outlined: {
        zIndex: 0,
      },
    },
  },
});

const WrapPicker = ({ children }: any) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default WrapPicker;
