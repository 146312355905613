import { useAtom } from "jotai";
import React, { memo, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { formatDateSmart } from "../../../../helpers/formatDate";
import CopyAccountNumber from "../../../System/CopyAccountNumber";
import SimpleTable from "../../Admin/Reports/Features/components/SimpleTable";
import { globalParamsAtom } from "./atoms/globalParamsAtom";
import CurrenciesFilter from "./components/CurrenciesFilter";
import SearchFilter from "./components/SearchFilter";
import UnreadFilter from "./components/UnreadFilter";
import { useQueryCardRequestsCompleted } from "./queries/useQueryCardRequestsCompleted";

export default memo(() => {
  const history = useHistory();

  const [params, setParams] = useAtom(globalParamsAtom);
  const tableQuery = useQueryCardRequestsCompleted(params, true);

  const onRowClick = useCallback(
    (row) => {
      history.push(`/app/card-admin/card-requests/${row.taskId}`);
    },
    [history]
  );

  const columns = useMemo(() => {
    return [
      {
        label: "ID",
        id: "taskId",
        nowrap: true,
        render: (rowData) => (
          <span>
            {rowData?.taskId} <CopyAccountNumber number={rowData?.taskId} />
          </span>
        ),
      },
      {
        label: "Status",
        id: "taskStateId",
        nowrap: true,
        render: (rowData) => <span>{rowData?.taskStateName}</span>,
      },
      {
        label: "Currency",
        id: "currencyId",
        nowrap: true,
        render: (rowData) => <span>{rowData?.currencyId}</span>,
      },
      {
        label: "Type",
        disableSortBy: true,
        nowrap: true,
        render: (rowData) => {
          return <span>{rowData?.paymentPurposeName || ""}</span>;
        },
      },
      {
        label: "From",
        id: "firstUserName",
        nowrap: true,
        render: (rowData) => (
          <span className="text-ellipsis overflow-hidden whitespace-nowrap w-32">
            {rowData?.fromUserName}
          </span>
        ),
      },

      {
        label: "Date",
        nowrap: true,
        id: "ts",
        render: (rowData) => formatDateSmart(rowData?.ts),
      },
    ];
  }, []);

  return (
    <div className="w-full">
      <div className="flex flex-wrap flex-row items-end w-full flex-1 mb-2">
        <SearchFilter params={params} setParams={setParams} />

        <UnreadFilter params={params} setParams={setParams} />

        <CurrenciesFilter params={params} setParams={setParams} />
      </div>

      <SimpleTable
        id="tableTasksCardRequests"
        columns={columns}
        queryData={tableQuery}
        setParams={setParams}
        params={params}
        onRowClick={onRowClick}
      />
    </div>
  );
});
