import { store } from "@risingstack/react-easy-state";
import ENDPOINTS from "../../endpoints";
import { del, get, patch, post, showError } from "../api";

const blocks = store({
  loading: false,
  sections: {},

  itemsBySettings: [],

  getBlockById(id: string, section: string) {
    if (id && section) {
      const sectionData = blocks.sections[section];

      if (sectionData) {
        const data = sectionData?.find((item: { id: any }) => item?.id === id);

        if (data) {
          return data;
        }
      }
    }

    return null;
  },

  async fetchSections() {
    try {
      const { data } = await get(ENDPOINTS.blocks.fetchSections);

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async fetchBlocksBySection(params: { sectionId: any }) {
    try {
      blocks.loading = true;

      const { data } = await get(
        ENDPOINTS.blocks.fetchBlocksBySection.replace(
          "{sectionId}",
          params?.sectionId
        )
      );

      blocks.itemsBySettings = data;

      blocks.sections[params?.sectionId] = data;

      blocks.loading = false;
      return data;
    } catch (err) {
      showError(err);
      blocks.loading = false;
      return false;
    }
  },

  async fetchBlock(id: string) {
    try {
      const { data } = await get(ENDPOINTS.blocks.block.replace("{id}", id));

      return data;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async deleteBlock(id: string) {
    try {
      await del(ENDPOINTS.blocks.block.replace("{id}", id));

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async changeBlock(id: string, payload: Record<string, any> | undefined) {
    try {
      await patch(ENDPOINTS.blocks.block.replace("{id}", id), payload);

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },

  async createBlock(payload: Record<string, any> | undefined) {
    try {
      await post(ENDPOINTS.blocks.createBlock, payload);

      return true;
    } catch (err) {
      showError(err);
      return false;
    }
  },
});

export default blocks;
