import React, { memo } from "react";

export default memo(
  ({
    label,
    children,
    id,
  }: {
    label: string;
    children?: React.ReactNode;
    id?: string;
  }) => {
    return (
      <div className="w-full border border-main rounded-md px-4 py-4">
        <h1 id={id} className="text-lg text-black">
          {label}
        </h1>

        <div className="w-full">{children}</div>
      </div>
    );
  }
);
