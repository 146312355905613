import { view } from "@risingstack/react-easy-state";
import React, { memo } from "react";
import ui from "../../store/modules/ui";
import Anima from "../System/Anima";
import LazyImage from "../System/LazyImage";

const LazyAnima = memo(() => {
  return <Anima json={"/keys/superdog.json"} className="w-28 h-28" />;
});

const HeaderLoading = view(() => {
  return ui.loadingOperation ? (
    <div className="flex items-center justify-center fixed inset-x-0 bottom-0 bg-white py-4 px-0 z-[2000] w-full shadow-md shadow-black/20">
      <div className="mr-2.5">
        <LazyImage anima={LazyAnima} />
      </div>

      <b
        className="w-80 -ml-8 relative"
        dangerouslySetInnerHTML={{ __html: ui.textLoading }}
      />
    </div>
  ) : null;
});

export default HeaderLoading;
