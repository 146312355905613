import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import { CSSProperties } from "@material-ui/styles";
import React from "react";
import IMaterialSize from "../../interfaces/materialSize";

const useStyles = makeStyles({
  paper: {
    width: "100%",
  },
  close: {
    position: "absolute",
    top: 0,
    right: 0,
  },
});

interface IAlert {
  ref?: React.Ref<undefined>;
  open: boolean;
  setOpen?: any;
  content: any;
  size?: IMaterialSize["size"];
  customClose?: () => void;
  id?: string;
  style?: CSSProperties;
  styleContent?: CSSProperties;
  styleDialog?: CSSProperties;
  hideCloseBtn?: boolean;
  onClose?: () => void;
  customTitle?: any;
  onScroll?: () => void;
  disabledClose?: boolean;
  disabledCloseBackground?: boolean;
}

const Alert = ({
  ref,
  open,
  setOpen,
  content,
  size = "sm",
  customClose,
  styleContent,
  hideCloseBtn,
  onClose,
  customTitle,
  onScroll,
  styleDialog,
  disabledClose,
  disabledCloseBackground,
}: IAlert) => {
  const classes = useStyles();

  const close = customClose
    ? customClose
    : () => {
        if (!disabledClose) {
          setOpen(false);
        }

        if (onClose) {
          onClose();
        }
      };

  return (
    <Dialog
      ref={ref}
      disableEnforceFocus={true}
      classes={{ paper: classes.paper }}
      style={styleDialog}
      open={open}
      onClick={(e) => e.stopPropagation()}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClose={disabledCloseBackground ? () => {} : close}
      maxWidth={size}
      fullWidth
    >
      <DialogTitle>
        {!hideCloseBtn && (
          <IconButton onClick={close} className={classes.close}>
            <Close />
          </IconButton>
        )}
        {customTitle}
      </DialogTitle>
      <DialogContent style={styleContent || {}} onScroll={onScroll}>
        {content}
      </DialogContent>
    </Dialog>
  );
};

export default Alert;
