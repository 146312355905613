import { view } from "@risingstack/react-easy-state";
import React from "react";
import ui from "../../store/modules/ui";
import { Alert, FormActions } from "../System";

const DialogAccessDenied = view(() => {
  return (
    <Alert
      open={ui.openDialogAccessDenied}
      setOpen={() => (ui.openDialogAccessDenied = false)}
      customTitle="Access Denied"
      content={
        <div>
          <p>Please contact support at support@remium.uk</p>

          <FormActions
            showCloseButton
            onClickClose={() => (ui.openDialogAccessDenied = false)}
          />
        </div>
      }
      size="xs"
    />
  );
});

export default DialogAccessDenied;
