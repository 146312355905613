import { Send, Slash } from "lucide-react";
import React from "react";
import {
  formatDateToMMddyyyyHHmm,
  formatToCurrencyString,
} from "../../../../../../helpers";
import ITask from "../../../../../../interfaces/task";
import IWireRecipient from "../../../../../../interfaces/wireRecipient";
import Box from "../components/Box";
import FieldBox from "../components/FieldBox";
import { useFetchUser } from "../hooks/useFetchUser";
import { getColorForState } from "../tools/getColorForState";

const SendWire = ({
  task,
  recipient,
}: {
  task?: ITask;
  recipient?: IWireRecipient;
}) => {
  // const taskTypeBooleans = getTaskTypeBooleans(task);

  const { user } = useFetchUser(task?.firstUser?.id);

  const arrUserAddress: string[] = [];

  const colorState = getColorForState(task);

  if (user?.address1) {
    arrUserAddress.push(user.address1);
  }

  if (user?.address2) {
    arrUserAddress.push(user?.address2);
  }

  if (user?.city) {
    arrUserAddress.push(user?.city);
  }

  if (user?.stateOrProvince) {
    arrUserAddress.push(user?.stateOrProvince);
  }

  if (user?.countryName) {
    arrUserAddress.push(user?.countryName);
  }

  if (user?.postalCode) {
    arrUserAddress.push(user?.postalCode);
  }

  const arrBankAddress: string[] = [];

  if (recipient?.bankAddress) {
    arrBankAddress.push(recipient?.bankAddress);
  }

  if (recipient?.bankCity) {
    arrBankAddress.push(recipient?.bankCity);
  }

  if (recipient?.bankCountryName) {
    arrBankAddress.push(recipient?.bankCountryName);
  }

  if (recipient?.bankPostalCode) {
    arrBankAddress.push(recipient?.bankPostalCode);
  }
  const arrRecipientAddress: string[] = [];

  if (recipient?.recipientAddress1) {
    arrRecipientAddress.push(recipient?.recipientAddress1);
  }

  if (recipient?.recipientAddress2) {
    arrRecipientAddress.push(recipient?.recipientAddress2);
  }

  if (recipient?.recipientCity) {
    arrRecipientAddress.push(recipient?.recipientCity);
  }

  if (recipient?.recipientCountryName) {
    arrRecipientAddress.push(recipient?.recipientCountryName);
  }

  if (recipient?.recipientPostalCode) {
    arrRecipientAddress.push(recipient?.recipientPostalCode);
  }

  return (
    <>
      <Box header="">
        <FieldBox
          label="Task state"
          value={task?.taskStateName}
          style={{ color: colorState }}
        />

        <>
          {task?.taskStateId === "Rejected" && (
            <FieldBox label="Reason" value={task?.internalSubject} />
          )}
        </>

        <FieldBox label="Task ID" value={task?.taskId} showCopy />

        <FieldBox label="Date:" value={formatDateToMMddyyyyHHmm(task?.ts)} />

        <FieldBox label="Payment system:" value={task?.taskTypeName} />

        <FieldBox
          label="Auto status:"
          value={
            <div className="flex">
              {task?.autoPossible ? (
                <Send size={44} className="text-main mr-1" />
              ) : (
                <Slash size={44} className="text-red-500 mr-1" />
              )}
              <span className="break-words">{task?.autoStatus}</span>
            </div>
          }
        />

        <FieldBox
          label="Amount:"
          value={formatToCurrencyString(
            task?.amount as number,
            task?.currencyId as string
          )}
        />

        <FieldBox
          label="Currency:"
          value={
            <div className="flex">
              <img
                src={`/currencies/${task?.currencyId?.toLowerCase()}.svg`}
                className="w-6 min-w-6 mr-2"
              />
              <span>{task?.currencyId}</span>
            </div>
          }
        />

        <FieldBox
          label="Message to recipient's bank:"
          value={task?.messageToBank}
        />

        <FieldBox
          label="Message to recipient:"
          value={task?.messageToRecipient}
        />

        <FieldBox label="Payment purpose:" value={task?.paymentPurposeName} />

        <FieldBox label="Memo:" value={task?.memo} />
      </Box>

      <Box header="Customer (Ultimate Debtor)">
        <FieldBox label="User name:" value={task?.firstUser?.name} showCopy />

        <FieldBox label="Address:" value={arrUserAddress.join(", ")} />

        <FieldBox
          label="Account:"
          value={`${task?.fromAccount?.number} (${task?.currencyId})`}
          showCopy
          copyValue={task?.fromAccount?.number}
        />
      </Box>

      <Box header="Recipient">
        <FieldBox label="Recipient name:" value={recipient?.recipientName} />

        <FieldBox
          label="Recipient address:"
          value={arrRecipientAddress.join(", ")}
          className="font-normal"
        />

        <FieldBox label="Bank name:" value={recipient?.bankName} />

        <FieldBox label="BIC code:" value={recipient?.swift} />

        <FieldBox
          label="Account number:"
          value={recipient?.recipientAccountNumber}
        />

        <FieldBox
          label="Bank address:"
          value={arrBankAddress.join(", ")}
          className="font-normal"
        />
      </Box>
    </>
  );
};

export default SendWire;
