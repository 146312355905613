import numbro from "numbro";
import React, { memo, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useQueryClient } from "react-query";
import { formatToCurrencyString } from "../../../../../helpers";
import admin from "../../../../../store/modules/admin";
import exchange from "../../../../../store/modules/exchange";
import MigomCryptoSender from "../../../../MigomCryptoSender";
import { Alert, AreYouSure, FormActions } from "../../../../System";
import MyAmount from "../../../../System/FormComponents/MyAmount";
import MyCheckboxLabel from "../../../../System/FormComponents/MyCheckboxLabel";
import MyTextField from "../../../../System/FormComponents/MyTextField";
import LazyImage from "../../../../System/LazyImage";
import Preview from "../components/Preview";
import SfoxExchangeDetail from "../components/SfoxExchangeDetail";
import IAccount from "./../../../../../interfaces/account";
import AutocompleteFromAccount from "./components/AutocompleteFromAccount";
import AutocompleteToAccount from "./components/AutocompleteToAccount";
import BuyAmount from "./components/BuyAmount";
import CalcBuySell from "./components/CalcBuySell";
import CurrentRate from "./components/CurrentRate";
import InitComment from "./components/InitComment";
import SellAmount from "./components/SellAmount";
import Socket from "./components/Socket";
import Title from "./components/Title";
import ZeroingAmount from "./components/ZeroingAmount";
import { useInitAccounts } from "./hooks/useInitAccounts";

const Exchange = memo(
  ({
    currentAccount,
    toAccountId,
    setOpen,
    refresh,
    amount,
    taskId,
    open,
    confirmCallback,
  }: {
    currentAccount?: IAccount;
    toAccountId?: string;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
    refresh: () => void;
    amount?: number;
    taskId?: string;
    confirmCallback?: () => void;
  }) => {
    const queryClient = useQueryClient();

    const [openAreYouSure, setOpenAreYouSure] = useState(false);
    const [loading, setLoading] = useState(false);
    const [previewData, setPreviewData] = useState<any>();
    const [step, setStep] = useState(1);
    const [rate, setRate] = useState();
    const [cursor, setCursor] = useState("");

    const form = useForm({
      defaultValues: {
        fromAccountId: "",
        toAccountId: "",
        sellAmount: "",
        sellCurrencyId: "",
        buyAmount: "",
        buyCurrencyId: "",
        useSfox: false,
        takeFixedFee: false,
        fixedFee: "",
        hiddenFee: "",
        rate: "",

        /*  from_comment: "",
        to_comment: "",

        transactionTime: null, */
      },
      mode: "onChange",
      shouldUnregister: false,
    });

    useInitAccounts(form, currentAccount, toAccountId);

    const { setValue, getValues } = form;

    useEffect(() => {
      if (amount) {
        setTimeout(() => setValue("sellAmount", amount?.toString()), 0);
        setCursor("sell");
      }
    }, [setValue, amount]);

    const preview = async (values) => {
      if (!values.rate) {
        return;
      }

      setLoading(true);

      const sa = numbro.unformat(values.sellAmount) || 0;
      const ba = numbro.unformat(values.buyAmount) || 0;

      // START

      const unformattedFixedFee = numbro.unformat(values.fixedFee);

      const payload: any = {
        fromAccountId: values?.fromAccountId,
        toAccountId: values?.toAccountId,
        pendingTransactionId: taskId,
        fixedFee:
          values.takeFixedFee && unformattedFixedFee >= 0
            ? unformattedFixedFee
            : undefined,
        hiddenFee: values?.hiddenFee
          ? numbro.unformat(values?.hiddenFee)
          : null,
      };

      if (cursor === "buy") {
        payload.buyAmount = ba;
      } else {
        payload.sellAmount = sa;
      }

      const [status, result] = await exchange.exchangePreviewByAdmin(payload);

      if (status) {
        if (result?.cryptoOperation) {
          setTimeout(() => setValue("useSfox", true), 0);
        }

        setPreviewData(result);
        setStep(2);
      }

      setLoading(false);
    };

    const [success, setSuccess] = useState(false);

    const confirm = async () => {
      const values = getValues();

      if (!values.useSfox && previewData?.cryptoOperation) {
        delete previewData.cryptoOperation;
      }

      setLoading(true);
      setOpenAreYouSure(false);

      const result = await admin.confirm(previewData);

      if (result) {
        queryClient.refetchQueries([`useQueryClientAccounts`]);
        if (confirmCallback) {
          confirmCallback();
        }

        refresh();
        setSuccess(true);
      }

      setLoading(false);
    };

    const sfox = previewData?.cryptoOperation || {};

    const useSfox = useWatch({
      name: "useSfox",
      control: form.control,
    });

    const takeFixedFee = useWatch({
      name: "takeFixedFee",
      control: form.control,
    });

    const [changeRate, setChangeRate] = useState(true);

    useEffect(() => {
      if (changeRate && rate) {
        setValue("rate", (rate || "") + "");
      }
    }, [changeRate, rate, setValue]);

    const rateField = useWatch({
      name: "rate",
      control: form.control,
    });

    return (
      <Alert
        open={open}
        setOpen={setOpen}
        size={step === 1 ? "sm" : "md"}
        content={
          <>
            <ZeroingAmount form={form} currentAccount={currentAccount} />

            <InitComment form={form} userId={currentAccount?.userId} />

            <Socket
              form={form}
              setRate={setRate}
              userId={currentAccount?.userId}
            />
            <CalcBuySell form={form} cursor={cursor} />

            <Title userId={currentAccount?.userId} form={form} />

            {step === 1 && (
              <>
                <div className="px-2 py-2 border-2 border-gray-200 rounded-lg mb-2">
                  <div className="flex mb-2">
                    <div className="flex-1 mr-6">
                      <AutocompleteFromAccount
                        form={form}
                        setRate={setRate}
                        currentAccount={currentAccount}
                      />
                    </div>

                    <div className="flex flex-1">
                      <SellAmount
                        form={form}
                        cursor={cursor}
                        setCursor={setCursor}
                        userId={currentAccount?.userId}
                      />
                    </div>
                  </div>

                  <div className="mb-2">
                    <MyCheckboxLabel
                      name="takeFixedFee"
                      label="Take Fixed Fee"
                      control={form.control}
                    />

                    {takeFixedFee && (
                      <MyAmount
                        control={form.control}
                        controlCurrency={form.control}
                        name="fixedFee"
                        label="Fixed Fee (the client pays us)"
                        currencyName="sellCurrencyId"
                      />
                    )}
                  </div>
                  {/*
                  <MyTextField
                    {...form}
                    label="Comment"
                    name="from_comment"
                    fullWidth
                    multiline
                    rows={2}
                  />{" "}
                  */}
                </div>

                <div className="flex justify-between">
                  <CurrentRate
                    form={form}
                    // userId={currentAccount?.userId}
                    setChangeRate={setChangeRate}
                  />

                  <div>
                    <MyTextField
                      control={form.control}
                      label="Hidden (Markup) Fee (%)"
                      name="hiddenFee"
                      fullWidth
                    />
                  </div>
                </div>

                <div className="mt-2 px-2 py-2 border-2 border-gray-200 rounded-lg">
                  <div className="flex mb-2">
                    <div className="flex-1 mr-6">
                      <AutocompleteToAccount
                        form={form}
                        setRate={setRate}
                        currentAccount={currentAccount}
                      />
                    </div>

                    <div className="flex flex-1">
                      <BuyAmount
                        form={form}
                        cursor={cursor}
                        setCursor={setCursor}
                      />
                    </div>
                  </div>
                  {/* 
                  <MyTextField
                    {...form}
                    label="Comment"
                    name="to_comment"
                    fullWidth
                    multiline
                    rows={2}
                  /> */}
                </div>
                {/* 
                <div className="max-w-[160px] mt-2">
                  <MyDate
                    {...form}
                    name="transactionTime"
                    label="Transaction date"
                  />
                </div>
 */}
                {!rateField && cursor && (
                  <div className="mt-2 mb-2 text-right text-red-600 font-bold">
                    We are having technical problems getting the exchange rate.
                    We will fix the problem soon. Please try again later
                  </div>
                )}

                <FormActions
                  showCloseButton
                  showNextButton
                  onClickNext={form.handleSubmit(preview)}
                  onClickClose={() => setOpen(false)}
                  nextButtonLabel="Next"
                  loading={loading}
                  disabledNextButton={loading || !rateField}
                />
              </>
            )}

            {step === 2 && (
              <Preview
                data={previewData}
                form={form}
                setStep={setStep}
                confirm={() => setOpenAreYouSure(true)}
                loading={loading}
                setOpen={setOpen}
                hideConfirmButtons={success}
              />
            )}

            <AreYouSure
              open={openAreYouSure && useSfox}
              customIcon={
                currentAccount?.currencyId === "MIGG" ? (
                  <MigomCryptoSender />
                ) : (
                  <LazyImage
                    src="/images/design/sfox_logo.png"
                    className="w-40"
                  />
                )
              }
              setOpen={setOpenAreYouSure}
              confirmCallback={confirm}
              loading={loading}
              disabledConfirmCallback={loading}
              confirmLabel="Perform Exchange"
              customText={
                <div className="mt-4">
                  <h2 className="mb-2">
                    Ready to exchange{" "}
                    {formatToCurrencyString(
                      sfox?.sellAmount || 0,
                      sfox?.fromAccount?.currencyId
                    )}{" "}
                    -&gt;{" "}
                    {formatToCurrencyString(
                      sfox?.buyAmount || 0,
                      sfox?.toAccount?.currencyId
                    )}
                  </h2>

                  <SfoxExchangeDetail showDetail sfox={sfox} />
                </div>
              }
              hideCloseButton
            />

            <AreYouSure
              open={openAreYouSure && !useSfox}
              setOpen={setOpenAreYouSure}
              confirmCallback={confirm}
              loading={loading}
              disabledConfirmCallback={loading}
              confirmLabel="Make Payment"
              hideCloseButton
            />
          </>
        }
      />
    );
  }
);

export default Exchange;
