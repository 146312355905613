import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useCallback, useEffect, useState } from "react";
import { useDebounce } from "../../../hooks/useDebounce";

const CustomAutocompleteFetch = ({
  options,
  label,
  fetch,
  getOptionLabel,
  getOptionSelected,
  filterSelectedOptions,
  noOptionsText,
  setOptions,
  multiple,
  value,
  onChange,
  placeholder,
  disabled = false,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [customValue, setValueCustom] = useState("");
  const [items, setItems] = useState(options);

  const debouncedValue = useDebounce(customValue, 500);

  const run = useCallback(
    async (value: any) => {
      setLoading(true);

      const result = await fetch({ search: value });

      if (result && setOptions) {
        setOptions(result);
      }
      setLoading(false);
    },
    [fetch, setOptions]
  );

  useEffect(() => {
    if (debouncedValue && fetch) {
      run(debouncedValue);
    }
  }, [run, debouncedValue, fetch]);

  useEffect(() => {
    if (!fetch) {
      setItems(() =>
        debouncedValue
          ? options.filter((item) =>
              item?.toLowerCase()?.includes(debouncedValue?.toLowerCase() || "")
            )
          : options
      );
    }
  }, [debouncedValue, fetch, options]);

  return (
    <Autocomplete
      value={value}
      options={fetch ? options : items}
      filterSelectedOptions={filterSelectedOptions}
      noOptionsText={noOptionsText}
      multiple={multiple}
      filterOptions={fetch ? undefined : (items) => items}
      disabled={disabled}
      renderInput={(props) => (
        <TextField
          {...props}
          label={label}
          InputLabelProps={{
            shrink: true,
          }}
          autoComplete="off"
          inputProps={{
            ...props.inputProps,
            autoComplete: "new-password",
          }}
          fullWidth
          placeholder={placeholder}
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="primary" size={20} />
                ) : null}
                {props.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      getOptionLabel={getOptionLabel}
      onChange={(e, value, reason) => {
        if (reason === "select-option" || reason === "remove-option") {
          onChange(e, value);
        }
      }}
      onInputChange={(e, value, reason) => {
        if (reason === "input") {
          setValueCustom(value);
        } else if (reason === "clear") {
          onChange(e, []);
        }
      }}
      getOptionSelected={getOptionSelected}
      fullWidth
    />
  );
};

export default CustomAutocompleteFetch;
