import React, { useState } from "react";
import { useWatch } from "react-hook-form";
import MyCheckboxLabel from "../../../../System/FormComponents/MyCheckboxLabel";
import ShowAndHide from "../../../../System/FormComponents/ShowAndHide";
import SfoxExchangeDetail from "./SfoxExchangeDetail";

const SfoxExchange = ({ data, ...form }) => {
  const [showDetail, setShowDetail] = useState(true);

  const useSfox = useWatch({
    control: form?.control,
    name: "useSfox",
  });

  const sfoxObject = data?.cryptoOperation || {};

  const sfox = data?.cryptoOperation;

  const currency = sfoxObject?.cryptoCurrencyId?.toUpperCase();

  return (
    <ShowAndHide show={Boolean(sfox)}>
      <div className="flex flex-col">
        <div className="flex flex-1 w-full justify-between">
          {useSfox && (
            <div className="flex flex-col">
              <b className="text-orange-400">
                {currency === "MIGG" ? "Remium Crypto Sender" : "SFOX"}{" "}
                Operation
              </b>
              <a
                onClick={() => setShowDetail((prevState) => !prevState)}
                className="cursor-pointer text-base font-bold"
              >
                {!showDetail ? "Show Detail" : "Close Detail"}
              </a>
            </div>
          )}

          <MyCheckboxLabel
            control={form.control}
            name="useSfox"
            label={`I want to confirm using ${
              currency === "MIGG" ? "Remium Crypto Sender" : "SFOX"
            }`}
          />
        </div>

        {useSfox && (
          <SfoxExchangeDetail showDetail={showDetail} sfox={sfoxObject} />
        )}
      </div>
    </ShowAndHide>
  );
};

export default SfoxExchange;
