const PLEASE_WAIT = "Please wait until the end of the operation";
const STARTED = "The requested transaction is being processed";
const ERROR = "The operation was completed with an error";
const SUCCESS = "The operation was completed successfully";

export const en = {
  exchange: {
    finish: {
      loading: PLEASE_WAIT,
      started: STARTED,
      success: "Exchange completed successfully!",
      error: "The exchange was completed with an error",
    },
  },
  deposit: {
    finish: {
      success:
        "Request for a deposit in the amount of {amount} to account number {shortNumber} sent",
      loading: PLEASE_WAIT,
    },
  },
  payInvoice: {
    finish: {
      successFullPayAmount: "You have successfully paid the invoice in full",
      successPartiallyAmount:
        "You have successfully partially paid the invoice.",
      loading: PLEASE_WAIT,
      error: ERROR,
    },
  },
  request: {
    finish: {
      success: STARTED,
      loading: PLEASE_WAIT,
      error: ERROR,
    },
  },
  requestFromMyCustomer: {
    finish: {
      success: STARTED,
      loading: PLEASE_WAIT,
      error: ERROR,
    },
  },
  sendToCryptoRecipients: {
    finish: {
      success: STARTED,
      loading: PLEASE_WAIT,
      error: ERROR,
    },
  },
  sendToMigomUser: {
    finish: {
      success: STARTED,
      loading: PLEASE_WAIT,
      error: ERROR,
    },
  },
  sendToMyCustomer: {
    finish: {
      success: STARTED,
      loading: PLEASE_WAIT,
      error: ERROR,
    },
  },
  sendWire: {
    finish: {
      success:
        "The requested wire transfer in the amount of {amount} from account number {shortNumber} to {recipientName} is being processed",
      loading: PLEASE_WAIT,
      error: ERROR,
    },
  },
  topUp: {
    finish: {
      success:
        "The requested top up in the amount of {amount}  is being processed",
      loading: PLEASE_WAIT,
      error: ERROR,
    },
  },
  transferPlaid: {
    finish: {
      successMultiCurrency:
        "We initiated transfer from your account {selectedFromAccountName} to your account with {toAccountShortNumber}",
      successSpan:
        "You have successfully initiated transfer of {amount} from your {selectedFromAccountName} account to your {toAccountShortNumber}.Please note, this transfer will be completed in 5-7 bushiness days.",

      loading: PLEASE_WAIT,
      error: ERROR,
    },
  },
  adminCreateAccount: {
    finish: {
      success: SUCCESS,
      loading: PLEASE_WAIT,
    },
  },
  adminCreateStaff: {
    finish: {
      success: SUCCESS,
      loading: PLEASE_WAIT,
    },
  },
  depositBranchAdmin: {
    finish: {
      success: SUCCESS,
      loading: PLEASE_WAIT,
    },
  },
  withdrawBranchAdmin: {
    finish: {
      success: SUCCESS,
      loading: PLEASE_WAIT,
    },
  },
  transactive: {
    finish: {
      success: SUCCESS,
      loading: PLEASE_WAIT,
    },
  },
  sendPush: {
    finish: {
      success: SUCCESS,
      loading: PLEASE_WAIT,
    },
  },
  cryptoRecipients: {
    addCrypto: {
      finish: {
        loading: PLEASE_WAIT,
      },
    },
  },
  transfer: {
    finish: {
      loading: PLEASE_WAIT,
    },
  },
  activateDebitCard: {
    finish: {
      loading: PLEASE_WAIT,
      success: "Congratulations! Your card has been activated.",
    },
  },
  branches: {
    addParent: {
      finish: {
        loading: PLEASE_WAIT,
        success: "The operation was completed successfully.",
      },
    },
    editParent: {
      finish: {
        loading: PLEASE_WAIT,
        success: "The operation was completed successfully.",
      },
    },
  },
};
