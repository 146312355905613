import { ArrowRightAlt } from "@material-ui/icons";
import React from "react";
import { useGetAccounts } from "../hooks/useGetAccounts";

const Title = ({ form, userId }) => {
  const { fromAccount, toAccount } = useGetAccounts({ userId, form });

  return (
    <h2 className="text-lg mb-6 block">
      <b>
        Exchange {fromAccount?.currencyId}{" "}
        {toAccount?.currencyId ? <ArrowRightAlt /> : null}{" "}
        {toAccount?.currencyId}
      </b>
    </h2>
  );
};

export default Title;
