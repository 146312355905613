import React, { memo, useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const MyEditor = memo(({ defaultValue = "", onChange, className }: any) => {
  const [df, setDf] = useState("");

  const init = useRef(false);

  useEffect(() => {
    if (!init.current && defaultValue) {
      init.current = true;
      setDf(defaultValue);
    }
  }, [defaultValue]);

  return (
    <ReactQuill
      key={df?.length}
      defaultValue={df}
      onChange={onChange}
      className={className ? className : "h-80"}
    />
  );
});

export default MyEditor;
