import { Button, LinearProgress } from "@material-ui/core";
import { AlertCircle, ThumbsUp, XOctagon } from "lucide-react";
import React, { memo, useCallback, useEffect, useState } from "react";
import { get, showError } from "../../../../../../store/api";
import CopyAccountNumber from "../../../../../System/CopyAccountNumber";

export default memo(({ address, currency, taskId }: any) => {
  const [response, setResponse] = useState<any>({});

  const risk = +((response?.data?.riskscore || 0) * 100).toFixed(2);

  const [showDetails, setShowDetails] = useState(false);
  const [showChainDetails, setShowChainDetails] = useState(false);
  const [chainMessage, setChainMessage] = useState("");

  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);

  const checkBot = useCallback(async () => {
    if (!address || !currency) {
      return;
    }

    let net = "ETH";

    if (currency === "BTC") {
      net = "BTC";
    }

    try {
      const { data } = await get(
        `${import.meta.env.VITE_API}/v1/admin/bot-check/${address}`,
        {
          currency: net,
          taskId: taskId,
        }
      );

      setResponse(JSON.parse(data));
      setLoading1(false);
    } catch (err) {
      console.log(err);
      setLoading1(false);

      showError(err);
    }
  }, [address, currency, taskId]);

  const checkChain = useCallback(async () => {
    if (!address || !currency) {
      return;
    }
    try {
      const { data } = await get(
        `${import.meta.env.VITE_API}/v1/admin/chain-check/${address}`,
        {
          currency,
        }
      );

      setChainMessage("OK");
      setLoading2(false);
      console.log(data);
    } catch (err) {
      console.log(err);

      setChainMessage((err as any)?.response?.data?.description);
      setLoading2(false);
    }
  }, [address, currency]);

  useEffect(() => {
    checkBot();
    checkChain();
  }, [checkChain, checkBot]);

  return (
    <div className="w-full">
      {(loading1 || loading2) && <LinearProgress />}

      <div className="flex mb-4">
        <div className=" mr-2 font-bold inline-flex text-main mb-4 px-3 py-1 border border-gray-300 rounded-lg">
          <span>{currency}</span>
        </div>

        <div className=" mr-2 font-bold inline-flex text-main mb-4 px-3 py-1 border border-gray-300 rounded-lg">
          <span>
            {address} <CopyAccountNumber number={address} />
          </span>
        </div>
      </div>

      {loading1 && (
        <h2 className="text-lg text-center">
          AML Bot is checking, please wait...
        </h2>
      )}

      {!loading1 && (
        <>
          <div className="flex mb-2">
            <div className="mr-2">
              <img
                className=" object-cover max-w-[10rem] w-full"
                src="/crypto-scan/amlbot.svg"
              />
            </div>

            {response?.devModeUsed && (
              <div className=" mr-2 font-bold inline-flex text-main mb-4 px-3 py-1 border border-gray-300 rounded-lg">
                <span>Test Mode</span>
              </div>
            )}

            {response?.result === false && (
              <div className=" mr-2 font-bold inline-flex text-black mb-4 px-3 py-1 border border-red-500 rounded-lg">
                <span>Check failed</span>
              </div>
            )}
          </div>

          <div className="grid gap-12 grid-cols-1 lg:grid-cols-3 mb-4">
            <div className=" col-span-2">
              {risk > 0 && risk <= 25 && (
                <div className="flex text-green-500 items-center">
                  <h2 className=" m-0 mr-2 text-[2rem] font-normal">
                    Low risk address
                  </h2>{" "}
                  <div>
                    <ThumbsUp size={40} />
                  </div>
                </div>
              )}

              {risk > 25 && risk <= 75 && (
                <div className="flex text-orange-500 items-center">
                  <h2 className=" m-0 mr-2 text-4xl font-normal">
                    Medium risk address
                  </h2>
                  <div>
                    <AlertCircle size={40} />
                  </div>
                </div>
              )}

              {risk > 75 && (
                <div className="flex text-red-600 items-center">
                  <h2 className=" m-0 mr-2 text-4xl font-normal">
                    High risk address
                  </h2>
                  <div>
                    <XOctagon size={40} />
                  </div>
                </div>
              )}

              <div className="flex justify-end mt-2">
                <Button
                  onClick={() => {
                    setShowDetails(!showDetails);
                  }}
                  variant="contained"
                  color="primary"
                >
                  <span className="text-sm">
                    {showDetails ? "Hide Details" : "Show Details"}
                  </span>
                </Button>
              </div>
            </div>

            <div className=" self-start w-full flex flex-col shadow-[0_-1px_30px_-10px_rgba(0,0,0,0.3)] rounded-xl p-4">
              <span className="text-[2rem] font-bold">{risk}%</span>
              <span className="text-lg">Risk assessment</span>
            </div>
          </div>

          {showDetails && (
            <>
              <div className="flex">
                <div
                  className="bg-green-300 h-[1rem] rounded-sm mr-1"
                  style={{
                    width: `${
                      response?.data?.signals?.exchange_mlrisk_low * 100
                    }%`,
                  }}
                  title={`Low-Risk Exchange: ${
                    response?.data?.signals?.exchange_mlrisk_low * 100
                  }%`}
                ></div>

                <div
                  className="bg-green-300 h-[1rem] rounded-sm mr-1"
                  style={{
                    width: `${response?.data?.signals?.marketplace * 100}%`,
                  }}
                  title={`Marketplace: ${
                    response?.data?.signals?.marketplace * 100
                  }%`}
                ></div>

                <div
                  className="bg-green-300 h-[1rem] rounded-sm mr-1"
                  style={{
                    width: `${response?.data?.signals?.miner * 100}%`,
                  }}
                  title={`Miner: ${response?.data?.signals?.miner * 100}%`}
                ></div>

                <div
                  className="bg-blue-500 h-[1rem] rounded-sm mr-1"
                  style={{
                    width: `${response?.data?.signals?.other * 100}%`,
                  }}
                  title={`Other: ${response?.data?.signals?.other * 100}%`}
                ></div>

                <div
                  className="bg-green-300 h-[1rem] rounded-sm mr-1"
                  style={{
                    width: `${
                      response?.data?.signals?.p2p_exchange_mlrisk_low * 100
                    }%`,
                  }}
                  title={`Low-Risk P2P Exchange: ${
                    response?.data?.signals?.p2p_exchange_mlrisk_low * 100
                  }%`}
                ></div>

                <div
                  className="bg-green-300 h-[1rem] rounded-sm mr-1"
                  style={{
                    width: `${response?.data?.signals?.payment * 100}%`,
                  }}
                  title={`Payment Management: ${
                    response?.data?.signals?.payment * 100
                  }%`}
                ></div>

                <div
                  className="bg-purple-300 h-[1rem] rounded-sm mr-1"
                  style={{
                    width: `${response?.data?.signals?.wallet * 100}%`,
                  }}
                  title={`Wallet: ${response?.data?.signals?.wallet * 100}%`}
                ></div>

                <div
                  className="bg-orange-500 h-[1rem] rounded-sm mr-1"
                  style={{
                    width: `${
                      response?.data?.signals?.exchange_mlrisk_high * 100
                    }%`,
                  }}
                  title={`High-Risk Exchange: ${
                    response?.data?.signals?.exchange_mlrisk_high * 100
                  }%`}
                ></div>

                <div
                  className="bg-orange-700 h-[1rem] rounded-sm mr-1"
                  style={{
                    width: `${
                      response?.data?.signals?.exchange_mlrisk_moderate * 100
                    }%`,
                  }}
                  title={`Moderate-Risk Exchange: ${
                    response?.data?.signals?.exchange_mlrisk_moderate * 100
                  }%`}
                ></div>

                <div
                  className="bg-orange-800 h-[1rem] rounded-sm mr-1"
                  style={{
                    width: `${
                      response?.data?.signals?.exchange_mlrisk_veryhigh * 100
                    }%`,
                  }}
                  title={`Very High-Risk Exchange: ${
                    response?.data?.signals?.exchange_mlrisk_veryhigh * 100
                  }%`}
                ></div>

                <div
                  className="bg-orange-700 h-[1rem] rounded-sm mr-1"
                  style={{
                    width: `${response?.data?.signals?.liquidity_pools * 100}%`,
                  }}
                  title={`Liquidity pools: ${
                    response?.data?.signals?.liquidity_pools * 100
                  }%`}
                ></div>

                <div
                  className="bg-orange-500 h-[1rem] rounded-sm mr-1"
                  style={{
                    width: `${
                      response?.data?.signals?.p2p_exchange_mlrisk_high * 100
                    }%`,
                  }}
                  title={`High-Risk P2P Exchange: ${
                    response?.data?.signals?.p2p_exchange_mlrisk_high * 100
                  }%`}
                ></div>

                <div
                  className="bg-orange-900 h-[1rem] rounded-sm mr-1"
                  style={{
                    width: `${response?.data?.signals?.gambling * 100}%`,
                  }}
                  title={`Gambling: ${
                    response?.data?.signals?.gambling * 100
                  }%`}
                ></div>

                <div
                  className="bg-red-600 h-[1rem] rounded-sm mr-1"
                  style={{
                    width: `${response?.data?.signals?.sanctions * 100}%`,
                  }}
                  title={`Sanctions: ${
                    response?.data?.signals?.sanctions * 100
                  }%`}
                ></div>

                <div
                  className="bg-black h-[1rem] rounded-sm"
                  style={{
                    width: `${response?.data?.signals?.stolen_coins * 100}%`,
                  }}
                  title={`Stolen coins: ${
                    response?.data?.signals?.stolen_coins * 100
                  }%`}
                ></div>
              </div>

              <div className="w-full flex flex-col items-center mt-8">
                <div className="flex flex-col w-full mb-4 text-center">
                  {risk > 0 && risk <= 25 && (
                    <div className="flex flex-col mb-4">
                      <b className="mb-2">Low Risk</b>
                      <span>
                        Transfer from the wallet should be safe.{" "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://amlbot.com/ru/what-do-we-analyze"
                        >
                          Learn More
                        </a>
                      </span>
                    </div>
                  )}

                  {risk > 25 && risk <= 75 && (
                    <div className="flex flex-col mb-4">
                      <b className="mb-2">Risk Zone</b>
                      <span>
                        From 50% and above, there is a chance that transfers
                        from this wallet can be blocked by centralized
                        cryptocurrency exchanges (such as Binance, Huobi, etc.)
                        or other compliant cryptocurrency arrangements.{" "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://amlbot.com/ru/what-do-we-analyze"
                        >
                          Learn More
                        </a>
                      </span>
                    </div>
                  )}

                  {risk > 75 && (
                    <div className="flex flex-col">
                      <b className="mb-2">Extreme Danger</b>
                      <span>
                        Transfers from this wallet have a big chance of being
                        blocked.{" "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://amlbot.com/ru/what-do-we-analyze"
                        >
                          Learn More
                        </a>
                      </span>
                    </div>
                  )}
                </div>

                <div className=" flex flex-col max-w-[20rem] w-full">
                  <div className="flex mb-4 text-green-500 items-center">
                    <div className="mr-2">
                      <ThumbsUp size={30} />
                    </div>
                    <h2 className=" m-0 text-xl font-normal">
                      Low risk address
                    </h2>
                  </div>

                  <div className="text-sm">
                    <div className="flex justify-between items-center mb-1 w-full">
                      <div className="flex items-center">
                        <div className="bg-green-500 w-3 h-3 rounded-sm mr-2"></div>
                        <span>Low-Risk Exchange</span>
                      </div>
                      <span>
                        {
                          +(
                            response?.data?.signals?.exchange_mlrisk_low * 100
                          ).toFixed(2)
                        }
                        %
                      </span>
                    </div>

                    <div className="flex justify-between items-center mb-1 w-full">
                      <div className="flex items-center">
                        <div className="bg-green-500 w-3 h-3 rounded-sm mr-2"></div>
                        <span>Marketplace</span>
                      </div>
                      <span>
                        {
                          +(response?.data?.signals?.marketplace * 100).toFixed(
                            2
                          )
                        }
                        %
                      </span>
                    </div>

                    <div className="flex justify-between items-center mb-1 w-full">
                      <div className="flex items-center">
                        <div className="bg-blue-300 w-3 h-3 rounded-sm mr-2"></div>
                        <span>Miner</span>
                      </div>
                      <span>
                        {+(response?.data?.signals?.miner * 100).toFixed(2)}%
                      </span>
                    </div>

                    <div className="flex justify-between items-center mb-1 w-full">
                      <div className="flex items-center">
                        <div className="bg-blue-500 w-3 h-3 rounded-sm mr-2"></div>
                        <span>Other</span>
                      </div>
                      <span>
                        {+(response?.data?.signals?.other * 100).toFixed(2)}%
                      </span>
                    </div>

                    <div className="flex justify-between items-center mb-1 w-full">
                      <div className="flex items-center">
                        <div className="bg-green-500 w-3 h-3 rounded-sm mr-2"></div>
                        <span>Low-Risk P2P Exchange</span>
                      </div>
                      <span>
                        {
                          +(
                            response?.data?.signals?.p2p_exchange_mlrisk_low *
                            100
                          ).toFixed(2)
                        }
                        %
                      </span>
                    </div>

                    <div className="flex justify-between items-center mb-1 w-full">
                      <div className="flex items-center">
                        <div className="bg-green-500 w-3 h-3 rounded-sm mr-2"></div>
                        <span>Payment Management</span>
                      </div>
                      <span>
                        {+(response?.data?.signals?.payment * 100).toFixed(2)}%
                      </span>
                    </div>

                    <div className="flex justify-between items-center mb-1 w-full">
                      <div className="flex items-center">
                        <div className="bg-blue-300 w-3 h-3 rounded-sm mr-2"></div>
                        <span>Wallet</span>
                      </div>
                      <span>
                        {+(response?.data?.signals?.wallet * 100).toFixed(2)}%
                      </span>
                    </div>
                  </div>

                  <div className="flex my-4 text-orange-500 items-center">
                    <div className="mr-2">
                      <AlertCircle size={30} />
                    </div>
                    <h2 className=" m-0 text-xl font-normal">
                      Medium risk address
                    </h2>
                  </div>

                  <div className="text-sm max-w-[24rem]">
                    <div className="flex justify-between items-center mb-1 w-full">
                      <div className="flex items-center">
                        <div className="bg-orange-500 w-3 h-3 rounded-sm mr-2"></div>
                        <span>High-Risk Exchange</span>
                      </div>
                      <span>
                        {
                          +(
                            response?.data?.signals?.exchange_mlrisk_high * 100
                          ).toFixed(2)
                        }
                        %
                      </span>
                    </div>

                    <div className="flex justify-between items-center mb-1 w-full">
                      <div className="flex items-center">
                        <div className="bg-orange-700 w-3 h-3 rounded-sm mr-2"></div>
                        <span>Moderate-Risk Exchange</span>
                      </div>
                      <span>
                        {
                          +(
                            response?.data?.signals?.exchange_mlrisk_moderate *
                            100
                          ).toFixed(2)
                        }
                        %
                      </span>
                    </div>

                    <div className="flex justify-between items-center mb-1 w-full">
                      <div className="flex items-center">
                        <div className="bg-orange-800 w-3 h-3 rounded-sm mr-2"></div>
                        <span>Very High-Risk Exchange</span>
                      </div>
                      <span>
                        {
                          +(
                            response?.data?.signals?.exchange_mlrisk_veryhigh *
                            100
                          ).toFixed(2)
                        }
                        %
                      </span>
                    </div>

                    <div className="flex justify-between items-center mb-1 w-full">
                      <div className="flex items-center">
                        <div className="bg-orange-700 w-3 h-3 rounded-sm mr-2"></div>
                        <span>Liquidity Pools</span>
                      </div>
                      <span>
                        {
                          +(
                            response?.data?.signals?.liquidity_pools * 100
                          ).toFixed(2)
                        }
                        %
                      </span>
                    </div>

                    <div className="flex justify-between items-center mb-1 w-full">
                      <div className="flex items-center">
                        <div className="bg-orange-500 w-3 h-3 rounded-sm mr-2"></div>
                        <span>High-Risk P2P Exchange</span>
                      </div>
                      <span>
                        {
                          +(
                            response?.data?.signals?.p2p_exchange_mlrisk_high *
                            100
                          ).toFixed(2)
                        }
                        %
                      </span>
                    </div>
                  </div>

                  <div className="flex my-4 text-red-600 items-center">
                    <div className="mr-2">
                      <XOctagon size={30} />
                    </div>
                    <h2 className=" m-0 text-xl font-normal">
                      High risk address
                    </h2>
                  </div>

                  <div className="text-sm max-w-[24rem]">
                    <div className="flex justify-between items-center mb-1 w-full">
                      <div className="flex items-center">
                        <div className="bg-orange-900 w-3 h-3 rounded-sm mr-2"></div>
                        <span>Gambling</span>
                      </div>
                      <span>
                        {+(response?.data?.signals?.gambling * 100).toFixed(2)}%
                      </span>
                    </div>

                    <div className="flex justify-between items-center mb-1 w-full">
                      <div className="flex items-center">
                        <div className="bg-red-600 w-3 h-3 rounded-sm mr-2"></div>
                        <span>Sanctions</span>
                      </div>
                      <span>
                        {+(response?.data?.signals?.sanctions * 100).toFixed(2)}
                        %
                      </span>
                    </div>

                    <div className="flex justify-between items-center mb-1 w-full">
                      <div className="flex items-center">
                        <div className="bg-black w-3 h-3 rounded-sm mr-2"></div>
                        <span>Stolen coins</span>
                      </div>
                      <span>
                        {
                          +(
                            response?.data?.signals?.stolen_coins * 100
                          ).toFixed(2)
                        }
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}

      {loading2 && (
        <h2 className="text-lg text-center">
          Chainalysis is checking, please wait...
        </h2>
      )}

      {!loading2 && (
        <div className="w-full mt-12">
          <div className="flex mb-2">
            <img
              className=" object-cover max-w-[10rem] w-full"
              src="/crypto-scan/chain.svg"
            />
          </div>

          <div className="grid gap-12 grid-cols-1 lg:grid-cols-3 mb-4">
            <div className=" col-span-2">
              {chainMessage === "OK" && (
                <div className="flex text-green-500 items-center">
                  <h2 className=" m-0 mr-2 text-[2rem] font-normal">
                    Low risk address
                  </h2>
                  <div>
                    <ThumbsUp size={40} />
                  </div>
                </div>
              )}

              {chainMessage !== "OK" && (
                <div className="flex text-red-600 items-center">
                  <h2 className=" m-0 mr-2 text-4xl font-normal">
                    High risk address
                  </h2>
                  <div>
                    <XOctagon size={40} />
                  </div>
                </div>
              )}

              {chainMessage !== "OK" && (
                <div className="flex justify-end mt-2">
                  <Button
                    onClick={() => {
                      setShowChainDetails(!showChainDetails);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    <span className="text-sm">
                      {showChainDetails ? "Hide Details" : "Show Details"}
                    </span>
                  </Button>
                </div>
              )}
            </div>

            <div className=" self-start w-full flex flex-col shadow-[0_-1px_30px_-10px_rgba(0,0,0,0.3)] rounded-xl p-4">
              <span className="text-[2rem] font-bold">
                {chainMessage === "OK" ? 10 : 100}%
              </span>
              <span className="text-lg">Risk assessment</span>
            </div>
          </div>

          {showChainDetails && (
            <p className=" mt-4 text-black text-base">{chainMessage}</p>
          )}
        </div>
      )}
    </div>
  );
});
