import {
  IconButton,
  LinearProgress,
  MenuItem,
  TextField,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Edit } from "@material-ui/icons";
import { view } from "@risingstack/react-easy-state";
import React, { useEffect, useRef, useState } from "react";
import {
  formatDateToMmDdYyyyHhMmSs,
  formatToCurrencyString,
} from "../../../../../../helpers";
import { checkIsCryptoCurrency } from "../../../../../../helpers/formatToCurrencyString";
import { isObject } from "../../../../../../helpers/typer";
import admin from "../../../../../../store/modules/admin";
import auth from "../../../../../../store/modules/auth";
import fees from "../../../../../../store/modules/fees";
import transactionDetails from "../../../../../../store/modules/transactionDetails";
import { FormatAmount } from "../../../../../System";
import LazyImage from "../../../../../System/LazyImage";

const TransactionSeeUser = view(() => {
  const { detailSeeUser: transaction } = transactionDetails;
  const [comment, setComment] = useState("");
  const [switchComment, setSwitchComment] = useState(false);
  const [loadingComment, setLoadingComment] = useState(false);

  const isOurFee = transaction?.operationId?.toLowerCase().includes("fee");

  const [fee, setFee] = useState("");
  const [switchFee, setSwitchFee] = useState(false);

  const [loadingFee, setLoadingFee] = useState(false);

  const { comment: commentOperation } = transaction || {};

  const refInit = useRef<boolean>(false);
  const { feeItems } = fees;

  useEffect(() => {
    if (
      !refInit.current &&
      feeItems?.length === 0 &&
      !!auth?.profile &&
      !auth?.profile?.isAnalyst
    ) {
      refInit.current = true;
      fees.fetchFees();
    }
  }, [feeItems]);

  const currencyId = transaction?.currencyId;

  const txHashLink =
    currencyId === "BTC"
      ? `${import.meta.env.VITE_BLOCKCYPHER}/btc/tx/${transaction?.txHash}`
      : `${import.meta.env.VITE_ETHERSCAN}/tx/${transaction?.txHash}`;

  useEffect(() => {
    if (commentOperation) {
      setComment(commentOperation);
    }
  }, [commentOperation]);

  const saveComment = async () => {
    /* useCallback */
    if (comment === transaction?.comment) {
      setSwitchComment(false);
      return;
    }
    setLoadingComment(true);
    const result = await admin.patchOperation(transaction?.operationId, {
      comment,
    });
    if (result) {
      transactionDetails.detailSeeUser.comment = comment;
      transactionDetails.fetchTransactions({ pageIndex: 0 });
      setSwitchComment(false);
    }

    setLoadingComment(false);
  };

  const saveFee = async () => {
    /* useCallback */
    if (fee === transaction?.feeId) {
      setSwitchFee(false);
      return;
    }
    setLoadingFee(true);
    const result = await admin.patchOperation(transaction?.operationId, {
      feeId: fee,
    });
    if (result) {
      transactionDetails.detailSeeUser.feeId = fee;
      transactionDetails.fetchTransactions({ pageIndex: 0 });
      setSwitchFee(false);
    }

    setLoadingFee(false);
  };

  const openEditFee = () => {
    setSwitchFee(true);
  };

  const onChangeFee = (event) => {
    const { value } = event.target;
    setFee(value);
  };

  const menuFees = fees.feeItems.map((item) => {
    return (
      <MenuItem key={item.titleId} value={item.titleId}>
        {item.titleId} - {item.name}
      </MenuItem>
    );
  });

  useEffect(() => {
    if (transaction?.feeId) {
      setFee(transaction?.feeId);
    }
  }, [transaction?.feeId]);

  const openEditComment = () => {
    setSwitchComment(true);
  };

  const onChangeComment = (event) => {
    const { value } = event.target;
    setComment(value);
  };

  const isCrypto = checkIsCryptoCurrency(currencyId);

  let feeObj = fees.feeItems?.find(
    (item) => item?.titleId === transaction?.feeId
  );

  if (
    isObject(feeObj) &&
    feeObj.titleId === "WDREU" &&
    transaction.currencyId !== "EUR"
  ) {
    feeObj = {
      ...feeObj,
      name: "Domestic",
    };
  }

  return (
    <>
      <div className="flex justify-center">
        {isOurFee ? <h2>Our fee:</h2> : <h2>What Client sees:</h2>}
      </div>

      <div className="mb-8">
        <h2 className="w-full flex justify-center text-main text-5xl m-0 p-0 leading-none">
          <b>
            {isCrypto ? (
              <FormatAmount
                amount={transaction?.amount}
                currency={currencyId}
              />
            ) : (
              <>{formatToCurrencyString(transaction?.amount, currencyId)}</>
            )}
          </b>
        </h2>

        <p className="text-center m-0 text-main">Amount</p>
      </div>

      <div className="flex justify-between items-center text-base flex-1 py-1 px-2 border-b border-solid border-slate-200 hover:bg-slate-200">
        <div className="flex-1 pr-4">Transaction date</div>

        <div className="flex-1 flex justify-end font-bold text-right break-words w-full">
          {transaction?.ts ? formatDateToMmDdYyyyHhMmSs(transaction?.ts) : null}
        </div>
      </div>

      {transaction?.invoiceId && (
        <div className="flex justify-between items-center text-base flex-1 py-1 px-2 border-b border-solid border-slate-200 hover:bg-slate-200">
          <div className="flex-1 pr-4">Invoice</div>

          <div className="flex-1 flex justify-end font-bold text-right break-words w-full">
            <a
              href={`/invoice/${transaction.invoiceId}`}
              target="_blank"
              rel="noreferrer"
            >
              {transaction.invoiceId}
            </a>
          </div>
        </div>
      )}

      {!transaction?.isPending && !isOurFee && (
        <div className="flex justify-between items-center text-base flex-1 py-1 px-2 border-b border-solid border-slate-200 hover:bg-slate-200">
          <div className="flex-1 pr-4">Sub Total</div>

          <span className="flex-1 flex justify-end font-bold text-right break-words w-full">
            {isCrypto ? (
              <FormatAmount
                amount={transaction?.subtotal}
                currency={currencyId}
              />
            ) : (
              <>{formatToCurrencyString(transaction?.subtotal, currencyId)}</>
            )}

            {transaction?.adminFeeId === "MARKUP" && (
              <div className="w-6">
                <LazyImage
                  src="/images/fear.svg"
                  classNameImageBlock="max-w-full"
                />
              </div>
            )}
          </span>
        </div>
      )}

      {!transaction?.isPending && !isOurFee && (
        <div className="flex justify-between items-center text-base flex-1 py-1 px-2 border-b border-solid border-slate-200 hover:bg-slate-200">
          <div className="flex-1 pr-4">Fee</div>

          <span className="flex-1 flex justify-end font-bold text-right break-words w-full">
            {isCrypto ? (
              <FormatAmount amount={transaction?.fee} currency={currencyId} />
            ) : (
              <>{formatToCurrencyString(transaction?.fee, currencyId)}</>
            )}
          </span>
        </div>
      )}

      {!transaction?.isPending && !isOurFee && (
        <div className="flex justify-between items-center text-base flex-1 py-1 px-2 border-b border-solid border-slate-200 hover:bg-slate-200">
          <div className="flex-1 pr-4">Total</div>

          <span className="flex-1 flex justify-end font-bold text-right break-words w-full">
            {isCrypto ? (
              <FormatAmount amount={transaction?.total} currency={currencyId} />
            ) : (
              <>{formatToCurrencyString(transaction?.total, currencyId)}</>
            )}
          </span>
        </div>
      )}
      {!transaction?.isPending && (
        <div className="flex justify-between items-center text-base flex-1 py-1 px-2 border-b border-solid border-slate-200 hover:bg-slate-200">
          <div className="flex-1 pr-4">Balance</div>

          <span className="flex-1 flex justify-end font-bold text-right break-words w-full">
            {isCrypto ? (
              <FormatAmount
                amount={transaction?.balance}
                currency={currencyId}
              />
            ) : (
              <>{formatToCurrencyString(transaction?.balance, currencyId)}</>
            )}
          </span>
        </div>
      )}

      {loadingComment && <LinearProgress />}
      {transaction?.comment && (
        <div className="flex justify-between items-center text-base flex-1 py-1 px-2 border-b border-solid border-slate-200 hover:bg-slate-200">
          <div className="flex-1 pr-4">Comment</div>

          {!switchComment && (
            <div className="flex justify-end font-bold text-right break-words w-full">
              <div className="flex items-start justify-center">
                {transaction?.comment}{" "}
                {!!auth?.profile && !auth?.profile?.isAnalyst && (
                  <IconButton
                    style={{ marginLeft: "0.5rem" }}
                    onClick={openEditComment}
                  >
                    <Edit />
                  </IconButton>
                )}
              </div>
            </div>
          )}
          {switchComment && (
            <div className="flex justify-end font-bold text-right break-words w-full">
              <div className="flex flex-1 items-start justify-center">
                <TextField
                  multiline
                  variant="outlined"
                  rows={4}
                  value={comment || ""}
                  onChange={onChangeComment}
                  fullWidth
                />

                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginLeft: "0.5rem",
                    whiteSpace: "nowrap",
                  }}
                  onClick={saveComment}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </div>
      )}

      {loadingFee && <LinearProgress />}

      <div className="flex justify-between items-center text-base flex-1 py-1 px-2 border-b border-solid border-slate-200 hover:bg-slate-200">
        <div className="flex-1 pr-4 whitespace-nowrap">Fee ID: </div>
        {!switchFee && (
          <div className="flex justify-end font-bold text-right break-words w-full">
            <div className="flex items-start justify-center">
              {feeObj?.titleId} - {feeObj?.name}
              {!!auth?.profile && !auth?.profile?.isAnalyst && (
                <IconButton
                  style={{ marginLeft: "0.5rem" }}
                  onClick={openEditFee}
                >
                  <Edit />
                </IconButton>
              )}
            </div>
          </div>
        )}
        {switchFee && (
          <div className="flex-1 flex justify-end font-bold text-right break-words w-full">
            <div className="flex items-start justify-center max-w-xs">
              <TextField
                name="feeId"
                value={fee ? fee : transaction?.feeId}
                onChange={onChangeFee}
                select
                fullWidth
                required
              >
                {menuFees}
              </TextField>

              <Button
                variant="contained"
                color="primary"
                style={{
                  marginLeft: "0.5rem",
                  whiteSpace: "nowrap",
                }}
                onClick={saveFee}
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </div>

      <div className="flex justify-between items-center text-base flex-1 py-1 px-2 border-b border-solid border-slate-200 hover:bg-slate-200">
        <div className="flex-1 pr-4">Reference code: </div>

        <div className="flex-1 flex justify-end font-bold text-right break-words w-full">
          {transaction?.operationId}
        </div>
      </div>
      {transaction?.txHash && (
        <div className="flex justify-between items-center text-base flex-1 py-1 px-2 border-b border-solid border-slate-200 hover:bg-slate-200">
          <div className="flex-1 pr-4 whitespace-nowrap">TX Hash: </div>

          <div className="flex justify-end font-bold text-right break-all w-full">
            <div className="flex items-start justify-center">
              <a
                href={txHashLink}
                className="break-all"
                target="_blank"
                rel="noreferrer"
              >
                {transaction.txHash}
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default TransactionSeeUser;
