import { useWatch } from "react-hook-form";
import IAccount from "../../../../../../interfaces/account";
import { useQueryClientAccounts } from "../queries/useQueryClientAccounts";
import { defaultFilter } from "../tools/defaultFilter";

export const useGetAccounts = ({ userId, form }) => {
  const queryResult = useQueryClientAccounts(userId);

  const options =
    (queryResult?.data?.filter(defaultFilter) as IAccount[]) || [];

  const fromAccountId = useWatch({
    control: form.control,
    name: "fromAccountId",
  });

  const toAccountId = useWatch({
    control: form.control,
    name: "toAccountId",
  });

  const fromAccount = options.find((a) => a.accountId === fromAccountId);
  const toAccount = options.find((a) => a.accountId === toAccountId);

  return { fromAccount, toAccount, options };
};
