import { useAtom } from "jotai";
import React, { memo, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { formatDateToMMddyyyyHHmm } from "../../../../helpers";
import { customSortArrayString } from "../../../../helpers/customSortArray";
import CustomCheckboxLabel from "../../../System/Components/CustomCheckboxLabel";
import FeatureDateFilter from "../Reports/Features/components/FeatureDateFilter";
import FeatureSearchFilter from "../Reports/Features/components/FeatureSearchFilter";
import SimpleTable from "../Reports/Features/components/SimpleTable";
import { filtersAtom } from "./atoms/filters";
import { globalParamsAtom } from "./atoms/globalParamsAtom";
import { useQueryAdminMessages } from "./queries/useQueryAdminMessages";

export default memo(({ userId }: any) => {
  const [params, setParams] = useAtom(globalParamsAtom);
  const adminEventsQuery = useQueryAdminMessages(userId, params);

  const columns = useMemo(() => {
    return [
      {
        label: "Created",
        id: "createAt",
        nowrap: true,
        render: (rowData) => {
          return formatDateToMMddyyyyHHmm(rowData.createAt);
        },
      },
      {
        label: "Subject",
        id: "subject",
        render: (rowData) => {
          return rowData.subject;
        },
      },
      {
        label: "Name",
        id: "name",
        nowrap: true,
        render(rowData) {
          return (
            <Link
              to={`/app/admin/clients/${rowData?.userId}/accounts?userId=${rowData?.userId}&sortBy=currency&sortDirection=desc`}
            >
              <b>{rowData.name}</b>
            </Link>
          );
        },
      },
      {
        label: "Phone",
        id: "phoneNumber",
        render: (rowData) => {
          return rowData.phoneNumber;
        },
      },
      {
        label: "ID",
        id: "sid",
        render: (rowData) => {
          return rowData.sid;
        },
      },
      {
        label: "Status",
        id: "status",
        render: (rowData) => {
          return rowData.status;
        },
      },
      {
        label: "Short Body",
        id: "shortBody",
        render: (rowData) => {
          return rowData.shortBody;
        },
      },
      {
        label: "Error",
        id: "errorMessage",
        render: (rowData) => {
          return rowData.errorMessage;
        },
      },
    ];
  }, []);

  const [showType, setShowType] = useState(false);
  const [showStatus, setShowStatus] = useState(false);

  const [filters, setFilters] = useAtom(filtersAtom);

  return (
    <div className="flex flex-col flex-1">
      <div className="flex items-start flex-1">
        <FeatureSearchFilter params={params} setParams={setParams} />

        <FeatureDateFilter
          filters={filters}
          setFilters={setFilters}
          params={params}
          setParams={setParams}
        />

        <div className="flex flex-col border border-main rounded-md px-1 mr-2">
          <CustomCheckboxLabel
            checked={showType}
            label={<b>Type</b>}
            onChange={(e) => {
              setParams((prevState) => ({
                ...prevState,
                type: undefined,
              }));

              setShowType(e.target.checked);
            }}
          />

          {showType && (
            <div className="flex flex-row flex-wrap">
              {["push", "sms", "verify"]
                .sort(customSortArrayString)
                .map((item) => (
                  <div key={item}>
                    <CustomCheckboxLabel
                      label={item}
                      isSmall
                      checked={params?.type?.includes(item) || false}
                      onChange={(e) => {
                        // logic

                        const arr = params.type?.split(",") || [];

                        const res = e.target.checked
                          ? [...arr, item]
                          : arr.filter((i) => i !== item);

                        setParams((prevState) => ({
                          ...prevState,
                          type: res.length > 0 ? res.join(",") : undefined,
                        }));
                      }}
                    />
                  </div>
                ))}
            </div>
          )}
        </div>

        <div className="flex flex-col border border-main rounded-md px-1 mr-2">
          <CustomCheckboxLabel
            checked={showStatus}
            label={<b>Status</b>}
            onChange={(e) => {
              setParams((prevState) => ({
                ...prevState,
                status: undefined,
              }));

              setShowStatus(e.target.checked);
            }}
          />

          {showStatus && (
            <div className="flex flex-row flex-wrap">
              {["new", "approved", "pending", "delivered", "undelivered"]
                .sort(customSortArrayString)
                .map((item) => (
                  <div key={item}>
                    <CustomCheckboxLabel
                      label={item}
                      isSmall
                      checked={
                        params?.status?.split(",")?.includes(item) || false
                      }
                      onChange={(e) => {
                        // logic

                        const arr = params.status?.split(",") || [];

                        const res = e.target.checked
                          ? [...arr, item]
                          : arr.filter((i) => i !== item);

                        setParams((prevState) => ({
                          ...prevState,
                          status: res.length > 0 ? res.join(",") : undefined,
                        }));
                      }}
                    />
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>

      <SimpleTable
        columns={columns}
        setParams={setParams}
        params={params}
        queryData={adminEventsQuery}
      />
    </div>
  );
});
