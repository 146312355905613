import React from "react";
import { formatToCurrencyString } from "../../../../../../helpers";
import ShowAndHide from "../../../../../System/FormComponents/ShowAndHide";

const SwiftDetail = ({ showDetail, swift }) => {
  return (
    <ShowAndHide show={showDetail}>
      <div className="flex flex-col text-base mt-4">
        <div className="flex flex-col mb-1">
          <span>
            Amount:{" "}
            <b>
              {formatToCurrencyString(
                swift?.paymentAmount?.amount || 0,
                swift?.paymentAmount?.currency
              )}
            </b>
          </span>
        </div>

        <div className="flex flex-col mb-1">
          <span>
            From: <b className="mr-0.5">{swift?.ultimateDebtor?.name},</b>
            {swift?.ultimateDebtor?.address?.street},{" "}
            {swift?.ultimateDebtor?.address?.town},{" "}
            {swift?.ultimateDebtor?.address?.postCode},{" "}
            {swift?.ultimateDebtor?.address?.country}
          </span>
        </div>
        <div className="flex flex-col mb-1">
          <span>
            Debtor account:{" "}
            <b className="mr-0.5">
              {swift?.debtorAccount?.accountNumber} (
              {swift?.debtorAccount?.accountNumberType})
            </b>
          </span>
        </div>
        <div className="flex flex-col mb-1">
          <span>
            To:{" "}
            <b className="mr-0.5">
              {swift?.creditor?.name}, {swift?.creditorBank?.bankId} (
              {swift?.creditorBank?.bankIdType}),{" "}
              {swift?.creditorAccount?.accountNumber},{" "}
            </b>
            {swift?.creditor?.address?.street}, {swift?.creditor?.address?.town}
            , {swift?.creditor?.address?.postCode},{" "}
            {swift?.creditor?.address?.country}
          </span>
        </div>

        <div className="flex flex-col mb-1">
          <span>
            Description: <b>{swift?.remittanceInformation}</b>
          </span>
        </div>
      </div>
    </ShowAndHide>
  );
};

export default SwiftDetail;
