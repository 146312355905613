import { Drawer, IconButton } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { ChevronRight, CloseOutlined } from "@material-ui/icons";
import { useAtom } from "jotai";
import React, { memo, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { formatDateToMMddyyyyHHmm } from "../../../../helpers";
import {
  customSortArrayEventTypeGroup,
  customSortArrayLabel,
  customSortArrayString,
  customSortArrayValue,
} from "../../../../helpers/customSortArray";
import {
  allCurrencies2,
  formatToCurrencyString,
} from "../../../../helpers/formatToCurrencyString";
import transactionDetails from "../../../../store/modules/transactionDetails";
import { MigomTable } from "../../../System";
import CustomCheckboxLabel from "../../../System/Components/CustomCheckboxLabel";
import CopyAccountNumber from "../../../System/CopyAccountNumber";
import TransactionDetail from "../AllAccounts/Transactions/Detail";
import FeatureDateFilter from "../Reports/Features/components/FeatureDateFilter";
import FeatureSearchFilter from "../Reports/Features/components/FeatureSearchFilter";
import SimpleTable from "../Reports/Features/components/SimpleTable";
import { filtersAtom } from "./atoms/filters";
import { globalParamsAtom } from "./atoms/globalParamsAtom";
import { useQueryAdminActors } from "./queries/useQueryAdminActors";
import { useQueryAdminEvent } from "./queries/useQueryAdminEvent";
import { useQueryAdminEventGroups } from "./queries/useQueryAdminEventGroups";
import { useQueryAdminEvents } from "./queries/useQueryAdminEvents";

const isJson = (json) => {
  try {
    JSON.parse(json);
    return true;
  } catch (e) {
    return false;
  }
};

const Pending = ({ children, pending }) => {
  return pending ? (
    <div className="opacity-50">{children}</div>
  ) : (
    children || null
  );
};

export default memo(({ userId }: any) => {
  const [params, setParams] = useAtom(globalParamsAtom);
  const adminEventsQuery = useQueryAdminEvents(userId, params);
  const [eventId, setEventId] = useState("");

  const adminEventQuery = useQueryAdminEvent(eventId);

  const actorsQuery = useQueryAdminActors();
  const eventGroups = useQueryAdminEventGroups();

  const columns = useMemo(() => {
    const arr = [
      {
        label: "Date",
        id: "ts",
        nowrap: true,
        render: (rowData) => {
          return formatDateToMMddyyyyHHmm(rowData.ts);
        },
      },
      {
        label: "Group",
        id: "eventGroupId",
        render: (rowData) => {
          return rowData.eventGroupId;
        },
      },
      {
        label: "Event",
        id: "eventName",
        nowrap: true,
        render: (rowData) => {
          return rowData.eventName;
        },
      },
      {
        label: "Actor",
        id: "actorUserName",
        nowrap: true,
        render: (rowData) => {
          return (
            <Link to={`/app/admin/clients/${rowData.actorUserId}/detail`}>
              {rowData.actorUserName}
            </Link>
          );
        },
      },
      {
        label: "Action",
        id: "action",
        className: "min-w-60",
        render: (rowData) => {
          return <span className="break-all">{rowData.action}</span>;
        },
      },
      {
        label: "Client",
        id: "clientUserName",
        nowrap: true,
        render: (rowData) => {
          return (
            <Link to={`/app/admin/clients/${rowData.clientUserId}/detail`}>
              {rowData.clientUserName}
            </Link>
          );
        },
      },
      {
        label: "Amount",
        id: "amount",
        right: true,
        nowrap: true,
        render: (rowData) => {
          return rowData.amount ? (
            <>
              {rowData.accountId ? (
                <Link to={`/app/admin/accounts/${rowData.accountId}`}>
                  {formatToCurrencyString(rowData.amount, rowData.currencyId)}
                </Link>
              ) : (
                formatToCurrencyString(rowData.amount, rowData.currencyId)
              )}
            </>
          ) : null;
        },
      },

      {
        label: " ",
        id: "",
        right: true,
        nowrap: true,
        render: (rowData) => {
          return (
            <div className="flex flex-1 justify-end">
              <IconButton
                size="small"
                onClick={() => setEventId(rowData.eventId)}
              >
                <ChevronRight />
              </IconButton>
            </div>
          );
        },
      },
    ];
    return arr.filter((i) => !userId || (i.label !== "Client" && userId));
  }, [userId]);

  const [showCurrencies, setShowCurrencies] = useState(false);
  const [showGroup, setShowGroup] = useState(false);
  const [showType, setShowType] = useState(false);
  const [showActors, setShowActors] = useState(false);

  const [filters, setFilters] = useAtom(filtersAtom);

  const columnsPreview = useMemo(() => {
    const styleHead = {
      color: grey[600],
      whiteSpace: "nowrap",
      fontSize: ".75rem",
    };

    const style = {
      whiteSpace: "nowrap",
      fontSize: ".85rem",
    };

    return [
      {
        Header: "Acc. ID",
        styleHead: styleHead,
        style: { ...style, fontSize: ".7rem" },
        id: "accountId",
        disableSortBy: true,
        accessor: (rowData) => (
          <Pending pending={rowData.IsPending}>{rowData.AccountId}</Pending>
        ),
      },
      {
        Header: "Comment",
        styleHead: styleHead,
        id: "comment",
        disableSortBy: true,
        style: {
          minWidth: 240,
          maxWidth: 320,
          wordBreak: "break-word",
          fontSize: ".85rem",
        },
        accessor: (rowData) => (
          <Pending pending={rowData.IsPending}>{rowData.Comment}</Pending>
        ),
      },
      {
        Header: "Fee ID",
        styleHead: styleHead,
        style: style,
        id: "feeId",
        disableSortBy: true,
        accessor: (rowData) => (
          <Pending pending={rowData.IsPending}>{rowData.FeeId}</Pending>
        ),
      },
      {
        Header: "Fee str.",
        styleHead: styleHead,
        style: style,
        id: "structureId",
        disableSortBy: true,
        accessor: (rowData) => (
          <Pending pending={rowData.IsPending}>{rowData.StructureId}</Pending>
        ),
      },
      {
        Header: "Amount",
        styleHead: {
          ...styleHead,
          textAlign: "right",
          whiteSpace: "nowrap",
        },
        style: {
          textAlign: "right",
          whiteSpace: "nowrap",
        },
        id: "amount",
        disableSortBy: true,
        accessor: (rowData) => (
          <Pending pending={rowData.IsPending}>
            <b>{formatToCurrencyString(rowData.Amount, rowData.CurrencyId)}</b>
          </Pending>
        ),
      },
    ];
  }, []);

  return (
    <div className="flex flex-col flex-1">
      <div className="flex items-start flex-1 flex-wrap mb-2">
        <FeatureSearchFilter params={params} setParams={setParams} />

        <FeatureDateFilter
          filters={filters}
          setFilters={setFilters}
          params={params}
          setParams={setParams}
        />

        <div className="flex flex-col border border-main rounded-md px-1 mr-2 mb-2">
          <CustomCheckboxLabel
            checked={showGroup}
            label={<b>Group</b>}
            onChange={(e) => {
              setParams((prevState) => ({
                ...prevState,
                eventGroups: undefined,
              }));

              setShowGroup(e.target.checked);
            }}
          />

          {showGroup && (
            <div className="flex flex-row flex-wrap">
              {(eventGroups?.data || [])
                .sort(customSortArrayEventTypeGroup)
                ?.map((item) => item.eventTypeGroupId)
                .map((item) => (
                  <div key={item}>
                    <CustomCheckboxLabel
                      label={item}
                      isSmall
                      checked={params?.eventGroups?.includes(item) || false}
                      onChange={(e) => {
                        // logic

                        const arr = params.eventGroups?.split(",") || [];

                        const res = e.target.checked
                          ? [...arr, item]
                          : arr.filter((i) => i !== item);

                        setParams((prevState) => ({
                          ...prevState,
                          eventGroups:
                            res.length > 0 ? res.join(",") : undefined,
                        }));
                      }}
                    />
                  </div>
                ))}
            </div>
          )}
        </div>

        <div className="flex flex-col border border-main rounded-md px-1 mr-2">
          <CustomCheckboxLabel
            checked={showType}
            label={<b>Type</b>}
            onChange={(e) => {
              setParams((prevState) => ({
                ...prevState,
                eventTypes: undefined,
              }));

              setShowType(e.target.checked);
            }}
          />

          {showType && (
            <div className="flex flex-row flex-wrap">
              {[
                "Formula",
                "Branch",
                "Reject",
                "Complete",
                "Return",
                "Override",
                "Open",
                "Close",
                "Change",
                "Deposit",
                "Withdraw",
                "Exchange",
                "Transfer",
                "Delete",
                "SetFee",
                "Password",
                "Activation",
                "Profile",
              ]
                .sort(customSortArrayString)
                .map((item) => (
                  <div key={item}>
                    <CustomCheckboxLabel
                      label={item}
                      isSmall
                      checked={params?.eventTypes?.includes(item) || false}
                      onChange={(e) => {
                        // logic

                        const arr = params.eventTypes?.split(",") || [];

                        const res = e.target.checked
                          ? [...arr, item]
                          : arr.filter((i) => i !== item);

                        setParams((prevState) => ({
                          ...prevState,
                          eventTypes:
                            res.length > 0 ? res.join(",") : undefined,
                        }));
                      }}
                    />
                  </div>
                ))}
            </div>
          )}
        </div>

        <div className="flex flex-col border border-main rounded-md px-1 mr-2">
          <CustomCheckboxLabel
            checked={showCurrencies}
            label={<b className="whitespace-nowrap">All currencies</b>}
            onChange={(e) => {
              setParams((prevState) => ({
                ...prevState,
                currencyIds: undefined,
              }));

              setShowCurrencies(e.target.checked);
            }}
          />

          {showCurrencies && (
            <div className="flex flex-row flex-wrap">
              {allCurrencies2.sort(customSortArrayValue).map((item) => (
                <div key={item.value}>
                  <CustomCheckboxLabel
                    label={item.value}
                    isSmall
                    checked={params?.currencyIds?.includes(item.value) || false}
                    onChange={(e) => {
                      // logic

                      const arr = params.currencyIds?.split(",") || [];

                      const res = e.target.checked
                        ? [...arr, item.value]
                        : arr.filter((i) => i !== item.value);

                      setParams((prevState) => ({
                        ...prevState,
                        currencyIds: res.length > 0 ? res.join(",") : undefined,
                      }));
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="flex flex-col border border-main rounded-md px-2">
          <CustomCheckboxLabel
            checked={showActors}
            label={<b className="whitespace-nowrap">Actors</b>}
            onChange={(e) => {
              setParams((prevState) => ({
                ...prevState,
                actorUserIds: undefined,
              }));

              setShowActors(e.target.checked);
            }}
          />

          {showActors && (
            <div className="flex flex-row flex-wrap">
              {actorsQuery?.data
                .map((item) => ({
                  label: item.name,
                  value: item.userId,
                }))
                .sort(customSortArrayLabel)
                .map((item) => (
                  <div key={item.value}>
                    <CustomCheckboxLabel
                      label={item.label}
                      isSmall
                      checked={
                        params?.actorUserIds?.includes(item.value) || false
                      }
                      onChange={(e) => {
                        // logic

                        const arr = params.actorUserIds?.split(",") || [];

                        const res = e.target.checked
                          ? [...arr, item.value]
                          : arr.filter((i) => i !== item.value);

                        setParams((prevState) => ({
                          ...prevState,
                          actorUserIds:
                            res.length > 0 ? res.join(",") : undefined,
                        }));
                      }}
                    />
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>

      <SimpleTable
        columns={columns}
        setParams={setParams}
        params={params}
        queryData={adminEventsQuery}
      />

      <Drawer
        anchor="right"
        open={Boolean(adminEventQuery?.data?.ts)}
        onClose={() => setEventId("")}
      >
        <div className="relative w-[30rem] max-w-[30rem] p-8">
          <IconButton
            className="!absolute !right-[15px] !top-0 !p-[10px]"
            onClick={() => setEventId("")}
          >
            <CloseOutlined />
          </IconButton>

          <h2 className="text-lg">
            {" "}
            {adminEventQuery?.data?.eventGroupId}{" "}
            {adminEventQuery?.data?.eventName}{" "}
          </h2>

          <div className="flex justify-between mb-2 pb-2 border-b border-gray-200">
            <span className="mr-4">Action:</span>
            <span className="text-left">
              {adminEventQuery?.data?.action}{" "}
              <CopyAccountNumber number={adminEventQuery?.data?.action} />
            </span>
          </div>

          <div className="flex justify-between mb-2 pb-2 border-b border-gray-200">
            <span className="mr-4">Event Group:</span>
            <span className="text-left">
              {adminEventQuery?.data?.eventGroupId}
              <CopyAccountNumber number={adminEventQuery?.data?.eventGroupId} />
            </span>
          </div>

          <div className="flex justify-between mb-2 pb-2 border-b border-gray-200">
            <span className="mr-4">Actor:</span>
            <Link
              to={`/app/admin/clients/${adminEventQuery?.data?.actorUserId}/detail`}
              className="text-left"
            >
              {adminEventQuery?.data?.actorUserName}
              <CopyAccountNumber
                number={adminEventQuery?.data?.actorUserName}
              />
            </Link>
          </div>

          <div className="flex justify-between mb-2 pb-2 border-b border-gray-200">
            <span className="mr-4">Client:</span>
            <Link
              to={`/app/admin/clients/${adminEventQuery?.data?.clientUserId}/detail`}
              className="text-left"
            >
              {adminEventQuery?.data?.clientUserName}
              <CopyAccountNumber
                number={adminEventQuery?.data?.actorUserName}
              />
            </Link>
          </div>

          {adminEventQuery?.data?.accountId && (
            <div className="flex justify-between mb-2 pb-2 border-b border-gray-200">
              <span className="mr-4">Account:</span>
              <Link
                to={`/app/admin/accounts/${adminEventQuery?.data?.accountId}`}
                className="text-left"
              >
                {adminEventQuery?.data?.accountId}
              </Link>
              <CopyAccountNumber number={adminEventQuery?.data?.accountId} />
            </div>
          )}

          <div className="flex justify-between mb-2 pb-2 border-b border-gray-200">
            <span className="mr-4">Transaction ID:</span>

            <a
              onClick={async () => {
                transactionDetails.fetchTransactions = () => setParams({});
                transactionDetails.fetchUserAccount = () => setParams({});

                const result =
                  await transactionDetails.fetchUserAccountTransaction(
                    false,
                    adminEventQuery?.data?.transactionId
                  );

                if (result) {
                  transactionDetails.openDetail = true;
                }
              }}
              className="text-left"
            >
              {adminEventQuery?.data?.transactionId}
            </a>
            {adminEventQuery?.data?.transactionId && (
              <CopyAccountNumber
                number={adminEventQuery?.data?.transactionId}
              />
            )}
          </div>

          <div className="flex justify-between mb-2 pb-2 border-b border-gray-200">
            <span className="mr-4">Country:</span>
            <span className="text-left">
              {adminEventQuery?.data?.country}
              <CopyAccountNumber number={adminEventQuery?.data?.country} />
            </span>
          </div>

          <div className="flex justify-between mb-2 pb-2 border-b border-gray-200">
            <span className="mr-4">City:</span>
            <span className="text-left">
              {adminEventQuery?.data?.city}{" "}
              <CopyAccountNumber number={adminEventQuery?.data?.city} />
            </span>
          </div>

          <div className="flex justify-between mb-2 pb-2 border-b border-gray-200">
            <span className="mr-4">Device:</span>
            <span className="text-left flex items-center">
              {adminEventQuery?.data?.deviceId}{" "}
              <CopyAccountNumber number={adminEventQuery?.data?.deviceId} />
            </span>
          </div>

          <div className="flex justify-between mb-2 pb-2 border-b border-gray-200">
            <span className="mr-4">IP:</span>
            <span className="text-left flex items-center">
              {adminEventQuery?.data?.ip}{" "}
              <CopyAccountNumber number={adminEventQuery?.data?.ip} />
            </span>
          </div>

          <div className="flex justify-between mb-2 pb-2 border-b border-gray-200">
            <span className="mr-4">Lat., Lon.:</span>
            <span className="text-left flex items-center">
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://www.google.com/maps/place/${adminEventQuery?.data?.latitude}, ${adminEventQuery?.data?.longitude}`}
              >
                {`${adminEventQuery?.data?.latitude}, ${adminEventQuery?.data?.longitude}`}
                <CopyAccountNumber
                  number={`${adminEventQuery?.data?.latitude}, ${adminEventQuery?.data?.longitude}`}
                />
              </a>
            </span>
          </div>

          {isJson(adminEventQuery?.data?.body) &&
          adminEventQuery?.data?.body?.includes("Operation") ? (
            <MigomTable
              columns={columnsPreview}
              data={
                JSON.parse(adminEventQuery?.data?.body || "")?.Operation || []
              }
              hidePagination
              autoHeight
            />
          ) : (
            <div className="flex justify-between mb-2 pb-2 border-b border-gray-200">
              <span className="mr-4">Body:</span>
              <span className="text-left break-all">
                {adminEventQuery?.data?.body}
                {adminEventQuery?.data?.body && (
                  <CopyAccountNumber number={adminEventQuery?.data?.body} />
                )}
              </span>
            </div>
          )}
        </div>
      </Drawer>

      <TransactionDetail />
    </div>
  );
});
