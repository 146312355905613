import { lazy } from "react";

const TemplatesInvoices = lazy(
  () => import("../../components/Roles/Client/Invoices/TemplatesInvoices")
);
const InvoicesItems = lazy(
  () => import("../../components/Roles/Client/Invoices/Items")
);
const Invoice = lazy(
  () => import("../../components/Roles/Client/Invoices/Invoice")
);
const InvoicesTaxes = lazy(
  () => import("../../components/Roles/Client/Invoices/Taxes")
);
const InvoicesList = lazy(
  () => import("../../components/Roles/Client/Invoices")
);
const AllInvoices = lazy(
  () => import("../../components/Roles/Client/Invoices/Invoices")
);
const InvoiceHandler = lazy(
  () => import("../../components/Roles/Client/Invoices/InvoiceHandler")
);
const InvoicePreview = lazy(
  () => import("../../components/Roles/Client/Invoices/Invoice")
);

export const Invoices = [
  {
    path: "/app/invoices",
    component: InvoicesList,

    routes: [
      {
        path: "/app/invoices/edit/:invoiceId",
        component: InvoiceHandler,
      },
      {
        path: "/app/invoices/create-invoice",
        component: InvoiceHandler,
      },
      {
        path: "/app/invoices/preview/:invoiceId",
        component: InvoicePreview,
      },
      {
        path: "/app/invoices/all-invoices",
        component: AllInvoices,
      },
      {
        path: "/app/invoices/items",
        component: InvoicesItems,
      },
      {
        path: "/app/invoices/taxes",
        component: InvoicesTaxes,
      },
      {
        path: "/app/invoices/templates",
        component: TemplatesInvoices,
      },

      {
        path: "/app/invoices/:invoiceId",
        component: Invoice,
      },
    ],
  },
];
