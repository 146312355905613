import { FormHelperText } from "@material-ui/core";
import React from "react";
import MyTextField from "../../../../../System/FormComponents/MyTextField";

const Comment = ({ prefix = "", control, formState }) => {
  const rules: any = {
    required: true,
    maxLength: 350,
  };

  return (
    <div>
      <MyTextField
        control={control}
        label="Comment"
        name={prefix + "comment"}
        fullWidth
        rules={rules}
        multiline
        rows={2}
      />

      {formState?.errors[prefix + "comment"]?.type === "required" && (
        <FormHelperText error>Field is required</FormHelperText>
      )}

      {formState?.errors[prefix + "comment"]?.type === "maxLength" && (
        <FormHelperText error>
          Maximum line length 350 characters
        </FormHelperText>
      )}
    </div>
  );
};

export default Comment;
